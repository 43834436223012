// # Imports

import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import * as R from 'ramda';
import L from 'leaflet';
import fr from 'date-fns/locale/fr';

import CollapsibleSection from '../CollapsibleSection/CollapsibleSection';
import clock from '../../_helpers/clock';
import { historicalActions } from '../../_modules/historical/historical.actions';
import MissionDetails from '../MissionDetails';
import Paginator from '../Paginator.jsx';
import { windowService } from '../../_services/window.service';
import { permissionService } from '../../_services/permission.service';

// # Assets
import loadingImage from '../../assets/images/sis-loading.gif';
import ArrowRight from '../../assets/icons/arrow-right.svg';
import HistoricalReport from '../../assets/icons/icon_historical-report.svg';
import './historical-sidebar.scss';
import 'react-datepicker/dist/react-datepicker.css';
import ProgressSpinner from '../ProgressSpinner';
import VideoPlayer from '../Video/MP4VideoPlayer';
import { historicalService } from '../../_services/historical.service';

export default (props) => {
	const isOpen = props.sideOpen;
	const intl = useIntl();
	const [savedState, setSavedState] = useState(
	sessionStorage.getItem('c4:historical-search') 
	? 
	JSON.parse(sessionStorage.getItem('c4:historical-search'))
	: {
		startDate: (new Date(Date.now() - 12096e5)).toJSON(),
		endDate: (new Date()).toJSON(),
		searchText: "",
		searchResults: [],
		coords: "",
		mapHasPolygons: false
	});	
	const locale = localStorage.getItem('sisLang') || 'en';
	registerLocale('fr', fr);

	// # Global State

	const searchResults = useSelector((state) => state.historical.results);

	const dispatch = useDispatch();

	//# Local State

	const [selectedMission, setSelectedMission] = useState({ id: 'NA' });
	const [searchOpen, setSearchOpen] = useState(props.missions.length < 1);
	const [maphasPolygon, setMayHasPolygon] = useState(savedState.mapHasPolygons);
	const [searchText, setSearchText] = useState(savedState.searchText);
	const [startDate, setStartDate] = useState(new Date(savedState.startDate));
	const [endDate, setEndDate] = useState(new Date(savedState.endDate));
	const [coords, setCoords] = useState(savedState.coords);
	const [searchDone, setSearchDone] = useState(false);

	useEffect(() => {
		if(savedState.searchResults.length > 0)
			performSearch();
	}, [])

	 useEffect(() => {
		 if(props.map !== null && savedState.mapHasPolygons) {
			let latlngs = coords.split(" ").map(coord => coord.split(",")).map(coord => [Number(coord[0]), Number(coord[1])]);
			L.polygon(latlngs).addTo(props.map.polygonLayers);
		 }
	 }, [props.map]);

	// # Effects

	useEffect(() => {
		setSelectedMission(
			props.missions[0] || {
				id: 'NA'
			}
		);
	}, [props.missions]);

	useEffect(() => {
		saveState();
	}, [searchText, startDate, endDate, coords, searchDone, locale, searchOpen, searchResults.length])

	let missionsLoading = useSelector(state => state.historical.loading);

	// # Funcs

	function saveState() {
		const newState = {
			...savedState,
			endDate: endDate,
			startDate: startDate,
			searchText: searchText,
			searchResults: searchResults,
			coords: coords,
			mapHasPolygons: maphasPolygon
		};
		sessionStorage.setItem('c4:historical-search', JSON.stringify(newState));
		setSavedState(newState);
	}

	const zoomTo = (mission) => {
		let tempMarkers = [];
		if (mission.nav.data.length !== 0) {
			for (let i = 0; i < mission.nav.data.length; i++) {
				mission.nav.positions[mission.nav.data[i].id].coords.map(
					(coord) => {
						tempMarkers.push([coord[1], coord[0]]);
					}
				);
			}
		}
		if (mission.ais.data.length !== 0) {
			for (let i = 0; i < mission.ais.data.length; i++) {
				mission.ais.positions[mission.ais.data[i].id].coords.map(
					(coord) => {
						tempMarkers.push([coord[1], coord[0]]);
					}
				);
			}
		}
		if (mission.radar.data.length !== 0) {
			for (let i = 0; i < mission.radar.data.length; i++) {
				mission.radar.positions[mission.radar.data[i].id].coords.map(
					(coord) => {
						tempMarkers.push([coord[1], coord[0]]);
					}
				);
			}
		}
		if (mission.manual.data.length !== 0) {
			for (let i = 0; i < mission.manual.data.length; i++) {
				mission.manual.positions[mission.manual.data[i].id].coords.map(
					(coord) => {
						tempMarkers.push([coord[1], coord[0]]);
					}
				);
			}
		}

		const bottomPad =
			window
				.getComputedStyle(
					document.getElementById('layout-bottom-bar-ctn')
				)
				.getPropertyValue('bottom') === '0px'
				? document.getElementById('layout-bottom-bar-ctn').clientHeight
				: 0;

		const sidePad = document.getElementById('layout-side-bar').clientWidth;

		if (tempMarkers.length !== 0) {
			const tempBounds = L.latLngBounds(tempMarkers);
			props.map.flyToBounds(tempBounds, {
				paddingBottomRight: [0, bottomPad],
				paddingTopLeft: [sidePad, 0]
			});
		}
	};

	const formatDate = (date) => {
		let d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	};

	const getGeoSpatialCoordinates = (latlngs) => {
		const coords = latlngs.map((item) => {
			return `${item.lat},${item.lng}`;
		});

		return coords.length > 0 ? [...coords, coords[0]].join(' ') : '';
	};

	const clearPolygon = () => {
		props.map.polygonDrawer.disable();
		props.map.polygonLayers.clearLayers();
		setMayHasPolygon(false);
		setCoords('');
	};

	const drawPolygon = () => {
		if (props.map.measureControl.enabled) props.map.measureControl.toggle();
		props.map.on('draw:created', function (e) {
			var type = e.layerType,
				layer = e.layer;

			// Do whatever you want with the layer.
			// e.type will be the type of layer that has been draw (polyline, marker, polygon, rectangle, circle)
			// E.g. add it to the map
			layer.addTo(props.map.polygonLayers);

			props.map.polygonDrawer.disable();
			setMayHasPolygon(true);

			setCoords(getGeoSpatialCoordinates(layer.getLatLngs()[0]));
		});
		props.map.polygonDrawer.enable();
	};

	const openReportWindow = (mission) => {
		if (!mission) {
			return;
		}
		windowService.openReportWindow(
			mission.Id,
			mission.Name
		);
	};

	const handleEndChange = (date) => {
		setEndDate(date === null ? new Date() : date);
	};

	const handleStartChange = (date) => {
		setStartDate(date === null ? new Date(Date.now() - 12096e5) : date);
	};

	const handleSearchNameChange = (e) => {
		setSearchText(e.target.value.trimLeft());
	};

	function performSearch() {
		const search = {
			searchText,
			startDate: formatDate(startDate),
			endDate: formatDate(endDate),
			coords
		};
		dispatch(historicalActions.searchMissions(search));
		setSearchDone(true);
	}

	function handleSearch(e) {
		e.preventDefault();
		e.stopPropagation();
		performSearch();
	}

	function handleMissionSelection(item) {
		dispatch(historicalActions.selectMission(item));
		setSearchOpen(false);
		// clock.start();
	}

	function clearSearchResults(e) {
		e.preventDefault();
		e.stopPropagation();
		const search = {
			searchText: "",
			startDate: formatDate(new Date(Date.now() + 12096e5)),
			endDate: formatDate(new Date()),
			coords: ""
		};
		dispatch(historicalActions.searchMissions(search));
		setStartDate(new Date(Date.now() - 12096e5));
		setEndDate(new Date());
		setSearchText("");
		clearPolygon();
		setSearchDone(false);
	}

	const resultsList = searchResults.map((item, key) => (
		<li
			key={key}
			onClick={() => handleMissionSelection(item)}
			className={classNames('search-results-item', {
				'search-results-item-alt': key % 2 !== 0
			})}
		>
			<div>{item.Name}</div>

			{permissionService.hasFeature('Reports') && (
				<div className="history-search-results-right">
					<button
						type="button"
						id={'reports-' + (item || {}).Id}
						onClick={() => {
							openReportWindow(item);
						}}
						title={intl.formatMessage({
							id:
								'app.map.sidebar.missionselection.reports.tooltip'
						})}
					>
						<img
							alt={intl.formatMessage({
								id:
									'app.map.sidebar.missionselection.reports.button'
							})}
							src={HistoricalReport}
						/>
					</button>
				</div>
			)}
		</li>
	));

	const missionList = R.values(props.missions).map((item, key) => {
		if (item.metadata && item.aircraft && item.crew && item.profile) {
			return (
				<MissionDetails
					key={item.id}
					sectionOpen={!searchOpen}
					idx={key}
					zoomTo={(e, item) => {
						e.preventDefault();
						e.stopPropagation();
						zoomTo(item);
					}}
					toggleVisibility={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
					item={item}
					mode="historical"
					showVideoPlayer={props.showVideoPlayer}
					searchOpen={searchOpen}
				/>
			);
		}
	});

	const SectionHeader = () => {
		return (
			<div id={'historical-search'} className="section-header">
				<div className="left">
					<span>
						<img src={ArrowRight} />
					</span>
					{intl.formatMessage({
						id: 'app.map.historical.search.header'
					})}
				</div>
			</div>
		);
	};

	return (
		<div id="hist-side-bar-ctn" className="hist-side-bar-container">
			<CollapsibleSection
				key={selectedMission.id}
				sectionOpen={searchOpen}
				onClick={(isOpen) => {
					setSearchOpen(isOpen);
				}}
				sectionHeader={() => {
					return <SectionHeader />;
				}}
			>
				<div id="hist-search-ctn" className="search-container">
					<form
						id="hist-search-form"
						name="searchForm"
						onSubmit={handleSearch}
					>
						<div className="search-name-container">
							<label
								htmlFor="nameSearch"
								className="search-name-label"
							>
								<FormattedMessage
									id="app.map.historical.search.name.label"
									defaultMessage="Mission Name"
								/>
							</label>

							<input
								id="hist-search-name"
								type="text"
								name="searchName"
								value={searchText}
								maxLength="64"
								onChange={handleSearchNameChange}
								className="search-name-input"
								placeholder={intl.formatMessage({
									id:
										'app.map.historical.search.name.placeholder'
								})}
								autoComplete="off"
							/>
						</div>

						<label className="search-date-label">
							<FormattedMessage
								id="app.map.historical.search.date.label"
								defaultMessage="Date Range"
							/>
						</label>
						<div className="search-date-container">
							<div className="search-date-from-container">
								<div className="search-date-from">
									<DatePicker
										name="startDate"
										selected={startDate}
										onChange={handleStartChange}
										maxDate={endDate}
										strictParsing
										locale={locale}
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
									/>
								</div>
							</div>
							<div className="search-date-to-container">
								<div className="search-date-to-label">
									<FormattedMessage
										id="app.map.historical.search.date.to.label"
										defaultMessage="To"
									/>
								</div>
								<div className="search-date-to">
									<DatePicker
										name="endDate"
										selected={endDate}
										onChange={handleEndChange}
										maxDate={new Date()}
										strictParsing
										locale={locale}
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
										popperPlacement="top-end"
										popperModifiers={{
											offset: {
											  enabled: true,
											},
											preventOverflow: {
											  enabled: true,
											  escapeWithReference: false,
											  boundariesElement: "viewport"
											}
										}}
									/>
								</div>
							</div>
						</div>
						<div className="search-geospatial-container">
							<label className="search-geospatial-label">
								<FormattedMessage
									id="app.map.historical.search.area.label"
									defaultMessage="Geospatial Area"
								/>
							</label>

							<div className="geospatial-container">
								<button
									id={'geospatial-draw'}
									type="button"
									className="area-btn"
									disabled={maphasPolygon}
									title={intl.formatMessage({
										id:
											'app.map.general.geospatialtool.button.tooltip'
									})}
									onClick={drawPolygon}
								>
									<FormattedMessage
										id="app.map.historical.search.area.draw.button"
										defaultMessage="Draw Area"
									/>
								</button>
								<button
									id={'geospatial-clear'}
									type="button"
									title={intl.formatMessage({
										id:
											'app.map.historical.search.area.clear.button.tooltip'
									})}
									disabled={!maphasPolygon}
									onClick={clearPolygon}
									className="area-btn"
								>
									<FormattedMessage
										id="app.map.historical.search.area.clear.button"
										defaultMessage="Clear Area"
									/>
								</button>
							</div>
						</div>
						<div 
							id='hist-search-btn-container'
							className="search-btn-container"
						>
							<button
								id="hist-search-default-btn"
								type="submit"
								name="hist-search-submit"
								className="search-btn hist-search-form-submit-btn"
								onClick={() => setSearchDone(false)}
							>
								<FormattedMessage
									id="app.map.historical.search.submit.button"
									defaultMessage="Search"
								/>
							</button>
							<div className={missionsLoading?"missions-loading":"missions-loaded"}><ProgressSpinner/></div>
						</div>
					</form>

					<Paginator
						data={resultsList}
						itemsPerPage={parseInt(
							process.env.REACT_APP_PAGINATION_LIMIT
						)}
						key={searchText}
						showNoResultsFound={false}
					>
						{(
							currentPageNumber,
							totalPages,
							page,
							firstPage,
							lastPage,
							nextPage,
							prevPage
						) => {
							return (
								<>
									<ul
										id="hist-search-results-list"
										className="search-results-list"
									>
										{page}
									</ul>
									{resultsList.length > 0 &&  (
										<>
										<div 
											id="hist-search-results-reset-button-spacer"
											className="search-result-reset-button-spacer"
										></div>
										<a 
											id="hist-search-results-reset-button"
											className="search-results-reset-button"
											onClick={e => clearSearchResults(e)}
										>
											<FormattedMessage
												id={
													'app.map.historical.results.button.reset'
												}
											/>
										</a>
										</>
									)}
									
									{searchDone && !missionsLoading && resultsList.length < 1 && totalPages < 1 && (
										<div className="no-results-statement">
											<FormattedMessage
												id={
													'app.map.historical.results.header.nothing'
												}
											/>
										</div>
									)}
									{totalPages > 1 && (
										<div className="pagination-btn-container">
											<div className="pagination-btn-left">
												<button
													className="btn-first"
													id={'hist-results-first'}
													title={intl.formatMessage({
														id:
															'app.pagination.first.button.tooltip'
													})}
													disabled={
														currentPageNumber === 1
													}
													onClick={firstPage}
												>
													{intl.formatMessage({
														id:
															'app.pagination.first.button'
													})}
												</button>
												<button
													className="btn-prev"
													id={'hist-results-prev'}
													title={intl.formatMessage({
														id:
															'app.pagination.previous.button.tooltip'
													})}
													disabled={
														currentPageNumber === 1
													}
													onClick={prevPage}
												></button>
											</div>
											<div className="pagination-btn-right">
												<button
													className="btn-next"
													id={'hist-results-next'}
													disabled={
														totalPages ===
														currentPageNumber
													}
													title={intl.formatMessage({
														id:
															'app.pagination.next.button.tooltip'
													})}
													onClick={nextPage}
												></button>
												<button
													onClick={lastPage}
													id={'hist-results-last'}
													className="btn-last"
													title={intl.formatMessage({
														id:
															'app.pagination.last.button.tooltip'
													})}
													disabled={
														totalPages ===
														currentPageNumber
													}
												>
													{intl.formatMessage({
														id:
															'app.pagination.last.button'
													})}
												</button>
											</div>
										</div>
									)}
								</>
							);
						}}
					</Paginator>
				</div>
			</CollapsibleSection>
			{missionList}
			{/* TODO: Add close button for sidebar - Erin is designing a new one */}
			<div
				id="layout-side-bar-btn"
				className="side-bar-button-container"
			></div>
		</div>
	);
};
