import React, { useEffect } from 'react';
import { useState } from 'react';

import { mediaService } from '../../_services/media.service';

import './mp4-video-player.scss';

export default function MP4VideoPlayer(props) {
    const autoPlay = props.autoPlay !== undefined ? props.autoPlay : false;
    const [sourceURL, setSourceURL] = useState(props.source || undefined);
    const [createdURL, setCreatedURL] = useState(false);
    const hasSource = sourceURL !== undefined;
    
    useEffect(() => {
        if(hasSource && sourceURL.id !== undefined) {
            mediaService.getMedia(sourceURL.id)
            .then(res => {
                if(res.status === 200) {
                    return res.body;
                } else
                    return null;
            })
            .then(stream => new Response(stream))
            .then(res => res.blob())
            .then(blob => {
                if(blob.size !== 0) {
                    const fileBlob = new Blob([blob], {type: sourceURL.mimeType});
                    const url = window.URL.createObjectURL(fileBlob);
                    setSourceURL(url);
                    setCreatedURL(true);
                }
            });
        }
    }, []);
    
    useEffect(() => {
        if(props.closing && createdURL) {
            window.URL.revokeObjectURL(sourceURL);
            setCreatedURL(false);
        }
    }, [props.closing]);

    return (<div id="mp4-video-player-container" className="mp4-video-player-ctn">        
        {hasSource && (<video
            className="mp4-video-player"
            id="mp4-video-player"
            controls
            controlsList="nodownload"
            disablePictureInPicture
            width={props.width || 256}
            autoPlay={autoPlay ? "autoPlay" : ""}
            src={sourceURL}
        />)}
    </div>);
}