import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { realtimeConstants } from './realtime.constants';

export const initialState = {
	missions: [],
	time: {
		availableTimes: [],
		currentIndex: -1
	},
	mediaAssc: [],
	selectedTracks: [],
	lastPollTime: new Date().getTime()
};

export function realtime(state = initialState, action) {
	switch (action.type) {
		case realtimeConstants.REALTIME_POLLING_SUCCESS:
			const missions = R.map((mission) => {
				const index = R.findIndex(
					R.propEq('id', mission.id),
					state.missions
				);

				if (index >= 0) {
					mission.isShown = state.missions[index].isShown;
				}
				return mission;
			}, action.missionList);
			//gets the updated selectedTracks values from the missions
			let selectedTracks = state.selectedTracks;
			const selectedTrack = selectedTracks[0];
			if (selectedTrack !== undefined) {
				missions.forEach((mission) => {
					const dataList = mission[selectedTrack.collectionId].data;
					if (dataList !== undefined) {
						for (let dataIndex in dataList) {
							const data = dataList[dataIndex];
							if (data.id === selectedTrack.id) {
								selectedTracks[0] = {
									...selectedTrack,
									...data
								};
								break;
							}
						}
					}
				});
			}
			return {
				...state,
				missions,
				time: action.time,
				selectedTracks,
				lastPollTime: new Date().getTime()

				//selectedTracks: [...state.selectedTracks]
			};

		case realtimeConstants.REALTIME_POLLING_FAILURE:
			if (state.missions.length > 0) {
				return state;
			}
			return initialState;
		case realtimeConstants.REALTIME_SELECT_TRACK:
			return {
				...state,
				// missions: [...state.missions],
				// mediaLinks: [...state.mediaLinks],
				selectedTracks: action.tracks
			};
		case realtimeConstants.REALTIME_SELECT_TRACK_FAILURE:
			return state;
		case realtimeConstants.REALTIME_MEDIA_SUCCESS:
			return {
				...state,
				//missions: [...state.missions],
				mediaAssc: action.mediaLinks
				//selectedTracks: [...state.selectedTracks]
			};

		case realtimeConstants.REALTIME_MEDIA_FAILURE:
			return state;
		case realtimeConstants.REALTIME_MISSION_SHOWN_TOGGLE:
			return {
				...state,
				missions: R.update(
					R.findIndex(
						R.propEq('id', action.mission.id),
						state.missions
					),
					action.mission,
					state.missions
				)
			};
		case realtimeConstants.REALTIME_DESELECT_TRACK:
			return {
				...state,
				selectedTracks: []
			};

		default:
			return state;
	}
}
