// # Imports

import * as R from 'ramda';
import SearchBox from './Search/SearchBox.jsx';

import React from 'react';
import { injectIntl } from 'react-intl';

class SearchFilter extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			searchText: ''
		};
	}

	// # Methods

	handleSearchChange = (text) => {
		this.setState((prevState) => {
			return R.assoc('searchText', text, prevState);
		});
	};

	// # Lifecycle

	render() {
		const intl = this.props.intl;
		return (
			<>
				<SearchBox
					searchText={this.state.searchText}
					placeholder={
						this.props.placeholder ||
						intl.formatMessage({ id: 'app.map.search.placeholder' })
					}
					onSearchTextChanged={this.handleSearchChange}
					onDoSearch={() => {}}
				/>
				{this.props.children({
					data: this.props.onSearch(
						this.state.searchText,
						this.props.data
					),
					searchText: this.state.searchText
				})}
			</>
		);
	}
}

export default injectIntl(SearchFilter);
