import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

// internals
import { permissionService } from '../_services/permission.service';
import { LoggingIn } from './LoggingIn';
import { ErrorPage } from './ErrorPage';
import { useSelector } from 'react-redux';

function supportsSection(sectionObj) {
	if(sectionObj === undefined)
		return true;
	return sectionObj.allowedFeatures.some(permissionService.hasFeature);
}

export const AuthRoute = ({ component: Component, section, ...rest }) => {
	const { isAuthenticated, isLoading, logout, error } = useAuth0();
	const loggedIn = useSelector(state => state.authentication.loggedIn);
	return (<Route
		{...rest}
		render={props => {
			if(error) {
				return (<ErrorPage />);
			} else if(isLoading) {
				return (<LoggingIn />);
			} else if(isAuthenticated) {
				if(!loggedIn)
					return (<LoggingIn />);
				else if(supportsSection(section)) {
					return (<Component {...props} />);
				}
				logout();
				return (<Redirect
						to={{ pathname: '/login', state: { from: props.location } }}
				/>);
			} else {
				return (<Redirect
					to={{ pathname: '/login', state: { from: props.location } }}
				/>);
			}
		}}
	/>);
};
