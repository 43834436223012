// import { mapConstants } from './map.constants';

const initialState = [
	{ id: 1, name: 'app.map.realtime.header', type: 'leaflet' },
	{ id: 2, name: 'app.map.historical.header', type: 'leaflet' },
];

export function maps(state = initialState, action) {
	switch (action.type) {
		   
		default:
			return state;
	}
}
