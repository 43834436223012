// # Imports
// External Imports
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

// Internal Imports

// Assets
import { ReactComponent as NotifySuccess } from '../../assets/icons/icon_alert-success.svg';
import { ReactComponent as NotifyError } from '../../assets/icons/icon_alert-error.svg';
import { ReactComponent as NotifyWarning } from '../../assets/icons/icon_alert-warning.svg';
import { ReactComponent as NotifyInfo } from '../../assets/icons/icon_alert-info.svg';
import { ReactComponent as Close } from '../../assets/icons/close-dialog.svg';
import './alert.scss';

export default function Alert(props) {
	const intl = useIntl();

	useEffect(() => {
		if (props.action === undefined) {
			setTimeout(() => {
				props.onClose(props.timeStamp);
			}, 6000);
		}
	}, []);

	const levelIcon = (level) => {
		switch (level) {
			case 'success':
				return (
					<NotifySuccess
						alt="Success"
						id="alert-icon-success"
					/>
				);
			case 'error':
				return <NotifyError alt="Error" id="alert-icon-error" />;
			case 'warning':
				return (
					<NotifyWarning
						alt="Warning"
						id="alert-icon-warning"
					/>
				);
			default:
				return <NotifyInfo alt="Info" id="alert-icon-info" />;
		}
	};

	return (
		<div id='alert-area-container' className={`alert-area-container ${props.action!==undefined ? 'alert-area-container-shaded' : ''}`}>
			<div className={'alert-container alert-level-' + props.level}>
				<div className="alert-icon">{levelIcon(props.level)}</div>
				<div className="alert-message">
					{intl.formatMessage({
						id: props.messageId
					})}
				</div>

				<div className="alert-actions">
					{props.action !== undefined && (
						<div className="alert-btn-container">
							<button
								id={'alert-ok-btn'}
								type="button"
								title={intl.formatMessage({
									id: 'app.common.button.ok.tooltip'
								})}
								onClick={() => {
									props.action();
									props.onClose(props.timeStamp);
								}}
								className="alert-btn"
							>
								{intl.formatMessage({
									id: 'app.common.button.ok.tooltip'
								})}
							</button>
							<button
								id={'alert-cancel-btn'}
								type="button"
								title={intl.formatMessage({
									id: 'app.common.button.cancel.tooltip'
								})}
								onClick={() => {
									if(props.onCancel) 
										props.onCancel();
									props.onClose(props.timeStamp);
								}}
								className="alert-btn"
							>
								{intl.formatMessage({
									id: 'app.common.button.cancel.tooltip'
								})}
							</button>
						</div>
					
				)}
				{props.action === undefined && (
					<button
						className="alert-close-btn"
						title={intl.formatMessage({
							id: 'app.common.button.close.tooltip'
						})}
						onClick={() => {
							// props.onCancel(false, 'close');
						}}
					>
						<Close
							id="alert-close-btn"
							className="alert-close-icon"
							title={intl.formatMessage({
								id: 'app.common.button.close.tooltip'
							})}
							onClick={() => {
								props.onClose(props.timeStamp);
							}}
						/>
					</button>
				)}
				</div>
			</div>
		</div>
	);
}
