// # Imports
// External Imports
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import * as R from 'ramda';
import L, { control } from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-rotatedmarker';
import 'leaflet-draw';
import 'leaflet-rotatedmarker';
import 'leaflet-timedimension';
import '../Leaflet/measure-control';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-timedimension/dist/leaflet.timedimension.control.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';

// Internal Imports
import OverlaySidebar from '../Overlays/OverlaySidebar';
import HistoricalSidebar from '../Historical/HistoricalSidebar';
import RealTimeSidebar from '../RealTime/RealTimeSidebar';
import { historicalActions } from '../../_modules/historical/historical.actions';
import { realtimeActions } from '../../_modules/realtime/realtime.actions';
import MapControls from '../MapContainer/MapControls';
import { navTarget } from '../Historical/Leaflet.NavTarget';
import { realtimeTrack } from '../Historical/Leaflet.Realtime.Track';
import { historicalTrack } from '../Historical/Leaflet.Historical.Track';
import TrackInspector from '../TrackInspector/TrackInspector';
import TrackGrid from '../TrackGrid/TrackGrid';
import {AutoSymbolizer} from '../../_helpers/auto-symbolizer.js';
import sisConfig from '../../sis.config.json';
import { triggerResize } from '../../_helpers/trigger-resize';
import VideoContainer from '../Video/VideoContainer';
import DraggableModalContainer from '../Video/DraggableModalContainer';
import { useAsyncState } from '../../_helpers/state';
import { realtime } from '../../_modules/realtime/realtime.reducer';
import { mediaService } from '../../_services/media.service';
import { alertActions } from '../../_modules/alerts/alert.actions';

// Assets
import Geolocation from '../../assets/icons/NM-LocationTarget.svg';
import { ReactComponent as BottomBarClose } from '../../assets/icons/icon_close-bottom-panel.svg';
import { ReactComponent as BottomBarOpen } from '../../assets/icons/icon_open-bottom-panel.svg';
import { ReactComponent as SideBarOpen } from '../../assets/icons/icon_open-left-panel.svg';
import { ReactComponent as SideBarClose } from '../../assets/icons/icon_close-left-panel.svg';
import '../../styles/leaflet.css';
import 'react-datepicker/dist/react-datepicker.css';
import './home.scss';
import './../MapContainer/map-container.scss';
import './../MapContainer/map.css';
import RealTimeTrackMedia from '../RealTime/RealTimeTrackMedia';

function Home() {
	
	const [savedState, setSavedState] = useState(
		sessionStorage.getItem('c4-state:home') 
		? 
		JSON.parse(sessionStorage.getItem('c4-state:home'))
		: {
				chatControlBoxOpen: false,
        mapOptions: {
					shouldShowLabels: true, shouldShowLabelsPrev: true,
					iconSize: 24, iconSizePrev: 24
				}
		  });

	const [downloadMediaAccess, setDownloadMediaAccess] = useState(false);
	const downloadMediaRequestOff = () => {
		setDownloadMediaAccess(false)
    }

	const downloadMediaRequestOn = () => {
		setDownloadMediaAccess(true)
    }

	const [downloadMediaAccessTrackGrid, setDownloadMediaAccessTrackGrid] = useState(true)
	const downloadMediaRequestOffTrackGrid = () => {
		setDownloadMediaAccessTrackGrid(false)
    }


	const intl = useIntl();
	const dispatch = useDispatch();

	// For the group chat close
	useEffect(() => {
		const closeGroupChat = jid => window._converse.chatboxviews.get(jid).close();

		const handleCloseGroupChat = event => {
			if(event.target.classList.contains('close-room')) {
				event.preventDefault();
				const jid = event.target.getAttribute('data-room-jid');
				const confirmCloseGroupChatAlert = {
					level: 'warning',
					messageId:
						'app.notification.converse.warning.groupchat.close',
					timeStamp: new Date(),
					action: () => closeGroupChat(jid) 
				};
				dispatch(alertActions.addAlert(confirmCloseGroupChatAlert));
			}
		};

		window.addEventListener('click', handleCloseGroupChat);

		return () => window.removeEventListener('click', handleCloseGroupChat);
	}, []);

	// we need to localize the measuring tool
	L.drawLocal.draw.handlers.polyline.tooltip.start = intl.formatMessage({
		id: 'app.map.draw.measure.start'
	});
	L.drawLocal.draw.handlers.polyline.tooltip.cont = intl.formatMessage({
		id: 'app.map.draw.measure.cont'
	});
	L.drawLocal.draw.handlers.polyline.tooltip.end = intl.formatMessage({
		id: 'app.map.draw.measure.end'
	});
	L.drawLocal.draw.handlers.polygon.tooltip.start = intl.formatMessage({
		id: 'app.map.draw.shape.start'
	});
	L.drawLocal.draw.handlers.polygon.tooltip.cont = intl.formatMessage({
		id: 'app.map.draw.shape.cont'
	});
	L.drawLocal.draw.handlers.polygon.tooltip.end = intl.formatMessage({
		id: 'app.map.draw.shape.end'
	});

	// Retrieve the config and mode from the location
	const location = useLocation();
	let currPath = location.pathname;
	let mode = currPath.slice(1);
	let modeConfig = sisConfig.sections.find((x) => x.id === mode);

	const selectedTracks = useSelector((state) =>
		mode === 'historical'
			? state.historical.selectedTracks
			: state.realtime.selectedTracks
	);

	// If mode has polling, start, restart, or end the polling
	// TODO: replace RealtimeActions with generic polling service or store actions
	if (modeConfig.polling) {
		realtimeActions.startPoll(dispatch);
	} else {
		realtimeActions.endPoll();
	}

	// # Refs

	const mapRef = useRef(null);
	const selectedMarkers = useRef({});
	const markers = useRef({});

	const timeDimensionLayers = useRef({});
	const geoJSONLayers = useRef({});
	const icons = useRef({});

	const spiderfiedTracks = useRef({});

	const _dblClickTimer = useRef(null);
  
	const showLabelOnHover = useRef(false);

	window.mapRef = mapRef;

	// # Local State
	const [videoStreamTitle, setVideoStreamTitle] = useState("Spring");
	const [videoStreamSource, setVideoStreamSource] = useState("https://woolyss.com/f/spring-vp9-vorbis.webm");
	const [videoStreamType, setVideoStreamType] = useState("stream");
	const [showVideoStream, setShowVideoStream] = useAsyncState(false);
	const [bottomOpen, setBottomOpen] = useState(true);
	const [sideOpen, setSideOpen] = useState(true);
	const [mapReady, setMapReady] = useState(false);
	const [manageOverlays, setManageOverlays] = useState(false);
	const [focusMarker, setFocusMarker] = useState(false);
	const [chatControlBoxOpen, setChatControlBoxOpen] = useState(savedState.chatControlBoxOpen);
	const markerTarget = useRef(L.featureGroup());

  const [mapOptions, setMapOptions] = useState(savedState.mapOptions);

	function getMaxClusterRadiusForZoom(zoom) {
		if(zoom <= 3) return 120;
		if(zoom <= 4) return 90;
		if(zoom <= 5) return 60;
		if(zoom <= 6) return 40;
		if(zoom <= 7) return 30;
		if(zoom <= 8) return 25;
		return 15;
	}

	function applyMapOptions() {
		const {
			shouldShowLabels, shouldShowLabelsPrev,
			iconSize, iconSizePrev
		} = mapOptions;
		if(shouldShowLabels !== shouldShowLabelsPrev) {
			const labels = Array.from(document.getElementsByClassName("track-label"));
			if(shouldShowLabels) {
				for(let i = 0; i < labels.length; i++) {
					labels[i].classList.remove("track-label-hidden");
				}
			} else {
				for(let i = 0; i < labels.length; i++) {
					labels[i].classList.add("track-label-hidden");
				}
			}
		}
		if(iconSize !== iconSizePrev) {
			Object.keys(markers.current).forEach(trackId => {
				const icon = markers.current[trackId].options.icon;
				icon.options.iconSize = [iconSize, iconSize];
				markers.current[trackId].setIcon(icon);
			})
		}
	}

	if(mapRef.current) {
		mapRef.current.on("zoomend", applyMapOptions);
	}

	useEffect(() => {
		applyMapOptions();
		showLabelOnHover.current = !mapOptions.shouldShowLabels;
		const newSavedState = {...savedState, mapOptions};
		sessionStorage.setItem('c4-state:home', JSON.stringify(newSavedState));
    setSavedState(newSavedState);
	}, [mapOptions]);

	// # Global state
	const time = useSelector((state) => {
		return mode === 'historical'
			? state.historical.time
			: state.realtime.time;
	});
	const missions =
		useSelector((state) => {
			return mode === 'historical'
				? state.historical.selectedMissions
				: state.realtime.missions;
		}) || [];
	
	const profile = useSelector((state) => state.profile);
	const lastPollTime = useSelector((state) => {
		return state.realtime.lastPollTime;
	});

	// # Processed state

	// Sometimes we want to see only certain missions
	const shownMissions = missions.filter((mission) => {
		return mission.isShown === true;
	});

	const controlBox = document.getElementById("controlbox");
	function reopenChatControlBox(controlBox) {
		if(controlBox !== null) {
			document.getElementById("nav-bar-action-chat-item").onclick = (e) => {
				const newState = {
					...savedState,
					chatControlBoxOpen: controlBox.classList.contains("hidden")
				};
				sessionStorage.setItem('c4-state:home', JSON.stringify(newState));
				setSavedState(newState);
			};
			if(chatControlBoxOpen && controlBox.classList.contains("hidden"))
				controlBox.classList.toggle('hidden');
		}
	}
	
	function isLatLngInView(latlng) {
		const navBar = document.getElementById("nav-bar-ctn");
		const sideBar = document.getElementById("layout-side-bar");
		const bottomBar = document.getElementById("layout-bottom-bar-ctn");
		if(mapRef.current) {
			if(sideBar && bottomBar && navBar) {
				let leftBound = sideOpen ? sideBar.clientWidth : 0;
				let rightBound = window.innerWidth;
				let topBound = 0;
				let bottomBound = bottomOpen ? (window.innerHeight - navBar.clientHeight) - (bottomBar.clientHeight) : window.innerHeight
				const { x, y } = mapRef.current.latLngToContainerPoint(latlng);
				return x >= leftBound && x <= rightBound && y >= topBound && y <= bottomBound;
			}
			return mapRef.current.getBounds().contains(latlng);
		}
		return false;
	}

	// # Effects

	useEffect(() => {
		document.addEventListener('mousemove', () => {
			if(mapRef.current && mapRef.current.measureControl.handler._enabled) {
				mapRef.current.measureControl.handler._mouseMarker.options.zIndexOffset = 10000;
				const measureMarkers = mapRef.current.measureControl.handler._markers;
				if(measureMarkers && measureMarkers.length > 0) {
					measureMarkers.forEach(m => {
						m.options.zIndexOffset = 12000;
						m._zIndex = 12000;
						m._icon.style.zIndex = "12000";
						m._icon.onclick = () => {};
					});
					measureMarkers[measureMarkers.length-1]._icon.onclick = () => mapRef.current.measureControl.toggle();
				}
			}
		});
		spiderfiedTracks.current = [];
	}, []);

	useEffect(() => { 
		reopenChatControlBox(controlBox)
	}, [controlBox]);

	window.onload = () => {
		reopenChatControlBox(document.getElementById("controlbox"));
		if(mapRef.current)
			mapRef.current.on("zoomstart", ()=>{
				if(mapRef.current._zoom === 18 && spiderfiedTracks.current.length > 0) {
					spiderfiedTracks.current.forEach(track => mapRef.current.removeLayer(track));
					deselectAllTracks();
				}
			})
	};

	useEffect(() => {
		if (mode === 'realtime') {
			const spiderfiedTracksSelected = spiderfiedTracks.current.filter(t => selectedTracks.filter(st => st.id === t.track.id).length > 0).length > 0;
			if(!spiderfiedTracksSelected)
				dispatch(realtimeActions.selectTrack(selectedTracks));
			else {
				deselectAllTracks();
				if(mapRef.current)
					mapRef.current.fireEvent('click');
			}
		}
	}, [lastPollTime]);

	useEffect(() => {
		if (
			(!shownMissions.length && mode === 'historical') ||
			mode !== 'historical'
		) {
			R.map((node) => {
				node.style.display = 'none';
			}, document.querySelectorAll('div.leaflet-bar-timecontrol'));
		} else if (mode === 'historical') {
			R.map((node) => {
				node.style.display = 'block';
			}, document.querySelectorAll('div.leaflet-bar-timecontrol'));
			R.map((node) => {
				node.title = intl.formatMessage({
					id: 'app.common.button.date.tooltip'
				});
			}, document.querySelectorAll('.timecontrol-date'));
			R.map((node) => {
				node.title = intl.formatMessage({
					id: 'app.common.button.loop.tooltip'
				});
			}, document.querySelectorAll('.timecontrol-loop'));
			R.map((node) => {
				node.title = intl.formatMessage({
					id: 'app.common.button.forward.tooltip'
				});
			}, document.querySelectorAll('.timecontrol-forward'));
			R.map((node) => {
				node.title = intl.formatMessage({
					id: 'app.common.button.backward.tooltip'
				});
			}, document.querySelectorAll('.timecontrol-backward'));
			R.map((node) => {
				node.title = intl.formatMessage({
					id: 'app.common.button.play.tooltip'
				});
			}, document.querySelectorAll('.timecontrol-play'));
		}

		if (
			R.innerJoin(
				(mission, track) => {
					return mission.id === track.missionId;
				},
				shownMissions,
				selectedTracks
			).length === 0
		) {
			deselectAllTracks();
		}
	}, [mode, shownMissions]);

	// When track selection changes
	useEffect(() => {
		//We want to remove any selection markers that are no longer valid
		R.keys(selectedMarkers.current).forEach((trackId) => {
			if (
				selectedTracks.some((track) => track.id === trackId) ===
					false &&
				mapRef.current !== null
			) {
				mapRef.current.removeLayer(selectedMarkers.current[trackId]);
				delete selectedMarkers.current[trackId];
			}
		});
		
		// Create a new selection marker for any selected Track
		selectedTracks.forEach((track) => {
			if (mapRef.current === null || shownMissions.filter(mission => mission.id === track.missionId).length === 0) {
				deselectAllTracks();
				return;
			}

			const currentMissionTime = mapRef.current.timeDimension.getCurrentTime();
			const availableTimes = mapRef.current.timeDimension.getAvailableTimes();

			// If the current time is set before the track shows up we want to
			// create a target and move the timeline forward.
			if (
				mode === 'historical' &&
				currentMissionTime < track.positions.times[0]
			) {
				const index = R.indexOf(
					track.positions.times[0],
					availableTimes
				);

				mapRef.current.timeDimension.setCurrentTimeIndex(index);
				const latLng = L.latLng(
					track.positions.coords[0][1],
					track.positions.coords[0][0]
				);

				const selectedTrackMarker = L.marker(latLng, {
					icon: L.divIcon({
						className: 'selection-icon',
						html: `<object width="50" type="image/svg+xml" data="${Geolocation}" />`,
						iconSize: L.point(50, 50)
					}),
					zIndexOffset: 1000,
					interactive: false
				});

				mapRef.current.addLayer(selectedTrackMarker);
				
				if(!mapRef.current.getBounds().contains(selectedTrackMarker.getLatLng()))
					mapRef.current.panTo(selectedTrackMarker.getLatLng());

				selectedMarkers.current[track.id] = selectedTrackMarker;
			} else if (
				selectedMarkers.current[track.id] === undefined &&
				timeDimensionLayers.current[track.id] !== undefined
			) {
				const currentTrackTime = R.findLast((time) => {
					return time <= currentMissionTime;
				}, track.positions.times);

				const marker = timeDimensionLayers.current[track.id];

				const index = R.indexOf(
					currentTrackTime,
					track.positions.times
				);

				const latLng = L.latLng(
					track.positions.coords[index][1],
					track.positions.coords[index][0]
				);

				const selectedTrackMarker = L.marker(latLng, {
					icon: L.divIcon({
						className: 'selection-icon',
						html: `<object width="50" type="image/svg+xml" data="${Geolocation}" />`,

						iconSize: L.point(50, 50)
					}),
					zIndexOffset: 1000,
					interactive: false
				});
				selectedMarkers.current[track.id] = selectedTrackMarker;
				selectedTrackMarker.selectedMarker = marker;
				marker.selector = selectedTrackMarker;

				if (markers.current[track.id] !== undefined) {
					markers.current[track.id].selector = selectedTrackMarker;
				}

				mapRef.current.addLayer(selectedTrackMarker);

				if (marker.__parent === undefined) {
					marker.__parent = marker;

					marker._zoom = -1;
				}

				const clusterLayers = mapRef.current.tracksByMission[
					track.missionId
				].tracks.getLayers();

				// Markers and geoJSONLayers aren't being clusted,
				// timeDimensionLayers are, but those layers don't have
				// a __Parent (provided by markerClusterGroup) so weight
				// have to search througth cluster to find the correct
				// layer to zoom to

				const zoomToThis = clusterLayers.reduce((layer, cluster) => {
					if (cluster._availableTimes !== undefined) {
						return track.id === cluster._baseLayer.options.track.id
							? cluster
							: layer;
					}

					return layer;
				}, marker);
				
				const hiddenByCluster = zoomToThis._map === null;

				if (track.collectionId !== 'nav') {
					if(!isLatLngInView(marker.getLatLng()) || hiddenByCluster)
						mapRef.current.tracksByMission[
							track.missionId
						].tracks.zoomToShowLayer(
							mode === 'historical' ? marker : zoomToThis,
							function () {
								const layer = zoomToThis;
								
								const showMarker = function () {
									this._map.off('moveend', showMarker, this);
									this.off('animationend', showMarker, this);
								};

								if (
									layer.__parent._zoom <
										Math.round(this._map._zoom) ||
									mode === 'historical'
								) {
									this._map.on('moveend', showMarker, this);
									this._map.panTo(layer.getLatLng());
								} else {
									this._map.on('moveend', showMarker, this);
									this.on('animationend', showMarker, this);
									layer.__parent.zoomToBounds();
								}
							}.bind(
								mapRef.current.tracksByMission[track.missionId]
									.tracks
							)
						);
				} else {
					if(!isLatLngInView(marker.getLatLng()))
						mapRef.current.tracksByMission[
							track.missionId
						].platforms.zoomToShowLayer(
							mode === 'historical' ? marker : zoomToThis
						);
				}
			}
		});
	}, [selectedTracks]);

	// When the component mounts for the first time
	useEffect(() => {
		window.L_PREFER_CANVAS = true;

		mapRef.current = L.map('map', {
			fullScreenControl: true,
			center: [48.163068, -56.008301],
			zoomControl: false,
			attributionControl: false,
			zoom: 6,
			preferCanvas: true,
			worldCopyJump: true,
			timeDimensionControl: true,
			timeDimensionControlOptions: {
				timeSliderDragUpdate: true,
				loopButton: true,
				autoPlay: false,
				playerOptions: {
					transitionTime: 1000,
					loop: false
				}
			},
			timeDimension: true
		});
		
		mapRef.current.baseLayers = new L.FeatureGroup([
			L.tileLayer(
				profile.baseMapUrl ||
					'https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=odrA8jXK5gV63WS5pSvV',
				{
					tileSize: 512,
					zoomOffset: -1,
					minZoom: 1,
					attributionControl: false,
					zoomControl: false,
					crossOrigin: true
				}
			)
		]).addTo(mapRef.current);
		mapRef.current.polygonLayers = new L.FeatureGroup();
		mapRef.current.addLayer(mapRef.current.polygonLayers);
		mapRef.current.polygonDrawer = new L.Draw.Polygon(mapRef.current);
    mapRef.current.measureControl = L.Control.measureControl({
      handler: {
        multiDistance: true,
        metric: false,
        imperial: false,
        nauticalMiles: true
      }
    }).addTo(mapRef.current);

		const overlayFeatureGroup = L.featureGroup({});

		const layers = R.reverse(profile.overlays)
			.filter((overlay) => {
				return overlay.shown === '1' ? true : false;
			})
			.map((overlay) => {
				const tempLayer = L.tileLayer
					.wms(
						`${sisConfig.build[window.location.hostname].wms}`,
						{
							layers: overlay.name,
							format: 'image/png',
							transparent: true,
							opacity: overlay.opacity / 100
						}
					)
					.addTo(overlayFeatureGroup);
			});
		mapRef.current.overlayFeatureGroup = overlayFeatureGroup;
		mapRef.current.addLayer(overlayFeatureGroup);

		setMapReady(true);

		return function cleanup() {
			realtimeActions.endPoll();
		};
	}, []);

	// Builds the markers for the map
	useLayoutEffect(() => {
		const processNavTargets = (a) => {
			const { times, coords, headings } = a.positions;

			if(times.length == 0)  {
				return null;	
			}

			const geoJSONMarker = {
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: coords
				},
				properties: {
					times,
					headings,
					data: a,
					id: a.id,
					track: a
				}
			};

			const geoJSONLayer = L.geoJSON(geoJSONMarker, {
				style: {
					color: 'red',
					weight: 1,
					opacity: 0.65
				},

				track: a,
				pointToLayer: function (feature, latLng) {
					const id = feature.properties.id;
					const tooltipText =
						a.cid + ' ' + (a.callSign ? a.callSign : '');

					const imageName = 'sis-platform';

					if (feature.properties.hasOwnProperty('last')) {
						const heading = feature.properties.hasOwnProperty(
							'heading'
						)
							? feature.properties.heading
							: 0;

						const clickEvent = (e) => { 
							L.DomEvent.preventDefault(e);
							L.DomEvent.stopPropagation(e);

							e.originalEvent.preventDefault();
							e.originalEvent.stopPropagation();
							
							if(mapRef.current.measureControl.handler._enabled !== true) {
								const currentTrackData = realtimeActions.getCurrentDataForTrack(mode, feature.properties.track);
								selectTracks([currentTrackData]);
							}
						};

						if (id && markers.current[id]) {
							const marker = markers.current[id];

							marker.setLatLng(latLng);
							marker.setRotationAngle(heading);
							if (selectedMarkers.current[id] !== undefined) {
								selectedMarkers.current[id].setLatLng(latLng);

								selectedMarkers.current[
									id
								].selectedMarker = marker;
								marker.selector = selectedMarkers.current[id];

								timeDimensionLayers.current[id].selector =
									selectedMarkers.current[id];

								mapRef.current.addLayer(
									selectedMarkers.current[id]
								);
							}
							return marker;
						}

						const marker = new L.marker(latLng, {
							rotationOrigin: 'center',
							rotationAngle: heading,
							icon: L.icon({
								className: 'top-level-marker',
								origUrl: `/images/${imageName.toLowerCase()}.png`,
								iconUrl: `/images/${imageName.toLowerCase()}.png`,
								iconSize: [mapOptions.iconSize, mapOptions.iconSize],
								scale: 1,
								horizontalOrigin: 1,
								verticalOrigin: 1
							})
						});

            if(marker && marker._icon && !mapOptions.shouldShowLabels)
							marker._icon.classList.add("tack-label-hidden")

						marker.feature = feature;
						marker.on('remove', whenTrackIsNoLongerVisible);

            marker.on('mouseover', event => {
							if(showLabelOnHover.current) 
							{
								const { target } = event;
								if(target) {
									const { _tooltip } = target;
									if(_tooltip) {
										const { _container } = _tooltip;
										if(_container) {
											_container.classList.remove("track-label-hidden");
										}
									}
								}
							}
						});
						marker.on('mouseout', event => {
							if(showLabelOnHover.current) 
							{
								const { target } = event;
								if(target) {
									const { _tooltip } = target;
									if(_tooltip) {
										const { _container } = _tooltip;
										if(_container) {
											_container.classList.add("track-label-hidden");	
										}
									}
								}
							}
						});

						marker.on('click', clickEvent);
						marker.bindTooltip(tooltipText, {
							permanent: true,
							direction: 'right',
							offset: new L.Point(5, -5),
							className: `track-label${!mapOptions.shouldShowLabels?" track-label-hidden":""}`
						});

						if (id) {
							markers.current[id] = marker;
						}

						return marker;
					}
				},
				onEachFeature: (feature, layer) => {}
			});

			const timeDimensionLayer = L.timeDimension.layer.navTarget(
				geoJSONLayer,
				{
					updateTimeDimension: false,
					track: a,
					updateTimeDimensionMode: 'union',
					addlastPoint: true
				}
			);
			geoJSONLayer.timeDimensionLayer = timeDimensionLayer;
			geoJSONLayers.current[a.id] = geoJSONLayer;
			timeDimensionLayers.current[a.id] = timeDimensionLayer;

			return timeDimensionLayer;
		};

		const processTargets = (a) => {
			const { times, coords } = a.positions;

			if (times.length === 0) {
				return null;
			}
			const geoJSONMarker = {
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: coords
				},
				properties: {
					times: times,
					course: a.course,
					track: a,
					id: a.id
				}
			};

			const tooltipText =
				a.cid + ' ' + (a.vesselName ? a.vesselName : '');

			const imageName = AutoSymbolizer.getSymbol(a);

			if (icons.current[imageName] === undefined) {
				icons.current[imageName] = L.icon({
					className: 'bot-level-marker',
					origUrl: `/images/${imageName}`,
					iconUrl: `/images/${imageName}`,
					iconSize: [mapOptions.iconSize, mapOptions.iconSize],

					scale: 1,
					horizontalOrigin: 1,
					verticalOrigin: 1
				});
			}
			const markerOptions = {
				icon: icons.current[imageName]
			};

			const toolTipOptions = {
				permanent: true,
				direction: 'right',
				offset: new L.Point(5, -5),
				className:  `track-label${!mapOptions.shouldShowLabels?" track-label-hidden":""}`
			};
			const geoJSONLayer = L.geoJSON(geoJSONMarker, {
				style: {
					color: 'red',
					weight: 1,
					opacity: 0.65
				},
				track: a,
				pointToLayer: function (feature, latLng) {
					if (feature.properties.hasOwnProperty('last')) {
						const id = feature.properties.id;
						const course = feature.properties.hasOwnProperty('course')
							? feature.properties.course
							: 0;
						const clickEvent = (e) => {
							L.DomEvent.preventDefault(e);
							L.DomEvent.stopPropagation(e);

							e.originalEvent.preventDefault();
							e.originalEvent.stopPropagation();

							if(mapRef.current.measureControl.handler._enabled !== true) {
								const currentTrackData = realtimeActions.getCurrentDataForTrack(mode, feature.properties.track);
								selectTracks([currentTrackData]);
							}
						};

						if (id && markers.current[id] !== undefined) {
							const marker = markers.current[id];
							marker.setLatLng(latLng);
							marker.setIcon(markerOptions.icon);
							marker.setRotationAngle(course);
							if (selectedMarkers.current[id] !== undefined) {
								selectedMarkers.current[id].setLatLng(latLng);
								selectedMarkers.current[
									id
								].selectedMarker = marker;
								marker.selector = selectedMarkers.current[id];
								timeDimensionLayers.current[id].selector =
									selectedMarkers.current[id];

								mapRef.current.addLayer(
									selectedMarkers.current[id]
								);
							}
							return marker;
						}

						const marker = new L.marker(latLng, {
							rotationOrigin: 'center',
							rotationAngle: course,
							icon: markerOptions.icon
						});

            if(marker && marker._icon && !mapOptions.shouldShowLabels)
							marker._icon.classList.add("tack-label-hidden")

						marker.on('mouseover', event => {
							if(showLabelOnHover.current) 
							{
								const { target } = event;
								if(target) {
									const { _tooltip } = target;
									if(_tooltip) {
										const { _container } = _tooltip;
										if(_container) {
											_container.classList.remove("track-label-hidden");
										}
									}
								}
							}
						});
						marker.on('mouseout', event => {
							if(showLabelOnHover.current) 
							{
								const { target } = event;
								if(target) {
									const { _tooltip } = target;
									if(_tooltip) {
										const { _container } = _tooltip;
										if(_container) {
											_container.classList.add("track-label-hidden");	
										}
									}
								}
							}
						});

						marker.on('click', clickEvent);
						marker.bindTooltip(tooltipText, toolTipOptions);
						marker.feature = feature;
						marker.on('remove', whenTrackIsNoLongerVisible);

						if (id) {
							markers.current[id] = marker;
						}

						return marker;
					}
				},
				onEachFeature: (feature, layer) => {}
			});

			const customTrack =
				mode === 'historical' ? historicalTrack : realtimeTrack;

			const timeDimensionLayer = customTrack(geoJSONLayer, {
				updateTimeDimension: false,
				track: a,
				updateTimeDimensionMode: 'union',
				addlastPoint: true
			});

			geoJSONLayer.timeDimensionLayer = timeDimensionLayer;
			geoJSONLayers.current[a.id] = geoJSONLayer;
			timeDimensionLayers.current[a.id] = timeDimensionLayer;

			// Settings these values on timeDimensionLayer below fixes a bug
			// related to track selection and marker clusters. Targeting a track
			// and then removing the mission from the map and re-adding it would
			// cause a marker cluster event to fire that was created before the
			// mission was removed. An object was deleted from the marker
			// representation within the markerclustergroup on removal of the
			// mission so the event fired a function that would look for someting
			// that wasn't there. Adding _icon fixes this, however _icon gets
			// used elsewhere in marker clusters to let marker clusters know
			// the run the three functions that are defined just below _icon.
			// Since _icon is set, we must also now pass in a custom callback
			// function when ever we run zoomToShowLayer on a marker because
			// _icon is used by that function to decide whether or not the
			// marker is visible on the map.
			// 2020-09-17 Thu 02:14 PM
			timeDimensionLayer._icon = true;
			timeDimensionLayer._setPos = () => {};
			timeDimensionLayer.clusterHide = () => {};
			timeDimensionLayer.clusterShow = () => {};

			timeDimensionLayer._latlng = L.latLng(
				a.positions.coords[0][1],
				a.positions.coords[0][0]
			);

			return timeDimensionLayer;
		};

		const tracksByMission = shownMissions.reduce(
			(tracksByMission, mission) => {
				const navTracks = mission.nav.data
					.map(processNavTargets)
					.filter((x) => {
						return x !== null;
					});
				const aisTracks = mission.ais.data
					.map(processTargets)
					.filter((x) => {
						return x !== null;
					});
				const radarTracks = mission.radar.data
					.map(processTargets)
					.filter((x) => {
						return x !== null;
					});

				const manualTracks = mission.manual.data
					.map(processTargets)
					.filter((x) => {
						return x !== null;
					});

				const platforms = L.markerClusterGroup({
					spiderfyOnMaxZoom: true,
					removeOutsideVisibleBounds: false
				});
				const tracks = L.markerClusterGroup({
					chunkedLoading: true,
					spiderfyOnMaxZoom: false,
          maxClusterRadius: getMaxClusterRadiusForZoom,
					spiderfyDistanceMultiplier: 3,
					disableClusteringAtZoom: mode === 'historical' ? 1 : null,
					spiderLegPolylineOptions: { color: "#ff6b6b", dashArray: '5, 5', weight: '1'}
				});

				tracks.on('clusterclick', (a) => {
					if(a.layer._zoom === 18) {
						if(spiderfiedTracks.current.length > 0) {
							mapRef.current.fireEvent('click')
							deselectAllTracks();
							spiderfiedTracks.current = [];
							return;
						}
						a.layer.spiderfy();
						let spiderfiedMarkers = [];
						a.layer._markers.forEach(marker => {
							const imageName = AutoSymbolizer.getSymbol(marker.options.track);
							if (icons.current[imageName] === undefined) {
								icons.current[imageName] = L.icon({
									className: 'bot-level-marker',
									origUrl: `/images/${imageName}`,
									iconUrl: `/images/${imageName}`,
									iconSize: [24, 24],
									scale: 1,
									horizontalOrigin: 1,
									verticalOrigin: 1
								});
							}
							const toolTipOptions = { permanent: true, direction: 'right', offset: new L.Point(5, -5), className: 'track-label' };
							const tooltipText = marker.options.track.cid + ' ' + (marker.options.track.vesselName ? marker.options.track.vesselName : '');
							const tempMarker = L.marker([marker.latlng.lat, marker.latlng.lng], { icon: icons.current[imageName] });
							tempMarker.bindTooltip(tooltipText, toolTipOptions);
							tempMarker.addTo(mapRef.current);
							tempMarker.track = marker.options.track;
							tempMarker.on('click', a => selectTracks([a.target.track]));
							spiderfiedMarkers.push(tempMarker);
						})
						spiderfiedTracks.current = spiderfiedMarkers;
						a.layer._markers.forEach(marker => marker.remove());
					}
				})

				platforms.addLayers(navTracks);
				tracks.addLayers(aisTracks);
				tracks.addLayers(manualTracks);
				tracks.addLayers(radarTracks);

				tracksByMission[mission.id] = {
					platforms,
					tracks
				};
				return tracksByMission;
			},
			{}
		);

		const clearMissionTracks = (missionTracks) => {
			missionTracks.platforms.clearLayers();
			missionTracks.tracks.clearLayers();
		};

		const addMissionTracksToMap = (missionTracks) => {
			mapRef.current.addLayer(missionTracks.platforms);
			mapRef.current.addLayer(missionTracks.tracks);
		};

		if (mapReady && mode === 'historical') {
			mapRef.current.off('click', deselectAllTracks);
			mapRef.current.on('click', deselectAllTracks)
				.on('dblclick', function () {
					clearTimeout(_dblClickTimer.current);
					_dblClickTimer.current = null;
					// momma knows child mama knows....

					// real 'dblclick' handler here (if any). Do not add anything
					// to just have the default zoom behavior
				});

			let previousMission =
				typeof mapRef.current.tracksByMission === 'object'
					? Object.keys(mapRef.current.tracksByMission)[0]
					: null;

			if (shownMissions.length === 0) {
				if (mapRef.current.tracksByMission) {
					R.map(clearMissionTracks, mapRef.current.tracksByMission);
					mapRef.current.tracksByMission = {};
				}
			}

			if (
				shownMissions.length > 0 &&
				shownMissions[0].id !== previousMission
			) {
				if (mapRef.current.tracksByMission) {
					R.map(clearMissionTracks, mapRef.current.tracksByMission);
				}

				mapRef.current.tracksByMission = tracksByMission;

				mapRef.current.timeDimension.setAvailableTimes(
					time.availableTimes,
					'replace'
				);
				mapRef.current.timeDimension._availableTimes =
					time.availableTimes;
				mapRef.current.timeDimension._currentTimeIndex =
					time.currentIndex;
				mapRef.current.timeDimension._loadingTimeIndex =
					time.currentInex;
				mapRef.current.timeDimensionControl._update();

				R.map(addMissionTracksToMap, tracksByMission);

				mapRef.current.timeDimension.setCurrentTimeIndex(0);
			}
		}

		if (mapReady && mode === 'realtime') {
			mapRef.current.off('click', deselectAllTracks);
			mapRef.current.on('click', deselectAllTracks);

			const previousMissionIds =
				typeof mapRef.current.tracksByMission === 'object'
					? Object.keys(mapRef.current.tracksByMission)
					: [];
			const currentMissionIds = shownMissions.map((mission) => {
				return mission.id;
			});

			if (shownMissions.length === 0) {
				if (mapRef.current.tracksByMission) {
					R.map(clearMissionTracks, mapRef.current.tracksByMission);
					mapRef.current.tracksByMission = {};
				}
			}

			if (
				shownMissions.length > 0 &&
				(!R.equals(currentMissionIds, previousMissionIds) ||
					!R.equals(
						time.availableTimes,
						mapRef.current.timeDimension.getAvailableTimes()
					))
			) {
				if (mapRef.current.tracksByMission) {
					R.map(clearMissionTracks, mapRef.current.tracksByMission);
				}

				mapRef.current.tracksByMission = tracksByMission;

				mapRef.current.timeDimension.setAvailableTimes(
					time.availableTimes,
					'replace'
				);
				mapRef.current.timeDimension._availableTimes =
					time.availableTimes;
				mapRef.current.timeDimension._currentTimeIndex =
					time.currentIndex;
				mapRef.current.timeDimension._loadingTimeIndex =
					time.currentInex;
				mapRef.current.timeDimensionControl._update();

				R.map(addMissionTracksToMap, tracksByMission);

				mapRef.current.timeDimension.setCurrentTimeIndex(
					time.currentIndex
				);
			}
		}
	}, [shownMissions, time]);

	// When the mode changes
	useEffect(() => {
		mapRef.current.removeLayer(markerTarget.current);
		if (mapRef.current.measureControl.handler._enabled)
			mapRef.current.measureControl.toggle();

		mapRef.current.polygonLayers.clearLayers();
		if (mapRef.current.polygonDrawer.enabled)
			mapRef.current.polygonDrawer.disable();
		mediaService.setMode(mode);
	}, [mode]);

	// # Funcs

	const selectTracks = (tracks) => {
		if (mode === 'historical') {
			dispatch(historicalActions.selectTrack(tracks));
		} else {
			dispatch(realtimeActions.selectTrack(tracks));
		}
	};

	async function showVideoPlayer(title, source, type = 'stream') {
		if(showVideoStream && title !== videoStreamTitle)
			await setShowVideoStream(false);
		setVideoStreamTitle(title);
		setVideoStreamSource(source);
		setVideoStreamType(type);
		setShowVideoStream(true);
	}

	function hideVideoPlayer() {
		setShowVideoStream(false);
		setVideoStreamTitle("");
		setVideoStreamSource(undefined);
	}

	function getDnsAddress() {
		let address = '';
		if(selectedTracks.length > 0) {
			const missionId = selectedTracks[0].missionId;
			shownMissions.forEach(mission => {
				if(mission.profile.length > 0 && mission.id === missionId)
					address = mission.profile[0].dnsAddress;
			})
		}
		return address;
	}	

	// Sometimes track markers wont be visable for various reasons.
	const whenTrackIsNoLongerVisible = (e) => {
		// If the mission is over or the track is simply not available at that
		// time.
		if (
			selectedMarkers.current[e.target.feature.properties.id] !==
				undefined &&
			e.target.feature.properties.times[0] >
				mapRef.current.timeDimension.getCurrentTime() &&
			R.keys(selectedMarkers.current).length > 0
		) {
			deselectAllTracks();
		}
		// IF the marker is clustered
		else if (
			e.target.selector !== undefined &&
			e.target.selector !== null &&
			mode === 'realtime'
		) {
			mapRef.current.removeLayer(e.target.selector);
		}
	};

	const deselectAllTracks = () => {
		if (selectedTracks.length > 0) {
			if (mode === 'historical') {
				dispatch(historicalActions.deSelectTracks());
			}
			if (mode === 'realtime') {
				dispatch(realtimeActions.deSelectTracks());
			}
		}
		spiderfiedTracks.current.forEach(track => mapRef.current.removeLayer(track));
		spiderfiedTracks.current = [];
	};

	// Sometimes we want to see only certain missions
	const toggleVisibility = (mission) => {
		dispatch(realtimeActions.toggleVisibility(mission));
	};

	return (
		<div id="layout-ctn" className="container">
			<div
				id="layout-side-bar"
				className={classNames('side-bar', {
					'show-side-bar': sideOpen
				})}
			>
			
				{manageOverlays && (
					<OverlaySidebar
						map={mapRef.current}
						setSideOpen={setSideOpen}
						sideOpen={sideOpen}
						setManageOverlays={setManageOverlays}
					/>
				)}
				{mode === 'historical' && !manageOverlays && (
					<HistoricalSidebar
						map={mapRef.current}
						missions={missions}
						sideOpen={sideOpen}
						setSideOpen={setSideOpen}
						showVideoPlayer={showVideoPlayer}
					/>
				)}
				{mode !== 'historical' && !manageOverlays && (
					<RealTimeSidebar
						map={mapRef.current}
						onVisibilityToggle={toggleVisibility}
						setSideOpen={setSideOpen}
						sideOpen={sideOpen}
						missions={missions}
						showVideoPlayer={showVideoPlayer}
					/>
				)}
			</div>
			{ showVideoStream && (<DraggableModalContainer
				component={VideoContainer}
				source={videoStreamSource}
				title={videoStreamTitle}
				autoPlay={videoStreamType === 'stream'}
				onClose={hideVideoPlayer}
			/>)}
			<div id="layout-main-ctn" className="main">
				<div
					className={classNames('side-bar-hide-control', {
						'show-side-bar-hide-control': sideOpen
					})}
					onClick={() => {
						setSideOpen(false);
					}}
					title={intl.formatMessage({
						id: 'app.map.sidebar.hidesidebar.button.tooltip'
					})}
				>
					<SideBarClose />
				</div>
				<MapControls
					map={mapRef.current}
					setManageOverlays={setManageOverlays}
					mapOptions={mapOptions}
					onMapOptionsChange={options => setMapOptions(options)}
				/>
				<div
					id="map-ctn"
					className={classNames(
						'map-container',
						{
							'full-width-map': !sideOpen
						},
						{
							'full-height-map': !bottomOpen
						}
					)}
				>
					<div
						id="side-bar-show-control"

						className={`side-bar-show-control ${sideOpen ? 'side-bar-hide-control' : 'show-side-bar-show-control'} `}
						
						title={intl.formatMessage({
							id: 'app.map.sidebar.showsidebar.button.tooltip'
						})}
						title={intl.formatMessage({
							id: 'app.map.sidebar.showsidebar.button.tooltip'
						})}
						onClick={() => {
							setSideOpen(true);
						}}
					>
						<SideBarOpen />
					</div>
					<div
						id="bottom-bar-show-control"
						className={classNames('bottom-bar-show-control', {
							'show-bottom-bar-show-control': !bottomOpen
						})}
						title={intl.formatMessage({
							id: 'app.map.bottombar.showbottom.button.tooltip'
						})}
						onClick={() => {
							setBottomOpen(true);
						}}
					>
						<BottomBarOpen />
					</div>
					<div id="map"></div>
				</div>

				<section
					id="layout-bottom-bar-ctn"
					className={classNames(
						'bottom-bar',
						{
							'show-bottom-bar': bottomOpen
						},
						{
							'full-bottom-bar': !sideOpen
						}
					)}
				>
					<div
						id="bottom-bar-hide-control"
						className={classNames('bottom-bar-hide-control', {
							'show-bottom-bar-hide-control': bottomOpen
						})}
						title={intl.formatMessage({
							id: 'app.map.bottombar.hidebottom.button.tooltip'
						})}
						onClick={() => {
							setBottomOpen(false);
						}}
					>
						<BottomBarClose />
					</div>
					<div
						id="layout-bottom-bar-content"
						className="bottom-bar-content"
					>
						<TrackGrid
							mode={mode}
							setFocusMarker={setFocusMarker}
							selectedTracks={selectedTracks}
							missions={shownMissions}
							downloadMediaRequestOff={downloadMediaRequestOff}
						></TrackGrid>
						<TrackInspector 
							mode={mode}
							dnsAddress={getDnsAddress()}
							showVideoPlayer={showVideoPlayer}
							downloadMediaRequestOff={downloadMediaRequestOff}
							downloadMediaRequestOn={downloadMediaRequestOn}
							downloadMediaAccess={downloadMediaAccess}
						></TrackInspector>
					</div>
				</section>
			</div>
		</div>
	);
}

export { Home };
