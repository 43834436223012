import { authConstants } from './auth.constants';

let user = JSON.parse(sessionStorage.getItem('sisUser'));
const initialState = user
	? { loggedIn: true, user: user }
	: { loggedIn: false, user: {} };

export function authentication(state = initialState, action) {
	switch (action.type) {
		case authConstants.LOGIN_REQUEST:
			return {
				...state,
				user: action.user
			};
		case authConstants.LOGIN_SUCCESS:
			return {
				loggedIn: true,
				user: action.user
			};
		case authConstants.LOGIN_FAILURE:
			return {};
		case authConstants.LOGOUT:
			return { loggedIn: false };
		default:
			return state;
	}
}
