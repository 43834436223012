export const realtimeConstants = {
	REALTIME_POLLING_SUCCESS: 'REALTIME_POLLING_SUCCESS',
	REALTIME_POLLING_FAILURE: 'REALTIME_POLLING_FAILURE',
	REALTIME_SELECT_TRACK: 'REALTIME_SELECT_TRACK',
	REALTIME_SELECT_TRACK_FAILURE: 'REALTIME_SELECT_TRACK_FAILURE',
	REALTIME_MEDIA_SUCCESS: 'REALTIME_MEDIA_SUCCESS',
	REALTIME_MEDIA_FAILURE: 'REALTIME_MEDIA_FAILURE',

	REALTIME_MISSION_SHOWN_TOGGLE: 'REALTIME_MISSION_SHOWN_TOGGLE',
	REALTIME_DESELECT_TRACK: 'REALTIME_DESELECT_TRACK'
};
