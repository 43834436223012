// # Imports
// External Imports
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import * as R from 'ramda';
import * as pl from 'partial.lenses';
import L from 'leaflet';
// Internal Imports
import sisConfig from '../../sis.config.json';

// Assets
import CheckboxNotSelected from '../../assets/icons/checkbox-not-selected.svg';
import CheckboxSelected from '../../assets/icons/checkbox-selected.svg';
import Close from '../../assets/icons/close-dialog.svg';
import Overlays from '../../assets/icons/managemaplayers-icon.svg';
import Eye from '../../assets/icons/eye-icon.svg';
import { profileActions } from '../../_modules/profiles/profile.actions';
import EyeSlash from '../../assets/icons/eyeslash-icon.svg';
import './settings-panel.scss';
import DropdownButtons from '../DropdownButtons/DropdownButtons';

export default function SettingsPanel(props) {
	const dispatch = useDispatch();
	const intl = useIntl();
	const profile = useSelector((state) => state.profile);
	const reversedOverlayList = R.reverse(profile.overlays);
	const [currBaseMap, setCurrBaseMap] = useState(
		profile.baseMapUrl ||
			'https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=odrA8jXK5gV63WS5pSvV'
	);

	const baseMaps = {
		'Klokantech Basic': {
			name: 'Klokantech Basic',
			dataUrl:
				'https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=odrA8jXK5gV63WS5pSvV',
			thumb: 'images/klokantech.png'
		},
		Positron: {
			name: 'Positron',
			dataUrl:
				'https://api.maptiler.com/maps/positron/{z}/{x}/{y}.png?key=odrA8jXK5gV63WS5pSvV',
			thumb: 'images/positron.png'
		}
	};

	const selectBaseMap = (name) => () => {
		const baseMap = baseMaps[name];
		props.map.baseLayers.eachLayer(function (layer) {
			layer.setUrl(baseMap.dataUrl);
			setCurrBaseMap(baseMap.dataUrl);
		});
		const newProfile = { ...profile, baseMapUrl: baseMap.dataUrl };
		dispatch(profileActions.updateProfile(newProfile));
	};

	const BaseMapSelector = R.values(baseMaps).map((item, key) => {
		return (
			<button
				id={'settings-panel-basemap-btn-' + key}
				className=" is-active"
				className={classNames('base-map-item', {
					'base-map-is-active': currBaseMap === item.dataUrl
				})}
				key={item.name}
				onClick={selectBaseMap(item.name)}
				name={item.name}
			>
				<img
					src={item.thumb}
					id={'settings-panel-basemap-img-' + key}
					alt={item.name}
				/>
			</button>
		);
	});

	const OverlayList = reversedOverlayList.map((overlay, key) => {
		let eyeIcon = overlay.shown === '1' ? Eye : EyeSlash;
		return (
			<li
				key={key}
				id={'settings-panel-overlay-list-item-' + key}
				className="settings-overlay-list-item"
				onClick={() => handleOverlayToggle(overlay)}
			>
				<img
					src={eyeIcon}
					id={'settings-panel-overlay-toggle-' + key}
					alt="Toggle Overlay Visibility"
				/>
				{overlay.name.replace('SISMap:', '')}
			</li>
		);
	});

	const handleOverlayToggle = (overlay) => {
		const shown = overlay.shown === '1' ? '0' : '1';

		const overlayLense = pl.set(
			['overlays', pl.find(R.whereEq({ name: overlay.name })), 'shown'],
			shown
		);

		const newProfile = overlayLense(profile);

		dispatch(profileActions.updateProfile(newProfile));

		let layerFound = false;
		props.map.overlayFeatureGroup.eachLayer((layer) => {
			if (layer.options.layers === overlay.name) {
				props.map.overlayFeatureGroup.removeLayer(layer);
				layerFound = true;
			}
		});

		if (!layerFound) {
			props.map.overlayFeatureGroup.eachLayer(function (layer){
				props.map.overlayFeatureGroup.removeLayer(layer)
			});
			newProfile.overlays.filter((overlay) => {
				return overlay.shown === '1' ? true : false;
			})
			.map((overlay) => {
				const tempLayer = L.tileLayer
					.wms(
						`${sisConfig.build[window.location.hostname].wms}`,
						{
							layers: overlay.name,
							format: 'image/png',
							transparent: true,
							opacity: overlay.opacity / 100
						}
					)
					.addTo(props.map.overlayFeatureGroup);
			});
		}
	};

	return (
		<div
			className={classNames('settings-panel-container', {
				'settings-panel-show': props.isOpen === true
			})}
			id={'Settings'}
		>
			<div className="settings-panel-header">
				<button
					type="button"
					onClick={props.onToggle}
					title={intl.formatMessage({
						id: 'app.map.general.mappanel.close.button.tooltip'
					})}
					className="settings-panel-close-btn"
				>
					<img alt="Close" src={Close} />
				</button>
			</div>
      <div className={classNames('dialog-section', 'base-map-header')}>
				{intl.formatMessage({
					id: 'app.map.general.mappanel.options.label'
				})}
			</div>
      <div className="settings-panel-checkbox">
				<button
					type="button"
					id="map-options-should-cluster"
					onClick={(event) => {
						const should = !props.mapOptions.shouldShowLabels;
						props.onMapOptionsChange({
							...props.mapOptions,
							shouldShowLabels: should,
							shouldShowLabelsPrev: props.mapOptions.shouldShowLabels,
						})
					}}
					title={intl.formatMessage({
						id:
							'app.map.sidebar.missionselection.visible.button.tooltip'
					})}
				>
					<img
						alt={intl.formatMessage({
							id:
								'app.map.sidebar.missionselection.visible.button.tooltip'
						})}
						src={props.mapOptions.shouldShowLabels ? CheckboxSelected: CheckboxNotSelected}
					/>
				</button>
				Symbol Labels
			</div>
			<div className="settings-panel-dropdown">
				<DropdownButtons
					title={{
						16: "Small Symbol Size", 24: "Medium Symbol Size", 32: "Large Symbol Size"
					}[props.mapOptions.iconSize]}
					option={{
						"Small Symbol Size": {
							callback: () => props.onMapOptionsChange({
								...props.mapOptions,
								iconSize: 16, iconSizePrev: props.mapOptions.iconSize
							}),
						},
						"Medium Symbol Size": {
							callback: () => props.onMapOptionsChange({
								...props.mapOptions,
								iconSize: 24, iconSizePrev: props.mapOptions.iconSize
							}),
						},
						"Large Symbol Size": {
							callback: () => props.onMapOptionsChange({
								...props.mapOptions,
								iconSize: 32, iconSizePrev: props.mapOptions.iconSize
							}),
						},
					}}
				>
				</DropdownButtons>
			</div>
			<div className={classNames('dialog-section', 'base-map-header')}>
				{intl.formatMessage({
					id: 'app.map.general.mappanel.basemap.label'
				})}
			</div>
			<div className="base-map">
				<ul className="base-map-list">{BaseMapSelector}</ul>
			</div>
			<div className={classNames('dialog-section', 'overlays-header')}>
				{intl.formatMessage({
					id: 'app.map.general.mappanel.overlays.label'
				})}
				<button
					type="button"
					id={'manage-overlays'}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						props.setManageOverlays(true);
					}}
					title={intl.formatMessage({
						id: 'app.map.general.mappanel.overlays.manage.tooltip'
					})}
					className="manage-overlay-btn"
				>
					<img alt="Manage Overlays" src={Overlays} />
				</button>
			</div>
			<ul className="overlay-list">{OverlayList}</ul>
		</div>
	);
}
