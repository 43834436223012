// # Imports
// External Imports
import React from 'react';
import classNames from 'classnames';

// Internal Imports

// Assets
import './task.scss';

export default function Task(props) {
	const rowCount = props.idx + 1;

	const patrolOptions = (options) => {
		return options.map(function (patrol, key) {
			return (
				<option
					id={'asis-task-item-patrol-option-' + key}
					value={patrol.id}
				>
					{patrol.value}
				</option>
			);
		});
	};

	const handlePatrol = (event) => {
		props.onTaskChanged(props.idx, 'patrolId', event.target.value);
	};

	const areaOptions = (options) => {
		return options.map(function (area, key) {
			return (
				<option
					id={'asis-task-item-area-option-' + key}
					value={area.id}
				>
					{area.value}
				</option>
			);
		});
	};

	const handleArea = (event) => {
		props.onTaskChanged(props.idx, 'areaId', event.target.value);
	};

	const visibilityOptions = (options) => {
		return options.map(function (visibility, key) {
			return (
				<option
					id={'asis-task-item-visibility-option-' + key}
					value={visibility.id}
				>
					{visibility.value}
				</option>
			);
		});
	};

	const handleVisibility = (event) => {
		props.onTaskChanged(props.idx, 'visibilityId', event.target.value);
	};

	const workElementTypeOptions = (options) => {
		return options.map(function (type, key) {
			return (
				<option
					id={'asis-task-item-work-element-type-option-' + key}
					value={type.id}
				>
					{type.value}
				</option>
			);
		});
	};

	const handleWorkElementType = (event) => {
		props.onTaskChanged(props.idx, 'typeId', event.target.value);
	};

	const workElementSpeciesOptions = () => {
		return filterSpecies(props.lookupData.species, props.task.typeId).map(
			function (species, key) {
				return (
					<option
						id={'asis-task-item-work-element-species-option-' + key}
						value={species.id}
					>
						{species.value}
					</option>
				);
			}
		);
	};

	const filterSpecies = (list, typeId) => {
		let filteredSpecies =
			typeId !== '' && typeId !== null
				? list.filter((x) => x.typeId.toString() === typeId.toString())
				: [];
		return filteredSpecies;
	};

	const handleWorkElementSpecies = (event) => {
		props.onTaskChanged(props.idx, 'speciesId', event.target.value);
	};

	const directedActivityOptions = (options) => {
		return options.map(function (directedActivity, key) {
			return (
				<option
					id={'asis-task-item-directed-activity-option-' + key}
					value={directedActivity.id}
				>
					{directedActivity.value}
				</option>
			);
		});
	};

	const handleDirectedActivity = (event) => {
		props.onTaskChanged(
			props.idx,
			'directedActivityId',
			event.target.value
		);
	};

	const directedSpecialAreaOptions = () => {
		return filterSpecialAreas(
			props.lookupData.specialAreas,
			props.task.directedActivityId
		).map(function (directedSpecialArea, key) {
			return (
				<option
					id={'asis-task-item-directed-special-area-option-' + key}
					value={directedSpecialArea.id}
				>
					{directedSpecialArea.value}
				</option>
			);
		});
	};

	const filterSpecialAreas = (list, activityId) => {
		if(activityId === null)
			return list;
		const filteredSpecialAreas =
			activityId !== ''
				? list.filter(
						(x) => x.activityId.toString() === activityId.toString()
				  )
				: [];
		return filteredSpecialAreas;
	};

	const handleDirectedSpecialArea = (event) => {
		props.onTaskChanged(
			props.idx,
			'directedSpecialAreaId',
			event.target.value
		);
	};

	const concurrentActivityOptions = (options) => {
		return options.map(function (concurrentActivity, key) {
			return (
				<option
					id={'asis-task-item-concurrent-activity-option-' + key}
					value={concurrentActivity.id}
				>
					{concurrentActivity.value}
				</option>
			);
		});
	};

	const handleConcurrentActivity = (event) => {
		props.onTaskChanged(
			props.idx,
			'concurrentActivityId',
			event.target.value
		);
	};

	const concurrentSpecialAreaOptions = () => {
		return filterSpecialAreas(
			props.lookupData.specialAreas,
			props.task.concurrentActivityId
		).map(function (concurrentSpecialArea, key) {
			return (
				<option
					id={'asis-task-item-concurrent-special-area-option-' + key}
					value={concurrentSpecialArea.id}
				>
					{concurrentSpecialArea.value}
				</option>
			);
		});
	};

	const handleConcurrentSpecialArea = (event) => {
		props.onTaskChanged(
			props.idx,
			'concurrentSpecialAreaId',
			event.target.value
		);
	};

	return (
		<div
			className={classNames('asis-task-item-row', {
				'asis-task-row-selected': props.isSelected === true
			})}
		>
			<div
				id={'asis-task-item-idx-' + props.idx}
				className={'asis-task-item-value-container asis-task-item-idx'}
				onClick={() => {
					props.onRowSelection(props.idx);
				}}
			>
				{rowCount}
				{props.isSelected}
			</div>
			<div
				id={'asis-task-item-patrol-' + props.idx}
				className="asis-task-item-value-container asis-task-item-patrol"
			>
				<select
					id="asis-task-item-select-patrol"
					onChange={handlePatrol}
					value={props.task.patrolId}
				>
					<option value=""></option>
					{patrolOptions(props.lookupData.patrols)}
				</select>
			</div>
			<div
				id={'asis-task-item-area' + props.idx}
				className="asis-task-item-value-container asis-task-item-area"
			>
				<select
					id="asis-task-item-select-area"
					onChange={handleArea}
					value={props.task.areaId}
				>
					<option value=""></option>
					{areaOptions(props.lookupData.areas)}
				</select>
			</div>
			<div
				id={'asis-task-item-patrolhours' + props.idx}
				className="asis-task-item-value-container asis-task-item-patrolhours"
			>
				<input
					value={props.task.areaHours}
					type="number"
					onChange={(event) => {
						props.onTaskChanged(
							props.idx,
							'patrolHours',
							event.target.value
						);
					}}
				></input>
			</div>
			<div
				id={'asis-task-item-visibility' + props.idx}
				className="asis-task-item-value-container asis-task-item-visibility"
			>
				<select
					id="asis-task-item-select-visibility"
					onChange={handleVisibility}
					value={props.task.visibilityId}
				>
					<option value=""></option>
					{visibilityOptions(props.lookupData.visibility)}
				</select>
			</div>
			<div
				id={'asis-task-item-work-element-type' + props.idx}
				className="asis-task-item-value-container asis-task-item-type"
			>
				<select
					id="asis-task-item-select-work-element-type"
					onChange={handleWorkElementType}
					value={props.task.typeId}
				>
					<option value=""></option>
					{workElementTypeOptions(props.lookupData.type)}
				</select>
			</div>
			<div
				id={'asis-task-item-species' + props.idx}
				className="asis-task-item-value-container asis-task-item-species"
			>
				<select
					id="asis-task-item-select-work-element-species"
					onChange={handleWorkElementSpecies}
					value={props.task.speciesId}
				>
					<option value=""></option>
					{workElementSpeciesOptions()}
				</select>
			</div>
			<div
				id={'asis-task-item-directed-activity' + props.idx}
				className="asis-task-item-value-container asis-task-item-directed-activity"
			>
				<select
					id="asis-task-item-select-directed-activity"
					onChange={handleDirectedActivity}
					value={props.task.directedActivityId}
				>
					<option value=""></option>
					{directedActivityOptions(props.lookupData.activities)}
				</select>
			</div>
			<div
				id={'asis-task-item-directed-specialarea' + props.idx}
				className="asis-task-item-value-container asis-task-item-directed-specialarea"
			>
				<select
					id="asis-task-item-select-directed-special-area"
					onChange={handleDirectedSpecialArea}
					value={props.task.directedSpecialAreaId}
				>
					<option value=""></option>
					{directedSpecialAreaOptions()}
				</select>
			</div>
			<div
				id={'asis-task-item-directed-hours' + props.idx}
				className="asis-task-item-value-container asis-task-item-directed-hours"
			>
				<input
					value={props.task.directedHours}
					type="number"
					onChange={(event) => {
						props.onTaskChanged(
							props.idx,
							'directedHours',
							event.target.value?event.target.value:0
						);
					}}
				></input>
			</div>
			<div
				id={'asis-task-item-concurrent-activity' + props.idx}
				className="asis-task-item-value-container asis-task-item-concurrent-activity"
			>
				<select
					id="asis-task-item-select-concurrent-activity"
					onChange={handleConcurrentActivity}
					value={props.task.concurrentActivityId}
				>
					<option value=""></option>
					{concurrentActivityOptions(props.lookupData.activities)}
				</select>
			</div>
			<div
				id={'asis-task-item-concurrent-specialarea' + props.idx}
				className="asis-task-item-value-container asis-task-item-concurrent-specialarea"
			>
				<select
					id="asis-task-item-select-concurrent-special-area"
					onChange={handleConcurrentSpecialArea}
					value={props.task.concurrentSpecialAreaId}
				>
					<option value=""></option>
					{concurrentSpecialAreaOptions()}
				</select>
			</div>
			<div
				id={'asis-task-item-concurrent-hours' + props.idx}
				className="asis-task-item-value-container asis-task-item-concurrent-hours"
			>
				<input
					value={props.task.concurrentHours}
					type="number"
					onChange={(event) => {
						props.onTaskChanged(
							props.idx,
							'concurrentHours',
							event.target.value?event.target.value:0
						);
					}}
				></input>
			</div>
			<div
				id={'asis-task-item-fishing-domid' + props.idx}
				className="asis-task-item-value-container asis-task-item-fishing-domid"
			>
				<input
					value={props.task.fishingDomId}
					type="number"
					onChange={(event) => {
						props.onTaskChanged(
							props.idx,
							'fishingDomId',
							event.target.value
						);
					}}
				></input>
			</div>
			<div
				id={'asis-task-item-fishing-forid' + props.idx}
				className="asis-task-item-value-container asis-task-item-fishing-forid"
			>
				<input
					value={props.task.fishingForId}
					type="number"
					onChange={(event) => {
						props.onTaskChanged(
							props.idx,
							'fishingForId',
							event.target.value
						);
					}}
				></input>
			</div>
			<div
				id={'asis-task-item-fishing-domobs' + props.idx}
				className="asis-task-item-value-container asis-task-item-fishing-domobs"
			>
				<input
					value={props.task.fishingDomObs}
					type="number"
					onChange={(event) => {
						props.onTaskChanged(
							props.idx,
							'fishingDomObs',
							event.target.value
						);
					}}
				></input>
			</div>
			<div
				id={'asis-task-item-fishing-forobs' + props.idx}
				className="asis-task-item-value-container asis-task-item-fishing-forobs"
			>
				<input
					value={props.task.fishingForObs}
					type="number"
					onChange={(event) => {
						props.onTaskChanged(
							props.idx,
							'fishingForObs',
							event.target.value
						);
					}}
				></input>
			</div>
			<div
				id={'asis-task-item-gear' + props.idx}
				className="asis-task-item-value-container asis-task-item-gear"
			>
				<input
					value={props.task.gearCount}
					type="number"
					onChange={(event) => {
						props.onTaskChanged(
							props.idx,
							'gearCount',
							event.target.value
						);
					}}
				></input>
			</div>
			<div
				id={'asis-task-item-group' + props.idx}
				className="asis-task-item-value-container asis-task-item-group"
			>
				<input
					value={props.task.groupCount}
					type="number"
					onChange={(event) => {
						props.onTaskChanged(
							props.idx,
							'groupCount',
							event.target.value
						);
					}}
				></input>
			</div>
		</div>
	);
}
