import { profileConstants } from './profile.constants';
import { history } from '../../_helpers/history';
import { authHeader } from '../../_helpers/auth-header';
import { httpInterceptor } from '../../_helpers/http-interceptor';
import sisConfig from '../../sis.config.json';

export const profileActions = {
	getProfileByUserName,
	insertProfile,
	updateProfile,
	deleteProfile
};

function getProfileByUserName(userName, location) {
	return (dispatch) => {
		dispatch(request({ userName }));

		const requestOptions = {
			method: 'GET',
			headers: authHeader()
		};

		return fetch(
			`${sisConfig.build[window.location.hostname].api}/userProfiles/${userName}/`,
			requestOptions
		)
			.then(httpInterceptor)
			.then(
				(profile) => {
					sessionStorage.setItem(
						'state:profile',
						JSON.stringify(profile)
					);
					dispatch(success(profile));

					const referrer = location.state
						? location.state.from.pathname
						: '/realtime';
					history.push(referrer);
				},
				(error) => {
					if (error === 'Not Found') {
						dispatch(insertProfile(userName));

						const referrer = location.state
							? location.state.from.pathname
							: '/realtime';
						history.push(referrer);
						return;
					}
					dispatch(failure(error.toString()));
				}
			);
	};

	function request(userName) {
		return { type: profileConstants.GET_REQUEST, userName };
	}
	function success(profile) {
		return { type: profileConstants.GET_SUCCESS, profile };
	}
	function failure(error) {
		return { type: profileConstants.GET_FAILURE, error };
	}
}

function insertProfile(userName) {
	return (dispatch) => {
		dispatch(request({ userName }));

		const blankUserProfile = {
			userName: userName,
			overlays: []
		};
		const requestOptions = {
			method: 'POST',
			headers: { ...authHeader(), 'Content-Type': 'application/json' },
			body: JSON.stringify(blankUserProfile)
		};

		return fetch(
			`${sisConfig.build[window.location.hostname].api}/userProfiles/`,
			requestOptions
		)
			.then(httpInterceptor)
			.then(
				(profile) => {
					dispatch(success(blankUserProfile));
					sessionStorage.setItem(
						'state:profile',
						JSON.stringify(blankUserProfile)
					);
				},
				(error) => {
					dispatch(failure(error.toString()));
				}
			);
	};
	function request(userName) {
		return { type: profileConstants.INSERT_REQUEST, userName };
	}
	function success(profile) {
		return { type: profileConstants.INSERT_SUCCESS, profile };
	}
	function failure(error) {
		return { type: profileConstants.INSERT_FAILURE, error };
	}
}

function updateProfile(profile) {
	return (dispatch) => {
		dispatch(request({ profile }));

		const requestOptions = {
			method: 'PUT',
			headers: { ...authHeader(), 'Content-Type': 'application/json' },
			body: JSON.stringify(profile)
		};

		return fetch(
			`${sisConfig.build[window.location.hostname].api}/userProfiles/${profile.userName}/`,
			requestOptions
		)
			.then(httpInterceptor)
			.then(
				() => {
					sessionStorage.setItem(
						'state:profile',
						JSON.stringify(profile)
					);
					dispatch(success(profile));
				},
				(error) => {
					dispatch(failure(error.toString()));
				}
			);
	};

	function request(profile) {
		return { type: profileConstants.UPDATE_REQUEST, profile };
	}
	function success(profile) {
		return { type: profileConstants.UPDATE_SUCCESS, profile };
	}
	function failure(error) {
		return { type: profileConstants.UPDATE_FAILURE, error };
	}
}

function deleteProfile(userName) {
	return (dispatch) => {
		dispatch(request(userName));

		const requestOptions = {
			method: 'DELETE',
			headers: authHeader()
		};

		return fetch(
			`${sisConfig.build[window.location.hostname].api}/userProfiles/${userName}/`,
			requestOptions
		)
			.then(httpInterceptor)
			.then(
				(profile) => {
					dispatch(success(profile));
				},
				(error) => {
					dispatch(failure(error.toString()));
				}
			);
	};
	function request(userName) {
		return { type: profileConstants.DELETE_REQUEST, userName };
	}
	function success(profile) {
		return { type: profileConstants.DELETE_SUCCESS, profile };
	}
	function failure(error) {
		return { type: profileConstants.DELETE_FAILURE, error };
	}
}
