import { authActions } from '../_modules/auth/auth.actions';
import WMSCapabilities from 'wms-capabilities';

export function httpInterceptor(response) {
	return response.text().then((text) => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if ([401, 403].indexOf(response.status) !== -1) {
				// If returned a 401 or 403, log out the user
				authActions.destroyUserObj();
			}

			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		return data;
	});
}

export function httpXMLInterceptor(response) {
	return response.text().then((text) => {
		const data = text && new WMSCapabilities(text).toJSON();
		if (!response.ok) {
			if ([401, 403].indexOf(response.status) !== -1) {
				// If returned a 401 or 403, log out the user
				authActions.destroyUserObj();
			}

			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		return data;
	});
}
