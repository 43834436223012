// # Imports

import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import SettingsPanel from '../Home/SettingsPanel';

// Assets
import Overlays from '../../assets/icons/mapcog-icon.svg';
import MapMinus from '../../assets/icons/mapminus-icon.svg';
import MapPlus from '../../assets/icons/mapplus-icon.svg';
import MapRefresh from '../../assets/icons/maprefresh-icon.svg';
import Measure from '../../assets/icons/measure.svg';
import Print from '../../assets/icons/mapprinter-icon.svg';

export default (props) => {
	const intl = useIntl();
	// # Local State

	const [mapSettingsOpen, setMapSettingsOpen] = useState(false);

	const handleZoomIn = () => {
		props.map.zoomIn();
	};

	const handleZoomOut = () => {
		props.map.zoomOut();
	};

	const handleZoomReset = () => {
		props.map.setZoom(6);
	};

	const handleMeasureToggle = () => {
		props.map.polygonDrawer.disable();
		props.map.measureControl.toggle();
	};

	const toggleOpen = () => {
		setMapSettingsOpen(!mapSettingsOpen);
	};

	return (
		<div id="map-controls-ctn" className="map-controls">
			<ul id="map-controls-list">
				<li id="map-controls-settings-item">
					{mapSettingsOpen && (
						<SettingsPanel
							map={props.map}
							onToggle={toggleOpen}
							setManageOverlays={props.setManageOverlays}
							onMapOptionsChange={props.onMapOptionsChange}
							mapOptions={props.mapOptions}
						></SettingsPanel>
					)}
					<div
						id="map-controls-settings-div"
						className="button"
						onClick={toggleOpen}
						title={intl.formatMessage({
							id: 'app.map.general.mappanel.button'
						})}
					>
						<img
							id="map-controls-settings-img"
							alt="Manage Overlays"
							src={Overlays}
						/>
					</div>
				</li>
				<li id="map-controls-zoom-item" className="zoom-controls">
					<div
						id="map-controls-zoom-in-div"
						className="button"
						onClick={handleZoomIn}
						title={intl.formatMessage({
							id: 'app.map.general.zoomtool.zoomin.button.tooltip'
						})}
					>
						<img
							id="map-controls-zoom-in-img"
							alt="Zoom In"
							src={MapPlus}
						/>
					</div>
					<div
						id="map-controls-zoom-reset-div"
						className="button"
						onClick={handleZoomReset}
						title={intl.formatMessage({
							id:
								'app.map.general.zoomtool.resetzoom.button.tooltip'
						})}
					>
						<img
							id="map-controls-zoom-reset-img"
							alt="Reset Zoom"
							src={MapRefresh}
						/>
					</div>
					<div
						id="map-controls-zoom-out-div"
						className="button"
						onClick={handleZoomOut}
						title={intl.formatMessage({
							id:
								'app.map.general.zoomtool.zoomout.button.tooltip'
						})}
					>
						<img
							id="map-controls-zoom-out-img"
							alt="Zoom Out"
							src={MapMinus}
						/>
					</div>
				</li>
				<li id="map-controls-measure-item">
					<div
						id="map-controls-measure-div"
						className="button"
						onClick={handleMeasureToggle}
						title={intl.formatMessage({
							id: 'app.map.general.measuretool.header'
						})}
					>
						<img
							id="map-controls-measure-img"
							width="20"
							height="20"
							alt="Measuring Tool"
							src={Measure}
						/>
					</div>
				</li>
			</ul>
		</div>
	);
};
