import { useState } from "react";

/*

    Usefull for when you need to wait on a state change.
    For example when closing and opening a video player.

    const [videoPlayerVisible, setVideoPlayerVisible] = useAsyncState(false);
    ...
    ...
    await setVideoPlayerVisible(false);
    ...    
    video player is now hidden so you can show it again with new settings
    ...
*/
export function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue);
    const setter = x =>
      new Promise(resolve => {
        setValue(x);
        resolve(x);
      });
    return [value, setter];
  }