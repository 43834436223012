import { mediaService } from './../_services/media.service';

export function downloadMedia(media = {
    id: null,
    mimeType: "",
    fileName: ""
}, dnsAddress = "") {
    return mediaService.getMedia(media.id, dnsAddress)
        .then(res => {
            if(res.status === 200) {
                return res.body;
            } else
                return null;
        })
        .then(stream => new Response(stream))
        .then(res => res.blob())
        .then(blob => {
            if(blob.size !== 0) {
                const fileBlob = new Blob([blob], {type: media.mimeType});
                const aElement = document.createElement('a');
                aElement.style.display = 'none';
                document.body.appendChild(aElement);
                aElement.href = window.URL.createObjectURL(fileBlob);
                aElement.setAttribute('download', media.fileName);
                aElement.click();
                window.URL.revokeObjectURL(aElement.href);
                document.body.removeChild(aElement);
            } else
                return null;
        });
    
}

export function previewMediaInNewTab(media = {
    id: null,
    mimeType: "",
    fileName: ""
}, dnsAddress = "") {
    if(media.id !== null) {
        mediaService
            .getMedia(media.id, dnsAddress)
            .then(res => {
                if(res.status === 200) {
                    return res.body;
                } else
                    return null;
            })
            .then(stream => new Response(stream))
            .then(res => res.blob())
            .then(blob => {
                if(blob.size !== 0) {
                    const fileBlob = new Blob([blob], {type: media.mimeType});
                    const url = window.URL.createObjectURL(fileBlob)
                    window.open(url);
                    // window.URL.revokeObjectURL(url);
                } else
                    return null;
            });
    }
}

export function canPlayMediaAsVideo(media) {
    switch(media.mimeType) {
        case "video/mp4":
            return true;
    }
    return false;
}