// # Imports
// External Imports

import * as R from 'ramda';
import { getTime, parseISO } from 'date-fns';
// Internal Imports
import sisConfig from '../../sis.config.json';
import { realtimeConstants } from './realtime.constants';
import { httpInterceptor } from '../../_helpers/http-interceptor';
import {
	cleanKeys,
	cleanUsernames,
	addCollectionId,
	hydrateData,
	unCapitalizeFirstLetterInString
} from '../../_helpers/data-cleanup';

//TODO: This is a big one - replace the polling (both media and realtime data) with subscriptions

export const realtimeActions = {
	startPoll,
	endPoll,
	startMediaPoll,
	endMediaPoll,
	getCurrentDataForTrack,
	getRealtimeMediaLinks,
	pollLoop,
	selectTrack,
	deSelectTracks,
	toggleVisibility
};

let canPoll = false;
let isPolling = false;
let canMediaPoll = false;
let isMediaPolling = false;
let missions = [];

const pollTypes = [
	'nav',
	'ais',
	'radar',
	'manual',
	'profile',
	'crew',
	'aircraft',
	'markers'
];

// Functions to start/stop the realtime polling if an option
function startPoll(dispatch) {
	canPoll = true;
	if (!isPolling) {
		dispatch(pollLoop());
	}
}

function endPoll() {
	canPoll = false;
}

// Functions to start/stop the media polling if an option
function startMediaPoll(dispatch, id) {
	canMediaPoll = true;
	if (!isMediaPolling) {
		dispatch(getRealtimeMediaLinks(id));
	}
}

function endMediaPoll() {
	canMediaPoll = false;
}

function getCurrentDataForTrack(mode, track) {
	if (mode !== 'realtime') {
		return track;
	}

	const mission = missions.find(m => m.id === track.missionId) || {};
	const collection = mission[track.collectionId] || {};
	const currentTrack = (collection.data || []).find(t => t.id === track.id);

	return currentTrack ? currentTrack : track;
}

const getTimesFromPositionData = (data) => {
	return R.pipe(
		R.values,
		R.map((item) => {
			return item.times;
		}),
		R.flatten
	)(data);
};

const hydrateNavPositionData = (data, nav) => {
	const positions = JSON.parse(nav.positions);

	data[nav.id] = {
		times: positions.map((pos) => {
			return getTime(parseISO(pos[2]));
		}),

		coords: positions.map((pos) => {
			return [pos[1], pos[0]];
		}),

		headings: positions.map((pos) => {
			return pos[4];
		})
	};
	return data;
};

const hydrateTrackPositionData = (data, track) => {
	const positions = JSON.parse(track.positions);

	data[track.id] = {
		times: positions.map((pos) => {
			return getTime(parseISO(pos[2]));
		}),

		coords: positions.map((pos) => {
			return [pos[1], pos[0]];
		})
	};
	return data;
};

function toggleVisibility(mission) {
	return (dispatch) => {
		mission.isShown = !mission.isShown;
		// triggerResize();
		dispatch({
			type: realtimeConstants.REALTIME_MISSION_SHOWN_TOGGLE,
			mission
		});
	};
}

function getUpdatedTracks(trackList) {
	let user = JSON.parse(sessionStorage.getItem('sisUser'));
	let requestOptions = {
		method: 'GET',
		headers: {
			Authorization: 'Bearer ' + user.token,
			responseType: 'application/json; charset=utf-8',
			Accept: 'application/json',
			'max-age-override': 10
		}
	};
	let partialTrack = trackList[0];
	return fetch(
		`${sisConfig.build[window.location.hostname].api}/targets/realtime/` +
			encodeURIComponent(trackList[0].id),
		requestOptions
	)
		.then(httpInterceptor)
		.then((details) => {
			let newDetails = {};
			Object.keys(details).forEach(
				(key) =>
					(newDetails[unCapitalizeFirstLetterInString(key)] =
						details[key])
			);
			if (newDetails.isDeleted) {
				deSelectTracks();
				return [];
			}
			return [R.mergeRight(partialTrack, newDetails)];
		});
}

function pollLoop() {
	return (dispatch) => {
		if (sessionStorage.getItem('sisUser')) {
			let user = JSON.parse(sessionStorage.getItem('sisUser'));
			let requestOptions = {
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + user.token,
					responseType: 'application/json; charset=utf-8',
					Accept: 'application/json',
					'max-age-override': 10
				}
			};

			isPolling = true;
			let fetchLoop = [];
			for (let i = 0; i < pollTypes.length; i++) {
				fetchLoop.push(
					fetch(
						`${sisConfig.build[window.location.hostname].api}/missions/realtime/` +
							encodeURIComponent(pollTypes[i]),
						requestOptions
					).then(httpInterceptor)
				);
			}

			Promise.all(fetchLoop)
				.then(([nav, ais, radar, manual, profile, crew, aircraft, markers]) => {
					return [
						nav.map(cleanKeys).map(addCollectionId('nav')),
						ais.map(cleanKeys).map(addCollectionId('ais')),
						radar.map(cleanKeys).map(addCollectionId('radar')),
						manual.map(cleanKeys).map(addCollectionId('manual')),
						profile.map(cleanKeys).map(addCollectionId('profile')),
						crew
							.map(cleanKeys)
							.map(addCollectionId('crew'))
							.map(cleanUsernames),
						aircraft.map(cleanKeys).map(addCollectionId('aircraft')),
						markers.map(cleanKeys).map(addCollectionId('markers'))
					];
				})
				.then(([nav, ais, radar, manual, profile, crew, aircraft, markers]) => {
					let mediaFetchLoop = [];
					for (let j = 0; j < profile.length; j++) {
						mediaFetchLoop.push(
							fetch(
								`${sisConfig.build[window.location.hostname].api}/missions/realtime/` +
									encodeURIComponent(profile[j].id) +
									`/medialinks`,
								requestOptions
							).then(httpInterceptor)
						);
					}
					return Promise.all(
						mediaFetchLoop,
						nav,
						ais,
						radar,
						manual,
						profile,
						crew,
						aircraft,
						markers
					).then((res) => {
						let mediaArr = [];
						res.map((mediaRes) => {
							for (let i = 0; i < mediaRes.length; i++) {
								mediaArr.push(mediaRes[i]);
							}
						});

						const cleanMediaArr = mediaArr.map(cleanKeys);
						return [
							nav,
							ais,
							radar,
							manual,
							profile,
							crew,
							aircraft,
							markers,
							cleanMediaArr
						];
					});
				})
				.then(
					([
						nav,
						ais,
						radar,
						manual,
						profile,
						crew,
						aircraft,
						markers,
						media
					]) => {
						let missionList = [];
						const times = [];
						for (let j = 0; j < profile.length; j++) {
							const tempNav =
								nav.filter(
									(x) =>
										x.missionNumber ===
										profile[j].missionNumber
								) || [];
							const tempAis =
								ais.filter(
									(x) =>
										x.missionNumber ===
										profile[j].missionNumber
								) || [];
							const tempRadar =
								radar.filter(
									(x) =>
										x.missionNumber ===
										profile[j].missionNumber
								) || [];
							const tempManual =
								manual.filter(
									(x) =>
										x.missionNumber ===
										profile[j].missionNumber
								) || [];
							const tempProfile =
								profile.filter(
									(x) =>
										x.missionNumber ===
										profile[j].missionNumber
								) || [];
							const tempCrew =
								crew.filter(
									(x) =>
										x.missionNumber ===
										profile[j].missionNumber
								) || [];
							const tempAircraft =
								aircraft.filter(
									(x) =>
										x.missionNumber ===
										profile[j].missionNumber
								) || [];
							const tempMarkers =
								markers.filter(
									(x) =>
										x.missionNumber ===
										profile[j].missionNumber
								) || [];
							const tempMedia =
								media.filter(
									(x) => x.missionId === profile[j].id
								) || [];
							const realtimeMission = {
								id: profile[j].id,
								isShown: true,
								metadata: {
									id: profile[j].id || '',
									name: profile[j].missionNumber || '',
									platformCallsign: profile[j].platform || '',
									startTime: profile[j].startTime || ''
								},
								nav: {
									data: tempNav.map(hydrateData),

									positions: tempNav.reduce(
										hydrateNavPositionData,
										{}
									)
								},
								ais: {
									data: tempAis.map(hydrateData),
									positions: ais.reduce(
										hydrateTrackPositionData,
										{}
									)
								},
								radar: {
									data: tempRadar.map(hydrateData),
									positions: radar.reduce(
										hydrateTrackPositionData,
										{}
									)
								},
								manual: {
									data: tempManual.map(hydrateData),
									positions: manual.reduce(
										hydrateTrackPositionData,
										{}
									)
								},
								markers: tempMarkers,
								profile: tempProfile,
								crew: tempCrew,
								aircraft: tempAircraft,
								mediaAssc: tempMedia
							};
							missionList.push(realtimeMission);

							const availableTimes = R.sort((a, b) => {
								return a - b;
							}, R.uniq([...getTimesFromPositionData(realtimeMission.nav.positions), ...getTimesFromPositionData(realtimeMission.ais.positions), ...getTimesFromPositionData(realtimeMission.radar.positions), ...getTimesFromPositionData(realtimeMission.manual.positions)]));
							times.push(availableTimes);
						}

						const availableTimes = R.sort((a, b) => {
							return a - b;
						}, R.uniq(R.flatten(times)));

						const time = {
							availableTimes: availableTimes,
							currentIndex: availableTimes.length - 1
						};
						if (canPoll) {
							setTimeout(function () {
								dispatch(pollLoop());
							}, 30000);
						} else {
							isPolling = false;
						}
						missions = missionList;
						return { missionList, time };
					}
				)
				.then(
					(res) => {
						dispatch(success(res.missionList, res.time));
					},
					(error) => {
						dispatch(failure(error.toString()));
					}
				);
		}

		function success(missionList, time) {
			return {
				type: realtimeConstants.REALTIME_POLLING_SUCCESS,
				missionList,
				time
			};
		}
		function failure(error) {
			return { type: realtimeConstants.REALTIME_POLLING_FAILURE, error };
		}
	};
}

function deSelectTracks() {
	return (dispatch) => {
		dispatch({
			type: realtimeConstants.REALTIME_DESELECT_TRACK
		});
	};
}

function selectTrack(tracks) {
	return (dispatch) => {
		if (sessionStorage.getItem('sisUser') && tracks.length > 0) {
			getUpdatedTracks(tracks).then(
				(tracks) => {
					dispatch(select(tracks));
				},
				(error) => {
					dispatch(failure(error.toString()));
				}
			);
		}
	};

	function select(tracks) {
		return { type: realtimeConstants.REALTIME_SELECT_TRACK, tracks };
	}
	function failure(error) {
		return { type: realtimeConstants.REALTIME_SELECT_TRACK_FAILURE, error };
	}
}

function getRealtimeMediaLinks(id) {
	return (dispatch) => {
		let user = JSON.parse(sessionStorage.getItem('sisUser'));
		let mediaRequestOptions = {
			method: 'GET',
			headers: {
				Authorization: 'Bearer ' + user.token,
				responseType: 'application/json; charset=utf-8',
				Accept: 'application/json',
				'max-age-override': 10
			}
		};

		fetch(
			`${sisConfig.build[window.location.hostname].api}/missions/realtime/` +
				encodeURIComponent(id) +
				`/medialinks`,
			mediaRequestOptions
		)
			.then(httpInterceptor)
			.then((mediaLinks) => {
				return mediaLinks;
			})
			.then(
				(mediaLinks) => {
					dispatch(success(mediaLinks));
				},
				(error) => {
					dispatch(failure(error.toString()));
				}
			);
	};

	function success(mediaLinks) {
		return { type: realtimeConstants.REALTIME_MEDIA_SUCCESS, mediaLinks };
	}

	function failure(error) {
		return { type: realtimeConstants.REALTIME_MEDIA_FAILURE, error };
	}
}
