import { authHeader } from '../_helpers/auth-header';
import { httpInterceptor } from '../_helpers/http-interceptor';
import sisConfig from '../sis.config.json';

export const historicalService = {
	searchMissions,
	getNavByMissionId,
	getAISByMissionId,
	getManualByMissionId,
	getRadarByMissionId,
	getCrewByMissionId,
	getAircraftByMissionId,
	getProfileByMissionId,
	getHistoricalMediaLinks,
	getHistoricalEventMarkers
};

function searchMissions(search) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(
		`${sisConfig.build[window.location.hostname].api}/missions/historical?missionNumber=` +
			encodeURIComponent(search.searchText) +
			'&rangeStartDate=' +
			encodeURIComponent(search.startDate) +
			'&rangeEndDate=' +
			encodeURIComponent(search.endDate) +
			'&polygonCoordinates=' +
			encodeURIComponent(search.coords),
		requestOptions
	).then(httpInterceptor);
}

function getNavByMissionId(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(
		`${sisConfig.build[window.location.hostname].api}/missions/historical/` +
			encodeURIComponent(id) +
			'/nav',
		requestOptions
	).then(httpInterceptor);
}

function getAISByMissionId(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(
		`${sisConfig.build[window.location.hostname].api}/missions/historical/` +
			encodeURIComponent(id) +
			'/ais',
		requestOptions
	).then(httpInterceptor);
}

function getManualByMissionId(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(
		`${sisConfig.build[window.location.hostname].api}/missions/historical/` +
			encodeURIComponent(id) +
			'/manual',
		requestOptions
	).then(httpInterceptor);
}

function getRadarByMissionId(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(
		`${sisConfig.build[window.location.hostname].api}/missions/historical/` +
			encodeURIComponent(id) +
			'/radar',
		requestOptions
	).then(httpInterceptor);
}

function getCrewByMissionId(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(
		`${sisConfig.build[window.location.hostname].api}/missions/historical/` +
			encodeURIComponent(id) +
			'/crew',
		requestOptions
	).then(httpInterceptor);
}

function getAircraftByMissionId(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(
		`${sisConfig.build[window.location.hostname].api}/missions/historical/` +
			encodeURIComponent(id) +
			'/aircraft',
		requestOptions
	).then(httpInterceptor);
}

function getProfileByMissionId(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(
		`${sisConfig.build[window.location.hostname].api}/missions/historical/` +
			encodeURIComponent(id) +
			'/profile',
		requestOptions
	).then(httpInterceptor);
}

function getHistoricalMediaLinks(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(
		`${sisConfig.build[window.location.hostname].api}/missions/historical/` +
			encodeURIComponent(id) +
			`/medialinks`,
		requestOptions
	).then(httpInterceptor);
}

function getHistoricalEventMarkers(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(
		`${sisConfig.build[window.location.hostname].api}/missions/historical/` +
			encodeURIComponent(id) +
			`/markers`,
		requestOptions
	).then(httpInterceptor);
}