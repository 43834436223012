// # Imports
// External Imports
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";

// Internal Imports
import sisConfig from '../../sis.config.json';
import { permissionService } from '../../_services/permission.service';

import sis3 from '../../_services/sis3.service';
import AlertList from '../Alert/AlertList';

// Assets
import { ReactComponent as Chat } from '../../assets/icons/icon_chat.svg';
import { ReactComponent as Profile } from '../../assets/icons/icon_profile.svg';
import { ReactComponent as Support } from '../../assets/icons/icon_help.svg';
import { ReactComponent as Close } from '../../assets/icons/close-dialog.svg';
import { ReactComponent as Email } from '../../assets/icons/ic_email_24px.svg';
import { ReactComponent as Phone } from '../../assets/icons/ic_phone_24px.svg';
import { ReactComponent as RealTime } from '../../assets/icons/icon_real-time.svg';
import { ReactComponent as Historical } from '../../assets/icons/icon_historical.svg';
import { ReactComponent as Media } from '../../assets/icons/icon_global-media.svg';
import { ReactComponent as ASIS } from '../../assets/icons/icon_global-asis.svg';
import { ReactComponent as Logo } from '../../assets/images/aims-c4-logo.svg';
import { ReactComponent as Reports } from '../../assets/icons/icon_global-reporting.svg';
import './nav-bar.scss';
import { profileActions } from '../../_modules/profiles/profile.actions';
import { httpInterceptor } from '../../_helpers/http-interceptor';
import { windowService } from '../../_services/window.service';
import { authActions } from '../../_modules/auth/auth.actions';
import { startConverse } from '../Chat/Chat';

export default function NavBar() {
	const intl = useIntl();
	const location = useLocation();
	const dispatch = useDispatch();

	const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
	useEffect(() => {
		if(isAuthenticated && !isLoading) {
			dispatch(authActions.buildUserObj(user, getAccessTokenSilently, location));
		}
	}, [ isAuthenticated, isLoading ]);

	let currPath = location.pathname;
	let mode = currPath.slice(1);

	const sisUser = JSON.parse(sessionStorage.getItem('sisUser')) || {};

	const version = process.env.REACT_APP_VERSION;

	const [lang, setLang] = useState('en');
	const [navDialogOpen, setNavDialogOpen] = useState('');
	const [loggingOut, setLoggingOut] = useState(false);
	const userBuilt = useSelector(state => state.authentication.loggedIn);
	const loggedIn = isAuthenticated && !isLoading && userBuilt;
	//const currUser = useSelector((state) => state.authentication.user);

	useEffect(() => {
		setLang(localStorage.getItem('sisLang') || 'en');
	}, []);

	// TODO: unused until we have a theme selector - defaults to dark
	// const handleThemeChange = () => {
	// 	//apply the theme to user profile
	// 	//set it rather than user selection for now
	// 	const theme = 'dark';

	// 	document.documentElement.className = '';
	// 	document.documentElement.classList.add(`theme-${theme}`);
	// };

	const navIcon = (id) => {
		switch (id) {
			case 'realtime':
				return <RealTime alt="Real Time" id="realtime-nav-icon" />;
			case 'historical':
				return <Historical alt="Historical" id="historical-nav-icon" />;
			case 'media':
				return <Media alt="Media" id="media-nav-icon" />;
			case 'asis':
				return <ASIS alt="Asis" id="asis-nav-icon" />;
			case 'reporting':
				return <Reports alt="Reporting" id="reporting-nav-icon" />;
			default:
				return '';
		}
	};

	const navList = sisConfig.sections.map(function (section, key) {
		if (section.allowedFeatures.some(permissionService.hasFeature)) {
			return (
				<li
					id={'nav-bar-mode-' + section.id + '-item '}
					className={mode === section.id ? 'selected' : ''}
					key={'nav-list-' + key}
					title={intl.formatMessage({
						id: section.name
					})}
				>
					<NavLink exact to={'/' + section.id}>
						{navIcon(section.id)}
					</NavLink>
				</li>
			);
		}
	});

	let breadcrumb = (mode) => {
		if (mode !== '' && mode !== 'login') {
			return (
				<div id="nav-bar-breadcrumb" className="nav-bar-breadcrumbs">
					{intl.formatMessage({
						id: 'app.navbar.title.' + mode
					})}
				</div>
			);
		} else {
			return '';
		}
	};

	return (
		<div id="nav-bar-ctn" className="nav-bar-container">
			<AlertList></AlertList>
			<div className="nav-bar-left-container">
				<div id="nav-bar-brand" className="nav-bar-branding">
					<Logo id="nav-bar-brand-img" alt="CarteNav" />
				</div>
				{breadcrumb(mode)}
				<div
					id="nav-bar-mode-ctn"
					className={classNames(
						'nav-bar-actions',
						loggedIn && !loggingOut ? '' : 'hidden'
					)}
				>
					<ul id="nav-bar-mode-list">{navList}</ul>
				</div>
			</div>
			<div id="nav-bar-action-ctn" className="nav-bar-actions">
				<ul id="nav-bar-action-list">
					<li
						id="nav-bar-action-chat-item"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							document
								.getElementById('controlbox')
								.classList.toggle('hidden');
							R.map(n => {
								n.title = intl.formatMessage({
									id: "converse.hide.contacts.tooltip"
								});
							}, document.getElementsByClassName("list-toggle group-toggle controlbox-padded"))
						}}
						className={loggedIn && !loggingOut ? '' : 'hidden'}
						title={intl.formatMessage({
							id: 'app.navbar.title.chat'
						})}
					>
						<Chat
							id="nav-bar-action-chat-img"
							className={'action-icon'}
						/>
					</li>
					<li
						id="nav-bar-action-support-item"
						className={navDialogOpen === 'help' ? 'selected' : ''}
						onClick={() => {
							if (navDialogOpen === 'help') {
								setNavDialogOpen('');
							} else {
								setNavDialogOpen('help');
							}
						}}
						title={intl.formatMessage({
							id: 'app.navbar.title.help'
						})}
					>
						<Support
							id="nav-bar-action-support-img"
							className={'action-icon'}
						/>
					</li>
					<li
						id="nav-bar-action-lang-item"
						className={'language-toggle'}
						onClick={() => {
							const newLang = lang === 'en' ? 'fr' : 'en';
							localStorage.setItem('sisLang', newLang);
							if (permissionService.hasFeature('Reports')) {
								sis3.changeLocalization(newLang);
							}
							setLang(newLang);

							window.location.reload();
						}}
						title={intl.formatMessage({
							id: 'app.navbar.title.language'
						})}
					>
						{lang}
					</li>
					<li
						id="nav-bar-action-profile-item"
						className={loggedIn && !loggingOut ? '' : 'hidden'}
						onClick={() => {
							if (navDialogOpen === 'profile') {
								setNavDialogOpen('');
							} else {
								setNavDialogOpen('profile');
							}
						}}
						title={intl.formatMessage({
							id: 'app.navbar.title.profile'
						})}
					>
						<Profile
							id="nav-bar-action-profile-img"
							className={'action-icon'}
						/>
					</li>
				</ul>
				<div
					id="nav-bar-profile-ctn"
					className={classNames(
						'dialog-box',
						navDialogOpen === 'profile' ? 'open' : ''
					)}
				>
					<Close
						id="nav-bar-profile-close-img"
						className="dialog-close-icon"
						onClick={() => {
							setNavDialogOpen('');
						}}
					></Close>
					<div id="nav-bar-profile-header" className="contact-title">
						<FormattedMessage id="app.navbar.title.profile" />
					</div>
					<div className="contact">
						<FormattedMessage id="app.navbar.profile.username.label" />
						:{' '}
						{sisUser.displayName !== null &&
						sisUser.displayName !== undefined
							? sisUser.displayName
							: sisUser.username}
					</div>
					<div>
						<FormattedMessage id="app.navbar.profile.version.label" />
						: {version}
					</div>{' '}
					<div id="nav-bar-profile-customer-ctn" className="contact">
						<NavLink
							exact
							to="/login"
							onClick={() => {
								setLoggingOut(true);
								logout();
								dispatch(authActions.destroyUserObj())
								setNavDialogOpen('');
							}}
						>
							<FormattedMessage id="app.logout.title" />
						</NavLink>
					</div>
				</div>
				<div
					id="nav-bar-support-ctn"
					className={classNames(
						'dialog-box',
						navDialogOpen === 'help' ? 'open' : ''
					)}
				>
					<Close
						id="nav-bar-support-close-img"
						className="dialog-close-icon"
						onClick={() => {
							setNavDialogOpen('');
						}}
					/>
					<div id="nav-bar-support-header" className="contact-title">
						<FormattedMessage id="app.navbar.title.help" />
					</div>
					<div id="nav-bar-support-customer-ctn" className="contact">
						<FormattedMessage id="app.navbar.support.customer" />
						<div id="nav-bar-support-customer-phone">
							<Phone
								id="nav-bar-support-customer-phone-img"
								className="contact-icon"
							/>
							+1(709)728-9098
						</div>
						<div id="nav-bar-support-customer-email">
							<Email
								id="nav-bar-support-customer-email-img"
								className="contact-icon"
							/>
							CustomerSupport@palaerospace.com
						</div>
					</div>
					<div id="nav-bar-support-help-ctn" className="contact">
						<FormattedMessage id="app.navbar.support.serviceDesk" />
						<div id="nav-bar-support-help-phone1">
							<Phone
								id="nav-bar-support-help-phone1-img"
								className="contact-icon"
							/>
							+1(709)570-4357
						</div>
						<div id="nav-bar-support-help-phone2">
							<Phone
								id="nav-bar-support-help-phone2-img"
								className="contact-icon"
							/>
							+1(709)769-4737 (
							<FormattedMessage id="app.navbar.support.afterHours" />
							)
						</div>
						<div id="nav-bar-support-help-email">
							<Email
								id="nav-bar-support-help-email-img"
								className="contact-icon"
							/>
							ServiceDesk@onepal.com
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
