// # Imports
// External Imports
import React from 'react';
import * as R from 'ramda';
import L from 'leaflet';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

// Internal Imports
import MissionDetails from '../MissionDetails';

// # Assets
import 'react-datepicker/dist/react-datepicker.css';
import './realtime-sidebar.scss';

export default (props) => {
	const intl = useIntl();
	const isOpen = props.sideOpen;
	const zoomTo = (mission) => {
		let tempMarkers = [];
		if (mission.nav.data.length !== 0) {
			for (let i = 0; i < mission.nav.data.length; i++) {
				mission.nav.positions[mission.nav.data[i].id].coords.map(
					(coord) => {
						tempMarkers.push([coord[1], coord[0]]);
					}
				);
			}
		}
		if (mission.ais.data.length !== 0) {
			for (let i = 0; i < mission.ais.data.length; i++) {
				mission.ais.positions[mission.ais.data[i].id].coords.map(
					(coord) => {
						tempMarkers.push([coord[1], coord[0]]);
					}
				);
			}
		}
		if (mission.radar.data.length !== 0) {
			for (let i = 0; i < mission.radar.data.length; i++) {
				mission.radar.positions[mission.radar.data[i].id].coords.map(
					(coord) => {
						tempMarkers.push([coord[1], coord[0]]);
					}
				);
			}
		}
		if (mission.manual.data.length !== 0) {
			for (let i = 0; i < mission.manual.data.length; i++) {
				mission.manual.positions[mission.manual.data[i].id].coords.map(
					(coord) => {
						tempMarkers.push([coord[1], coord[0]]);
					}
				);
			}
		}

		const bottomPad =
			window
				.getComputedStyle(
					document.getElementById('layout-bottom-bar-ctn')
				)
				.getPropertyValue('bottom') === '0px'
				? document.getElementById('layout-bottom-bar-ctn').clientHeight
				: 0;

		const sidePad = document.getElementById('layout-side-bar').clientWidth;

		if (tempMarkers.length !== 0) {
			const tempBounds = L.latLngBounds(tempMarkers);
			props.map.flyToBounds(tempBounds, {
				paddingBottomRight: [0, bottomPad],
				paddingTopLeft: [sidePad, 0]
			});
		}
	};

	const missionList = R.values(props.missions).map((item, key) => {
		if (item.metadata && item.aircraft && item.crew && item.profile) {
			return (<div>
				<MissionDetails
					key={item.id}
					idx={key}
					zoomTo={(e, item) => {
						e.preventDefault();
						e.stopPropagation();
						zoomTo(item);
					}}
					toggleVisibility={(e) => {
						e.preventDefault();
						e.stopPropagation();
						props.onVisibilityToggle(item);
					}}
					item={item}
					mode="realtime"
					showVideoPlayer={props.showVideoPlayer}
					missionCount={props.missions.length}
				/>
				</div>
			);
		} else {
			return;
		}
	});

	return (
		<div
			id="real-time-side-bar-ctn"
			className="real-time-side-bar-container"
		>
			<div
				id="real-time-side-bar-header"
				className="real-time-side-bar-header"
			>
				<div></div>
				<span>
					{intl.formatMessage({
						id: 'app.map.realtime.missions.header'
					})}
				</span>
				<div></div>
			</div>
			{missionList}
			<div
				id="layout-side-bar-btn"
				className="side-bar-button-container"
			></div>
		</div>
	);
};
