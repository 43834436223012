import React from 'react';

import './progress-spinner.scss';

export default function ProgressSpinner(props) {
    return (
    <div className="spinner-ctn">
        <div className={`spinner-${props.size === undefined ? "small" : props.size}`}>
        </div>
    </div>
    )
}