import React,{useEffect} from 'react';
import { useIntl } from 'react-intl';

import './legend.scss';

export default function Legend(props) {
	const legendItemsData = [
		{
			id: "app.map.sidebar.legend.unknown",
			src: "icon_track-dfo-unknown.png"
		},
		{
			id: "app.map.sidebar.legend.other",
			src: "icon_track-dfo-unknown.png"
		},
		{
			id: "app.map.sidebar.legend.foreign.fishing.vessel",
			src: "icon_track-foreign-fishing-vessel.png"
		},
		{
			id: "app.map.sidebar.legend.unknown.fishing.vessel",
			src: "icon_track-unknown-fishing-vessel.png"
		},
		{
			id: "app.map.sidebar.legend.can.fishing.vessel",
			src: "icon_track-can-fishing-vessel.png"
		},
		{
			id: "app.map.sidebar.legend.patrol.vessel",
			src: "icon_track-patrol-vessel.png"
		},
		{
			id: "app.map.sidebar.legend.pleasure.vessel",
			src: "icon_track-pleasure-craft.png"
		},
		{
			id: "app.map.sidebar.legend.submarine",
			src: "icon_track-submarine.png"
		},
		{
			id: "app.map.sidebar.legend.unknown.vessel",
			src: "icon_track-unknown-vessel.png"
		},
		{
			id: "app.map.sidebar.legend.warship",
			src: "icon_track-warship.png"
		},
		{
			id: "app.map.sidebar.legend.iceberg",
			src: "icon_track-iceberg.png"
		},
		{
			id: "app.map.sidebar.legend.buoy",
			src: "icon_track-buoy.png"
		},
		{
			id: "app.map.sidebar.legend.gear",
			src: "icon_track-buoy.png"
		},
		{
			id: "app.map.sidebar.legend.animal",
			src: "icon_track-mammal.png"
		},
		{
			id: "app.map.sidebar.legend.mission.event",
			src: "icon_track-dfo-mission-event.png"
		},
		{
			id: "app.map.sidebar.legend.pack.ice",
			src: "icon_track-dfo-unknown.png"
		},
		{
			id: "app.map.sidebar.legend.fishing.gear",
			src: "icon_track-fishing-gear.png"
		},
		{
			id: "app.map.sidebar.legend.pollutant.vessel",
			src: "icon_track-pollutant.png"
		},
		{
			id: "app.map.sidebar.legend.fixed.wing.aircraft",
			src: "icon_track-aircraft-fixed-wing.png"
		},
		{
			id: "app.map.sidebar.legend.rotary.aircraft",
			src: "icon_track-aircraft-rotary.png"
		},
		{
			id: "app.map.sidebar.legend.seaplane.aircraft",
			src: "icon_track-aircraft-seaplane.png"
		},
		{
			id: "app.map.sidebar.legend.infrastructure.landing.pad",
			src: "icon_track-infrastructure-landing-pad.png"
		},
		{
			id: "app.map.sidebar.legend.infrastructure.aq.fish",
			src: "icon_track-infrastructure-aq-fish.png"
		},
		{
			id: "app.map.sidebar.legend.infrastructure.aq.shellfish",
			src: "icon_track-infrastructure-aq-fish.png"
		},
		{
			id: "app.map.sidebar.legend.infrastructure.boat.launch",
			src: "icon_track-infrastructure-boat-launch.png"
		},
		{
			id: "app.map.sidebar.legend.campsite",
			src: "icon_track-campsite.png"
		},
		{
			id: "app.map.sidebar.legend.coastline",
			src: "icon_track-coastline.png"
		},
		{
			id: "app.map.sidebar.legend.floating.accommodation",
			src: "icon_track-floating-accommodation.png"
		},
		{
			id: "app.map.sidebar.legend.light.station",
			src: "icon_track-light-station.png"
		},
		{
			id: "app.map.sidebar.legend.log.dump",
			src: "icon_track-campsite.png"
		},
		{
			id: "app.map.sidebar.legend.logging.general",
			src: "icon_track-campsite.png"
		},
		{
			id: "app.map.sidebar.legend.marina",
			src: "icon_track-marina.png"
		},
		{
			id: "app.map.sidebar.legend.moorage",
			src: "icon_track-moorage.png"
		},
		{
			id: "app.map.sidebar.legend.port",
			src: "icon_track-marina.png"
		},
		{
			id: "app.map.sidebar.legend.road.access",
			src: "icon_track-road-access.png"
		},
		{
			id: "app.map.sidebar.legend.marine.mammal",
			src: "icon_track-mammal.png"
		},
		{
			id: 'app.map.sidebar.legend.friend.airspace',
			src: 'sfap-----------.png'
		},
		{
			id: 'app.map.sidebar.legend.friend.ground',
			src: 'sfgp-----------.png'
		},
		{
			id: 'app.map.sidebar.legend.friend.seasurface',
			src: 'sfgpe----------.png'
		},
		{
			id: 'app.map.sidebar.legend.friend.subsurface',
			src: 'sfup-----------.png'
		},
		{
			id: 'app.map.sidebar.legend.neutral.airspace',
			src: 'snap-----------.png'
		},
		{
			id: 'app.map.sidebar.legend.neutral.groundsea',
			src: 'snsp-----------.png'
		},
		{
			id: 'app.map.sidebar.legend.neutral.subsurface',
			src: 'snup-----------.png'
		},
		{
			id: 'app.map.sidebar.legend.hostile.airspace',
			src: 'shap-----------.png'
		},
		{
			id: 'app.map.sidebar.legend.hostile.groundsea',
			src: 'shgp-----------.png'
		},
		{
			id: 'app.map.sidebar.legend.hostile.subsurface',
			src: 'shup-----------.png'
		},
		{
			id: 'app.map.sidebar.legend.unknown.airspace',
			src: 'suap-----------.png'
		},
		{
			id: 'app.map.sidebar.legend.unknown.groundsea',
			src: 'sugpu----------.png'
		},
		{
			id: 'app.map.sidebar.legend.unknown.subsurface',
			src: 'suup-----------.png'
		}
	];
	const intl = useIntl();

	let legendItems = legendItemsData.map(function (data, key) {
		return (
			<li className="legend-item" key={key} id={'legend-item-' + key}>
				<img
					className="legend-item-img"
					alt={intl.formatMessage({
						id: data.id
					})}
					src={'/images/' + data.src}
				/>
				{intl.formatMessage({
					id: data.id
				})}
			</li>
		);
	});
	useEffect(() => {
		props.downloadMediaRequestOff();
	},[props.downloadMediaRequestOff])
	
	return <ul className="legend">{legendItems}</ul>;
}
