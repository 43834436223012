import { store } from './store';

var intervalId;
const start = () => {
	if (intervalId) {
		return;
	}

	intervalId = setInterval(() => {
		store.dispatch({ type: 'TICK' });
	}, 1000);
};

const stop = () => {
	clearInterval(intervalId);
	intervalId = null;
};

export default { start, stop };
