// # Imports

import * as R from 'ramda';
import PropTypes from 'prop-types';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

class Paginator extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPageNumber: 1,
			defaultItemsPerPage: 10,
			totalPages: 1,
			pages: []
		};
	}

	// # Methods

	goToPage = (index) => {
		return this.setState((prevState) => {
			return {
				currentPageNumber: index
			};
		});
	};

	lastPage = () => {
		return this.goToPage(this.state.totalPages);
	};

	firstPage = () => {
		return this.goToPage(1);
	};

	prevPage = () => {
		return this.goToPage(R.max(this.state.currentPageNumber - 1, 1));
	};

	nextPage = () => {
		return this.goToPage(
			R.min(this.state.currentPageNumber + 1, this.state.totalPages)
		);
	};

	// # Lifecycle

	static getDerivedStateFromProps(nextProps, currentState) {
		const pages = R.splitEvery(
			nextProps.itemsPerPage || currentState.defaultItemsPerPage,
			nextProps.data
		);

		return {
			totalPages: pages.length,
			currentPage: R.max(currentState.currentPage, pages.length),
			pages: pages
		};
	}

	render() {
		// Using Render Prop
		const Children = this.props.children(
			this.state.currentPageNumber,
			this.state.totalPages,
			this.state.pages[this.state.currentPageNumber - 1],
			this.firstPage,
			this.lastPage,
			this.nextPage,
			this.prevPage,
			this.goToPage
		);
		return (
			<div className="paginator-ctn">
				{Children}
			</div>
		);
	}
}

Paginator.propTypes = {
	data: PropTypes.array.isRequired,
	itemsPerPage: PropTypes.number,
	children: PropTypes.func.isRequired
};

export default Paginator;
