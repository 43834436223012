// # Imports

import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { registerLocale } from 'react-datepicker';
import * as R from 'ramda';
import * as pl from 'partial.lenses';
import fr from 'date-fns/locale/fr';
// import Sortable from 'react-anything-sortable';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import L from 'leaflet';

// # Internal Imports
import Paginator from '../Paginator.jsx';
import SearchFilter from '../SearchFilter.jsx';
import { httpXMLInterceptor } from '../../_helpers/http-interceptor.js';
import { profileActions } from '../../_modules/profiles/profile.actions';
import OverlayItem from './OverlayItem';
import { alertActions } from '../../_modules/alerts/alert.actions';
import sisConfig from '../../sis.config.json';

// # Assets
import Close from '../../assets/icons/close-dialog.svg';
import CheckboxNotSelected from '../../assets/icons/checkbox-not-selected.svg';
import CheckboxSelected from '../../assets/icons/checkbox-selected.svg';
import 'react-datepicker/dist/react-datepicker.css';
import './overlay-sidebar.scss';

export default (props) => {
	const intl = useIntl();

	registerLocale('fr', fr);

	// # Global State
	const profile = useSelector((state) => state.profile);
	let reversedOverlayList = R.reverse(profile.overlays);
	const dispatch = useDispatch();

	// # Local State
	const [allOverlays, setAllOverlays] = useState([]);

	// # Effects
	useEffect(() => {
		const unCapitalizeFirstLetter = (
			[first, ...rest],
			locale = navigator.language
		) => {
			return [first.toLocaleLowerCase(locale), ...rest].join('');
		};

		const cleanKeyPair = (pair) => {
			const newKey = unCapitalizeFirstLetter(
				R.test(/_/, pair[0]) ? pair[0].split('_')[1] : pair[0]
			);
			return [newKey, pair[1]];
		};

		const cleanKeys = (obj) => {
			return R.pipe(R.toPairs, R.map(cleanKeyPair), R.fromPairs)(obj);
		};

		fetch(
			`${sisConfig.build[window.location.hostname].wms}?service=WMS&request=GetCapabilities&version=1.3.0&tiled=true`
		)
			.then(httpXMLInterceptor)
			.then((res) => {
				return res.Capability.Layer.Layer.map(cleanKeys);
			})
			.then((res) => {
				setAllOverlays(res);
			});
	}, []);

	// # Funcs
	const onSortEnd = ({ oldIndex, newIndex }) => {
		const newOverlayList = arrayMove(reversedOverlayList, oldIndex, newIndex);

		const restoredOverlayList = R.reverse(newOverlayList);

		dispatch(
			profileActions.updateProfile(
				pl.set(['overlays'], restoredOverlayList, profile)
			)
		);

		props.map.overlayFeatureGroup.clearLayers();

		restoredOverlayList.filter((overlay) => {
			return overlay.shown === '1' ? true : false;
		})
		.map((overlay) => {
			const tempLayer = L.tileLayer
				.wms(
					`${sisConfig.build[window.location.hostname].wms}`,
					{
						layers: overlay.name,
						format: 'image/png',
						transparent: true,
						opacity: overlay.opacity / 100
					}
				)
				.addTo(props.map.overlayFeatureGroup);
		});
	};

	const preventSort = (e) => {
		if (e.path[0].className !== 'draggable') {
			return true;
		} else {
			return false;
		}
	};

	const removeFromProfile = (overlay) => {
		const overlayOptic = [
			'overlays',
			pl.find(R.whereEq({ name: overlay.name }))
		];

		dispatch(
			profileActions.updateProfile(pl.remove(overlayOptic, profile))
		);

		props.map.overlayFeatureGroup.eachLayer((layer) => {
			if (layer.options.layers === overlay.name) {
				props.map.overlayFeatureGroup.removeLayer(layer);
			}
		});
	};

	const changeOpacity = (overlay) => {
		props.map.overlayFeatureGroup.eachLayer((layer) => {
			if (layer.options.layers === overlay.name) {
				layer.setOpacity(overlay.opacity / 100);
			}
		});
	};

	const handleOpacityFinished = (overlay) => {
		const overlayOpacityLens = pl.set(
			['overlays', pl.find(R.whereEq({ name: overlay.name })), 'opacity'],
			overlay.opacity
		);

		const newProfile = overlayOpacityLens(profile);
		dispatch(profileActions.updateProfile(newProfile));		

		reversedOverlayList = R.reverse(newProfile.overlays);
	}

	const handleOverlaySelection = (overlay) => {
		const index = R.findIndex((o) => {
			return o.name === overlay.name;
		}, profile.overlays);

		if (index !== -1) {
			return removeFromProfile(overlay);
		}

		const overlayOptic = ['overlays', pl.appendTo];

		const newProfile = pl.set(
			overlayOptic,
			{
				name: overlay.name,
				url: sisConfig.build[window.location.hostname].wms,
				shown: '1',
				opacity: '100'
			},
			profile
		);

		dispatch(profileActions.updateProfile(newProfile));

		let layerFound = false;
		props.map.overlayFeatureGroup.eachLayer((layer) => {
			if (layer.options.layers === overlay.name) {
				props.map.overlayFeatureGroup.removeLayer(layer);
				layerFound = true;
			}
		});

		if (!layerFound) {
			L.tileLayer
				.wms(
					`${sisConfig.build[window.location.hostname].wms}`,
					{
						layers: overlay.name,
						format: 'image/png',
						transparent: true,
						opacity: overlay.opacity / 100
					}
				)
				.addTo(props.map.overlayFeatureGroup);
		}
	};

	const toggleVisibility = (overlay) => {
		const shown = overlay.shown === '1' ? '0' : '1';

		const overlayLense = pl.set(
			['overlays', pl.find(R.whereEq({ name: overlay.name })), 'shown'],
			shown
		);

		const newProfile = overlayLense(profile);

		dispatch(profileActions.updateProfile(newProfile));

		let layerFound = false;
		props.map.overlayFeatureGroup.eachLayer((layer) => {
			if (layer.options.layers === overlay.name) {
				props.map.overlayFeatureGroup.removeLayer(layer);
				layerFound = true;
			}
		});

		if (!layerFound) {
			props.map.overlayFeatureGroup.eachLayer(function (layer){
				props.map.overlayFeatureGroup.removeLayer(layer)
			});
			newProfile.overlays.filter((overlay) => {
				return overlay.shown === '1' ? true : false;
			})
			.map((overlay) => {
				const tempLayer = L.tileLayer
					.wms(
						`${sisConfig.build[window.location.hostname].wms}`,
						{
							layers: overlay.name,
							format: 'image/png',
							transparent: true,
							opacity: overlay.opacity / 100
						}
					)
					.addTo(props.map.overlayFeatureGroup);
			});
		}
	};

	const SortableOverlayContainer = sortableContainer(({ children }) => (
		<div className="overlay-list-container">{children}</div>
	));

	const SortableOverlay = sortableElement(({ overlay }) => {
		return (
			<OverlayItem
				index={overlay.name}
				key={overlay}
				overlay={overlay}
				mapLayers={props.map.overlayFeatureGroup}
				sortData={overlay}
				preventSort={preventSort}
				idx={overlay.name}
				removeFromProfile={removeFromProfile}
				toggleVisibility={toggleVisibility}
				changeOpacity={changeOpacity}
				updateProfileOpacity={handleOpacityFinished}
			/>
		);
	});

	const page = (
		currentPageNumber,
		totalPages,
		pageData,
		firstPage,
		lastPage,
		nextPage,
		prevPage
	) => {
		return (
			<>
				<ul
					id="overlay-search-results-list"
					className="search-results-list"
				>
					{pageData}
				</ul>

				{totalPages > 1 && (
					<div className="pagination-btn-container">
						<div className="pagination-btn-left">
							<button
								className="btn-first"
								id={'overlay-results-first'}
								title={intl.formatMessage({
									id: 'app.pagination.first.button.tooltip'
								})}
								disabled={currentPageNumber === 1}
								onClick={firstPage}
							>
								{intl.formatMessage({
									id: 'app.pagination.first.button'
								})}
							</button>
							<button
								className="btn-prev"
								id={'overlay-results-prev'}
								title={intl.formatMessage({
									id: 'app.pagination.previous.button.tooltip'
								})}
								disabled={currentPageNumber === 1}
								onClick={prevPage}
							></button>
						</div>
						<div className="pagination-btn-right">
							<button
								className="btn-next"
								id={'overlay-results-next'}
								disabled={totalPages === currentPageNumber}
								title={intl.formatMessage({
									id: 'app.pagination.next.button.tooltip'
								})}
								onClick={nextPage}
							></button>
							<button
								onClick={lastPage}
								id={'overlay-results-last'}
								className="btn-last"
								title={intl.formatMessage({
									id: 'app.pagination.last.button.tooltip'
								})}
								disabled={totalPages === currentPageNumber}
							>
								{intl.formatMessage({
									id: 'app.pagination.last.button'
								})}
							</button>
						</div>
					</div>
				)}
			</>
		);
	};

	return (
		<div
			id="layout-side-bar"
			className={classNames('side-bar', {
				'show-side-bar': props.sideOpen
			})}
		>
			<div className="overlay-header">
				<div>
					<FormattedMessage id="app.map.overlays.header" />
				</div>
				<button
					type="button"
					onClick={(e) => {
						props.setManageOverlays(false);
					}}
					id={'overlay-sidebar-back'}
					className={'btn close-btn'}
					disabled={false}
					title={intl.formatMessage({
						id: 'app.map.general.mappanel.close.button.tooltip'
					})}
				>
					<img alt="Close" src={Close} />
				</button>
			</div>

			<Tabs
				id="overlay-sidebar-tabs"
				className="overlay-sidebar-tabs"
				selectedTabClassName="overlay-tab-selected"
			>
				<TabList
					id="overlay-sidebar-tab-list"
					className="overlay-sidebar-tab-list"
				>
					<Tab
						id="overlay-sidebar-tab-manage"
						key="overlay-manage"
						className="overlay-sidebar-tab"
					>
						<FormattedMessage id={'app.map.overlays.manage.tab'} />
					</Tab>
					<Tab
						id="overlay-sidebar-tab-selection"
						key="overlay-selection"
						className="overlay-sidebar-tab"
					>
						<FormattedMessage id={'app.map.overlays.list.tab'} />
					</Tab>
				</TabList>
				<TabPanel
					id="overlay-sidebar-tab-manage"
					key="overlay-manage"
					className="overlay-tab-panel"
				>
					<SortableOverlayContainer
						axis="y"
						pressDelay={10}
						onSortEnd={onSortEnd}
						shouldCancelStart={preventSort}
					>
						{reversedOverlayList.map((overlay, key) => (
							<SortableOverlay
								index={key}
								key={key}
								overlay={overlay}
							/>
						))}
					</SortableOverlayContainer>
				</TabPanel>
				<TabPanel
					id="overlay-sidebar-select"
					key="overlay-selection"
					className="overlay-sidebar-tab-panel"
				>
					<SearchFilter
						data={allOverlays}
						placeholder={intl.formatMessage({
							id: 'app.map.overlays.add.search.placeholder'
						})}
						onSearch={(searchText, data) => {
							if (searchText === '')
								return data;
							return data.filter((item) => {
								
								if(item.name.includes(":")) {
									const splitItem = item.name.split(":");
									if(splitItem.length > 1)
										return splitItem[1].toLowerCase().includes(searchText.toLowerCase());
									else 
										return false;
								} else
									return item.name.toLowerCase().includes(searchText.toLowerCase())
							});
						}}
					>
						{({ searchText, data }) => {
							const AllOverlayList = data.map((overlay, key) => {
								const isChecked = profile.overlays.some(
									(item) => {
										return overlay.name === item.name;
									}
								)
									? true
									: false;
								return (
									<li
										id={
											'overlay-selection-list-item-' + key
										}
										className={`overlay-selection-list-item ${
											isChecked
												? 'overlay-selection-list-item-selected'
												: ''
										}`}
										onClick={() => {
											if (
												profile.overlays.length < 10 ||
												isChecked
											) {
												handleOverlaySelection(overlay);
											} else {
												const overlayWarning = {
													level: 'warning',
													messageId:
														'app.notification.map.warning.overlay.limit',
													timeStamp: new Date()
												};

												dispatch(
													alertActions.addAlert(
														overlayWarning
													)
												);
											}
										}}
									>
										<div>
											{overlay.name.replace(
												'SISMap:',
												''
											)}
										</div>

										<img
											title={
												isChecked
													? intl.formatMessage({
															id:
																'app.map.overlays.add.remove.button.tooltip'
													  })
													: intl.formatMessage({
															id:
																'app.map.overlays.add.add.button.tooltip'
													  })
											}
											alt={
												isChecked
													? intl.formatMessage({
															id:
																'app.map.overlays.add.remove.button.tooltip'
													  })
													: intl.formatMessage({
															id:
																'app.map.overlays.add.add.button.tooltip'
													  })
											}
											src={
												isChecked
													? CheckboxSelected
													: CheckboxNotSelected
											}
										/>
									</li>
								);
							});

							return (
								<Paginator
									data={AllOverlayList}
									itemsPerPage={10}
									key={searchText}
								>
									{page}
								</Paginator>
							);
						}}
					</SearchFilter>
				</TabPanel>
			</Tabs>

			<div
				id="layout-side-bar-btn"
				className="side-bar-button-container"
			></div>
		</div>
	);
};
