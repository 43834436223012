import React, { useEffect } from 'react'
import './dropdown-buttons.scss'
import { useIntl } from 'react-intl';

let __uid = 0;

export default function DropdownButtons (props){
    __uid = (__uid + 1) % (document.querySelectorAll(".drop-down-btn").length+1);
    const uid = props.title + (__uid);
    const btn_id = `drop-down-btn-${__uid}`;
    const intl = useIntl();


    function closeOpenItems(current) {
        let openMenus = document.querySelectorAll('.drop-down-container');
        openMenus.forEach(function(menus) {
            if (menus.classList.contains('display-show') && menus.isSameNode(current)){}
            else
                menus.classList.remove('display-show')
        });  
    }
    
    function toggleButtonList() {
        let currentSelection = document.getElementById(uid);
        closeOpenItems(currentSelection);
        document.getElementById(uid).classList.toggle('display-show');
    }

    function performCallback(callback) {
        callback();
        toggleButtonList();
    }

    document.addEventListener ('click', event => { 
        if (!event.target.matches('.drop-down-btn')) { 
            var dropdowns = document.getElementsByClassName("drop-down-container"); 
         
            for (let i = 0; i < dropdowns.length; i++) { 
                var openDropdown = dropdowns[i]; 
                if (openDropdown.classList.contains('display-show')) { 
                    openDropdown.classList.remove('display-show'); 
                }  
            }      
        }
    }) 

    return (
        <div>
            <button type="button" className="drop-down-btn" onClick={() => toggleButtonList()} disabled={Object.values(props.option).every(entries => entries.disabled === true)}>
                {props.title}
                <div id="arrow-down"></div>
            </button>
            <div id={uid} className="drop-down-container">
                {Object.keys(props.option).map(c => (<div className={`drop-down-btn-list${props.option[c].disabled ? "-disabled" : ""}`} 
                                                            onClick={() => props.option[c].disabled ? {} : performCallback(props.option[c].callback)}
                                                    > 
                                                    {c}
                                                    </div>
                                                ))}                               
            </div>
        </div>
    );
}
