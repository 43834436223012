import React, { useState, useEffect } from 'react';
import ProgressSpinner from './ProgressSpinner';

import './logging-in.scss';
import { useIntl } from 'react-intl';

function LoggingIn() {
    const intl = useIntl();
    return (
    <div id="logging-in-ctn" className="logging-in-ctn">
        <ProgressSpinner size="large"/>
        {intl.formatMessage({
            id: "app.logging.in.msg"
        })}
    </div>
    );
}

export { LoggingIn };
