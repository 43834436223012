import { authHeader } from '../_helpers/auth-header';
import { httpInterceptor } from '../_helpers/http-interceptor';
import sisConfig from '../sis.config.json';

let mode = 'realtime';

export const mediaService = {
	getMedia,
	getDetails,
	getThumbnail,
	getLargerThumbnail,
	associateMedia,
	disAssociateMedia,
	setMode
};
const requestOptions = {
	method: 'GET',
	headers: authHeader()
};

function setMode(newMode) {
	mode = newMode;
}

function getThumbnail(mediaId, dnsAddress = "") {
	dnsAddress = dnsAddress === undefined ? "" : dnsAddress;
	if(mode === 'realtime') 
	{
		return fetch(
			`${sisConfig.build[window.location.hostname].api}/media/thumbnail/${mediaId}?source=${dnsAddress === "" ? sisConfig.build[window.location.hostname].remote_media : dnsAddress}`,
			requestOptions
		);
	}
	else 
	{
		return fetch(
			`${sisConfig.build[window.location.hostname].api}/media/thumbnail/${mediaId}`,
			requestOptions
		);
	}
}

function getLargerThumbnail(mediaId, dnsAddress = "") {
	dnsAddress = dnsAddress === undefined ? "" : dnsAddress;
	if(mode === 'realtime') 
	{
		return fetch(
			`${sisConfig.build[window.location.hostname].api}/media/thumbnail/${mediaId}?source=${dnsAddress === "" ? sisConfig.build[window.location.hostname].remote_media : dnsAddress}&size=large`,
			requestOptions
		);
	} 
	else 
	{
		return fetch(
			`${sisConfig.build[window.location.hostname].api}/media/thumbnail/${mediaId}?size=large`,
			requestOptions
		);
	}
}

function getMedia(mediaId, dnsAddress = "") {
	dnsAddress = dnsAddress === undefined ? "" : dnsAddress;
	if(mode === 'realtime') 
	{
		return fetch(
			`${sisConfig.build[window.location.hostname].api}/media/content/${mediaId}?source=${dnsAddress === "" ? sisConfig.build[window.location.hostname].remote_media : dnsAddress}`,
			requestOptions
		);
	}
	else 
	{
		return fetch(
			`${sisConfig.build[window.location.hostname].api}/media/content/${mediaId}`,
			requestOptions
		);
	}
}

function getDetails(mediaId, dnsAddress = "") {
	dnsAddress = dnsAddress === undefined ? "" : dnsAddress;
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};
	if(mode === 'realtime') 
	{
		return fetch(
			`${sisConfig.build[window.location.hostname].api}/media/${mediaId}?source=${dnsAddress === "" ? sisConfig.build[window.location.hostname].remote_media : dnsAddress}`,
			requestOptions
		).then(httpInterceptor);
	}
	else 
	{
		return fetch(
			`${sisConfig.build[window.location.hostname].api}/media/${mediaId}`,
			requestOptions
		).then(httpInterceptor);
	}
}
function associateMedia(trackId, formData) {
	const options = {
		method: 'POST',
		headers: authHeader(),
		body: formData
	};
	return fetch(
		`${sisConfig.build[window.location.hostname].api}/targets/historical/${trackId}/media`,
		options
	)
		.then(httpInterceptor)
		.catch((error) => {
			console.error(error);
		});
}
function disAssociateMedia(trackId, mediaId) {
	return fetch(
		`${sisConfig.build[window.location.hostname].api}/targets/historical/${trackId}/media/${mediaId}`,
		{
			method: 'DELETE',
			headers: authHeader()
		}
	)
		.then(httpInterceptor)
		.catch((error) => {
			console.error(error);
		});
}
