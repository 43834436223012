// # Imports

// External imports
import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect } from 'react';
import { Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';

// Internal imports
import { startConverse } from './_components/Chat/Chat';
import { Login } from './_components/Login/Login';
import { GlobalMedia } from './_components/GlobalMedia/GlobalMedia';
import { ASISContainer } from './_components/ASIS/ASISContainer';

import ReportingContainer from './_components/Reporting/ReportingContainer';
import { Home } from './_components/Home/Home';
import { AuthRoute } from './_components/AuthRoute';
import { history } from './_helpers/history';
import NavBar from './_components/NavBar/NavBar';
import { windowService } from './_services/window.service';
import sisConfig from './sis.config.json';

// Assets
import './App.scss';
import './_components/Chat/chat.scss'
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

const notify = Notification;
window.Notification = function (...values) {
	const notification = new notify(...values);
	notification.onclick = function () {
		window.focus();
	};
	return notification;
};
window.Notification.__proto__ = notify;
// if the sisChatSid is found it means a session is active on the chat server
// for us to connect too.
windowService.reloadReportWindow();

function App() {
	const { isAuthenticated, isLoading } = useAuth0();

	const SISLogin = (props) => {
		return <Login {...props} />;
	};

	const getSection = (sectionId) => {
		if(sectionId === null)
			return undefined;
		let resSection = null;
		sisConfig.sections.forEach(section => {
			if(section.id === sectionId)
				resSection = {...section};
		});
		return resSection === null ? undefined : resSection;
	};
	
	const userBuilt = useSelector(state => state.authentication.loggedIn);
	const loggedIn = isAuthenticated && !isLoading && userBuilt;
	useEffect(() => {
		if(loggedIn)
			setTimeout(() => startConverse(), 2000);
	}, [loggedIn])

	return (
		<div className="App">
			<Router history={history}>
				<NavBar />
				<Switch>
					<AuthRoute exact path="/realtime" section={getSection("realtime")} component={Home} />
					<AuthRoute exact path="/historical" section={getSection("historical")} component={Home} />
					<AuthRoute exact path="/media" section={getSection("media")} component={GlobalMedia} />
					<AuthRoute exact path="/asis" section={getSection("asis")} component={ASISContainer} />
					<AuthRoute
						exact
						path="/reporting"
						section={getSection("reporting")}
						component={ReportingContainer}
					/>
					<Route exact path="/login" section={undefined} component={SISLogin} />
					<Redirect from="*" to="/realtime" />
				</Switch>
			</Router>
		</div>
	);
}

export default hot(App);
