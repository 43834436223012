import L from 'leaflet';
import * as R from 'ramda';
import 'leaflet-timedimension';
import { store } from '../../_helpers/store';

let previousTime = 0;
L.TimeDimension.Layer.NavTarget = L.TimeDimension.Layer.GeoJson.extend({
	setLatLng: function (latlng) {
		this.latlng = latlng;

		this._latlng = latlng;
	},
	getLatLng: function () {
		const layers = this._baseLayer.getLayers();
		if (layers.length === 1) {
			if (layers[0].feature.geometry.type === 'LineString') {
				const latLng = R.last(layers[0].getLatLngs());
				this._latlng = latLng;

				layers[0]._latlng = latLng;
				return latLng;
			}

			return layers[0].getLatLngs();
		}
		return null;
	},

	_update: function () {
		let currentTrack = store.getState().historical.selectedTracks[0];
		if (!this._map) return;
		if (!this._loaded) {
			return;
		}

		var time = this._timeDimension.getCurrentTime();

		var maxTime = this._timeDimension.getCurrentTime(),
			minTime = 0;
		if (this._duration) {
			var date = new Date(maxTime);
			L.TimeDimension.Util.subtractTimeDuration(
				date,
				this._duration,
				true
			);
			minTime = date.getTime();
		}

		var layer = L.geoJson(null, this._baseLayer.options);
		var layers = this._baseLayer.getLayers();
		for (var i = 0, l = layers.length; i < l; i++) {
			var feature = this._getFeatureBetweenDates(
				layers[i].feature,
				minTime,
				maxTime
			);
			if (feature) {
				layer.addData(feature);
				if (
					this._addlastPoint &&
					feature.geometry.type == 'LineString'
				) {
					if (feature.geometry.coordinates.length > 0) {
						var properties = feature.properties;
						properties.last = true;
						let marker = layer.addData({
							type: 'Feature',
							properties: properties,
							geometry: {
								type: 'Point',
								coordinates:
									feature.geometry.coordinates[
										feature.geometry.coordinates.length - 1
									]
							}
						});
					}
				}
			}
		}

		if (this._currentLayer) {
			this._map.removeLayer(this._currentLayer);
		}
		if (layer.getLayers().length) {
			layer.addTo(this._map);
			this._currentLayer = layer;
		}
	},
	_getFeatureBetweenDates: function (feature, minTime, maxTime) {
		var featureTimes = this._getFeatureTimes(feature);
		if (featureTimes.length == 0) {
			return feature;
		}

		var index_min = null,
			index_max = null,
			l = featureTimes.length;

		if (featureTimes[0] > maxTime || featureTimes[l - 1] < minTime) {
			return null;
		}

		if (featureTimes[l - 1] > minTime) {
			for (var i = 0; i < l; i++) {
				if (index_min === null && featureTimes[i] > minTime) {
					// set index_min the first time that current time is greater the minTime
					index_min = i;
				}
				if (featureTimes[i] > maxTime) {
					index_max = i;
					break;
				}
			}
		}
		if (index_min === null) {
			index_min = 0;
		}
		if (index_max === null) {
			index_max = l;
		}
		var new_coordinates = [];
		if (feature.geometry.coordinates[0].length) {
			new_coordinates = feature.geometry.coordinates.slice(
				index_min,
				index_max
			);
		} else {
			new_coordinates = feature.geometry.coordinates;
		}

		var new_headings = [];
		if (
			feature.properties.headings &&
			feature.properties.headings.length > 0
		) {
			new_headings = feature.properties.headings.slice(
				index_min,
				index_max
			);
		}
		const props = feature.properties;
		props.heading = R.last(new_headings);

		return {
			type: 'Feature',
			properties: props,
			geometry: {
				type: feature.geometry.type,
				coordinates: new_coordinates
			}
		};
	}
});

const navTarget = function (layer, options) {
	return new L.TimeDimension.Layer.NavTarget(layer, options);
};

L.timeDimension.layer.navTarget = navTarget;

export { navTarget };
