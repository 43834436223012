export const initialState = {
	tick: 0
};

export function clock(state = initialState, action) {
	switch (action.type) {
		case 'TICK':
			return { tick: state.tick + 1 };
		default:
			return initialState;
	}
}
