// # Imports
// External Imports
import React, { useEffect, useState } from 'react';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useWindowResize } from "beautiful-react-hooks";

// Internal Imports
import RealTimeMedia from './RealTime/RealTimeMedia';
import HistoricalMedia from './Historical/HistoricalMedia';
import CollapsibleSection from './CollapsibleSection/CollapsibleSection';
import Legend from './Legend';
import { dateTimeService } from '../_services/datetime.service';
import moment from 'moment';

// Assets
import { ReactComponent as VideoAvailable } from './../assets/icons/icon_video-available.svg';
import { ReactComponent as VideoPlaying } from './../assets/icons/icon_video-playing.svg';
import CheckboxNotSelected from '../assets/icons/checkbox-not-selected.svg';
import CheckboxSelected from '../assets/icons/checkbox-selected.svg';
import ZoomCenterRest from '../assets/icons/zoom-center-rest.svg';
import arrowRight from '../assets/icons/arrow-right.svg';
import 'react-tabs/style/react-tabs.css';
import './mission-details.scss';
import { ColumnGroup } from 'ag-grid-community';
import { createAndDownloadCSVFromObjList, createAndDownloadCSVFromObj } from '../_helpers/csv';
import DropdownButtons from './DropdownButtons/DropdownButtons';

export default function MissionDetails(props) {
	const savedStateKey = 'c4-state:mission-' + props.item.metadata.id;
	const [savedState, setSavedState] = useState(sessionStorage.getItem(savedStateKey) ?
		JSON.parse(sessionStorage.getItem(savedStateKey)) :
		{
			sectionsOpen: { profile: true, crew: true, aircraft: true }
		});

	const [sectionsOpen, setSectionsOpen] = useState(savedState.sectionsOpen);

	const [open, setOpen] = useState(props.sectionOpen || false);
	const [currentMoment, setCurrentMoment] = useState(dateTimeService.currentMoment());
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);

	const intl = useIntl();

	const [downloadMediaAccess, setDownloadMediaAccess] = useState(false);
	const downloadMediaRequestOn = () => {
		setDownloadMediaAccess(true)
    }

	const downloadMediaRequestOff = () => {
		setDownloadMediaAccess(false)
    }

	useEffect(() => {
		setOpen(props.sectionOpen);
		if (props.mode === 'realtime') {
			const currentMomentRefreshInterval = setInterval(() => {
				setCurrentMoment(dateTimeService.currentMoment());
			},
				process.env.REACT_APP_CURRENT_MOMENT_REFRESH_INTERVAL);

			return () => {
				clearInterval(currentMomentRefreshInterval);
			}
		}
	}, [props.sectionOpen]);

	useEffect(() => {
		const newState = {
			...savedState,
			sectionsOpen: sectionsOpen,
		}
		sessionStorage.setItem(savedStateKey, JSON.stringify(newState))
	}, [sectionsOpen]);

	useWindowResize((event) => {
		setWindowHeight(window.innerHeight);
	});

	// For dynamic height in mission-tab-panel-realtime
	// Total view height - (NavBar + Mission header + (MissionCount * height) + Tab header + Export button + spaces)
	const maxHeightForMissionTabPanel = windowHeight - (49 + 32 + (props.missionCount * 40) + 43 + 32 + 20);

	const convertHTMLEntity = (text, unitsSuffixId = null) => {
		const span = document.createElement('span');
		if (text === undefined) {
			return undefined;
		}
		const fullText =
			text +
			(unitsSuffixId ? intl.formatMessage({ id: unitsSuffixId }) : '');

		return fullText.replace(
			/&[#A-Za-z0-9]+;/gi,
			(entity, position, fullText) => {
				span.innerHTML = entity;
				return span.innerText;
			}
		);
	};

	const hasStringValue = (val) => {
		return (val || '').length > 0;
	}

	function getInteger(num) {
		if( num.toString().length < 2 ) 
			return "0" + num; 
		return num.toString(); 
	}
	let getCoordinates = (x) => {
		const degree = Math.abs(Math.trunc(x));
		const minutes = `${Math.abs((x-Math.trunc(x))*60).toFixed(2)}`;
		const minutes_integer = getInteger(Math.trunc(minutes));
		const minutes_fraction = minutes.substring(minutes.indexOf(".")+1, minutes .length);
		return `${degree}°${minutes_integer}.${minutes_fraction}`;
	}

	let getLat = (x) => {
		const coordinate = getCoordinates(x)
		const direction = Math.sign(x) > 0 ? 'N' : 'S';
		return `${coordinate}'${direction}`
	}

	let getLng = (x) => {
		const coordinate = getCoordinates(x)
		const direction = Math.sign(x) > 0 ? 'E' : 'W';
		return `${coordinate}'${direction}`
	}

	const downloadNavData = (navData) => {
		const navTracks = navData;
		const trackIds = Object.keys(navData.positions);
		if(trackIds.length > 0) {
			const times = navData.positions[trackIds[0]].times;
			const coords = navData.positions[trackIds[0]].coords;
			
			let coords_list = coords.map(tuples => ({lat: getLat(tuples[1]), lng: getLng(tuples[0])}))
			
			createAndDownloadCSVFromObjList(coords_list, {
				columns: {
					lat: {
						header: "Latitude"
					},
					lng: {
						header: "Longitude"
					}
				},
				fileName: `Platform Tracks__${new Date().getFullYear()}${String(new Date().getMonth()+1).padStart(2, '0')}${String(new Date().getDate()).padStart(2, '0')}`
			})
		}
	}

	const downloadAISData = (aisData) => {
		if (aisData.length > 0){
			createAndDownloadCSVFromObjList(aisData,
			{
				columns: {
					missionNumber: {
						header: intl.formatMessage({
							id: "app.map.grid.column.mission"
						}),
					},
					cid: {
						header: intl.formatMessage({
							id: "app.map.grid.column.cid"
						})
					},
					createTime: {
						header: intl.formatMessage({
							id: "app.map.grid.column.createtime"
						})
					},
					latitude: {
						header: intl.formatMessage({
							id: "app.map.grid.column.latitude"
						})
					},
					longitude: {
						header: intl.formatMessage({
							id: "app.map.grid.column.longitude"
						}),
					},
					vesselName: {
						header: intl.formatMessage({
							id: "app.map.grid.column.vesselname"
						})
					},
					vesselCallsign: {
						header: intl.formatMessage({
							id: "app.map.grid.column.callsign"
						})
					},
					registrationNumber: {
						header: intl.formatMessage({
							id: "app.map.grid.column.registrationnumber"
						})
					},
					vesselActivity: {
						header: intl.formatMessage({
							id: "app.map.grid.column.vesselactivity"
						})
					},
					surfaceType: {
						header: intl.formatMessage({
							id: "app.map.grid.column.vesseltype"
						})
					},
					vesselCountry: {
						header: intl.formatMessage({
							id: "app.map.grid.column.nationality"
						})
					},
					mMSI: {
						header: intl.formatMessage({
							id: "app.map.grid.column.mmsi"
						})
					},
					range: {
						header: intl.formatMessage({
							id: "app.map.grid.column.range"
						}),
					},
					bearing: {
						header: intl.formatMessage({
							id: "app.map.grid.column.bearing"
						})
					},
					speed: {
						header: intl.formatMessage({
							id: "app.map.grid.column.speed"
						})
					},
					course: {
						header: intl.formatMessage({
							id: "app.map.grid.column.course"
						})
					},
					updateTime: {
						header: intl.formatMessage({
							id: "app.map.grid.column.updatetime"
						})
					},
					comments: {
						header: intl.formatMessage({
							id: "app.map.grid.column.comments"
						})
					},
				},
				fileName: `AIS Tracks_${new Date().getFullYear()}${String(new Date().getMonth()+1).padStart(2, '0')}${String(new Date().getDate()).padStart(2, '0')}`
			})
		}	
	}
	
	const downloadManualData = (manualData) => {
		if (manualData.length > 0){
			createAndDownloadCSVFromObjList(manualData,
			{
				columns: {
					missionNumber: {
						header: intl.formatMessage({
							id: "app.map.grid.column.mission"
						}),
					},
					cid: {
						header: intl.formatMessage({
							id: "app.map.grid.column.cid"
						})
					},
					createTime: {
						header: intl.formatMessage({
							id: "app.map.grid.column.createtime"
						})
					},
					latitude: {
						header: intl.formatMessage({
							id: "app.map.grid.column.latitude"
						})
					},
					longitude: {
						header: intl.formatMessage({
							id: "app.map.grid.column.longitude"
						}),
					},
					vesselName: {
						header: intl.formatMessage({
							id: "app.map.grid.column.vesselname"
						})
					},
					vesselCallsign: {
						header: intl.formatMessage({
							id: "app.map.grid.column.callsign"
						})
					},
					registrationNumber: {
						header: intl.formatMessage({
							id: "app.map.grid.column.registrationnumber"
						})
					},
					vesselActivity: {
						header: intl.formatMessage({
							id: "app.map.grid.column.vesselactivity"
						})
					},
					surfaceType: {
						header: intl.formatMessage({
							id: "app.map.grid.column.vesseltype"
						})
					},
					vesselCountry: {
						header: intl.formatMessage({
							id: "app.map.grid.column.nationality"
						})
					},
					mMSI: {
						header: intl.formatMessage({
							id: "app.map.grid.column.mmsi"
						})
					},
					range: {
						header: intl.formatMessage({
							id: "app.map.grid.column.range"
						}),
					},
					bearing: {
						header: intl.formatMessage({
							id: "app.map.grid.column.bearing"
						})
					},
					speed: {
						header: intl.formatMessage({
							id: "app.map.grid.column.speed"
						})
					},
					course: {
						header: intl.formatMessage({
							id: "app.map.grid.column.course"
						})
					},
					updateTime: {
						header: intl.formatMessage({
							id: "app.map.grid.column.updatetime"
						})
					},
					comments: {
						header: intl.formatMessage({
							id: "app.map.grid.column.comments"
						})
					},
				},
				fileName: `Manual Tracks_${new Date().getFullYear()}${String(new Date().getMonth()+1).padStart(2, '0')}${String(new Date().getDate()).padStart(2, '0')}`
			})
		}
	}

	const downloadRadarData = (radarData) => {
		if (radarData.length > 0){
			createAndDownloadCSVFromObjList(radarData,
			{
				columns: {
					missionNumber: {
						header: intl.formatMessage({
							id: "app.map.grid.column.mission"
						}),
					},
					cid: {
						header: intl.formatMessage({
							id: "app.map.grid.column.cid"
						})
					},
					createTime: {
						header: intl.formatMessage({
							id: "app.map.grid.column.createtime"
						})
					},
					latitude: {
						header: intl.formatMessage({
							id: "app.map.grid.column.latitude"
						})
					},
					longitude: {
						header: intl.formatMessage({
							id: "app.map.grid.column.longitude"
						}),
					},
					vesselName: {
						header: intl.formatMessage({
							id: "app.map.grid.column.vesselname"
						})
					},
					vesselCallsign: {
						header: intl.formatMessage({
							id: "app.map.grid.column.callsign"
						})
					},
					registrationNumber: {
						header: intl.formatMessage({
							id: "app.map.grid.column.registrationnumber"
						})
					},
					vesselActivity: {
						header: intl.formatMessage({
							id: "app.map.grid.column.vesselactivity"
						})
					},
					surfaceType: {
						header: intl.formatMessage({
							id: "app.map.grid.column.vesseltype"
						})
					},
					vesselCountry: {
						header: intl.formatMessage({
							id: "app.map.grid.column.nationality"
						})
					},
					mMSI: {
						header: intl.formatMessage({
							id: "app.map.grid.column.mmsi"
						})
					},
					range: {
						header: intl.formatMessage({
							id: "app.map.grid.column.range"
						}),
					},
					bearing: {
						header: intl.formatMessage({
							id: "app.map.grid.column.bearing"
						})
					},
					speed: {
						header: intl.formatMessage({
							id: "app.map.grid.column.speed"
						})
					},
					course: {
						header: intl.formatMessage({
							id: "app.map.grid.column.course"
						})
					},
					updateTime: {
						header: intl.formatMessage({
							id: "app.map.grid.column.updatetime"
						})
					},
					comments: {
						header: intl.formatMessage({
							id: "app.map.grid.column.comments"
						})
					},
				},
				fileName: `Radar Tracks_${new Date().getFullYear()}${String(new Date().getMonth()+1).padStart(2, '0')}${String(new Date().getDate()).padStart(2, '0')}`
			})
			
		}
	}

	const SectionHeader = ({ intl, isShown, toggleVisibility, zoomTo }) => {
		return (
			<div
				id={'mission-' + props.item.metadata.id}
				className="section-header"
			>
				<div className="left">
					<span>
						<img alt="Section Arrow" src={arrowRight} />
					</span>
					{props.item.metadata.name}
				</div>
				<div className="right">
					{props.item.isShown && (
						<button
							type="button"
							id={'zoom-' + props.item.metadata.id}
							onClick={(event) => {
								props.zoomTo(event, props.item);
							}}
							title={intl.formatMessage({
								id:
									'app.map.sidebar.missionselection.zoom.button.tooltip'
							})}
							className="btnZoom"
						>
							<img alt="Zoom" src={ZoomCenterRest} />
						</button>
					)}
					{false &&
						(<button
							onClick={(event) => {
								event.preventDefault();
								event.stopPropagation();
								props.showVideoPlayer("Empty", "");
							}}
						>
							<VideoAvailable />
						</button>)
					}
					{false &&
						(<button
							onClick={(event) => {
								event.preventDefault();
								event.stopPropagation();
								props.showVideoPlayer("Empty", "");
							}}
						>
							<VideoPlaying />
						</button>)
					}
					{props.mode === 'realtime' && (
						<button
							type="button"
							id={'visibility-' + props.item.metadata.id}
							onClick={(event) => {
								props.toggleVisibility(event, props.item);
							}}
							title={intl.formatMessage({
								id:
									'app.map.sidebar.missionselection.visible.button.tooltip'
							})}
							className="btnVisibility"
						>
							<img
								alt={intl.formatMessage({
									id:
										'app.map.sidebar.missionselection.visible.button.tooltip'
								})}
								src={
									props.item.isShown === true
										? CheckboxSelected
										: CheckboxNotSelected
								}
							/>
						</button>
					)}
				</div>
			</div>
		);
	};

	const SectionSubHeader = ({ intl, open, headerTitle }) => {
		return (
			<div className="section-sub-header">
				<div className="left">
					<span>
						<img alt="Section Arrow" src={arrowRight} />
					</span>
					{intl.formatMessage({
						id: headerTitle
					})}
				</div>
			</div>
		);
	};


	const ProfileData = () => {
		useEffect(() => {
			setDownloadMediaAccess(false);
		})
		
		if (props.item.profile.length !== 0) {

			const profile = props.item.profile[0];
			var lastUpdated = null;
			var missionConnected = null;
			var elapsedTime = null;

			if (props.mode === 'realtime') {
				lastUpdated = dateTimeService.computeAndFormatDuration(
					profile.lastUpdate,
					dateTimeService.currentMoment(),
					intl,
					'app.common.format.lastupdated.with.hours',
					'app.common.format.lastupdated.without.hours'
				);
				missionConnected = dateTimeService.formatDateTimefromEpochOffset(profile.missionConnected);
				elapsedTime = dateTimeService.computeAndFormatDuration(
					profile.missionConnected,
					dateTimeService.currentMoment(),
					intl
				);
			}
			const missionStart = dateTimeService.formatDateTimefromEpochOffset(profile.missionStart);
			const missionEnd = dateTimeService.formatDateTimefromEpochOffset(profile.missionEnd);
			const missionDuration = dateTimeService.computeAndFormatDuration(
				profile.missionStart,
				props.mode === 'realtime'
					? profile.missionEnd || dateTimeService.currentMoment()
					: profile.missionEnd,
				intl
			);

			return (

				<CollapsibleSection
					key={'mission' + props.item.metadata.id}
					sectionOpen={sectionsOpen.profile}
					onSubsectionClick={() => {
						setSectionsOpen({ ...sectionsOpen, profile: !sectionsOpen.profile })
					}}
					sectionClass="sub-section-container"
					sectionHeader={() => {
						return (
							<SectionSubHeader
								intl={intl}
								open={open}
								headerTitle="app.map.sidebar.realtime.properties.missionprofile"
							/>
						);
					}}
				>
					<ul className="mission-properties-list">
						{props.item.profile[0].missionNumber !== null &&
							props.item.profile[0].missionNumber !== '' &&
							props.item.profile[0].missionNumber !==
							undefined && (
								<li>
									<span>
										{intl.formatMessage({
											id:
												'app.map.sidebar.realtime.properties.mission'
										})}
										:
									</span>{' '}
									{props.item.profile[0].missionNumber}
								</li>
							)}
						{hasStringValue(lastUpdated) && (
							<li>
								<span>
									{intl.formatMessage({
										id: 'app.map.sidebar.realtime.properties.lastupdated'
									})}
										:
									</span>{' '}
								{lastUpdated}
							</li>
						)}
						{hasStringValue(missionConnected) && (
							<li>
								<span>
									{intl.formatMessage({
										id: 'app.map.sidebar.realtime.properties.missionconnected'
									})}
										:
									</span>{' '}
								{missionConnected}
							</li>
						)}
						{hasStringValue(elapsedTime) && (
							<li>
								<span>
									{intl.formatMessage({
										id: 'app.map.sidebar.realtime.properties.elapsedtime'
									})}
										:
									</span>{' '}
								{elapsedTime}
							</li>
						)}
						{hasStringValue(missionStart) && (
							<li>
								<span>
									{intl.formatMessage({
										id: 'app.map.sidebar.realtime.properties.missionstart'
									})}
										:
									</span>{' '}
								{missionStart}
							</li>
						)}
						{hasStringValue(missionEnd) && (
							<li>
								<span>
									{intl.formatMessage({
										id: 'app.map.sidebar.realtime.properties.missionend'
									})}
										:
									</span>{' '}
								{missionEnd}
							</li>
						)}
						{hasStringValue(missionDuration) && (
							<li>
								<span>
									{intl.formatMessage({
										id: 'app.map.sidebar.realtime.properties.missionduration'
									})}
										:
									</span>{' '}
								{missionDuration}
							</li>
						)}
						{props.item.profile[0].region !== null &&
							props.item.profile[0].region !== '' &&
							props.item.profile[0].region !== undefined && (
								<li>
									<span>
										{intl.formatMessage({
											id:
												'app.map.sidebar.realtime.properties.region'
										})}
										:
									</span>{' '}
									{props.item.profile[0].region}
								</li>
							)}
						{props.item.profile[0].taskedArea !== null &&
							props.item.profile[0].taskedArea !== '' &&
							props.item.profile[0].taskedArea !== undefined && (
								<li>
									<span>
										{intl.formatMessage({
											id:
												'app.map.sidebar.realtime.properties.taskedarea'
										})}
										:
									</span>{' '}
									{props.item.profile[0].taskedArea}
								</li>
							)}
						{props.item.profile[0].platform !== null &&
							props.item.profile[0].platform !== '' &&
							props.item.profile[0].platform !== undefined && (
								<li>
									<span>
										{intl.formatMessage({
											id:
												'app.map.sidebar.realtime.properties.aircraft'
										})}
										:
									</span>{' '}
									{props.item.profile[0].platform}
								</li>
							)}{' '}
					</ul>
				</CollapsibleSection>
			);
		} else {
			return '';
		}
	};

	const CrewData = () => {
		if (props.item.crew.length !== 0) {
			return (
				<CollapsibleSection
					key={'mission' + props.item.metadata.id}
					sectionOpen={sectionsOpen.crew}
					onSubsectionClick={() => {
						setSectionsOpen({ ...sectionsOpen, crew: !sectionsOpen.crew })
					}}
					sectionClass="sub-section-container"
					sectionHeader={() => {
						return (
							<SectionSubHeader
								intl={intl}
								headerTitle="app.map.sidebar.realtime.properties.crew"
							/>
						);
					}}
				>
					<ul className="mission-properties-list">
						{(props.item.crew[0].pilot || '').length > 0 && (
							<li>
								<span>
									{intl.formatMessage({
										id:
											'app.map.sidebar.realtime.properties.crew.pilot'
									})}
									:
								</span>{' '}
								{props.item.crew[0].pilot}
							</li>
						)}
						{(props.item.crew[0].firstOfficer || '').length > 0 && (
							<li>
								<span>
									{intl.formatMessage({
										id:
											'app.map.sidebar.realtime.properties.crew.firstofficer'
									})}
									:
								</span>{' '}
								{props.item.crew[0].firstOfficer}
							</li>
						)}
						{(props.item.crew[0].dmsOperator || '').length > 0 && (
							<li>
								<span>
									{intl.formatMessage({
										id:
											'app.map.sidebar.realtime.properties.crew.dmsoperator'
									})}
									:
								</span>{' '}
								{props.item.crew[0].dmsOperator}
							</li>
						)}
						{(props.item.crew[0].radarOperator || '').length > 0 && (
							<li>
								<span>
									{intl.formatMessage({
										id:
											'app.map.sidebar.realtime.properties.crew.radaroperator'
									})}
									:
								</span>{' '}
								{props.item.crew[0].radarOperator}
							</li>
						)}
						{(props.item.crew[0].observer || '').length > 0 && (
							<li>
								<span>
									{intl.formatMessage({
										id:
											'app.map.sidebar.realtime.properties.crew.observer'
									})}
									:
								</span>{' '}
								{props.item.crew[0].observer}
							</li>
						)}
						{(props.item.crew[0].tester || '').length > 0 && (
							<li>
								<span>
									{intl.formatMessage({
										id:
											'app.map.sidebar.realtime.properties.crew.tester'
									})}
									:
								</span>{' '}
								{props.item.crew[0].tester}
							</li>
						)}
						{(props.item.crew[0].fisheriesOfficer || '').length > 0 && (
							<li>
								<span>
									{intl.formatMessage({
										id:
											'app.map.sidebar.realtime.properties.crew.fisheriesofficer'
									})}
									:
								</span>{' '}
								{props.item.crew[0].fisheriesOfficer}
							</li>
						)}
						{(props.item.crew[0].other || '').length > 0 && (
							<li>
								<span>
									{intl.formatMessage({
										id:
											'app.map.sidebar.realtime.properties.crew.other'
									})}
									:
								</span>{' '}
								{props.item.crew[0].other}
							</li>
						)}
					</ul>
				</CollapsibleSection>
			);
		} else {
			return '';
		}
	};

	const AircraftData = () => {
		if (props.item.aircraft.length !== 0) {
			return (
				<CollapsibleSection
					key={'mission' + props.item.metadata.id}
					sectionOpen={sectionsOpen.aircraft}
					sectionClass="sub-section-container"
					onSubsectionClick={() => {
						setSectionsOpen({ ...sectionsOpen, aircraft: !sectionsOpen.aircraft })
					}}
					sectionHeader={() => {
						return (
							<SectionSubHeader
								intl={intl}
								headerTitle="app.map.sidebar.realtime.properties.aircraft"
							/>
						);
					}}
				>
					<ul className="mission-properties-list">
						{props.item.aircraft[0].altitude !== null &&
							props.item.aircraft[0].altitude !== '' &&
							props.item.aircraft[0].altitude !== undefined && (
								<li>
									<span>
										{intl.formatMessage({
											id:
												'app.map.sidebar.realtime.properties.altitude'
										})}
										:
									</span>{' '}
									{props.item.aircraft[0].altitude +
										' ' +
										intl.formatMessage({
											id: 'app.map.suffix.feet'
										})}
								</li>
							)}
						{convertHTMLEntity(props.item.aircraft[0].course) !==
							null &&
							convertHTMLEntity(props.item.aircraft[0].course) !==
							'' &&
							convertHTMLEntity(props.item.aircraft[0].course) !==
							undefined && (
								<li>
									<span>
										{intl.formatMessage({
											id:
												'app.map.sidebar.realtime.properties.course'
										})}
										:
									</span>{' '}
									{convertHTMLEntity(
										props.item.aircraft[0].course,
										'app.map.suffix.degrees.true'
									)}
								</li>
							)}
						{convertHTMLEntity(props.item.aircraft[0].position) !==
							null &&
							convertHTMLEntity(
								props.item.aircraft[0].position
							) !== '' &&
							convertHTMLEntity(
								props.item.aircraft[0].position
							) !== undefined && (
								<li>
									<span>
										{intl.formatMessage({
											id:
												'app.map.sidebar.realtime.properties.position'
										})}
										:
									</span>{' '}
									{convertHTMLEntity(
										props.item.aircraft[0].position
									)}
								</li>
							)}
						{props.item.aircraft[0].speed !== null &&
							props.item.aircraft[0].speed !== '' &&
							props.item.aircraft[0].speed !== undefined && (
								<li>
									<span>
										{intl.formatMessage({
											id:
												'app.map.sidebar.realtime.properties.speed'
										})}
										:
									</span>{' '}
									{props.item.aircraft[0].speed +
										' ' +
										intl.formatMessage({
											id: 'app.map.suffix.knots'
										})}
								</li>
							)}
					</ul>
				</CollapsibleSection>
			);
		} else {
			return '';
		}
	};

	const Media = () => {
		if (props.mode === 'historical') {
			return (
				<HistoricalMedia
					itemId={props.item.metadata.id}
					mode={props.mode}
					showVideoPlayer={props.showVideoPlayer}
					searchOpen={props.searchOpen}
					sectionOpen={open}
				></HistoricalMedia>
			);
		} else {
			const profiles = ((props || {}).item || {}).profile || [];
			const dnsAddress = profiles.length > 0 ? profiles[0].dnsAddress : "";
			return (
				<RealTimeMedia
					mediaLinks={props.item.mediaAssc}
					mode={props.mode}
					showVideoPlayer={props.showVideoPlayer}
					dnsAddress={dnsAddress}
					downloadMediaRequestOn={downloadMediaRequestOn}
					downloadMediaAccess={downloadMediaAccess}
				></RealTimeMedia>
			);
		}
	};

	const EventMarkers  = () => {
		const open = true;
		const getLocalizedLabel = marker => {
			const markerLocalizationKey = (marker && marker.markerTypeName && marker.markerTypeName.length > 0) ? 
				String(marker.markerTypeName).toLowerCase().replace(" ", "") : 
				"unknown";
			return intl.formatMessage({id: `app.common.eventmarker.${markerLocalizationKey}`}); 
		};
		if(props.item.markers && props.item.markers.length !== 0) {
			return (
			<CollapsibleSection
				key={'mission'+props.item.metadata.id}
				sectionOpen={open}
				sectionClass="sub-section-container"
				sectionHeader={()=>{
					return (
						<SectionSubHeader 
							intl={intl}
							headerTitle="app.map.sidebar.realtime.properties.markers"
						/>
					);
				}}
			>
				<ul className="mission-properties-list">
					{
						props.item.markers.sort(
							(a, b) => {
								const ad = new Date(a.timestamp), bd = new Date(b.timestamp);
								return ad > bd ? 1 : ad < bd ? -1 : 0;
							}
						).map((marker) => {
							const ts = new Date(marker.timestamp);
							return (<>
								<li title={marker.note}>
									<span>
										{getLocalizedLabel(marker)}
									</span>
									{` ${moment(ts).format("MM/DD HH:mm:ss")}Z`}
									{(marker.note && marker.note.length > 0) ? `, ${marker.note}` : ""}
								</li>
							</>)
						})
					}
				</ul>
			</CollapsibleSection>
			);
		}
		return (<></>);
	};

	return (
		<CollapsibleSection
			key={'mission' + props.item.metadata.id}
			sectionOpen={open}
			onClick={() => {
				if (props.onClick) {
					props.onClick(!open);
				}
				setOpen(!open);
				setDownloadMediaAccess(false);
			}}
			sectionHeader={() => {
				return (
					<SectionHeader
						intl={intl}
					// isShown={isShown}
					// zoomTo={zoomTo}
					// toggleVisibility={toggleVisibility}
					/>
				);
			}}
		>
			<Tabs
				id={'mission-tabs-' + props.idx}
				className= {`mission-tabs-${props.mode === 'historical' ? 'historical' : 'realtime'}`}
				selectedTabClassName="tab-selected"
			>
				<TabList
					id={'mission-tab-list-' + props.idx}
					className = {`mission-tab-list-${props.mode === 'historical' ? 'historical' : 'realtime'}`}
				>
					<Tab
						id={'mission-properties-tab-' + props.idx}
						className={`mission-tab-${props.mode === 'historical' ? 'historical' : 'realtime'}`}
					>
						{intl.formatMessage({
							id: 'app.map.sidebar.missionproperties.button'
						})}
					</Tab>
					<Tab
						id={'mission-media-tab-' + props.idx}
						className={`mission-tab-${props.mode === 'historical' ? 'historical' : 'realtime'}`}
					>
						{intl.formatMessage({
							id: 'app.map.sidebar.media.button'
						})}
					</Tab>
					<Tab
						id={'mission-legend-tab-' + props.idx}
						className={`mission-tab-${props.mode === 'historical' ? 'historical' : 'realtime'}`}
					>
						{intl.formatMessage({
							id: 'app.map.sidebar.legend.button'
						})}
					</Tab>
				</TabList>
				<TabPanel
					id={'mission-properties-tab-panel-historical' + props.idx}
					className={classNames(`mission-tab-panel-${props.mode === 'historical' ? 'historical' : 'realtime'}`, props.searchOpen && props.mode === 'historical' ? 'minimized' : '')}
					style={props.mode === 'historical' ? {} : {maxHeight: maxHeightForMissionTabPanel}}
				>
					<ul className="mission-properties-container">
						<ProfileData />
						<CrewData />
						<AircraftData />
						<EventMarkers />
					</ul>
				</TabPanel>
				<TabPanel
					id={'mission-media-tab-panel' + props.idx}
					className={classNames(`mission-tab-panel-${props.mode === 'historical' ? 'historical' : 'realtime'}`, props.searchOpen && props.mode === 'historical'  ? 'minimized' : 'minimized-media')}
					style={props.mode === 'historical' ? {} : {maxHeight: maxHeightForMissionTabPanel}}
				>
					<Media />
				</TabPanel>
				<TabPanel
					id={'mission-legend-tab-panel' + props.idx}
					className={classNames(`mission-tab-panel-${props.mode === 'historical' ? 'historical' : 'realtime'}`, props.searchOpen && props.mode === 'historical' ? 'minimized' : '')}
					style={props.mode === 'historical' ? {} : {maxHeight: maxHeightForMissionTabPanel}}
				>
					<Legend 
						downloadMediaRequestOff={downloadMediaRequestOff}
					/>
				</TabPanel>
			</Tabs>
			<DropdownButtons
				title={intl.formatMessage({ id: 'app.map.sidebar.export.mission.button'})}
				option={(() => {
					const options = {}
					options[`${intl.formatMessage({id: 'app.map.sidebar.export.ais'})}`] = {callback: ()=>downloadAISData(props.item.ais.data), disabled: !(props.item && props.item.ais && Array.isArray(props.item.ais.data) && props.item.ais.data.length > 0)}
					options[`${intl.formatMessage({id: 'app.map.sidebar.export.manual'})}`] = {callback: ()=>downloadManualData(props.item.manual.data), disabled: !(props.item && props.item.manual && Array.isArray(props.item.manual.data) && props.item.manual.data.length > 0)}
					options[`${intl.formatMessage({id: 'app.map.sidebar.export.radar'})}`] = {callback: ()=>downloadRadarData(props.item.radar.data), disabled: !(props.item && props.item.radar && Array.isArray(props.item.radar.data) && props.item.radar.data.length > 0)}
					options[`${intl.formatMessage({id: 'app.map.sidebar.export.platform'})}`] = {callback: ()=>downloadNavData(props.item.nav), disabled: !(props.item && props.item.nav && Array.isArray(props.item.nav.data) && props.item.nav.data.length > 0)}
					return options;
				  })()}
				>	
			</DropdownButtons>	
		</CollapsibleSection>
	);
}
