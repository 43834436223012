// # Imports
// External Imports
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import React, { useState, useEffect } from 'react';

// Internal Imports
import { mediaService } from '../../_services/media.service.js';
import MediaItem from '../Media/MediaItem';
import sisConfig from '../../sis.config.json';
import {canPlayMediaAsVideo, downloadMedia} from './../../_helpers/media.js';
import { ReactComponent as DownloadMedia } from '../../assets/icons/icon_media-download-media.svg';
import { ReactComponent as PlayVideo } from '../../assets/icons/icon_media-play-video.svg';

// Assets
import './historical-media.scss';
import ProgressSpinner from '../ProgressSpinner.jsx';
import { historicalService } from '../../_services/historical.service.js';

export default function HistoricalMedia(props) {
	const [mediaArr, setMediaArr] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingDown, setLoadingDown] = useState(false);
	const [layout, setLayout] = useState('tile');
	const [images, setImages] = useState(true);
	const [search, setSearch] = useState('');
	const [video, setVideo] = useState(true);
	const [selectedMediaData, setSelectedMediaData] = useState({
		id: null,
		mimeType: "",
		fileName: ""
	});
	const [mediaLinks, setMediaLinks] = useState([]);

	const intl = useIntl();

	const selectedMission = useSelector(
		(state) => state.historical.selectedMissions[0]
	);

	useEffect(() => {
		historicalService.getHistoricalMediaLinks(selectedMission.id).then(setMediaLinks);
	}, []);

	useEffect(() => {
		setLoading(true);
		let mediaRequests = [];
		mediaLinks.map((media) => {
			mediaRequests.push(mediaService.getDetails(media.MediaId).catch(() => null));
		});
		Promise.all(mediaRequests)
			.then((res) => {
				const noNullRes = res.filter(r => r !== null);
				return noNullRes.map(details => ({
						id: details._id,
						file: details.fileInfo.originalFileName,
						size: details.fileInfo.fileSizeBytes,
						type: details.fileInfo.mimeType,
						thumb: null
				}));
		
			})
			.then((newMediaArr) => {
				function compare (media1, media2){
					const file1 = media1.file.toLowerCase();
					const file2 = media2.file.toLowerCase();
					let comparison = 0;
					if (file1 < file2)
						return -1
					else if (file1 > file2)
						return 1
					return comparison;
				}
				setMediaArr(newMediaArr.sort(compare));
				setLoading(false);
			});
	}, [mediaLinks]);

	const performSearch = (searchStr) => {
		//console.log(searchStr);
	};

	const handleChange = (event) => {
		const value = event.target.value;
		setSearch(value);
		performSearch(search);
	};

	const clearSearch = () => {
		setSearch('');
		performSearch();
	};

	const onKeyDown = (event) => {
		if (event.keyCode === 13) {
			performSearch();
		}
	};

	const selectMedia = (id) => {
		if(id !== null) {
			mediaService.getDetails(id)
				.then(res => {
					setSelectedMediaData({
						id,
						mimeType: res.fileInfo.mimeType,
						fileName: res.fileInfo.originalFileName
					});
				});
		}
	};

	return (
		<div className="hist-mission-media-container">
			<div className="hist-mission-media-actions">
				<div className="left">
					<button
						type="button"
						onClick={(e) => {
							setLayout('list');
						}}
						className={layout === 'list' ? 'btn-selected' : 'btn'}
						disabled={false}
						title={intl.formatMessage({
							id: 'app.map.sidebar.realtime.media.list.title',
							defaultMessage: 'Organize media as a list'
						})}
					>
						<FormattedMessage
							id="app.map.sidebar.realtime.media.list"
							defaultMessage="list"
						/>
					</button>
					<button
						type="button"
						onClick={(e) => {
							setLayout('tile');
						}}
						className={layout === 'tile' ? 'btn-selected' : 'btn'}
						disabled={false}
						title={intl.formatMessage({
							id: 'app.map.sidebar.realtime.media.tile.title',
							defaultMessage: 'Organize media as tiles'
						})}
					>
						<FormattedMessage
							id="app.map.sidebar.realtime.media.tile"
							defaultMessage="Tile"
						/>
					</button>
				</div>
				<div className="right">
					<div style={{"visibility": loadingDown ? "visible" : "hidden"}}><ProgressSpinner/></div>
					<button
						className="historical-media-play-video-button"
						onClick={(event) => {
							if(selectedMediaData.id !== null) {
								event.preventDefault();
								event.stopPropagation();
								props.showVideoPlayer(selectedMediaData.fileName, selectedMediaData, "video");
							}
						}}
						disabled={
							selectedMediaData.id === null ? 'disabled' : canPlayMediaAsVideo(selectedMediaData) ? false : 'disabled'
						}
					>
						<PlayVideo
							title={intl.formatMessage({
								id:
									'app.map.trackinspector.media.play.title',
								defaultMessage:
									'Play video media from an associated track'
							})}
							className="media-icon"
						/>
					</button>
					<button
						className="historical-media-download-button"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setLoadingDown(true);
							downloadMedia(selectedMediaData)
								.then(() => setLoadingDown(false));
						}}
						disabled={
							selectedMediaData.id === null ? 'disabled' : false
						}
					>
						<DownloadMedia 
							title={intl.formatMessage({
								id: 'app.map.trackinspector.media.download.title',
								defaultMessage: 'disassociate a media from a track'
							})}
							className="media-icon"
						/>
					</button>
				</div>
			</div>
			<div
				id="hist-mission-media-ctn"
				className="hist-mission-media-container"
			>
				<div
					id="hist-mission-media-list"
					className={
						layout === 'list'
							? 'hist-mission-media-list-view'
							: 'hist-mission-media-tile-view'
					}
				>
					{mediaArr.map((media, key) => (
						<MediaItem
							media={media}
							idx={key}
							layout={layout}
							selectMedia={selectMedia}
							isSelected={selectedMediaData.id === media.id}
						></MediaItem>
					))}
					{!loading && mediaArr.length === 0 && (
						<li
							id="hist-mission-media-list-empty"
							className="hist-mission-media-tab-empty"
						>
							{intl.formatMessage({
								id: 'app.media.nomedia'
							})}
						</li>
					)}
				</div>
				<div className="absolute-spinner" style={{"display": loading ? "block" : "none"}}><ProgressSpinner size="large"/></div>
			</div>
		</div>
	);
}
