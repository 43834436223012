import { combineReducers } from 'redux';
import { authentication } from '../_modules/auth/auth.reducer';
import { profile } from '../_modules/profiles/profile.reducer';
import { maps } from '../_modules/maps/map.reducer';
import { alert } from '../_modules/alerts/alert.reducer';
import { realtime } from '../_modules/realtime/realtime.reducer';
import { historical } from '../_modules/historical/historical.reducer';
import { clock } from '../_modules/clock/clock.reducer';

const rootReducer = combineReducers({
	authentication,
	maps,
	alert,
	realtime,
	historical,
	profile,
	clock
});

export default rootReducer;
