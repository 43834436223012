import React, { useState, useEffect } from 'react';
import ProgressSpinner from './ProgressSpinner';

import './error-page.scss';
import { useIntl } from 'react-intl';

export function ErrorPage() {
    const intl = useIntl();
    return (
    <div id="logging-in-ctn" className="logging-in-ctn">
        {intl.formatMessage({
            id: "app.error.page.msg"
        })}
    </div>
    );
}

