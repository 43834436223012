// # Imports

// External imports
import 'react-hot-loader';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Auth0Provider } from "@auth0/auth0-react";

import * as R from 'ramda';
import * as pl from 'partial.lenses';

// Internal imports
import sisConfig from './sis.config.json';
import { initConversePlugin, startConverse } from './_components/Chat/Chat';
import { store } from './_helpers/store';
import App from './App';
import translations from './i18n/locales';
// import { mockServer } from './_helpers/mock-server';

// Assets
import './index.scss';
import './sass/normalize.scss';
import './sass/datePicker.scss';

window.R = R;
window.pl = pl;

// Set the language for the app
const localeProp = localStorage.getItem('sisLang') || 'en';

// Initalize mock server
// mockServer();

// Initalize clock
initConversePlugin();

const root = document.getElementById('root');

render(
	<Auth0Provider 
		domain={sisConfig.build[window.location.hostname].auth0_domain} 
		clientId={sisConfig.build[window.location.hostname].auth0_clientId} 
		redirectUri={`${window.location.origin}/realtime`}
		audience={sisConfig.build[window.location.hostname].auth0_audience}
		scope={sisConfig.build[window.location.hostname].auth0_scope}
	>
		<Provider store={store}>
			<IntlProvider
				locale={localeProp}
				defaultLocale="en"
				key={localeProp}
				messages={translations[localeProp]}
			>
				<App />
			</IntlProvider>
		</Provider>
	</Auth0Provider>,
	root
);
