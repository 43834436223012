import sisConfig from '../../sis.config.json';

// # Funcs

const initConversePlugin = () => {
	window.converse.plugins.add('sis', {
		initialize: function () {
			window._converse = this._converse;
			this._converse.api.listen.on('roster', () => {
				sessionStorage.setItem(
					'sisChatSid',
					window._converse.api.tokens.get('sid')
				);
				sessionStorage.setItem(
					'sisChatRid',
					window._converse.api.tokens.get('rid')
				);
				sessionStorage.setItem(
					'sisChatJid',
					window._converse.api.user.jid()
				);
			});
		}
	});
};

const startConverse = (jid, token) => {
	const localeProp = localStorage.getItem('sisLang') || 'en';
	const hostname = sisConfig.build[window.location.hostname].chat_server;
	// https://conversejs.org/docs/html/configuration.html
	const options = {
		bosh_service_url: `https://${hostname}:7443/http-bind/`,
		show_controlbox_by_default: false,
		csi_waiting_time: 60,
		allow_logout: false,
		allow_registration: false,
		allow_contact_removal: false,
		allow_contact_requests: false,
		show_client_info: false,
		synchronize_availability: true,
		show_send_button: true,

		muc_instant_rooms: false,
		muc_mention_autocomplete_show_avatar: false,
		muc_disable_slash_commands: true,
		muc_domain: `conference.${sisConfig.build[window.location.hostname].chat_domain}`,
		muc_nickname_from_jid: true,
		locked_muc_domain: 'hidden',
		muc_show_logs_before_join: false,
		muc_show_join_leave: false,
		muc_fetch_members: false,
		muc_show_join_leave_status: false,
		auto_register_muc_nickname: true,
		visible_toolbar_buttons: {
			call: false
		},
		auto_list_rooms: true,
		notification_icon: 'aims-c4-logo.svg',
		prebind_url: `https://${hostname}:7443/http-bind/`,
		allow_bookmarks: false,
		play_sounds: true,
		auto_focus: false,
		auto_away: 300,
		auto_reconnect: true,
		auto_subscribe: true,
		auto_join_on_invite: true,
		hide_offline_users: true,
		i18n: localeProp,
		locales: ['en', 'fr'],
		keepalive: true,
		whitelisted_plugins: ['sis'],
		expose_rid_and_sid: true
	};
	
	// We want to load an existing session from the server if the browser was
	// refreshed.
	if (sessionStorage.getItem('sisChatSid') !== null) {
		options.jid = sessionStorage.getItem('sisChatJid');
		// rid gets incremented with every intereaction  between the client
		// and the server
		options.sid = sessionStorage.getItem('sisChatSid');
		options.rid = sessionStorage.getItem('sisChatRid') + 1;
		options.authentication = 'prebind';
	} else if(sessionStorage.getItem("sisUser")) {
		const { username, token } = JSON.parse(sessionStorage.getItem("sisUser"));
		options.jid = username;
		options.password = token;
		options.auto_login = true;
		options.authentication = 'login';
	}

	window.converse.initialize(options);

	const toggle = document.getElementsByClassName('converse-chatboxes')[0];
	if (toggle) {
		toggle.classList.remove('hidden');
	}
};

export { startConverse, initConversePlugin };
