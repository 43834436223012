import * as R from 'ramda';
import { historicalConstants } from './historical.constants';

export const initialState =
	sessionStorage.getItem('state:historical') !== undefined &&
	sessionStorage.getItem('state:historical') !== null
		? JSON.parse(sessionStorage.getItem('state:historical'))
		: {
				criteria: [],
				results: [],
				missions: {},
				selectedMissions: [],

				time: {
					availableTimes: [],
					currentIndex: 0
				},
				nav: {
					data: [],
					fields: [],
					navLoading: false,
					navError: false
				},
				ais: {
					data: [],
					fields: [],
					aisLoading: false,
					aisError: false
				},
				radar: {
					data: [],
					fields: [],
					radarLoading: false,
					radarError: false
				},
				manual: {
					data: [],
					fields: [],
					manualLoading: false,
					manualError: false
				},
				mediaAssc: [],
				selectedTracks: []
		  };

// Check if the mission already exists
// function getIndex(missions, id) {
// 	let missionIndex = missions
// 		.map(function (x) {
// 			return x.base.Id;
// 		})
// 		.indexOf(id);
// 	return missionIndex;
// }

export function historical(state = initialState, action) {
	switch (action.type) {
		//Search cases
		case historicalConstants.HISTORICAL_SEARCH_REQUEST:
			return { ...state, loading: true };
		case historicalConstants.HISTORICAL_SEARCH_SUCCESS:
			return {
				...state,
				results: action.historical,
				loading: false,
				hasErrors: false
			};
		case historicalConstants.HISTORICAL_SEARCH_FAILURE:
			return { ...state, loading: false, hasErrors: true };
		//SelectMission cases
		case historicalConstants.HISTORICAL_MISSION_SELECT:
			//let missions = state.missions.slice();
			const missions = state.missions;

			if (missions[action.mission.id] === undefined) {
				missions[action.mission.id] = action.mission;
			}
			// will be used for multiple missions.
			// if (getIndex(state.missions, action.historical.Id) === -1) {
			// 	console.log('newMission', newMission);
			// 	missions.push(newMission);
			// }
			const newState = {
				...state,
				missions,
				time: action.time,
				selectedMissions: [action.mission],
				loading: false,
				hasErrors: false
			};
			try {
				sessionStorage.setItem(
					'state:historical',
					JSON.stringify(newState)
				);
			} catch(ex) {
				console.log(ex);
			}
			return newState;
		//Nav cases
		case historicalConstants.HISTORICAL_NAV_REQUEST:
			return { ...state, navLoading: true };
		case historicalConstants.HISTORICAL_NAV_SUCCESS:
			return {
				...state,
				nav: action.historical
			};
		case historicalConstants.HISTORICAL_NAV_FAILURE:
			return {
				...state,
				nav: initialState.nav,
				navLoading: false,
				navErrors: true
			};
		//AIS cases
		case historicalConstants.HISTORICAL_AIS_REQUEST:
			return { ...state, aisLoading: true };
		case historicalConstants.HISTORICAL_AIS_SUCCESS:
			return {
				...state,
				ais: action.historical
			};
		case historicalConstants.HISTORICAL_AIS_FAILURE:
			return {
				...state,
				ais: initialState.ais,
				aisLoading: false,
				aisErrors: true
			};
		//Radar cases
		case historicalConstants.HISTORICAL_RADAR_REQUEST:
			return { ...state, radarLoading: true };
		case historicalConstants.HISTORICAL_RADAR_SUCCESS:
			return {
				...state,
				radar: action.historical
			};
		case historicalConstants.HISTORICAL_RADAR_FAILURE:
			return {
				...state,
				radar: initialState.radar,
				radarLoading: false,
				radarErrors: true
			};
		//Manual cases
		case historicalConstants.HISTORICAL_MANUAL_REQUEST:
			return { ...state, manualLoading: true };
		case historicalConstants.HISTORICAL_MANUAL_SUCCESS:
			return {
				...state,
				manual: action.historical
			};
		case historicalConstants.HISTORICAL_MANUAL_FAILURE:
			return {
				...state,
				manual: initialState.manual,
				manualLoading: false,
				manualErrors: true
			};
		//Crew cases
		case historicalConstants.HISTORICAL_CREW_REQUEST:
			return { ...state, loading: true };
		case historicalConstants.HISTORICAL_CREW_SUCCESS:
			return {
				...state,
				crew: action.historical
			};
		case historicalConstants.HISTORICAL_CREW_FAILURE:
			return {
				...state,
				crew: initialState.crew,
				loading: false,
				hasErrors: true
			};
		//Aircraft cases
		case historicalConstants.HISTORICAL_AIRCRAFT_REQUEST:
			return { ...state, loading: true };
		case historicalConstants.HISTORICAL_AIRCRAFT_SUCCESS:
			// const indexAircraft = getIndex(
			// 	state.missions,
			// 	action.historical[0].HistoricalMissionAircraft_Id
			// );

			// let newAircrafts = state.missions.slice();
			// let updatedAircrafts = newAircrafts.slice(
			// 	indexAircraft,
			// 	indexAircraft + 1
			// );
			// updatedAircrafts[0].aircrafts = action.historical;

			// newAircrafts.map((item, index) => {
			// 	if (index !== indexAircraft) {
			// 		return item;
			// 	}
			// 	return {
			// 		...item,
			// 		updatedAircrafts,
			// 	};
			// });

			// return {
			// 	...state,
			// 	missions: newAircrafts,
			// 	loading: false,
			// 	hasErrors: false,
			// };
			return {
				...state,
				aircraft: action.historical,
				loading: false,
				hasErrors: false
			};
		case historicalConstants.HISTORICAL_AIRCRAFT_FAILURE:
			return {
				...state,
				aircraft: initialState.aircraft,
				loading: false,
				hasErrors: true
			};
		//Profile cases
		case historicalConstants.HISTORICAL_PROFILE_REQUEST:
			return { ...state, loading: true };
		case historicalConstants.HISTORICAL_PROFILE_SUCCESS:
			// const indexProfile = getIndex(
			// 	state.missions,
			// 	action.historical[0].HistoricalMissionProfile_Id
			// );

			// let newProfiles = state.missions.slice();
			// let updatedProfiles = newProfiles.slice(
			// 	indexProfile,
			// 	indexProfile + 1
			// );
			// updatedProfiles[0].profiles = action.historical;

			// newProfiles.map((item, index) => {
			// 	if (index !== indexProfile) {
			// 		return item;
			// 	}
			// 	return {
			// 		...item,
			// 		updatedProfiles,
			// 	};
			// });

			// return {
			// 	...state,
			// 	missions: newProfiles,
			// 	loading: false,
			// 	hasErrors: false,
			// };

			return {
				...state,
				profile: action.historical,
				loading: false,
				hasErrors: false
			};
		case historicalConstants.HISTORICAL_PROFILE_FAILURE:
			return {
				...state,
				profile: initialState.profile,
				loading: false,
				hasErrors: true
			};

		case historicalConstants.HISTORICAL_SELECT_TRACK:
			const track = action.tracks[0];
			const selectedMission = R.clone(state.missions[track.missionId]);
			
			const associatedMedia = track.associatedMedia;
			
			selectedMission.mediaAssc = R.union(
				associatedMedia,
				R.dropWhile((media) => {
					return media.SisTargetId === track.id;
				}, selectedMission.mediaAssc)
			);
			
			return {
				...state,
				missions: R.assoc(
					selectedMission.id,
					selectedMission,
					state.missions
				),
				selectedMissions: [selectedMission],
				selectedTracks: action.tracks
			};
		case historicalConstants.HISTORICAL_SELECT_TRACK_FAILURE:
			return state;
		case historicalConstants.HISTORICAL_DESELECT_TRACK:
			return {
				...state,
				selectedTracks: []
			};
		default:
			return state;
	}
}
