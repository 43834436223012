import * as R from 'ramda';
import { getTime, parseISO } from 'date-fns';
import { historicalConstants } from './historical.constants';
import { historicalService } from '../../_services/historical.service';
import {
	hydrateData,
	cleanUsernames,
	cleanKeys
} from '../../_helpers/data-cleanup';
import { httpInterceptor } from '../../_helpers/http-interceptor';
import sisConfig from '../../sis.config.json';

export const historicalActions = {
	searchMissions,
	selectMission,
	selectTrack,
	deSelectTracks,
	getNavByMissionId,
	getAISByMissionId,
	getManualByMissionId,
	getRadarByMissionId,
	getCrewByMissionId,
	getAircraftByMissionId,
	getProfileByMissionId
};

function searchMissions(search) {
	return (dispatch) => {
		dispatch(request());

		historicalService.searchMissions(search).then(
			(historical) => dispatch(success(historical)),
			(error) => dispatch(failure(error.toString()))
		);
	};

	function request() {
		return { type: historicalConstants.HISTORICAL_SEARCH_REQUEST };
	}
	function success(historical) {
		return {
			type: historicalConstants.HISTORICAL_SEARCH_SUCCESS,
			historical
		};
	}
	function failure(error) {
		return { type: historicalConstants.HISTORICAL_SEARCH_FAILURE, error };
	}
}

const hydrateNavPositionData = (data, nav) => {
	data[nav.id] = nav.positions;
	return data;
};

const hydrateTrackPositionData = (data, track) => {
	data[track.id] = track.positions;
	return data;
};

const addCollectionId = (collectionId) => (obj) => {
	obj.collectionId = collectionId;
	return obj;
};

const getTimesFromPositionData = (data) => {
	return R.pipe(
		R.values,
		R.map((item) => {
			return item.times;
		}),
		R.flatten
	)(data);
};

function selectMission(mission) {
	return (dispatch) => {
		const id = mission.Id;

		const dataRequests = [
			historicalService.getNavByMissionId(id),
			historicalService.getAISByMissionId(id),
			historicalService.getRadarByMissionId(id),
			historicalService.getManualByMissionId(id),
			historicalService.getProfileByMissionId(id),
			historicalService.getCrewByMissionId(id),
			historicalService.getAircraftByMissionId(id),
			historicalService.getHistoricalMediaLinks(id),
			historicalService.getHistoricalEventMarkers(id)
		];

		Promise.all(dataRequests)
			.then(
				([
					nav,
					ais,
					radar,
					manual,
					profile,
					crew,
					aircraft,
					mediaIds,
					markers
				]) => {
					return [
						nav
							.map(cleanKeys)
							.map(addCollectionId('nav'))
							.map(hydrateData),
						ais
							.map(cleanKeys)
							.map(addCollectionId('ais'))
							.map(hydrateData),
						radar
							.map(cleanKeys)
							.map(addCollectionId('radar'))
							.map(hydrateData),
						manual
							.map(cleanKeys)
							.map(addCollectionId('manual'))
							.map(hydrateData),
						profile.map(cleanKeys).map(addCollectionId('profile')),
						crew
							.map(cleanKeys)
							.map(addCollectionId('crew'))
							.map(cleanUsernames),
						aircraft
							.map(cleanKeys)
							.map(addCollectionId('aircraft')),
						mediaIds,
						markers
					];
				}
			)
			.then(
				([
					nav,
					ais,
					radar,
					manual,
					profile,
					crew,
					aircraft,
					mediaIds,
					markers
				]) => {
					const loadedMission = {
						id: mission.Id,
						isShown: true,
						metadata: cleanKeys(mission),
						nav: {
							data: nav,
							positions: nav.reduce(hydrateNavPositionData, {})
						},
						ais: {
							data: ais,
							positions: ais.reduce(hydrateTrackPositionData, {})
						},
						radar: {
							data: radar,
							positions: radar.reduce(
								hydrateTrackPositionData,
								{}
							)
						},
						manual: {
							data: manual,
							positions: manual.reduce(
								hydrateTrackPositionData,
								{}
							)
						},
						profile: profile,
						crew: crew,
						aircraft: aircraft,
						mediaAssc: mediaIds,
						markers: markers
					};

					const availableTimes = R.sort((a, b) => {
						return a - b;
					}, R.uniq([...getTimesFromPositionData(loadedMission.nav.positions), ...getTimesFromPositionData(loadedMission.ais.positions), ...getTimesFromPositionData(loadedMission.radar.positions), ...getTimesFromPositionData(loadedMission.manual.positions)]));

					return dispatch({
						type: 'HISTORICAL_MISSION_SELECT',
						mission: loadedMission,
						time: {
							availableTimes,
							currentIndex: 0
						}
					});
				},
				(error) => {
					return dispatch({
						type: 'HISTORICAL_MISSION_SELECT_FAIL'
					});
				}
			);

		return {
			type: historicalConstants.HISTORICAL_MISSION_SELECT,
			historical: mission
		};
	};
}

function getNavByMissionId(id) {
	return (dispatch) => {
		dispatch(request());

		historicalService.getNavByMissionId(id).then(
			(data) => {
				const historical = {
					data: data,
					navLoading: false,
					navErrors: false
				};
				dispatch(success(historical));
			},
			(error) => dispatch(failure(error.toString()))
		);
	};

	function request() {
		return { type: historicalConstants.HISTORICAL_NAV_REQUEST };
	}
	function success(historical) {
		return { type: historicalConstants.HISTORICAL_NAV_SUCCESS, historical };
	}
	function failure(error) {
		return { type: historicalConstants.HISTORICAL_NAV_FAILURE, error };
	}
}

function getAISByMissionId(id) {
	return (dispatch) => {
		dispatch(request());

		historicalService.getAISByMissionId(id).then(
			(data) => {
				const historical = {
					data: data,
					aisLoading: false,
					aisErrors: false
				};
				dispatch(success(historical));
			},
			(error) => dispatch(failure(error.toString()))
		);
	};

	function request() {
		return { type: historicalConstants.HISTORICAL_AIS_REQUEST };
	}
	function success(historical) {
		return { type: historicalConstants.HISTORICAL_AIS_SUCCESS, historical };
	}
	function failure(error) {
		return { type: historicalConstants.HISTORICAL_AIS_FAILURE, error };
	}
}

function getRadarByMissionId(id) {
	return (dispatch) => {
		dispatch(request());

		historicalService.getRadarByMissionId(id).then(
			(data) => {
				const historical = {
					data: data,
					radarLoading: false,
					radarErrors: false
				};
				dispatch(success(historical));
			},
			(error) => dispatch(failure(error.toString()))
		);
	};

	function request() {
		return { type: historicalConstants.HISTORICAL_RADAR_REQUEST };
	}
	function success(historical) {
		return {
			type: historicalConstants.HISTORICAL_RADAR_SUCCESS,
			historical
		};
	}
	function failure(error) {
		return { type: historicalConstants.HISTORICAL_RADAR_FAILURE, error };
	}
}

function getManualByMissionId(id) {
	return (dispatch) => {
		dispatch(request());

		historicalService.getManualByMissionId(id).then(
			(data) => {
				const historical = {
					data: data,
					manualLoading: false,
					manualErrors: false
				};
				dispatch(success(historical));
			},
			(error) => dispatch(failure(error.toString()))
		);
	};

	function request() {
		return { type: historicalConstants.HISTORICAL_MANUAL_REQUEST };
	}
	function success(historical) {
		return {
			type: historicalConstants.HISTORICAL_MANUAL_SUCCESS,
			historical
		};
	}
	function failure(error) {
		return { type: historicalConstants.HISTORICAL_MANUAL_FAILURE, error };
	}
}

function getCrewByMissionId(id) {
	return (dispatch) => {
		dispatch(request());

		historicalService.getCrewByMissionId(id).then(
			(historical) => dispatch(success(historical)),
			(error) => dispatch(failure(error.toString()))
		);
	};

	function request() {
		return { type: historicalConstants.HISTORICAL_CREW_REQUEST };
	}
	function success(historical) {
		return {
			type: historicalConstants.HISTORICAL_CREW_SUCCESS,
			historical
		};
	}
	function failure(error) {
		return { type: historicalConstants.HISTORICAL_CREW_FAILURE, error };
	}
}

function getAircraftByMissionId(id) {
	return (dispatch) => {
		dispatch(request());

		historicalService.getAircraftByMissionId(id).then(
			(historical) => dispatch(success(historical)),
			(error) => dispatch(failure(error.toString()))
		);
	};

	function request() {
		return { type: historicalConstants.HISTORICAL_AIRCRAFT_REQUEST };
	}
	function success(historical) {
		return {
			type: historicalConstants.HISTORICAL_AIRCRAFT_SUCCESS,
			historical
		};
	}
	function failure(error) {
		return { type: historicalConstants.HISTORICAL_AIRCRAFT_FAILURE, error };
	}
}

function getProfileByMissionId(id) {
	return (dispatch) => {
		dispatch(request());

		historicalService.getProfileByMissionId(id).then(
			(historical) => dispatch(success(historical)),
			(error) => dispatch(failure(error.toString()))
		);
	};

	function request() {
		return { type: historicalConstants.HISTORICAL_PROFILE_REQUEST };
	}
	function success(historical) {
		return {
			type: historicalConstants.HISTORICAL_PROFILE_SUCCESS,
			historical
		};
	}
	function failure(error) {
		return { type: historicalConstants.HISTORICAL_PROFILE_FAILURE, error };
	}
}

function deSelectTracks() {
	return (dispatch) => {
		dispatch({
			type: historicalConstants.HISTORICAL_DESELECT_TRACK
		});
	};
}
function selectTrack(tracks) {
	let partialTrack = tracks[0];

	return (dispatch) => {
		if (sessionStorage.getItem('sisUser')) {
			let user = JSON.parse(sessionStorage.getItem('sisUser'));
			let requestOptions = {
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + user.token,
					responseType: 'application/json; charset=utf-8',
					Accept: 'application/json',
					'max-age-override': 10
				}
			};

			fetch(
				`${sisConfig.build[window.location.hostname].api}/targets/historical/` +
					encodeURIComponent(tracks[0].id),
				requestOptions
			)
				.then(httpInterceptor)
				.then((details) => {
					partialTrack = R.mergeRight(
						partialTrack,
						cleanKeys(details)
					);

					// Merge the new details with existing track where needed
					partialTrack.trackComments =
						(details || {}).TrackComments || [];
					return [partialTrack];
				})
				.then(
					(tracks) => {
						dispatch(select(tracks));
					},
					(error) => {
						dispatch(failure(error.toString()));
					}
				);
		}
	};

	function select(tracks) {
		return { type: historicalConstants.HISTORICAL_SELECT_TRACK, tracks };
	}
	function failure(error) {
		return {
			type: historicalConstants.HISTORICAL_SELECT_TRACK_FAILURE,
			error
		};
	}
}
