import React, {useState, useEffect } from 'react';
import VideoPlayer from './MP4VideoPlayer';

import './video-container.scss';

export default function VideoContainer(props) {
    return (
        <div
            id="video-container"
            className="video-ctn"
        >
            <div
                id="video-container-content"
                className="video-ctn-content"
            >
                <VideoPlayer source={props.source} autoPlay={props.autoPlay}/>
            </div>
        </div>
    );
}