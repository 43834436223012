import { alertConstants } from './alert.constants';

export const alertActions = {
	addAlert,
	removeAlert,
	removeAnyAlertsWith
};

function addAlert(alert) {
	return { type: alertConstants.ADD_ALERT, alert };
}
function removeAlert(timeStamp) {
	return { type: alertConstants.REMOVE_ALERT, timeStamp };
}
function removeAnyAlertsWith(messageId) {
	return { type: alertConstants.REMOVE_ANY_ALERTS_WITH, messageId }
}
