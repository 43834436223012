export const profileConstants = {
    GET_REQUEST: 'PROFILES_GET_REQUEST',
    GET_SUCCESS: 'PROFILES_GET_SUCCESS',
    GET_FAILURE: 'PROFILES_GET_FAILURE',

    INSERT_REQUEST: 'PROFILES_INSERT_REQUEST',
    INSERT_SUCCESS: 'PROFILES_INSERT_SUCCESS',
    INSERT_FAILURE: 'PROFILES_INSERT_FAILURE',

    UPDATE_REQUEST: 'PROFILES_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'PROFILES_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PROFILES_UPDATE_FAILURE',

    DELETE_REQUEST: 'PROFILES_DELETE_REQUEST',
    DELETE_SUCCESS: 'PROFILES_DELETE_SUCCESS',
    DELETE_FAILURE: 'PROFILES_DELETE_FAILURE'    
};