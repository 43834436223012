// # Imports
// External Imports
import React from 'react';
import createReactClass from 'create-react-class';
import debounce from 'lodash.debounce';

// Internal Imports

// Assets
import './search-box.scss';

const DEBOUNCE_INTERVAL = 2000;

/**
 * Simple dumb search box component that leaves the actual execution of searches to the component that renders it. Note
 * that just like an input, this calls onSearchTextChanged when the value is changed, and expects that its parent
 * component will listen for this and update searchText with the new value.
 */
export default createReactClass({
	displayName: 'SearchBox',

	getDefaultProps() {
		return {
			placeholder: 'Search',
			alwaysShowClear: false,
			autoFocus: false
		};
	},

	/* eslint-disable-next-line camelcase */
	UNSAFE_componentWillMount() {
		this.searchWithDebounce = debounce(this.search, DEBOUNCE_INTERVAL);
	},

	componentWillUnmount() {
		this.removeDebounce();
	},

	hasValue() {
		return this.props.searchText.length > 0;
	},

	search() {
		this.removeDebounce();
		this.props.onDoSearch();
	},

	removeDebounce() {
		this.searchWithDebounce.cancel();
	},

	handleChange(event) {
		const value = event.target.value;
		this.props.onSearchTextChanged(value);
		this.searchWithDebounce();
	},

	clearSearch() {
		this.props.onSearchTextChanged('');
		this.search();

		if (this.props.onClear) {
			this.props.onClear();
		}
	},

	onKeyDown(event) {
		if (event.keyCode === 13) {
			this.search();
		}
	},

	render() {
		const clearButton = (
			<button
				type="button"
				className={'searchClear'}
				onClick={this.clearSearch}
			>
				x
			</button>
		);

		return (
			<form
				className={'searchData'}
				autoComplete="off"
				onSubmit={(event) => event.preventDefault()}
			>
				<label htmlFor="search" className={'formLabel'}></label>
				<input
					id="search"
					type="text"
					name="search"
					value={this.props.searchText}
					onChange={this.handleChange}
					onFocus={this.props.onFocus}
					onKeyDown={this.onKeyDown}
					className={'searchField'}
					placeholder={this.props.placeholder}
					autoComplete="off"
					autoFocus={this.props.autoFocus}
				/>
				{(this.props.alwaysShowClear || this.hasValue()) && clearButton}
			</form>
		);
	}
});
