import React from 'react';
import { useIntl} from 'react-intl';
import './thumbnail-warning.scss'

export default function ThumbnailWarning(props){
    const intl = useIntl();
    
    return (
        <div id="realtime-media-warning-container" className="realtime-media-warning-container">
            <div
                id="realtime-media-warning-message"
                className="realtime-media-warning-message"
            > 
                {props.message}
            </div>
            <button
                type="button"
                className="realtime-ok-download-media"
                disabled={false}
                onClick = {props.downloadMediaRequestOn}
            >
               {props.title}
            </button>
        </div>
    );
}
