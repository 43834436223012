import sisConfig from '../sis.config.json';
import MilStd2525Resolver from './milStd2525-resolver.js';

export const AutoSymbolizer = {
    getSymbol: target => {
        let loc = "milStd2525";
        let path = MilStd2525Resolver.getSidc(target.mapSymbolAttributes).toLowerCase();
        if(sisConfig.client && sisConfig.customSymbology[sisConfig.client] && sisConfig.customSymbology[sisConfig.client][path]) {
            loc = sisConfig.client;
            path = sisConfig.customSymbology[sisConfig.client][path];
            if(typeof path === 'object') 
            {
                if(target.vesselCountry !== "") {
                    path = target.vesselCountry === "CAN" ? path.canadian : path.other;
                } else {
                    path = path.default;
                }
            }
        }    
        return `${loc}/${path}.png`;
    }
};