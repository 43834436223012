// # Imports
// External Imports
import React, { useEffect, useState } from 'react';

import { useIntl, FormattedMessage } from 'react-intl';

import { ReactComponent as PlayVideo } from '../../assets/icons/icon_media-play-video.svg';

import { ReactComponent as AssociateMedia } from '../../assets/icons/icon_media-associate-media.svg';

import { ReactComponent as DisAssociateMedia } from '../../assets/icons/icon_media-unassociate.svg';

import { ReactComponent as DownloadMedia } from '../../assets/icons/icon_media-download-media.svg';

// Internal Imports
import { mediaService } from '../../_services/media.service.js';
import {canPlayMediaAsVideo, downloadMedia} from './../../_helpers/media.js';
import MediaItem from '../Media/MediaItem';
import { permissionService } from './../../_services/permission.service'

// Assets
import './historical-track-media.scss';
import ProgressSpinner from '../ProgressSpinner';

export default function HistoricalTrackMedia(props) {
	let savedState = sessionStorage.getItem('state:historical-track-media')
		? JSON.parse(sessionStorage.getItem('state:historical-track-media'))
		: {
			layout: 'tile'
		};

	const [mediaArr, setMediaArr] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingAssoc, setLoadingAssoc] = useState(false);
	const [loadingDown, setLoadingDown] = useState(false);
	const [layout, setLayout] = useState(savedState.layout);
	const [selectedMediaData, setSelectedMediaData] = useState({
		id: null,
		mimeType: "",
		fileName: ""
	});

	const intl = useIntl();

	useEffect(() => {
		const newState = {
			...savedState,
			layout
		};
		sessionStorage.setItem('state:historical-track-media', JSON.stringify(newState));
		savedState = newState;
	}, [layout]);

	useEffect(() => {
		setLoading(true);
		let mediaRequests = [];
		props.mediaList.map((media) => {
			mediaRequests.push(mediaService.getDetails(media.MediaId).catch(() => null));
		});
		Promise.all(mediaRequests)
			.then((res) => {
				const noNullRes = res.filter(r => r !== null);
				return noNullRes.map(details => ({
						id: details._id,
						file: details.fileInfo.originalFileName,
						size: details.fileInfo.fileSizeBytes,
						type: details.fileInfo.mimeType,
						thumb: null
				}));
		
			})
			.then((newMediaArr) => {
				function compare (media1, media2){
					const file1 = media1.file.toLowerCase();
					const file2 = media2.file.toLowerCase();
					let comparison = 0;
					if (file1 < file2)
						return -1
					else if (file1 > file2)
						return 1
					return comparison;
				}
				setMediaArr(newMediaArr.sort(compare));
				setLoading(false);
			});
	}, [props.mediaList]);

	const selectMedia = (id) => {
		if(id !== null) {
			mediaService.getDetails(id)
				.then(res => {
					setSelectedMediaData({
						id,
						mimeType: res.fileInfo.mimeType,
						fileName: res.fileInfo.originalFileName
					});
				});
		}
	};
	return (
		<div
			id="hist-track-media-ctn"
			className="hist-track-media-container"
			onClick={() => {
				setSelectedMediaData({...selectedMediaData, id: null});
			}}
		>	
			<div className="media-container">
				<div className="left">
					<button
						type="button"
						onClick={(e) => {
							setLayout('list');
						}}
						className={layout === 'list' ? 'btn-selected' : 'btn'}
						disabled={false}
						title={intl.formatMessage({
							id: 'app.map.sidebar.realtime.media.list.title',
							defaultMessage: 'Organize media as a list'
						})}
					>
						<FormattedMessage
							id="app.map.sidebar.realtime.media.list"
							defaultMessage="list"
						/>
					</button>
					<button
						type="button"
						onClick={(e) => {
							setLayout('tile');
						}}
						className={layout === 'tile' ? 'btn-selected' : 'btn'}
						disabled={false}
						title={intl.formatMessage({
							id: 'app.map.sidebar.realtime.media.tile.title',
							defaultMessage: 'Organize media as tiles'
						})}
					>
						<FormattedMessage
							id="app.map.sidebar.realtime.media.tile"
							defaultMessage="Tile"
						/>
					</button>
				</div>
				<ul
					id="track-media-list"
					className={
						layout === 'list'
							? 'media-list-view'
							: 'media-tile-view'
					}
				>
					{mediaArr.map((media, key) => (
						<MediaItem
							media={media}
							idx={key}
							layout={layout}
							selectMedia={selectMedia}
							isSelected={selectedMediaData.id === media.id}
						></MediaItem>
					))}
					<div style={{"position": "absolute", "display": loading ? "block" : "none", "margin-left": "45%"}}><ProgressSpinner size="large"/></div>
					{!loading && mediaArr.length === 0 && (
						<li
							id="hist-track-media-list-empty"
							className="hist-media-tab-empty"
						>
							{intl.formatMessage({
								id: 'app.media.nomedia'
							})}
						</li>
					)}
				</ul>
			</div>
			{props.collectionId !== 'nav' && (<div className="hist-track-media-actions">
				<div className="hist-track-media-actions-group">
					<div className="hist-track-media-actions-column">
						<button
							className="historical-track-media-play-video-button"
							onClick={(event) => {
								if(selectedMediaData.id !== null) {
									event.preventDefault();
									event.stopPropagation();
									props.showVideoPlayer(selectedMediaData.fileName, selectedMediaData, "video");
								}
							}}
							disabled={
								selectedMediaData.id === null ? 'disabled' : canPlayMediaAsVideo(selectedMediaData) ? false : 'disabled'
							}
						>
						<PlayVideo
							title={intl.formatMessage({
								id:
									'app.map.trackinspector.media.play.title',
								defaultMessage:
									'Play video media from an associated track'
							})}
							className="media-icon"
						/>
						</button>
							{permissionService.hasFeature("Media_Associate") && props.onMediaAssociation && (
								<>
									<label
										htmlFor="media-association"
										className="historical-track-media-association-label"
										disabled="disabled"
									>
										<AssociateMedia
											title={intl.formatMessage({
												id:
													'app.map.trackinspector.media.associate.title',
												defaultMessage:
													'associate a media with a track'
											})}
											className="media-icon"
										/>
									</label>
									<input
										type="file"
										multiple
										onChange={e => {
											setLoadingAssoc(true);
											props.onMediaAssociation(e)
												.then(() => setLoadingAssoc(false));
										}}
										id={'media-association'}
										disabled={false}
										title={intl.formatMessage({
											id:
												'app.map.trackinspector.media.associate.title',
											defaultMessage: 'associate a media with a track'
										})}
									/>
								</>
							)}
							{permissionService.hasFeature("Media_Disassociate") && props.onMediaDisAssociation && (
									<div style={{"display": "flex", "flex-direction": "row"}}> 
										<button
											className="historical-track-media-disassociation-button"
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												if (selectedMediaData.id !== null) {
													props
														.onMediaDisAssociation(selectedMediaData.id)
														.then(() => {
															setSelectedMediaData({...selectedMediaData, id: null});
													});
												}
											}}
											disabled={
												selectedMediaData.id === null ? 'disabled' : false
											}
										>
											<DisAssociateMedia
												title={intl.formatMessage({
													id:
														'app.map.trackinspector.media.disassociate.title',
													defaultMessage:
														'disassociate a media from a track'
												})}
												className="media-icon"
											/>
										</button>
									</div>
							)}
							<button
								className="historical-track-media-download-button"
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setLoadingDown(true);
									downloadMedia(selectedMediaData)
										.then(() => setLoadingDown(false));
								}}
								disabled={
									selectedMediaData.id === null ? 'disabled' : false
								}
							>
							<DownloadMedia 
								title={intl.formatMessage({
									id: 'app.map.trackinspector.media.download.title',
									defaultMessage: 'disassociate a media from a track'
								})}
								className="media-icon"
							/>
						</button>
					</div>
				</div>
				<div className="hist-track-media-spinner-hidden"><ProgressSpinner/></div>
				<div className={loadingAssoc ? "hist-track-media-spinner-visible" : "hist-track-media-spinner-hidden"}><ProgressSpinner/></div>
				<div className="hist-track-media-spinner-hidden"><ProgressSpinner/></div>
				<div className={loadingDown ? "hist-track-media-spinner-visible" : "hist-track-media-spinner-hidden"}><ProgressSpinner/></div>
			</div>)}
		</div>
	);
}
