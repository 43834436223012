const classificationStringLength = 15;

function MilStd2525Resolver() {}

/**
 * Gets the SIDC code based on the passed in attributes.
 * @param {string} mapSymbolAttributes
 * @returns {string}
 */
MilStd2525Resolver.getSidc = function (mapSymbolAttributes) {
	// Default symbol we use for unknown.
	var rtn = 'sugp-----------';
	if (mapSymbolAttributes) {
		var ids = mapSymbolAttributes.split(',');
		if (ids.length === 1 && ids[0].length === classificationStringLength) {
			rtn = mapSymbolAttributes;
		} else if (ids.length === 5) {
			var sensorType = ids[0] ? parseInt(ids[0], 10) : -1;
			var idType = ids[1] ? parseInt(ids[1], 10) : -1;
			var hostilityType = ids[2] ? parseInt(ids[2], 10) : -1;
			var surfaceType = ids[3] ? parseInt(ids[3], 10) : -1;
			var detailType = ids[4] ? parseInt(ids[4], 10) : -1;
			switch (idType) {
				case 1:
					rtn = getVesselSidc(hostilityType, surfaceType, detailType);
					break;
				case 2:
					rtn = GetIcebergSidc(
						hostilityType,
						surfaceType,
						detailType
					);
					break;
				case 3:
					rtn = BuoySidc;
					break;
				case 4:
					rtn = GetAnimalSidc(hostilityType, surfaceType, detailType);
					break;
				case 5:
					rtn = GetMissionEventSidc(
						hostilityType,
						surfaceType,
						detailType
					);
					break;
				case 6:
					rtn = PackIceSidc;
					break;
				case 7:
					rtn = GearSidc;
					break;
				case 9:
					rtn = "susppl---------"
					break;
				case 10:
					rtn = GetAircraftSidc(
						hostilityType,
						surfaceType,
						detailType
					);
					break;
				case 11:
					rtn = GetInfrastructureSidc(
						hostilityType,
						surfaceType,
						detailType
					);
					break;
				case 12:
					rtn = MarineMammalSidc;
					break;
				default:
					if (
						sensorType !== null &&
						(sensorType.Value === 2 || sensorType.Value === 6)
					) {
						rtn = 'spzp-----------';
					} else {
						rtn = formatString(
							'S{0}{1}{2}-----------',
							HostilityToSidc(hostilityType),
							SurfaceToSidc(surfaceType),
							StatusToSidc()
						);
					}
					break;
			}
		}
	}
	return rtn;
};

var BuoySidc = 'WOS-HABB--P----';
var GearSidc = 'WOS-HABB--PG---';
var PackIceSidc = 'WOS-HABB--PI---';
//var ManualUnknownSidc = "G-GPGPPW-------";
var MarineMammalSidc = 'G-OASM---------';
//var MarkerSidc = "sug-usm--------";
//var mUseNationalityAsHostile = false;
//var mOwnNation;

var VESSEL_MAP = [
	/* Unknown */ '----',
	/* Cruise = 1, */ 'XMP-',
	/* Kayak = 2, */ 'XR--',
	/* Lodge = 3, */ 'XM--',
	/* USCoastGuard = 4, */ 'XL--',
	/* Unknown = 5, */ '----',
	/* TugBoat = 6, */ 'XMTU',
	/* BulkCarrier = 7, */ 'XMC-',
	/* Container = 8, */ 'XMC-',
	/* DrillingUnit = 9, */ 'XM--',
	/* Barge = 10, */ 'NS--',
	/* FishingVessel = 11, */ 'XF--',
	/* GeneralCargo = 12, */ 'XMC-',
	/* ResearchVessel = 13, */ 'RV--',
	/* CoastGuardVessel = 14, */ 'XL--',
	/* Boom = 15, */ 'XM--',
	/* PleasureCraft = 16, */ 'XR--',
	/* Merchantman = 17, */ 'MV--',
	/* Combination = 18, */ 'XMC-',
	/* Other = 19, */ 'XMOO',
	/* PatrolVessel = 20, */ 'XL--',
	/* RhoRho = 21, */ 'XMR-',
	/* SupplyShip = 22, */ 'XMC-',
	/* Tanker = 23, */ 'XMO-',
	/* WarShip = 24, */ 'CL--',
	/* Submarine = 25, */ 'S---',
	/* SailingYacht = 26, */ 'XR--',
	/* Ferry = 27, */ 'XMF-',
	/* Commercial = 28, */ 'XM--',
	/* Government = 29, */ 'GV--'
];

/*
var AIRCRAFT_MAP = [
     Unknown            "----",
     FixedWing = 1,     "CF--",
     Rotary = 2,        "CH--",
     Seaplane = 3,      "MF--",
     Unknown = 4        "----",
];
*/

var SURFACE_MAP = [
	/* Unknown */ '-',
	/* Space = 1, */ 'P',
	/* Air = 2, */ 'A',
	/* Ground = 3, */ 'G',
	/* Sea = 4, */ 'S',
	/* SOF = 5, */ 'F',
	/* Other = 6, */ 'X',
	/* Unknown = 7, */ 'Z',
	/* Sub = 8 */ 'U'
];

var HOSTILITY_MAP = [
	/* Unknown */ '-',
	/* Pending = 1 */ 'P',
	/* Unknown = 2 */ 'U',
	/* AssumedFriendly = 3 */ 'A',
	/* Friend = 4 */ 'F',
	/* Neutral = 5 */ 'N',
	/* Suspect = 6 */ 'S',
	/* Hostile = 7 */ 'H',
	/* ExercisePending = 8 */ 'G',
	/* ExerciseUnknown = 9 */ 'W',
	/* ExerciseAssumedFriend = 10 */ 'M',
	/* ExerciseFriend = 11 */ 'D',
	/* ExerciseNeutral = 12 */ 'L',
	/* ExerciseSuspect = 13 */ 'J',
	/* ExerciseHostile = 14 */ 'K'
];

/**
 * Get hostility portion of SIDC.
 * @param {number} h
 */
function HostilityToSidc(h) {
	if (h === undefined) {
		return '-';
	}
	try {
		return HOSTILITY_MAP[h];
	} catch (ex) {
		console.log(ex);
		return '-';
	}
}

/**
 * Gets surface portion of SIDC/
 * @param {*} st
 * @returns {string}
 */
function SurfaceToSidc(st) {
	if (st === undefined) {
		return '-';
	}
	try {
		return SURFACE_MAP[st];
	} catch (ex) {
		console.log(ex);
		return '-';
	}
}

/**
 * Gets Vessel portion of SIDC.
 * @param {number} id
 * @returns {string}
 */
function VesselToSidc(id) {
	if (id === undefined || id === -1) {
		return VESSEL_MAP[0];
	}
	try {
		return VESSEL_MAP[id];
	} catch (ex) {
		console.log(ex);
		return VESSEL_MAP[0];
	}
}

/**
 * Returns Status portion for SIDC.
 * @returns {string}
 */
function StatusToSidc() {
	//make all tracks displayed as present
	return 'P';
	//return ts == DynamicTargetData.TrackStatus.Active ? 'P' : 'A';
}

/**
 * Gets the Mil Std SIDC based on the provided parameters.
 * @param {number} hostilityType
 * @param {number} surfaceType
 * @param {number} detailType
 * @returns {string}
 */
function GetIcebergSidc(hostilityType, surfaceType, detailType) {
	return formatString('G-O{0}HI---------', StatusToSidc());
}

/**
 * Gets the Mil Std SIDC based on the provided parameters.
 * @param {number} hostilityType
 * @param {number} surfaceType
 * @param {number} detailType
 * @returns {string}
 */
function GetAnimalSidc(hostilityType, surfaceType, detailType) {
	return formatString('G-O{0}SM---------', StatusToSidc());
}

/**
 * Gets the Mil Std SIDC based on the provided parameters.
 * @param {number} hostilityType
 * @param {number} surfaceType
 * @param {number} detailType
 * @returns {string}
 */
function GetAircraftSidc(hostilityType, surfaceType, detailType) {
	var rtn = formatString(
		'S{0}A{1}',
		HostilityToSidc(hostilityType),
		StatusToSidc()
	);
	if (detailType !== null) {
		switch (detailType) {
			case 1:
				rtn += 'CF---------';
				break;
			case 2:
				rtn += 'CH---------';
				break;
			case 3:
				rtn += 'MF---------';
				break;
			default:
				rtn += '-----------';
				break;
		}
	}
	return rtn;
}

/**
 * Gets the Mil Std SIDC based on the provided parameters.
 * @param {number} hostilityType
 * @param {number} surfaceType
 * @param {number} detailType
 * @returns {string}
 */
function GetInfrastructureSidc(hostilityType, surfaceType, detailType) {
	var rtn = 'G-GPGPPW------X-null-dt';
	if (detailType !== null) {
		switch (detailType) {
			case 1:
				rtn = formatString(
					'S{0}G{1}IBA---H----',
					HostilityToSidc(hostilityType),
					StatusToSidc()
				);
				break;
			case 2:
				rtn = 'WOS-HPFH--P----';
				break;
			case 3:
				rtn = 'WOS-HPFH--P----';
				break;
			case 4:
				rtn = 'WOS-HPML--P----';
				break;
			case 5:
				rtn = 'WOS-HABP--P----';
				break;
			case 6:
				rtn = 'WO-DHCB-----A--';
				break;
			case 7:
				rtn = 'EFFPHB----H----';
				break;
			case 8:
				rtn = 'WOS-HALH--P----';
				break;
			case 9:
				rtn = 'WOS-HABP--P----';
				break;
			case 10:
				rtn = 'WOS-HABP--P----';
				break;
			case 11:
				rtn = 'WOS-HPB-O-P----';
				break;
			case 12:
				rtn = 'WOS-HPBA--P----';
				break;
			case 13:
				rtn = 'WOS-HPB-O-P----';
				break;
			case 14:
				rtn = 'WO-DHPMO---L---';
				break;
		}
	}
	return rtn;
}

/**
 * Gets the Mil Std SIDC based on the provided parameters.
 * @param {number} hostilityType
 * @param {number} surfaceType
 * @returns {string}
 */
/*
function DefaultSidc(hostilityType, surfaceType) {
    return formatString("S{0}{1}{2}------------",
        HostilityToSidc(hostilityType),
        SurfaceToSidc(surfaceType),
        StatusToSidc());
}
*/

/**
 * Gets the Mil Std SIDC based on the provided parameters.
 * @param {number} hostilityType
 * @param {number} surfaceType
 * @param {number} detailType
 * @returns {string}
 */
function getVesselSidc(hostilityType, surfaceType, detailType) {
	var rtn = formatString(
		'S{0}S{1}XM---------',
		HostilityToSidc(hostilityType),
		StatusToSidc()
	);
	var h = HostilityToSidc(hostilityType);
	var st = StatusToSidc();
	try {
		rtn = formatString(
			'S{0}{1}{2}{3}-------',
			h,
			SurfaceToSidc(surfaceType),
			st,
			VesselToSidc(detailType)
		);
	} catch (ex) {
		console.log(ex);
	}
	return rtn;
}

function GetMissionEventSidc(hostilityType, surfaceType, detailType) {
	if (detailType === 11) {
		return 'G-GPGPPW------X';
	} else {
		return 'G-GPGPRI-------';
	}
}

/**
 * Functionally the same as String.Format in c#.
 * @param {string} format
 * @returns {string}
 */
function formatString(format) {
	var args = Array.prototype.slice.call(arguments, 1);
	return format.replace(/{(\d+)}/g, function (match, number) {
		return typeof args[number] !== 'undefined' ? args[number] : match;
	});
}

export default MilStd2525Resolver;
