//TODO: Fix the back-end so nothing in this file is needed (SISSearch tech debt)
import * as R from 'ramda';
import he from 'he';
import { getTime, parseISO } from 'date-fns';

const hydratePositionData = (serializedPositions) => {
	const positions = JSON.parse(serializedPositions).sort(
		(a, b) => getTime(parseISO(a[2])) - getTime(parseISO(b[2]))
	);

	return {
		times: positions.map((pos) => {
			return getTime(parseISO(pos[2]));
		}),

		coords: positions.map((pos) => {
			return [pos[1], pos[0]];
		}),

		headings: positions.map((pos) => {
			return pos[4];
		})
	};
};

export const hydrateData = R.evolve({
	latitude: he.decode,
	longitude: he.decode,
	positions: hydratePositionData
});

export const unCapitalizeFirstLetter = (
	[first, ...rest],
	locale = navigator.language
) => {
	return [first.toLocaleLowerCase(locale), ...rest].join('');
};

export const unCapitalizeFirstLetterInString = (string) => {
	return string.charAt(0).toLocaleLowerCase() + string.slice(1);
};

export const cleanKeyPair = (pair) => {
	const newKey = unCapitalizeFirstLetter(
		R.test(/_/, pair[0]) ? pair[0].split('_')[1] : pair[0]
	);
	return [newKey, pair[1]];
};

export const stripDownUPN = (value) => {
	return R.test(/@/, value) ? value.split('@')[0] : value;
};

export const cleanUsernames = (obj) => {
	return R.map(stripDownUPN)(obj);
};
export const cleanKeys = (obj) => {
	return R.pipe(R.toPairs, R.map(cleanKeyPair), R.fromPairs)(obj);
};

export const hydrateNavPositionData = (data, nav) => {
	const positions = JSON.parse(nav.positions);

	data[nav.id] = {
		times: positions.map((pos) => {
			return getTime(parseISO(pos[2]));
		}),

		coords: positions.map((pos) => {
			return [pos[1], pos[0]];
		}),

		headings: positions.map((pos) => {
			return pos[4];
		})
	};
	return data;
};

export const hydrateTrackPositionData = (data, track) => {
	const positions = JSON.parse(track.positions);

	data[track.id] = {
		times: positions.map((pos) => {
			return getTime(parseISO(pos[2]));
		}),

		coords: positions.map((pos) => {
			return [pos[1], pos[0]];
		})
	};
	return data;
};

export const addCollectionId = (collectionId) => (obj) => {
	obj.collectionId = collectionId;
	return obj;
};

export const getTimesFromPositionData = (data) => {
	return R.pipe(
		R.values,
		R.map((item) => {
			return item.times;
		}),
		R.flatten
	)(data);
};
