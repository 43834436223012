// # Imports
// External Imports
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import classNames from 'classnames';
import sisConfig from '../../sis.config.json';

// Internal Imports
import { authHeader } from '../../_helpers/auth-header';
import { httpInterceptor } from '../../_helpers/http-interceptor';
import ExceptionList from './Exceptions/ExceptionList';
import ExceptionForm from './Exceptions/ExceptionForm';
import TaskList from './Tasks/TaskList';

// Assets
import CheckboxNotSelected from '../../assets/icons/checkbox-not-selected.svg';
import CheckboxSelected from '../../assets/icons/checkbox-selected.svg';
import 'ag-grid-community/dist/styles/ag-grid.css';
import '../../styles/ag-theme-balham-dark.css';
import './asis-container.scss';

function ASISContainer() {
	
	// Get the fiscal year start date for the current year (keep this above state setting)
	const fiscalDate = (currDate) => {
		const currFiscal = new Date('04-01-' + currDate.getFullYear());
		const startFiscal =
			currDate > currFiscal
				? currFiscal
				: new Date('04-01-' + (currDate.getFullYear() - 1));
		return startFiscal;
	};
	const [savedState, setSavedState] = useState(
		sessionStorage.getItem('c4:asis-search') 
		? 
		JSON.parse(sessionStorage.getItem('c4:asis-search'))
		: {
				startDate: (new Date(Date.now() - 12096e5)).toJSON(),
				endDate: (new Date()).toJSON(),
				statusOpen: true,
				statusClosed: false
		  });	
	const [exceptions, setExceptions] = useState([]);
	const [filteredExceptions, setFilteredExceptions] = useState([]);
	const [selectedException, setSelectedException] = useState({ tasks: [] });
	const [lookupData, setLookupData] = useState({
		activities: [],
		areas: [],
		clients: [],
		patrols: [],
		regions: [],
		specialAreas: [],
		species: [],
		type: [],
		visibility: []
	});
	const [directedHours, setDirectedHours] = useState(0);
	const [formShown, setFormShown] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [startDate, setStartDate] = useState(new Date(savedState.startDate));
	const [endDate, setEndDate] = useState(new Date(savedState.endDate));
	const [statusOpen, setStatusOpen] = useState(savedState.statusOpen);
	const [statusClosed, setStatusClosed] = useState(savedState.statusClosed);
	const dispatch = useDispatch();
	const intl = useIntl();
	const todaysDate = new Date();

	const locale = localStorage.getItem('sisLang') || 'en';
	registerLocale('fr', fr);

	const getRequestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	useEffect(() => {
		getExceptions();
		getLookupData();
	}, []);

	useEffect(() => {
		const newState = {
			...savedState,
			endDate: endDate,
			startDate: startDate,
			statusOpen: statusOpen,
			statusClosed: statusClosed
		};
		sessionStorage.setItem('c4:asis-search', JSON.stringify(newState));
		setSavedState(newState);
	}, [startDate, endDate, statusOpen, statusClosed, locale])

	const updateExceptions = (exceptionList) => 
	{
		setExceptions(exceptionList);
		setFilteredExceptions(
			filterExceptions(exceptionList, statusOpen, statusClosed)
		);
		return exceptionList;
	}

	const getExceptions = () => {
		const chosenStartDate = startDate === null ? fiscalDate(new Date()) : startDate;
		const chosenEndDate = endDate.getDay() == todaysDate.getDay() ? new Date() : endDate;
		chosenStartDate.setHours(0, 0, 0, 0);
		chosenEndDate.setHours(23, 59, 59, 999);
		return fetch(
			`${sisConfig.build[window.location.hostname].api}/asis/exceptions?startDate=` +
				encodeURIComponent(chosenStartDate.toISOString()) +
				`&endDate=` +
				encodeURIComponent(chosenEndDate.toISOString()),
			getRequestOptions
		)
			.then(httpInterceptor)
			.then(res => updateExceptions(res));
	};

	const dateFormatter = (dateValue) => {
		var d = new Date(dateValue);
		var mon = addZero(d.getMonth() + 1);
		var day = addZero(d.getDate());
		var year = d.getFullYear();
		var h = addZero(d.getHours());
		var m = addZero(d.getMinutes());
		const newDate = mon + '/' + day + '/' + year + ' ' + h + ':' + m;
		return newDate;
	};

	const addZero = (i) => {
		if (i < 10) {
			i = '0' + i;
		}
		return i;
	};

	const getLookupData = () => {
		return fetch(
			`${sisConfig.build[window.location.hostname].api}/asis/lookup-data`,
			getRequestOptions
		)
			.then(httpInterceptor)
			.then((res) => {
				setLookupData(res);
			});
	};

	const getExceptionById = (id, onComplete = () => {}) => {
		return fetch(
			`${sisConfig.build[window.location.hostname].api}/asis/exceptions/` +
				encodeURIComponent(id),
			getRequestOptions
		)
			.then(httpInterceptor)
			.then((res) => {
				setSelectedException(res);
				calculateDirectedHours(res.tasks);
				onComplete();
			});
	};

	const toggleOpenStatus = () => {
		setFilteredExceptions(
			filterExceptions(exceptions, !statusOpen, statusClosed)
		);
		setStatusOpen(!statusOpen);
	};

	const toggleClosedStatus = () => {
		setFilteredExceptions(
			filterExceptions(exceptions, statusOpen, !statusClosed)
		);
		setStatusClosed(!statusClosed);
	};

	const toggleShowForm = (editException, action, formException) => {
		setEditMode(editException);
		setFormShown(!formShown);

		if (action === 'task') {
			getExceptions()
				.then(res => {
					for(let index = 0; index < res.length; index++) {
						if(
							res[index].clientId === formException.clientId &&
							res[index].missionNumber === formException.missionNumber
						) {
							getExceptionById(res[index].id);
							break;
						}
					}
				});
		}

		if (action === 'reload') {
			getExceptions();
		}

		if(formException !== undefined && formException.id !== undefined)
			getExceptionById(formException.id);
	};

	const filterExceptions = (list, open, closed) => {
		const openFiltered =
			open === false
				? list.filter(
						(x) =>
							x.status !== 'Open' && x.status !== 'Open (Draft)'
				  )
				: list;
		const closedFiltered =
			closed === false
				? openFiltered.filter((x) => x.status !== 'Closed')
				: openFiltered;
		return closedFiltered;
	};

	const handleEndChange = (date) => {
		setEndDate(date === null ? new Date() : date);
	};

	const handleStartChange = (date) => {
		setStartDate(date === null ? new Date(Date.now() - 12096e5) : date);	
	};

	const selectException = (params, onComplete = () => {}) => {
		getExceptionById(params.data.id, onComplete);
	};

	const handleFilterSubmit = () => {
		getExceptions();
	};

	const calculateDirectedHours = (tasks) => {
		let directedHoursTotal = 0.0;
		if (tasks) {
			tasks.map((x) => {
				directedHoursTotal =
					directedHoursTotal + parseFloat(x.directedHours);
			});
		}
		setDirectedHours(directedHoursTotal);
	};

	const updatedTasks = (tempTaskList) => {
		calculateDirectedHours(tempTaskList);
	};

	const regionName = (id) => {
		let region = lookupData.regions.filter((x) => {
			return x.id === id;
		});
		return region[0].value;
	};

	const clientName = (id) => {
		let client = lookupData.clients.filter((x) => {
			return x.id === id;
		});
		return client[0].value;
	}

	return (
		<div id="asis-ctn" className="asis-container">
			{formShown && (
				<ExceptionForm
					selectedException={selectedException}
					editMode={editMode}
					onCancel={toggleShowForm}
					getExceptionById={getExceptionById}
					selectException={selectException}
					lookupData={lookupData}
				></ExceptionForm>
			)}
			<div id="asis-header" className="asis-header">
				<div
					id="asis-header-date-ctn"
					className="asis-header-date-container"
				>
					<div
						id="asis-header-date-label"
						className="asis-header-date-label"
					>
						{intl.formatMessage({
							id: 'app.map.historical.search.date.label'
						})}
					</div>
					<div
						id="asis-header-date-range-ctn"
						className="asis-header-date-range-container"
					>
						<div className="asis-header-date-from-container">
							<div className="search-date-from">
								<DatePicker
									name="startDate"
									selected={startDate}
									onChange={handleStartChange}
									maxDate={endDate}
									strictParsing
									locale={locale}
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
								/>
							</div>
						</div>
						<div className="asis-header-date-to-container">
							<div className="search-date-to-label">
								{intl.formatMessage({
									id:
										'app.map.historical.search.date.to.label',
									defaultMessage: 'To'
								})}
							</div>
							<div className="search-date-to">
								<DatePicker
									name="endDate"
									selected={endDate}
									onChange={handleEndChange}
									maxDate={new Date()}
									strictParsing
									locale={locale}
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
								/>
							</div>
						</div>

						<button
							id={'asis-header-date-submit-btn'}
							type="button"
							title={intl.formatMessage({
								id: 'app.asis.date.submit.button'
							})}
							onClick={() => {
								handleFilterSubmit();
							}}
							className="asis-header-date-submit-btn"
						>
							{intl.formatMessage({
								id: 'app.asis.date.submit.button'
							})}
						</button>
					</div>
				</div>
				<div
					id="asis-header-status-ctn"
					className="asis-header-status-container"
				>
					<div
						id="asis-header-status-label"
						className="asis-header-status-label"
					>
						{intl.formatMessage({
							id: 'app.asis.header.status.label'
						})}
					</div>
					<div
						id="asis-header-status-selection-ctn"
						className="asis-header-status-selection-container"
					>
						<button
							type="button"
							name="asis-header-status-open-btn"
							id={'asis-header-status-open-btn'}
							onClick={() => {
								toggleOpenStatus();
							}}
							title={intl.formatMessage({
								id: 'app.asis.header.status.open.label'
							})}
							className="asis-header-status-open-btn"
						>
							<img
								alt={intl.formatMessage({
									id: 'app.asis.header.status.open.label'
								})}
								src={
									statusOpen === true
										? CheckboxSelected
										: CheckboxNotSelected
								}
							/>
						</button>
						<label
							htmlFor="asis-header-status-open-btn"
							id="asis-header-status-selection-open-label"
							className="asis-header-status-selection-label"
						>
							{intl.formatMessage({
								id: 'app.asis.header.status.open.label'
							})}
						</label>
						<button
							type="button"
							name="asis-header-status-closed-btn"
							id={'asis-header-status-closed-btn'}
							onClick={() => {
								toggleClosedStatus();
							}}
							title={intl.formatMessage({
								id: 'app.asis.header.status.closed.label'
							})}
							className="asis-header-status-closed-btn"
						>
							<img
								alt={intl.formatMessage({
									id: 'app.asis.header.status.closed.label'
								})}
								src={
									statusClosed === true
										? CheckboxSelected
										: CheckboxNotSelected
								}
							/>
						</button>

						<label
							htmlFor="asis-header-status-closed-btn"
							id="asis-header-status-selection-closed-label"
							className="asis-header-status-selection-label"
						>
							{intl.formatMessage({
								id: 'app.asis.header.status.closed.label'
							})}
						</label>
					</div>
				</div>
			</div>
			<div id="asis-body-top-ctn" className="asis-body-top-container">
				<ExceptionList
					exceptions={filteredExceptions}
					selectedException={selectedException}
					selectException={selectException}
					toggleForm={toggleShowForm}
					lookupData={lookupData}
					onRefresh={getExceptions}
					regionName={regionName}
					clientName={clientName}
				></ExceptionList>
				{selectedException.missionNumber !== undefined && filteredExceptions.filter(exception => exception.id === selectedException.id).length > 0 && (
					<ul
						id="asis-exception-selected-ctn"
						className="asis-exception-selected-container"
					>
						<li
							id="asis-exception-selected-mission-row"
							className="asis-exception-selected-row"
						>
							<span
								id="asis-exception-selected-mission-field"
								className="asis-exception-selected-field"
							>
								{intl.formatMessage({
									id: 'data.asis.missionNumber'
								})}
								:
							</span>
							{selectedException.missionNumber}
						</li>

						<li
							id="asis-exception-selected-time-row"
							className="asis-exception-selected-row"
						>
							<span
								id="asis-exception-selected-time-field"
								className="asis-exception-selected-field"
							>
								{intl.formatMessage({
									id: 'data.asis.startDate'
								})}
								:
							</span>
							{dateFormatter(selectedException.startTime)}
						</li>
						<li
							id="asis-exception-selected-region-row"
							className="asis-exception-selected-row"
						>
							<span
								id="asis-exception-selected-region-field"
								className="asis-exception-selected-field"
							>
								{intl.formatMessage({
									id: 'data.asis.region'
								})}
								:
							</span>
							{regionName(selectedException.regionId)}
						</li>
						<li
							id="asis-exception-selected-other-row"
							className="asis-exception-selected-row"
						>
							<span
								id="asis-exception-selected-other-field"
								className="asis-exception-selected-field"
							>
								{intl.formatMessage({
									id: 'data.asis.altFisheriesOfficer'
								})}
								:
							</span>
							{selectedException.altFisheriesOfficerName !== ''
								? selectedException.altFisheriesOfficerName
								: 'N/A'}
						</li>
						<li
							id="asis-exception-selected-status-row"
							className="asis-exception-selected-row"
						>
							<span
								id="asis-exception-selected-status-field"
								className="asis-exception-selected-field"
							>
								{intl.formatMessage({
									id: 'data.asis.status'
								})}
								:
							</span>
							{selectedException.status}
						</li>
						<li
							id="asis-exception-selected-flight-row"
							className="asis-exception-selected-row"
						>
							<span
								id="asis-exception-selected-flight-field"
								className="asis-exception-selected-field"
							>
								{intl.formatMessage({
									id: 'data.asis.flightHours'
								})}
								:
							</span>
							{selectedException.flightHours}
						</li>
						<li
							id="asis-exception-selected-patrol-row"
							className="asis-exception-selected-row"
						>
							<span
								id="asis-exception-selected-patrol-field"
								className="asis-exception-selected-field"
							>
								{intl.formatMessage({
									id: 'data.asis.patrolHours'
								})}
								:
							</span>
							{selectedException.patrolHours}
						</li>
						<li
							id="asis-exception-selected-directed-row"
							className="asis-exception-selected-row"
						>
							<span
								id="asis-exception-selected-directed-field"
								className="asis-exception-selected-field"
							>
								{intl.formatMessage({
									id: 'data.asis.directedHours'
								})}
								:
							</span>
							<div className={selectedException.patrolHours < directedHours ? "asis-exception-selected-hours-errors": ''}> {directedHours.toFixed(2)}</div>
						</li>
					</ul>
				)}
			</div>
			<div
				id="asis-body-bottom-ctn"
				className="asis-body-bottom-container"
			>
				<TaskList
					exception={selectedException}
					lookupData={lookupData}
					onTaskChange={updatedTasks}
					onRefresh={getExceptions}
				></TaskList>
			</div>
		</div>
	);
}

export { ASISContainer };
