// # Imports

import { authHeader } from '../_helpers/auth-header';
import { httpInterceptor } from '../_helpers/http-interceptor';
import sisConfig from '../sis.config.json';

export default {
	login,
	logout,
	changeLocalization
};

// # Funcs

function changeLocalization(lang) {
	const langToLanguage = {
		en: 'English',
		fr: 'French'
	};

	const language = langToLanguage[lang];

	const data = new URLSearchParams();
	data.append('__EVENTTARGET', 'ctl00$ContentPlaceHolder1$ctl00');
	data.append(
		'__EVENTARGUMENT',
		`ContentPlaceHolder1_m${language}Button|event|Click`
	);
	data.append(
		'submitDirectEventConfig',
		`{"config":{"extraParams":{"Language":"${language}"}}}`
	);

	const requestOptions = {
		method: 'POST',
		body: data,
		headers: {
			'X-Ext.Net': 'delta=true',
			...authHeader()
		}
	};

	return fetch('/SIS3/Login/?ReturnUrl=%2f', requestOptions);
}

function logout() {
	const data = new URLSearchParams();
	data.append('__EVENTTARGET', 'ctl00$logoutButton');

	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: data
	};

	return fetch(
		`${sisConfig.build[window.location.hostname].sis3_logout}`,
		requestOptions
	).then((res) => {
		console.log('logout', res);
		return res;
	});
}
function login(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(
		`${sisConfig.build[window.location.hostname].sis3_login}`,
		requestOptions
	).then((res) => {
		console.log('login', res);
		return res;
	});
}
