// # Imports
// External Imports
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal Imports
import Alert from './Alert';
import { alertActions } from '../../_modules/alerts/alert.actions';

// Assets
import './alert-list.scss';

export default function AlertList() {
	const dispatch = useDispatch();
	const stateAlerts = useSelector((state) => state.alert);

	const closeAlert = (timeStamp) => {
		dispatch(alertActions.removeAlert(timeStamp, stateAlerts.alerts));
	};

	return (
		<ul className={'alert-list-container'}>
			{stateAlerts.alerts.map((alert, key) => {
				return (
					<li>
						<Alert
							level={alert.level}
							idx={key}
							timeStamp={alert.timeStamp}
							messageId={alert.messageId}
							action={alert.action}
							onCancel={alert.onCancel}
							onClose={closeAlert}
						></Alert>
					</li>
				);
			})}
		</ul>
	);
}
