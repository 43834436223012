// # Imports
// External Imports
import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';
import { createAndDownloadCSVFromObjList } from './../../../_helpers/csv'

// Internal Imports

// Assets
import { ReactComponent as Refresh } from '../../../assets/icons/icon_refresh.svg';
import { ReactComponent as First } from '../../../assets/icons/icon_nav-first.svg';
import { ReactComponent as Previous } from '../../../assets/icons/icon_nav-previous.svg';
import { ReactComponent as Next } from '../../../assets/icons/icon_nav-next.svg';
import { ReactComponent as Last } from '../../../assets/icons/icon_nav-last.svg';
import 'ag-grid-community/dist/styles/ag-grid.css';
import '../../../styles/ag-theme-balham-dark.css';
import './exception-list.scss';
import { useEffect } from 'react';
import ProgressSpinner from '../../ProgressSpinner';
import { permissionService } from '../../../_services/permission.service';

export default function ExceptionList(props) {

	const closedStatuses = ["Closed", "Fermée"];

	const PAGINATION_SIZE = 7;
	const intl = useIntl();
	const grid = {
		exceptions: useRef()
	};
	let user = JSON.parse(sessionStorage.getItem('sisUser'));
	

	const [loading, setLoading] = useState(false);
	const [selectedException, setSelectedException] = useState(null);
	const [selectingException, setSelectingException] = useState(false);
	const [currPage, setCurrPage] = useState(1);
	
	const dateFormatter = (params) => {
		var d = new Date(params.value);
		var mon = addZero(d.getMonth() + 1);
		var day = addZero(d.getDate());
		var year = d.getFullYear();
		var h = addZero(d.getHours());
		var m = addZero(d.getMinutes());
		const newDate = mon + '/' + day + '/' + year + ' ' + h + ':' + m;
		return newDate;
	};

	const parseExternal = (params) => {
		const stringId =
			params.value === false
				? 'app.asis.exceptions.grid.value.external'
				: 'app.asis.exceptions.grid.value.interal';
		const translatedValue = intl.formatMessage({
			id: stringId
		});
		return translatedValue;
	};

	const addZero = (i) => {
		if (i < 10) {
			i = '0' + i;
		}
		return i;
	};

	const exceptionListcolumns = [
		{
			headerName: intl.formatMessage({
				id: 'app.asis.exceptions.grid.column.missionNumber'
			}),
			field: 'missionNumber',
			resizable: true
		},
		{
			headerName: intl.formatMessage({
				id: 'app.asis.exceptions.grid.column.startTime'
			}),
			field: 'startTime',
			valueFormatter: dateFormatter,
			resizable: true
		},
		{
			headerName: intl.formatMessage({
				id: 'app.asis.exceptions.grid.column.type'
			}),
			field: 'external',
			valueFormatter: parseExternal,
			resizable: true
		},
		{
			headerName: intl.formatMessage({
				id: 'app.asis.exceptions.grid.column.fisheriesOfficerName'
			}),
			field: 'fisheriesOfficerName',
			resizable: true
		},
		{
			headerName: intl.formatMessage({
				id: 'app.asis.exceptions.grid.column.status'
			}),
			field: 'status',
			resizable: true,
			cellClass: function (params) {
				return closedStatuses.includes(params.value)
					? 'asis-exception-grid-status-closed'
					: 'asis-exception-grid-status-open';
			}
		}
	];

	const onExceptionSelection = (params) => {
		setSelectingException(true);
		setSelectedException(params.data);
		// edit button isn't disabled while the async selectException is occuring so an edit can cause a crash without this
		props.selectException(params, () => setSelectingException(false));
	};

	function setText(selector, text) {
		document.querySelector(selector).innerHTML = text;
	}

	function setLastButtonDisabled(disabled) {
		document.querySelector(
			'#asis-exception-list-last-btn'
		).disabled = disabled;
	}

	const onFirst = () => {
		grid.exceptions.current.api.paginationGoToFirstPage();
	};

	const onLast = () => {
		grid.exceptions.current.api.paginationGoToLastPage();
	};

	const onNext = () => {
		grid.exceptions.current.api.paginationGoToNextPage();
	};

	const onPrevious = () => {
		grid.exceptions.current.api.paginationGoToPreviousPage();
	};

	const onPaginationChanged = () => {
		setCurrPage(grid.exceptions.current.api.paginationGetCurrentPage() + 1);
		setText(
			'#asis-exception-list-lbTotalPages',
			grid.exceptions.current.api.paginationGetTotalPages()
		);
		setLastButtonDisabled(
			!grid.exceptions.current.api.paginationIsLastPageFound()
		);
	};

	const canEditException = () => {
		const validException = selectedException !== null && selectedException !== undefined;
		if(!validException || selectingException)
			return false;
		const visibleException = props.exceptions.filter(exception => exception.id === selectedException.id).length !== 0;
		const closedException = selectedException.status === 'Closed';
		const createdException = selectedException.fisheriesOfficerName === user.displayName;
		if(permissionService.hasFeature("ASIS_Admin"))
			return validException && visibleException;
		return validException && visibleException && !closedException && createdException;
	};

	const translateStatus = (status) => {
		switch(status) {
			case "Open":
				return intl.formatMessage({
					id: "data.asis.status.open"
				}); 
			case "Open (Draft)":
				return intl.formatMessage({
					id: "data.asis.status.draft"
				}); 
			case "Closed":
				return intl.formatMessage({
					id: "data.asis.status.closed"
				}); 
		}
	};

	const exportToCSV = (list) => {
		createAndDownloadCSVFromObjList(list, 
			{
				columns: {
					dateCreated: { header: intl.formatMessage({ id: 'data.asis.dateCreated'}) },
					dateModified: { header: intl.formatMessage({ id: 'data.asis.dateModified'}) },
					startTime: { header: intl.formatMessage({ id: 'data.asis.startDate'}) },
					clientId: { 
						header: intl.formatMessage({ id: 'data.asis.client'}),
						mapper: props.clientName
					},
					missionNumber: { header: intl.formatMessage({ id: 'data.asis.missionNumber'}) },
					regionId: { 
						header: intl.formatMessage({ id: 'data.asis.region'}), 
						mapper: props.regionName
					},
					fisheriesOfficerName: { header: intl.formatMessage({ id: 'data.asis.fisheriesOfficer'}) },
					altFisheriesOfficerName: { header: intl.formatMessage({ id: 'data.asis.altFisheriesOfficer'}) },
					flightHours: { header: intl.formatMessage({ id: 'data.asis.flightHours'}) },
					areaHours: { header: intl.formatMessage({ id: 'data.asis.patrolHours'}) },
					status: { 
						header: intl.formatMessage({ 
							id: 'data.asis.status'
						}),
						mapper: status => translateStatus(status)
					},	
					active: { header: intl.formatMessage({ id: 'data.asis.active'}) },
					external: { 
						header: intl.formatMessage({ id: 'data.asis.type'}),
						mapper: externalValue => {
							return intl.formatMessage({
								id: externalValue ? 'app.asis.exceptions.grid.value.interal' : 'app.asis.exceptions.grid.value.external'
							})
						}
					},
					userPrincipalName: { header: "User", include: false },
					id: { header: "Exception Identifier", include: false }
				},
				fileName: "exception_list"
			}
		);
	};

	const getTranslatedExceptions = () =>
	{
		return props.exceptions.map(e => ({
			...e, status: translateStatus(e.status)
		}));
	}

	return (
		<div
			id="asis-exception-list-ctn"
			className="asis-exception-list-container"
		>
			<div
				id="asis-exception-list-grid"
				className="asis-exception-list-grid ag-theme-balham-dark"
			>
				<AgGridReact
					ref={grid.exceptions}
					columnDefs={exceptionListcolumns}
					defaultColDef={{
						sortable: true
					}}
					rowData={getTranslatedExceptions()}
					pagination="true"
					paginationPageSize={PAGINATION_SIZE}
					suppressPaginationPanel="true"
					rowSelection="single"
					rowDeselection="true"
					// getRowNodeId={(data) => data.id}
					onRowClicked={(params) => {
						onExceptionSelection(params);
					}}
					overlayNoRowsTemplate={intl.formatMessage({
						id: 'app.asis.exceptions.grid.norows'
					})}
					onPaginationChanged={() => {
						onPaginationChanged();
					}}
				></AgGridReact>
			</div>
			<div
				id="asis-exception-list-bottom-ctn"
				className="asis-exception-list-bottom-container"
			>
				<div
					id="asis-exception-list-pagination-ctn"
					className="asis-exception-list-pagination-container"
				>
					<button
						id={'asis-exception-list-refresh-btn'}
						type="button"
						title={intl.formatMessage({
							id: 'app.common.button.refresh.tooltip'
						})}
						onClick={() => {
							props.onRefresh('reload');
						}}
						className="asis-exception-list-pagination-btn"
					>
						<Refresh
							id="asis-exception-list-refresh-icon"
							className="asis-exception-grid-action-refresh-icon"
							title={intl.formatMessage({
								id: 'app.common.button.refresh.tooltip'
							})}
							onClick={() => {
								props.onRefresh('reload');
							}}
						/>
					</button>
					<button
						id={'asis-exception-list-first-btn'}
						type="button"
						title={intl.formatMessage({
							id: 'app.common.button.first.tooltip'
						})}
						onClick={() => {
							onFirst();
						}}
						className="asis-exception-list-pagination-btn"
					>
						<First
							id="asis-exception-list-first-icon"
							className="asis-exception-grid-action-first-icon"
							title={intl.formatMessage({
								id: 'app.common.button.first.tooltip'
							})}
						/>
					</button>
					<button
						id={'asis-exception-list-previous-btn'}
						type="button"
						title={intl.formatMessage({
							id: 'app.common.button.first.tooltip'
						})}
						onClick={() => {
							onPrevious();
						}}
						className="asis-exception-list-pagination-btn"
					>
						<Previous
							id="asis-exception-list-previous-icon"
							className="asis-exception-grid-action-previous-icon"
							title={intl.formatMessage({
								id: 'app.common.button.previous.tooltip'
							})}
						/>
					</button>
					<input
						id="asis-exception-list-lbCurrentPage"
						className="asis-exception-list-pagination-input"
						value={props.exceptions.length > 0 ? currPage : 0}
						onChange={(event) => {
							setCurrPage(Number(event.target.value.toString().replace(/[^0-9\.]+/g, '')));
						}}
						onKeyPress={(event) => {
							if(event.key === "Enter") {
								grid.exceptions.current.api.paginationGoToPage(currPage-1);
							}
						}}
					></input>
					<span className="asis-exception-list-pagination-indicator">
						of
					</span>
					<span id="asis-exception-list-lbTotalPages"></span>
					<button
						id={'asis-exception-list-next-btn'}
						type="button"
						title={intl.formatMessage({
							id: 'app.common.button.next.tooltip'
						})}
						onClick={() => {
							onNext();
						}}
						className="asis-exception-list-pagination-btn"
					>
						<Next
							id="asis-exception-list-next-icon"
							className="asis-exception-grid-action-next-icon"
							title={intl.formatMessage({
								id: 'app.common.button.next.tooltip'
							})}
						/>
					</button>
					<button
						id={'asis-exception-list-last-btn'}
						type="button"
						title={intl.formatMessage({
							id: 'app.common.button.last.tooltip'
						})}
						onClick={() => {
							onLast();
						}}
						className="asis-exception-list-pagination-btn"
					>
						<Last
							id="asis-exception-list-last-icon"
							className="asis-exception-grid-action-refresh-icon"
							title={intl.formatMessage({
								id: 'app.common.button.last.tooltip'
							})}
						/>
					</button>
				</div>
				<div
					id="asis-exception-list-actions-ctn"
					className="asis-exception-list-actions-container"
				>
					<button
						id={'asis-exception-list-edit-btn'}
						type="button"
						title={intl.formatMessage({
							id: 'app.common.button.edit.tooltip'
						})}
						disabled={!canEditException()}
						onClick={() => {
							props.toggleForm(true, 'open');
						}}
						className="asis-exception-list-edit-btn"
					>
						<div className="asis-exception-list-btn-label">{intl.formatMessage({
							id: 'app.common.button.edit.tooltip'
						})}</div>
					</button>

					<button
						id={'asis-exception-list-create-btn'}
						type="button"
						title={intl.formatMessage({
							id: 'app.asis.exceptions.grid.actions.create'
						})}
						onClick={() => {
							props.toggleForm(false, 'open');
						}}
						className="asis-exception-list-create-btn"
					>
						<div className="asis-exception-list-btn-label">
						{intl.formatMessage({
							id: 'app.asis.exceptions.grid.actions.create'
						})}
						</div>
					</button>
					{loading ? <ProgressSpinner/> : null}
					<button	
						id={'asis-exception-list-export-btn'}
						type="button"
						title={intl.formatMessage({
							id: 'app.asis.exceptions.grid.actions.export'
						})}
						onClick={() => {
							setLoading(true);
							exportToCSV(props.exceptions);
							setLoading(false);
						}}
						className="asis-exception-list-export-btn"
						disabled={props.exceptions.length === 0 ? 'disabled' : ''}
					>
						<div className="asis-exception-list-btn-label">
						{intl.formatMessage({
							id: 'app.asis.exceptions.grid.actions.export'
						})}
						</div>
					</button>
				</div>
			</div>
		</div>
	);
}
