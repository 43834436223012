// # Imports
// External Imports
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

// Internal Imports
import Task from './Task';
import { authHeader } from '../../../_helpers/auth-header';
import { httpInterceptor } from '../../../_helpers/http-interceptor';
import { permissionService } from '../../../_services/permission.service';
import { alertActions } from '../../../_modules/alerts/alert.actions';
import sisConfig from '../../../sis.config.json';

// Assets
import { ReactComponent as Refresh } from '../../../assets/icons/icon_refresh.svg';
import './task-list.scss';

export default function TaskList(props) {
	const [taskData, setTaskData] = useState([]);
	const [selectedRow, setSelectedRow] = useState(null);
	const intl = useIntl();
	const dispatch = useDispatch();

	useEffect(() => {
		setTaskData(props.exception.tasks);
	}, [props.exception.tasks]);

	const newTask = {
		id: null,
		patrolId: '',
		areaId: '',
		areaHours: 0,
		visibilityId: '',
		typeId: '',
		speciesId: '',
		directedActivityId: '',
		directedSpecialAreaId: '',
		directedHours: 0,
		concurrentActivityId: '',
		concurrentSpecialAreaId: '',
		concurrentHours: 0,
		fishingDomId: 0,
		fishingForId: 0,
		fishingDomObs: 0,
		fishingForObs: 0,
		gearCount: 0,
		groupCount: 0
	};

	const insertTask = () => {
		let tempData = [...taskData];
		tempData.push(newTask);
		setTaskData(tempData);
	};

	const deleteTask = () => {
		let tempData = [...taskData];
		tempData.splice(selectedRow, 1);
		setTaskData(tempData);
		props.onTaskChange(tempData);
		setSelectedRow(null);
	};

	const selectTask = (rowNumber) => {
		const tempRowNumber = selectedRow === rowNumber ? null : rowNumber;
		setSelectedRow(tempRowNumber);
	};

	const refresh = () => {
		props.onRefresh('reload');
	};

	const updateTask = (idx, field, data) => {
		let tempTasks = [...taskData];
		tempTasks[idx][field] = data;
		setTaskData(tempTasks);
		props.onTaskChange(tempTasks);
	};

	const validTask = (task) => {
		let valid = true;
		if (task.patrolId === '') {
			valid = false;
		}
		if (task.areaId === '') {
			valid = false;
		}
		if (task.visibilityId === '') {
			valid = false;
		}
		if (task.typeId === '') {
			valid = false;
		}
		if (task.speciesId === '') {
			valid = false;
		}
		if (task.directedActivityId === '') {
			valid = false;
		}
		return valid;
	};

	const comparePatrolDirectedHours = (tasks, patrolHours) => {
		let directedHoursTotal = 0.0;
		if (tasks) {
			tasks.map((x) => {
				directedHoursTotal =
					parseFloat(directedHoursTotal) +
					parseFloat(x.directedHours);
			});
		}
		return patrolHours >= directedHoursTotal;
	};

	const saveTasks = (exception, action) => {
		let hoursError = false;
		let validError = false;
		if (
			comparePatrolDirectedHours(taskData, exception.patrolHours) ===
			false
		) {
			hoursError = true;
		}
		taskData.map((task, key) => {
			if (validTask(task) === false) {
				validError = true;
			}
		});
		if (hoursError !== true && validError !== true) {
			if (action === 'draft') {
				exception.status = 'Open (Draft)';
				exception.tasks = taskData;
				updateException(exception);
			} else {
				exception.tasks = taskData;
				exception.status = 'Closed';
				updateException(exception);
			}
		}

		if (hoursError === true) {
			const invalidAlert = {
				level: 'error',
				messageId: 'app.notification.asis.error.task.hours',
				timeStamp: new Date()
			};

			dispatch(alertActions.addAlert(invalidAlert));
		} else {
			dispatch(alertActions.removeAnyAlertsWith("app.notification.asis.error.task.hours"))
		}
		if (validError === true) {
			const invalidAlert = {
				level: 'error',
				messageId: 'app.notification.asis.error.task.validation',
				timeStamp: new Date()
			};

			dispatch(alertActions.addAlert(invalidAlert));
		} else {
			dispatch(alertActions.removeAnyAlertsWith("app.notification.asis.error.task.validation"))
		}
	};

	let putRequestOptions = {
		method: 'PUT',
		headers: {...authHeader(), 'Content-Type': 'application/json' },
		body: ''
	};

	const updateException = (exception) => {
		putRequestOptions.body = JSON.stringify(exception);
		return fetch(
			`${sisConfig.build[window.location.hostname].api}/asis/exceptions/` +
				encodeURIComponent(exception.id),
			putRequestOptions
		)
			.then(httpInterceptor)
			.then(() => {
				const successAlert = {
					level: 'success',
					messageId:
						exception.status === 'Closed'
							? 'app.notification.asis.success.exception.finalize'
							: 'app.notification.asis.success.exception.draft',
					timeStamp: new Date()
				};

				dispatch(alertActions.addAlert(successAlert));
				refresh();
			});
	};

	const canSaveAsDraft = () => {
		if (props.exception.missionNumber === undefined) {
			return false;
		}
		if (props.exception.status === 'Closed') {
			return false;
		}
		return true;
	};

	const canFinalize = () => {
		if (props.exception.missionNumber === undefined) {
			return false;
		}
		if (
			!permissionService.hasFeature('ASIS_Admin') &&
			props.exception.status === 'Closed'
		) {
			return false;
		}
		return true;
	};

	return (
		<div id="asis-task-list-ctn" className="asis-task-list-container">
			<div
				id="asis-task-list-grid"
				className="asis-task-list-grid ag-theme-balham-dark"
			>
				<div className="asis-task-header-container">
					{intl.formatMessage({
						id: 'app.asis.tasks.grid.header'
					})}
					<span className="asis-task-header-mission-label">
						{props.exception.missionNumber !== undefined
							? props.exception.missionNumber
							: '--'}
					</span>
				</div>
				<div className="asis-task-header-row">
					<div
						id={'asis-task-header-patrol'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-header-idx"
					></div>
					<div
						id={'asis-task-header-patrol'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-header-patrol"
					></div>
					<div
						id={'asis-task-header-patrol'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-header-area"
					>
						{intl.formatMessage({
							id: 'data.asis.area'
						})}
					</div>
					<div
						id={'asis-task-header-patrol'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-header-work-element"
					>
						{intl.formatMessage({
							id: 'data.asis.workElement'
						})}
					</div>
					<div
						id={'asis-task-header-patrol'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-header-directed"
					>
						{intl.formatMessage({
							id: 'data.asis.directed'
						})}
					</div>
					<div
						id={'asis-task-header-patrol'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-header-concurrent"
					>
						{intl.formatMessage({
							id: 'data.asis.concurrent'
						})}
					</div>
					<div
						id={'asis-task-header-patrol'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-header-fishing"
					>
						{intl.formatMessage({
							id: 'data.asis.fishing'
						})}
					</div>
					<div
						id={'asis-task-header-patrol'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-header-gear"
					></div>
					<div
						id={'asis-task-header-patrol'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-header-group"
					></div>
				</div>
				<div className="asis-task-header-row">
					<div
						id={'asis-task-header-patrol'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-idx"
					></div>
					<div
						id={'asis-task-header-patrol'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-patrol"
					>
						<span
							title={intl.formatMessage({
								id: 'app.common.label.required.tooltip'
							})}
						>
							{intl.formatMessage({
								id: 'data.asis.patrol'
							})}
							*
						</span>
					</div>
					<div
						id={'asis-task-header-area'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-area"
					>
						<span
							title={intl.formatMessage({
								id: 'app.common.label.required.tooltip'
							})}
						>
							{intl.formatMessage({
								id: 'data.asis.area'
							})}
							*
						</span>
					</div>
					<div
						id={'asis-task-header-patrolhours'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-patrolhours"
					>
						{intl.formatMessage({
							id: 'data.asis.hours'
						})}
					</div>
					<div
						id={'asis-task-header-visibility'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-visibility"
					>
						<span
							title={intl.formatMessage({
								id: 'app.common.label.required.tooltip'
							})}
						>
							{intl.formatMessage({
								id: 'data.asis.visibility'
							})}
							*
						</span>
					</div>
					<div
						id={'asis-task-header-type'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-type"
					>
						<span
							title={intl.formatMessage({
								id: 'app.common.label.required.tooltip'
							})}
						>
							{intl.formatMessage({
								id: 'data.asis.workElementType'
							})}
							*
						</span>
					</div>
					<div
						id={'asis-task-header-species'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-species"
					>
						<span
							title={intl.formatMessage({
								id: 'app.common.label.required.tooltip'
							})}
						>
							{intl.formatMessage({
								id: 'data.asis.workElementSpecies'
							})}
							*
						</span>
					</div>
					<div
						id={'asis-task-header-directed-activity'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-directed-activity"
					>
						<span
							title={intl.formatMessage({
								id: 'app.common.label.required.tooltip'
							})}
						>
							{intl.formatMessage({
								id: 'data.asis.directedActivity'
							})}
							*
						</span>
					</div>
					<div
						id={'asis-task-header-directed-specialarea'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-directed-specialarea"
					>
						{intl.formatMessage({
							id: 'data.asis.directedSpecialArea'
						})}
					</div>
					<div
						id={'asis-task-header-directed-hours'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-directed-hours"
					>
						{intl.formatMessage({
							id: 'data.asis.hours'
						})}
					</div>
					<div
						id={'asis-task-header-concurrent-activity'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-concurrent-activity"
					>
						{intl.formatMessage({
							id: 'data.asis.concurrentActivity'
						})}
					</div>
					<div
						id={'asis-task-header-concurrent-specialarea'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-concurrent-specialarea"
					>
						{intl.formatMessage({
							id: 'data.asis.concurrentSpecialArea'
						})}
					</div>
					<div
						id={'asis-task-header-concurrent-hours'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-concurrent-hours"
					>
						{intl.formatMessage({
							id: 'data.asis.hours'
						})}
					</div>
					<div
						id={'asis-task-header-fishing-domid'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-fishing-domid"
					>
						{intl.formatMessage({
							id: 'data.asis.fishingDomId'
						})}
					</div>
					<div
						id={'asis-task-header-fishing-forid'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-fishing-forid"
					>
						{intl.formatMessage({
							id: 'data.asis.fishingForId'
						})}
					</div>
					<div
						id={'asis-task-header-fishing-domobs'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-fishing-domobs"
					>
						{intl.formatMessage({
							id: 'data.asis.fishingDomObs'
						})}
					</div>
					<div
						id={'asis-task-header-fishing-forobs'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-fishing-forobs"
					>
						{intl.formatMessage({
							id: 'data.asis.fishingForObs'
						})}
					</div>
					<div
						id={'asis-task-header-gear'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-gear"
					>
						{intl.formatMessage({
							id: 'data.asis.gear'
						})}
					</div>
					<div
						id={'asis-task-header-group'}
						className="asis-task-header-container asis-task-sub-header-container asis-task-item-group"
					>
						{intl.formatMessage({
							id: 'data.asis.group'
						})}
					</div>
				</div>
				<div className="asis-task-rows">
				{taskData.map((task, key) => (
					<Task
						key={key}
						task={task}
						onRowSelection={selectTask}
						isSelected={selectedRow === key ? true : false}
						idx={key}
						lookupData={props.lookupData}
						onTaskChanged={updateTask}
					></Task>
				))}
				</div>
			</div>
			<div
				id="asis-task-list-bottom-ctn"
				className="asis-task-list-bottom-container"
			>
				<div
					id="asis-task-list-grid-controls-ctn"
					className="asis-task-list-grid-controls-container"
				>
					<button
						id={'asis-task-list-grid-controls-refresh-btn'}
						type="button"
						title={intl.formatMessage({
							id: 'app.common.button.refresh.tooltip'
						})}
						disabled={props.exception.missionNumber === undefined}
						onClick={() => {
							refresh();
						}}
						className="asis-task-list-grid-controls-btn"
					>
						<Refresh
							id="asis-task-list-grid-controls-refresh-btn"
							className="asis-task-list-grid-controls-refresh-icon"
							title={intl.formatMessage({
								id: 'app.common.button.refresh.tooltip'
							})}
							onClick={() => {
								refresh();
							}}
						/>
					</button>
					<button
						id={'asis-task-list-grid-controls-insert-btn'}
						type="button"
						title={intl.formatMessage({
							id: 'app.asis.tasks.grid.actions.insert'
						})}
						disabled={props.exception.missionNumber === undefined}
						onClick={() => {
							insertTask();
						}}
						className="asis-task-list-grid-controls-btn"
					>
						{intl.formatMessage({
							id: 'app.asis.tasks.grid.actions.insert'
						})}
					</button>

					<button
						id={'asis-task-list-grid-controls-delete-btn'}
						type="button"
						disabled={selectedRow === null}
						title={intl.formatMessage({
							id: 'app.asis.tasks.grid.actions.delete'
						})}
						onClick={() => {
							deleteTask(selectedRow);
						}}
						className="asis-task-list-grid-controls-btn"
					>
						{intl.formatMessage({
							id: 'app.asis.tasks.grid.actions.delete'
						})}
					</button>
				</div>
				<div
					id="asis-task-list-actions-ctn"
					className="asis-task-list-actions-container"
				>
					<button
						id={'asis-exception-list-draft-btn'}
						type="button"
						title={intl.formatMessage({
							id: 'app.asis.tasks.grid.actions.draft'
						})}
						disabled={!canSaveAsDraft()}
						onClick={() => {
							saveTasks(props.exception, 'draft');
						}}
						className="asis-task-list-draft-btn"
					>
						{intl.formatMessage({
							id: 'app.asis.tasks.grid.actions.draft'
						})}
					</button>

					<button
						id={'asis-task-list-finalize-btn'}
						type="button"
						title={intl.formatMessage({
							id: 'app.asis.tasks.grid.actions.finalize'
						})}
						disabled={!canFinalize()}
						onClick={() => {
							saveTasks(props.exception, 'finalize');
						}}
						className="asis-task-list-finalize-btn"
					>
						{intl.formatMessage({
							id: 'app.asis.tasks.grid.actions.finalize'
						})}
					</button>
				</div>
			</div>
		</div>
	);
}
