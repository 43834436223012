import { alertConstants } from './alert.constants';

const initialState = { alerts: [] };

export function alert(state = initialState, action) {
	switch (action.type) {
		case alertConstants.ADD_ALERT:
			let tempAlerts = [...state.alerts];
			if(tempAlerts.length >= 5) 
				tempAlerts.pop();
			tempAlerts.push(action.alert);
			return {
				alerts: tempAlerts
			};
		case alertConstants.REMOVE_ALERT:
			let tempRemoveAlerts = [...state.alerts];
			const i = tempRemoveAlerts.findIndex((x) => {
				return x.timeStamp === action.timeStamp;
			});

			if (i !== -1) {
				tempRemoveAlerts.splice(i, 1);
			}

			return {
				alerts: [...tempRemoveAlerts]
			};
		case alertConstants.REMOVE_ANY_ALERTS_WITH:
			return {
				alerts: state.alerts.filter(alert => alert.messageId !== action.messageId)
			};
		default:
			return state;
	}
}
