export const historicalConstants = {
	HISTORICAL_SEARCH_REQUEST: 'HISTORICAL_SEARCH',
	HISTORICAL_SEARCH_SUCCESS: 'HISTORICAL_SEARCH_SUCCESS',
	HISTORICAL_SEARCH_FAILURE: 'HISTORICAL_SEARCH_FAILURE',
	HISTORICAL_MISSION_SELECT: 'HISTORICAL_MISSION_SELECT',
	HISTORICAL_NAV_REQUEST: 'HISTORICAL_NAV_GET',
	HISTORICAL_NAV_SUCCESS: 'HISTORICAL_NAV_GET_SUCCESS',
	HISTORICAL_NAV_FAILURE: 'HISTORICAL_NAV_GET_FAILURE',
	HISTORICAL_AIS_REQUEST: 'HISTORICAL_AIS_GET',
	HISTORICAL_AIS_SUCCESS: 'HISTORICAL_AIS_GET_SUCCESS',
	HISTORICAL_AIS_FAILURE: 'HISTORICAL_AIS_GET_FAILURE',
	HISTORICAL_RADAR_REQUEST: 'HISTORICAL_RADAR_GET',
	HISTORICAL_RADAR_SUCCESS: 'HISTORICAL_RADAR_GET_SUCCESS',
	HISTORICAL_RADAR_FAILURE: 'HISTORICAL_RADAR_GET_FAILURE',
	HISTORICAL_MANUAL_REQUEST: 'HISTORICAL_MANUAL_GET',
	HISTORICAL_MANUAL_SUCCESS: 'HISTORICAL_MANUAL_GET_SUCCESS',
	HISTORICAL_MANUAL_FAILURE: 'HISTORICAL_MANUAL_GET_FAILURE',
	HISTORICAL_CREW_REQUEST: 'HISTORICAL_CREW_GET',
	HISTORICAL_CREW_SUCCESS: 'HISTORICAL_CREW_GET_SUCCESS',
	HISTORICAL_CREW_FAILURE: 'HISTORICAL_CREW_GET_FAILURE',
	HISTORICAL_AIRCRAFT_REQUEST: 'HISTORICAL_AIRCRAFT_GET',
	HISTORICAL_AIRCRAFT_SUCCESS: 'HISTORICAL_AIRCRAFT_GET_SUCCESS',
	HISTORICAL_AIRCRAFT_FAILURE: 'HISTORICAL_AIRCRAFT_GET_FAILURE',
	HISTORICAL_PROFILE_REQUEST: 'HISTORICAL_PROFILE_GET',
	HISTORICAL_PROFILE_SUCCESS: 'HISTORICAL_PROFILE_GET_SUCCESS',
	HISTORICAL_PROFILE_FAILURE: 'HISTORICAL_PROFILE_GET_FAILURE',
	HISTORICAL_SELECT_TRACK: 'HISTORICAL_SELECT_TRACK',
	HISTORICAL_SELECT_TRACK_FAILURE: 'HISTORICAL_SELECT_TRACK_FAILURE',
	HISTORICAL_DESELECT_TRACK: 'HISTORICAL_DESELECT_TRACK'
};
