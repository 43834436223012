export const radarColumns = [
	{
		field: 'id',
		label: 'ID'
	},
	{
		field: 'missionNumber',
		label: 'app.map.grid.column.mission'
	},
	{
		field: 'cid',
		label: 'app.map.grid.column.cid'
	},
	{
		field: 'createTime',
		label: 'app.map.grid.column.createtime',
		style: 'key-val'
	},
	{
		field: 'latitude',
		label: 'app.map.grid.column.latitude'
	},
	{
		field: 'longitude',
		label: 'app.map.grid.column.longitude'
	},
	{
		field: 'vesselName',
		label: 'app.map.grid.column.vesselname'
	},
	{
		field: 'vesselActivity',
		label: 'app.map.grid.column.vesselactivity'
	},
	{
		field: 'vesselCallsign',
		label: 'app.map.grid.column.callsign'
	},
	{
		field: 'registrationNumber',
		label: 'app.map.grid.column.registrationnumber'
	},
	{
		field: 'vesselCountry',
		label: 'app.map.grid.column.nationality'
	},
	{
		field: 'hostility',
		label: 'app.map.grid.column.hostility'
	},
	{
		field: 'surfaceType',
		label: 'app.map.grid.column.surface'
	},
	{
		field: 'vesselType',
		label: 'app.map.grid.column.vesseltype'
	},
	{
		field: 'mMSI',
		label: 'app.map.grid.column.mmsi'
	},
	{
		field: 'range',
		label: 'app.map.grid.column.range'
	},
	{
		field: 'bearing',
		label: 'app.map.grid.column.bearing'
	},
	{
		field: 'speed',
		label: 'app.map.grid.column.speed'
	},
	{
		field: 'course',
		label: 'app.map.grid.column.course'
	},
	{
		field: 'updateTime',
		label: 'app.map.grid.column.updatetime'
	},
	{
		field: 'comments',
		label: 'app.map.grid.column.comments'
	},
	{
		field: 'aircraftLatitude',
		label: 'AircraftLatitude'
	},
	{
		field: 'aircraftLongitude',
		label: 'AircraftLongitude'
	},
	{
		field: 'mapSymbolAttributes',
		label: 'MapSymbolAttributes'
	},
	{
		field: 'geom',
		label: 'Geometry'
	},
	{
		field: 'geomTime',
		label: 'GeomTime'
	},
	{
		field: 'positions',
		label: 'Positions'
	},
	{
		field: 'trackIdentification',
		label: ''
	},
	{
		field: 'trackSpecies',
		label: ''
	},
	{
		field: 'trackComments',
		label: ''
	}
];

export default { radarColumns };
