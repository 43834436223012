// # Imports
// External Imports
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';

// Internal Imports
import { authHeader } from '../../../_helpers/auth-header';
import { httpInterceptor } from '../../../_helpers/http-interceptor';
import { permissionService } from '../../../_services/permission.service';
import { alertActions } from '../../../_modules/alerts/alert.actions';
import sisConfig from '../../../sis.config.json';

// Assets
import { ReactComponent as Close } from '../../../assets/icons/close-dialog.svg';
import './exception-form.scss';

export default function ExceptionForm(props) {
	useEffect(() => {
		if (props.editMode && props.selectedException) {
			setFormException({ ...props.selectedException });
		}
	}, []);

	const maxPatrolFlightHours = 9999.99;
	const dispatch = useDispatch();
	const tempDate = new Date();
	let user = JSON.parse(sessionStorage.getItem('sisUser'));

	const blankException = {
		missionNumber: '',
		startTime: tempDate,
		regionId: '',
		clientId: '',
		external: true,
		fisheriesOfficerName: user.displayName || 'Default',
		status: "Open",
		flightHours: 0,
		patrolHours: 0,
		altFisheriesOfficerName: '',
		tasks: []
	};

	const parseTime = (date) => {
		const tempDate = new Date(date);
		return tempDate;
	};

	const validException = (exception) => {
		/* 
		NOT GOOD, react-time-picker does not call onChange when 
		values change to invalid values so need to do a dom walk to get 
		the content of the input elements to check if they are invalid
		*/
		const startTimeParent = document.getElementById("asis-exception-form-row-mission-time");
		const inputGroup = startTimeParent.children[1].children[0].children[0];
		if(
			Number(inputGroup.children[1].value) > 23 || 
			Number(inputGroup.children[1].value) > 59
		) {
			return false;
		}
		if (
			exception.missionNumber === '' ||
			exception.missionNumber === undefined ||
			exception.missionNumber === null
		) {
			return false;
		}
		if (
			exception.startTime === '' ||
			exception.startTime === undefined ||
			exception.startTime === null
		) {
			return false;
		}
		if (
			exception.regionId === '' ||
			exception.regionId === undefined ||
			exception.regionId === null
		) {
			return false;
		}
		if (
			exception.clientId === '' ||
			exception.clientId === undefined ||
			exception.clientId === null
		) {
			return false;
		}
		if (
			exception.flightHours === '' ||
			exception.flightHours === undefined ||
			exception.flightHours === null
		) {
			return false;
		}
		if (
			exception.patrolHours === '' ||
			exception.patrolHours === undefined ||
			exception.patrolHours === null
		) {
			return false;
		}
		return true;
	};

	const [formException, setFormException] = useState(blankException);
	const intl = useIntl();

	const locale = localStorage.getItem('sisLang') || 'en';
	registerLocale('fr', fr);

	const handleStartChange = (date) => {
		setFormException({ ...formException, startTime: date });
	};

	const handleTimeChange = (time) => {
		if (time !== null) {
			const timeArr = time.split(':');
			let d = new Date(formException.startTime);
			d.setHours(timeArr[0], timeArr[1]);
			setFormException({ ...formException, startTime: d });
		}
	};

	const postRequestOptions = {
		method: 'POST',
		headers: {...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(formException)
	};

	const putRequestOptions = {
		method: 'PUT',
		headers: {...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(formException)
	};

	const deleteRequestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	const createException = (openTasks) => {
		return fetch(
			`${sisConfig.build[window.location.hostname].api}/asis/exceptions`,
			postRequestOptions
		)
			.then(httpInterceptor)
			.then(() => {
				const successAlert = {
					level: 'success',
					messageId: 'app.notification.asis.success.exception.create',
					timeStamp: new Date()
				};
				dispatch(alertActions.addAlert(successAlert));
				if (openTasks) {
					props.onCancel(false, 'task', formException);
				} else {
					props.onCancel(false, 'reload', formException);
				}
			});
	};

	const updateException = (openTasks) => {
		return fetch(
			`${sisConfig.build[window.location.hostname].api}/asis/exceptions/` +
				encodeURIComponent(formException.id),
			putRequestOptions
		)
			.then(httpInterceptor)
			.then(() => {
				const successAlert = {
					level: 'success',
					messageId: 'app.notification.asis.success.exception.update',
					timeStamp: new Date()
				};
				dispatch(alertActions.addAlert(successAlert));
				if (openTasks) {
					props.onCancel(false, 'task', formException);
				} else {
					props.onCancel(false, 'reload', formException);
				}	
			});
	};

	const deleteException = () => {
		return fetch(
			`${sisConfig.build[window.location.hostname].api}/asis/exceptions/` +
				encodeURIComponent(formException.id),
			deleteRequestOptions
		)
			.then(httpInterceptor)
			.then(() => {
				const successAlert = {
					level: 'success',
					messageId: 'app.notification.asis.success.exception.delete',
					timeStamp: new Date()
				};

				dispatch(alertActions.addAlert(successAlert));
				props.onCancel(false, 'reload');
			});
	};

	const clientsForRegion = (regionId) => {
		let clients = []
		let clientIds = [];
		props.lookupData.regions.forEach(region => {
			if(region.id === regionId)
				clientIds.push(region.clientId);
		});
		clients = props.lookupData.clients.filter(client => clientIds.includes(client.id));
		return clients;
	};

	const clientOptions = () => {
		return clientsForRegion(formException.regionId).map(function (client, key) {
			return (
				<option
					key={'asis-exception-form-client-option-' + key}
					id={'asis-exception-form-client-option-' + key}
					value={client.id}
				>
					{client.value}
				</option>
			);
		});
	};

	const regionOptions = () => {
		return props.lookupData.regions.map(function (species, key) {
			return (
				<option
					key={'asis-exception-form-region-option-' + key}
					id={'asis-exception-form-region-option-' + key}
					value={species.id}
				>
					{species.value}
				</option>
			);
		});
	};

	const canDelete = () => {
		if (
			permissionService.hasFeature('ASIS_Admin') &&
			props.editMode === true
		) {
			return true;
		}
		return false;
	};

	return (
		<div className="asis-exception-form-area-container">
			<div className="asis-exception-form-inner-container">
				<div className="asis-exception-form-inner-header">
					<div className="asis-exception-form-inner-header-label">
						{intl.formatMessage({
							id:
								props.editMode === true
									? 'app.asis.exception.form.header.edit.label'
									: 'app.asis.exception.form.header.insert.label'
						})}
					</div>
					<button
						className="asis-exception-form-inner-header-btn"
						title={intl.formatMessage({
							id: 'app.common.button.close.tooltip'
						})}
						onClick={() => {
							props.onCancel(false, 'close');
						}}
					>
						<Close
							id="asis-exception-form-header-close-btn"
							className="asis-exception-header-close-icon"
							title={intl.formatMessage({
								id: 'app.common.button.close.tooltip'
							})}
							onClick={() => {
								props.onCancel(false, 'close');
							}}
						/>
					</button>
				</div>
				<div className="asis-exception-form-inner-content">
					<ul
						id="asis-exception-form-ctn"
						className="asis-exception-form-container"
					>
						<li
							id="asis-exception-form-row-fishery-officer"
							className="asis-exception-form-row"
						>
							<span
								id="asis-exception-form-field-fishery-officer"
								className="asis-exception-form-field"
							>
								{intl.formatMessage({
									id: 'app.asis.exception.form.owner.label'
								})}
								:
							</span>
							{formException.fisheriesOfficerName}
						</li>

						<li
							id="asis-exception-form-row-mission-number"
							className="asis-exception-form-row"
						>
							<span
								id="asis-exception-form-field-mission-number"
								className="asis-exception-form-field"
							>
								{intl.formatMessage({
									id: 'data.asis.missionNumber'
								})}
								* :
							</span>
							<input
								id="asis-exception-form-input-mission-number"
								value={formException.missionNumber}
								onChange={(event) => {
									setFormException({
										...formException,
										missionNumber: event.target.value
									});
								}}
							></input>
						</li>
						<li
							id="asis-exception-form-row-mission-date"
							className="asis-exception-form-row asis-exception-form-date-picker"
						>
							<span
								id="asis-exception-form-field-mission-date"
								className="asis-exception-form-field"
							>
								{intl.formatMessage({
									id: 'data.asis.missionDate'
								})}
								* :
							</span>
							<DatePicker
								name="startTime"
								selected={parseTime(formException.startTime)}
								onChange={(event) => {
									handleStartChange(event);
								}}
								maxDate={new Date()}
								strictParsing
								locale={locale}
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
							/>
						</li>
						<li
							id="asis-exception-form-row-mission-time"
							className="asis-exception-form-row asis-exception-form-time-picker"
						>
							<span
								id="asis-exception-form-field-mission-time"
								className="asis-exception-form-field"
							>
								{intl.formatMessage({
									id: 'data.asis.startDate'
								})}{' '}
								(UTC)* :
							</span>
							<TimePicker
								onChange={(event) => {
									handleTimeChange(event);
								}}
								format="HH:mm"
								clearIcon={null}
								disableClock={true}
								value={parseTime(formException.startTime)}
							/>
						</li>
						<li
							id="asis-exception-form-row-region"
							className="asis-exception-form-row"
						>
							<span
								id="asis-exception-form-field-region"
								className="asis-exception-form-field"
							>
								{intl.formatMessage({
									id: 'data.asis.region'
								})}
								* :
							</span>
							<select
								value={formException.regionId}
								id="asis-exception-form-select-region"
								onChange={(event) => {
									setFormException({
										...formException,
										regionId: event.target.value
									});
								}}
							>
								<option value=""></option>
								{regionOptions()}
							</select>
						</li>
						{formException.regionId !== '' && (<li
							id="asis-exception-form-row-client"
							className="asis-exception-form-row"
						>
							<span
								id="asis-exception-form-field-client"
								className="asis-exception-form-field"
							>
								{intl.formatMessage({
									id: 'data.asis.client'
								})}
								* :
							</span>
							<select
								value={formException.clientId}
								id="asis-exception-form-select-client"
								onChange={(event) => {
									setFormException({
										...formException,
										clientId: event.target.value
									});
								}}
							>
								<option value=""></option>
								{clientOptions(props.lookupData.clients)}
							</select>
						</li>)}
						<li
							id="asis-exception-form-row-flight-hours"
							className="asis-exception-form-row"
						>
							<span
								id="asis-exception-form-field-flight-hours"
								className="asis-exception-form-field"
							>
								{intl.formatMessage({
									id: 'data.asis.flightHours'
								})}
								* :
							</span>
							<input
								id="asis-exception-form-input-flight-hours"
								value={Number(formException.flightHours).toString()}
								type="number"
								min={0}
								max={maxPatrolFlightHours}
								onChange={(event) => {
									const newValue = Number(event.target.value);
									if(newValue < formException.patrolHours) 
										formException.patrolHours = newValue;
									setFormException({
										...formException,
										flightHours: newValue < maxPatrolFlightHours ? newValue : maxPatrolFlightHours
									});
								}}
							></input>
						</li>
						<li
							id="asis-exception-form-row-patrol-hours"
							className="asis-exception-form-row"
						>
							<span
								id="asis-exception-form-field-patrol-hours"
								className="asis-exception-form-field"
							>
								{intl.formatMessage({
									id: 'data.asis.patrolHours'
								})}
								* :
							</span>
							<input
								id="asis-exception-form-input-patrol-hours"
								value={Number(formException.patrolHours).toString()}
								type="number"
								min={0}
								max={Math.min(formException.flightHours, maxPatrolFlightHours)}
								onChange={(event) => {
									const newValue = Number(event.target.value);
									setFormException({
										...formException,
										patrolHours: newValue > formException.flightHours ? formException.flightHours : newValue

									});
								}}
							></input>
						</li>
						<li
							id="asis-exception-form-row-alt-fishery-officer"
							className="asis-exception-form-row"
						>
							<span
								id="asis-exception-form-field-alt-fishery-officer"
								className="asis-exception-form-field"
							>
								{intl.formatMessage({
									id: 'data.asis.altFisheriesOfficer'
								})}
								:
							</span>
							<input
								id="asis-exception-form-input-alt-fishery-officer"
								value={formException.altFisheriesOfficerName}
								onChange={(event) => {
									setFormException({
										...formException,
										altFisheriesOfficerName:
											event.target.value
									});
								}}
							></input>
						</li>
					</ul>
				</div>
				<div className="asis-exception-form-actions">
					{props.editMode === false && (
						<button
							id={'asis-exception-form-action-saveopen-btn'}
							type="button"
							title={intl.formatMessage({
								id:
									'app.asis.exception.form.actions.saveopen.button'
							})}
							onClick={() => {
								if (validException(formException)) {
									if (props.editMode) {
										updateException(true);
									} else {
										createException(true);
									}
								} else {
									const invalidAlert = {
										level: 'error',
										messageId:
											'app.notification.asis.error.exception.validation',
										timeStamp: new Date()
									};

									dispatch(
										alertActions.addAlert(invalidAlert)
									);
								}
							}}
							className="asis-exception-form-action-btn"
						>
							{intl.formatMessage({
								id:
									'app.asis.exception.form.actions.saveopen.button'
							})}
						</button>
					)}

					<button
						id={'asis-exception-form-action-save-btn'}
						type="button"
						title={intl.formatMessage({
							id: 'app.common.button.save.tooltip'
						})}
						onClick={() => {
							if (validException(formException)) {
								if (props.editMode) {
									updateException(false);
								} else {
									createException(false);
								}
							} else {
								const invalidAlert = {
									level: 'error',
									messageId:
										'app.notification.asis.error.exception.validation',
									timeStamp: new Date()
								};

								dispatch(alertActions.addAlert(invalidAlert));
							}
						}}
						className="asis-exception-form-action-btn"
					>
						{intl.formatMessage({
							id: 'app.common.button.save.tooltip'
						})}
					</button>

					<button
						id={'asis-exception-form-action-cancel-btn'}
						type="button"
						title={intl.formatMessage({
							id: 'app.common.button.cancel.tooltip'
						})}
						onClick={() => {
							props.onCancel(false, 'close');
						}}
						className="asis-exception-form-action-btn"
					>
						{intl.formatMessage({
							id: 'app.common.button.cancel.tooltip'
						})}
					</button>
					{canDelete() === true && (
						<button
							id={'asis-exception-form-action-delete-btn'}
							type="button"
							title={intl.formatMessage({
								id: 'app.common.button.delete.tooltip'
							})}
							onClick={() => {
								const confirmAlert = {
									level: 'warning',
									messageId:
										'app.notification.asis.warning.exception.delete',
									timeStamp: new Date(),
									action: deleteException
								};

								dispatch(alertActions.addAlert(confirmAlert));
							}}
							className="asis-exception-form-action-btn"
						>
							{intl.formatMessage({
								id: 'app.common.button.delete.tooltip'
							})}
						</button>
					)}
				</div>
			</div>
		</div>
	);
}
