import { profileConstants } from './profile.constants';

export const initialState =
	sessionStorage.getItem('state:profile') !== undefined &&
	sessionStorage.getItem('state:profile') !== null
		? JSON.parse(sessionStorage.getItem('state:profile'))
		: {
				userName: '',
				overlays: [],
				baseMapUrl:
					'https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=odrA8jXK5gV63WS5pSvV',
				isFetching: false
		  };

export function profile(state = initialState, action) {
	switch (action.type) {
		//Profile Get cases
		case profileConstants.GET_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case profileConstants.GET_SUCCESS:
			const newState = {
				...state,
				userName: action.profile.userName,
				overlays: action.profile.overlays,
				baseMapUrl: action.profile.baseMapUrl,
				isFetching: false
			};

			sessionStorage.setItem('state:profile', JSON.stringify(newState));
			return newState;
		case profileConstants.GET_FAILURE:
			return {
				...state,
				isFetching: false
			};
		//Profile Insert cases
		case profileConstants.INSERT_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case profileConstants.INSERT_SUCCESS:
			return {
				...state,
				userName: action.profile.userName,
				overlays: action.profile.overlays,
				baseMapUrl: action.profile.baseMapUrl,
				isFetching: false
			};
		case profileConstants.INSERT_FAILURE:
			return {};
		//Profile Update cases
		case profileConstants.UPDATE_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case profileConstants.UPDATE_SUCCESS:
			return {
				...state,
				userName: action.profile.userName,
				overlays: action.profile.overlays,
				baseMapUrl: action.profile.baseMapUrl,
				isFetching: false
			};
		case profileConstants.UPDATE_FAILURE:
			return {};
		//Profile Delete cases
		case profileConstants.DELETE_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case profileConstants.DELETE_SUCCESS:
			return {
				...state,
				userName: '',
				overlays: [],
				baseMapUrl:
					'https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=odrA8jXK5gV63WS5pSvV',
				isFetching: false
			};
		case profileConstants.DELETE_FAILURE:
			return state;
		default:
			return state;
	}
}
