import { authHeader } from '../_helpers/auth-header';
import sis3 from './sis3.service.js';
import sisConfig from '../sis.config.json';

export const windowService = {
    openReportWindow,
    reloadReportWindow,
    closeChildWindows
};

function openReportWindow(missionId, missionName) {
    sis3.logout()
        .then((res) => {
            return sis3.login();
        })
        .then((res) => {
            return sis3.changeLocalization(localStorage.getItem('sisLang'));
        })
        .then((res) => {
            openSis3MissionReportWindow(missionId, missionName);
        });
}

function openSis3MissionReportWindow(missionId, missionName) {
    document.domain = sisConfig.build[window.location.hostname].window_domain;
    window.reportRequest = {
        missionId,
        missionName,
        auth: authHeader().Authorization || ''
    };

    var width = 380;
    var height = 658;
    var left = (window.screen.width - width)/2;
    var top = (window.screen.height - height)/2;
    var params = 'width='+width+', height='+height;
    params += ', top='+top+', left='+left;
    params += ', location=no';
    params += ', menubar=yes';
    params += ', resizable=no';
    params += ', status=yes';
    params += ', toolbar=yes';
    params += ', animationatStart=true';
    params += ', transparentatStart=false';
    
    window.reportWindow = window.open(sisConfig.build[window.location.hostname].reporting, process.env.REACT_APP_REPORT_WINDOW_NAME, params);

    if (window.reportWindow) {
        sessionStorage.setItem('reportRequest', JSON.stringify(window.reportRequest));
        window.addEventListener('message', (event) => {
            setTimeout(() => {
                if (event.data.childWindowEvent === 'beforeunload' && ((window.reportWindow || {}).closed)) {
                    sessionStorage.removeItem('reportRequest');
                }
            },
            2000);
        });
    }
}

function reloadReportWindow() {
    const reportRequest = JSON.parse(sessionStorage.getItem('reportRequest'));
    if (reportRequest) {
        openReportWindow(reportRequest.missionId, reportRequest.missionName);
    }
}

function closeChildWindows() {
    if (window.reportWindow) {
		window.reportWindow.close();
    }
}
