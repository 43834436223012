const hasFeature = (feature) => {
	const userAuthorizations =
		JSON.parse(sessionStorage.getItem('sisUser')) || {};
	return (userAuthorizations.userFeatures || []).includes(feature);
};

const permissionService = {
	hasFeature: hasFeature,
	getInitialMapMode: () => {
		const mode = sessionStorage.getItem('sisMode') || '';
		return mode !== ''
			? mode
			: hasFeature('RealTime')
			? 'real-time'
			: hasFeature('Historical')
			? 'historical'
			: 'overlays';
	},
};

export { permissionService };
