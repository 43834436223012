// # Imports

import React from 'react';
import sis3 from '../../_services/sis3.service.js';
import sisConfig from '../../sis.config.json';

import './reporting-container.scss';

export default ({}) => {
	const user = JSON.parse(sessionStorage.getItem('sisUser'));
	const token = user.token;

	React.useEffect(() => {
		const iframe = document.getElementById('c4-reporting');

		sis3.logout()
			.then((res) => {
				return sis3.login();
			})
			.then((res) => {
				return sis3.changeLocalization(localStorage.getItem('sisLang'));
			})
			.then((res) => {
				return fetch(sisConfig.build[window.location.hostname].sis3_reporting, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
			})
			.then((response) => {
				console.log(response.headers);
				return response.text();
			})
			.then((response) => {
				console.log(response);
				iframe.srcdoc = response;
			});

		function handler() {
			if (this.readyState === this.DONE) {
				if (this.status === 200) {
					console.log(this);
				} else {
					console.error('error');
				}
			}
		}
	});
	return <iframe id="c4-reporting" title="Reporting"></iframe>;
};
