import React, {useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as Close } from '../../assets/icons/close-dialog.svg';
import './draggable-modal-container.scss';


export default function DraggableModalContainer({ component: Component, ...rest }) {
    const [moving, setMoving] = useState(false);
    const [transform, setTransform] = useState(
      sessionStorage.getItem("c4-draggable-modal-container-transform") 
      ? JSON.parse(sessionStorage.getItem("c4-draggable-modal-container-transform"))
      : {
        x: (window.innerWidth*5/7)+"px",
        y: "12px"
      });
    const [closing, setClosing] = useState(false);
    const intl = useIntl();

    useEffect(() => {
      let thisElem = document.getElementById('modal-draggable-container');
      dragElement(thisElem);
      thisElem.style.top = transform.y;
      thisElem.style.left = transform.x;
      thisElem.style.visibility = "visible";
    }, []);

    useEffect(() => {
      sessionStorage.setItem("c4-draggable-modal-container-transform", JSON.stringify(transform));
      setMoving(true);
    }, [transform]);
    
    function dragElement(elmnt) {
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      elmnt.onmousedown = dragMouseDown;
    
      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
      }
    
      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        setTransform({
          y: elmnt.style.top,
          x: elmnt.style.left
        })
      }
    
      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }

    return (<div
                id="modal-draggable-container"
                className="modal-draggable-ctn"
            >
        <div
            id="modal-draggable-container-header"
            className="modal-draggable-ctn-header"
        >
            <div
              id="modal-draggable-container-title"
              className="modal-draggable-ctn-title"
            >
            {rest.title}
            </div>
            <div
              id="modal-draggable-container-actions"
              className="modal-draggable-ctn-actions"
            >
              <Close
                id="modal-draggable-container-close-btn"
                className="modal-draggable-ctn-close-btn"
                title={intl.formatMessage({
                  id: 'app.common.button.close.tooltip'
                })}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setClosing(true);
                  rest.onClose();
                }}
              />
             </div>
        </div>
        <div
            id="modal-draggable-container-content"
            className="modal-draggable-ctn-content"
        >
            <Component {...rest} moving={moving} closing={closing}/>
        </div>
    </div>);
};