import L from 'leaflet';
import * as R from 'ramda';
import 'leaflet-timedimension';

L.TimeDimension.Layer.realtimeTrack = L.TimeDimension.Layer.GeoJson.extend({
	setLatLng: function (latlng) {
		this.latlng = latlng;

		this._latlng = latlng;
	},
	getLatLng: function () {
		const layers = this._baseLayer.getLayers();
		if (layers.length === 1) {
			if (layers[0].feature.geometry.type === 'LineString') {
				const latLng = R.last(layers[0].getLatLngs());
				this._latlng = latLng;

				layers[0]._latlng = latLng;
				return latLng;
			}

			return layers[0].getLatLngs();
		}
		return null;
	},

	_update: function () {
		if (!this._map) return;
		if (!this._loaded) {
			return;
		}

		var maxTime = this._timeDimension.getCurrentTime(),
			minTime = 0;
		if (this._duration) {
			var date = new Date(maxTime);
			L.TimeDimension.Util.subtractTimeDuration(
				date,
				this._duration,
				true
			);
			minTime = date.getTime();
		}

		var layer = L.geoJson(null, this._baseLayer.options);
		var layers = this._baseLayer.getLayers();
		for (var i = 0, l = layers.length; i < l; i++) {
			var feature = this._getFeatureBetweenDates(
				layers[i].feature,
				minTime,
				maxTime
			);
			if (feature) {
				layer.addData(feature);
				if (
					this._addlastPoint &&
					feature.geometry.type == 'LineString'
				) {
					if (feature.geometry.coordinates.length > 0) {
						var properties = feature.properties;
						properties.last = true;
						let marker = layer.addData({
							type: 'Feature',
							properties: properties,
							geometry: {
								type: 'Point',
								coordinates:
									feature.geometry.coordinates[
										feature.geometry.coordinates.length - 1
									]
							}
						});
					}
				}
			}
		}

		if (this._currentLayer) {
			this._map.removeLayer(this._currentLayer);
		}
		if (layer.getLayers().length) {
			layer.addTo(this._map);
			this._currentLayer = layer;
		}
	}
});

const realtimeTrack = function (layer, options) {
	return new L.TimeDimension.Layer.realtimeTrack(layer, options);
};

L.timeDimension.layer.realtimeTrack = realtimeTrack;

export { realtimeTrack };
