// # Imports
// External Imports

import React, { useRef, useEffect } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { useIntl } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';

// Internal Imports
import { gridColumns } from '../../_helpers/data-columns';
import { historicalActions } from '../../_modules/historical/historical.actions';
import { realtimeActions } from '../../_modules/realtime/realtime.actions';
import { navColumns } from '../../_schema/nav.schema';
import { aisColumns } from '../../_schema/ais.schema';
import { radarColumns } from '../../_schema/radar.schema';
import { manualColumns } from '../../_schema/manual.schema';
import sisConfig from '../../sis.config.json';

// Assets
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'react-tabs/style/react-tabs.css';
import '../../styles/ag-theme-balham-dark.css';
import './track-grid.scss';

export default function TrackGrid(props) {
	const intl = useIntl();
	const grid = {
		nav: useRef(),
		ais: useRef(),
		radar: useRef(),
		manual: useRef()
	};
	const modeConfig = sisConfig.sections.find((x) => x.id === props.mode);

	const dispatch = useDispatch();
	const [gridReady, setGridReady] = React.useState(false);
	const [prevMarker, setPrevMarker] = React.useState('0000');
	const [selectedTab, setSelectedTab] = React.useState(R.keys(grid)[0]);

	const convertTab = {
		0: 'nav',
		1: 'ais',
		2: 'radar',
		3: 'manual'
	};

	const missions = props.missions;
	const selectedTracks = props.selectedTracks;

	useEffect(() => {
		setSelectedTab(
			R.propOr(selectedTab, 'collectionId', selectedTracks[0])
		);
	}, [props.selectedTracks]);

	useEffect(() => {
		if (gridReady) {
			const firstTrack = R.propOr(
				{
					id: ''
				},

				0,
				selectedTracks
			);

			const api =
				grid[R.propOr(selectedTab, 'collectionId', selectedTracks[0])]
					.current;

			if (api !== null && api !== undefined) {
				api.forEachNode((node) => {
					if (node.data && node.data.id === firstTrack.id) {
						node.setSelected(true, true);
						setPrevMarker(firstTrack);
						api.ensureNodeVisible(node, 'top');
					} else {
						node.setSelected(false);
					}
				});
			}
		}
	}, [props.selectedTracks, props.missions]);

	function populateGridData() {
		let tempGridData = {
			nav: {
				data: [],
				fields: []
			},
			ais: {
				data: [],
				fields: []
			},
			radar: {
				data: [],
				fields: []
			},
			manual: {
				data: [],
				fields: []
			}
		};

		if (tempGridData.nav.fields.length === 0) {
			tempGridData.nav.fields = gridColumns(
				navColumns,
				modeConfig.trackGrid['nav']
			);
		}

		if (tempGridData.ais.fields.length === 0) {
			tempGridData.ais.fields = gridColumns(
				aisColumns,
				modeConfig.trackGrid['ais']
			);
		}

		if (tempGridData.radar.fields.length === 0) {
			tempGridData.radar.fields = gridColumns(
				radarColumns,
				modeConfig.trackGrid['radar']
			);
		}

		if (tempGridData.manual.fields.length === 0) {
			tempGridData.manual.fields = gridColumns(
				manualColumns,
				modeConfig.trackGrid['manual']
			);
		}

		for (var i = 0; i < missions.length; i++) {
			tempGridData.nav.data = tempGridData.nav.data.concat(
				missions[i].nav.data
			);
			tempGridData.ais.data = tempGridData.ais.data.concat(
				missions[i].ais.data
			);
			tempGridData.radar.data = tempGridData.radar.data.concat(
				missions[i].radar.data
			);
			tempGridData.manual.data = tempGridData.manual.data.concat(
				missions[i].manual.data
			);
		}
		return tempGridData;
	}

	let gridData = populateGridData();

	// Translate the provided column list
	const translatedColumns = (columns) => {
		let translated = [];
		for (var i = 0; i < columns.length; i++) {
			let column = {
				...columns[i],
				headerName: intl.formatMessage({
					id: columns[i].headerName
				})
			};
			translated.push(column);
		}
		return translated;
	};

	const trackSelection = (params) => {
		let selectedRows = params.api.getSelectedRows();
		if (props.mode === 'historical') {
			
			if (props.selectedTracks.filter(track => track.id === selectedRows[0].id).length > 0) {
				dispatch(historicalActions.deSelectTracks(selectedRows));
			}
			dispatch(historicalActions.selectTrack(selectedRows));
		} else {
			props.downloadMediaRequestOff();
			if (props.selectedTracks.filter(track => track.id === selectedRows[0].id).length > 0){
				dispatch(realtimeActions.deSelectTracks(selectedRows));
			}
			dispatch(realtimeActions.selectTrack(selectedRows));
		
		}
	};

	const getTrackID = (track, type) => {
		switch (type) {
			case 'nav':
				return track['id'];
			case 'ais':
				return track['id'];
			case 'radar':
				return track['id'];
			case 'manual':
				return track['id'];
			default:
				return '';
		}
	};

	const onGridReady = (params, type) => {
		grid[type].current = params.api;
		setGridReady(true);
		if (selectedTracks.length > 0) {
			const trackId = getTrackID(selectedTracks[0], type);
			params.api.forEachNode((node) => {
				if (node.id === trackId) {
					node.setSelected(true, true);
					params.api.ensureNodeVisible(node, 'top');
				}
			});
		}
	};

	const selectedIndex = () => {
		return parseInt(R.invertObj(convertTab)[selectedTab]);
	};

	return (
		<div id="track-grid-ctn" className="track-grid">
			<Tabs
				id="track-grid-tabs"
				className="track-grid-tabs"
				selectedTabClassName="tab-selected"
				selectedIndex={selectedIndex()}
				onSelect={(index) => {
					R.forEachObjIndexed((tab) => {
						const api = tab.current;
						if (api !== undefined && api !== null) {
							api.deselectAll();
						}
					}, grid);

					setSelectedTab(convertTab[index]);
				}}
				forceRenderTabPanel={false}
			>
				<TabPanel
					id="track-grid-tab-nav-panel"
					key="nav"
					className="grid-tab-panel"
				>
					<div
						id="track-grid-tab-nav-ctn"
						className="grid-tab-area-large ag-theme-balham-dark"
					>
						<AgGridReact
							ref={grid.nav}
							columnDefs={translatedColumns(gridData.nav.fields)}
							defaultColDef={{
								sortable: true,
								resizable: true,
								wrapText: true
							}}
							rowData={gridData.nav.data}
							rowSelection="single"
							rowDeselection="true"
							onGridReady={(params) => onGridReady(params, 'nav')}
							getRowNodeId={(data) => data.id}
							onRowClicked={(params) => trackSelection(params)}
							overlayNoRowsTemplate={intl.formatMessage({
								id: 'app.map.grid.norows'
							})}
						></AgGridReact>
					</div>
				</TabPanel>

				<TabPanel
					id="track-grid-tab-ais-panel"
					key="ais"
					className="grid-tab-panel"
				>
					<div
						id="track-grid-tab-ais-ctn"
						className="grid-tab-area-large ag-theme-balham-dark"
					>
						<AgGridReact
							ref={grid.ais}
							columnDefs={translatedColumns(gridData.ais.fields)}
							defaultColDef={{
								sortable: true,
								resizable: true,
								wrapText: true
							}}
							rowData={gridData.ais.data}
							suppressScrollOnNewData="true"
							rowSelection="single"
							rowDeselection="true"
							onGridReady={(params) => onGridReady(params, 'ais')}
							getRowNodeId={(data) => data.id}
							onRowClicked={(params) => trackSelection(params)}
							overlayNoRowsTemplate={intl.formatMessage({
								id: 'app.map.grid.norows'
							})}
						></AgGridReact>
					</div>
				</TabPanel>

				<TabPanel
					id="track-grid-tab-radar-panel"
					key="radar"
					className="grid-tab-panel"
				>
					<div
						id="track-grid-tab-radar-ctn"
						className="grid-tab-area-large ag-theme-balham-dark"
					>
						<AgGridReact
							ref={grid.radar}
							columnDefs={translatedColumns(
								gridData.radar.fields
							)}
							defaultColDef={{
								sortable: true,
								resizable: true,
								wrapText: true
							}}
							rowData={gridData.radar.data}
							rowSelection="single"
							rowDeselection="true"
							onGridReady={(params) => {
								onGridReady(params, 'radar');
							}}
							getRowNodeId={(data) => data.id}
							onRowClicked={(params) => trackSelection(params)}
							overlayNoRowsTemplate={intl.formatMessage({
								id: 'app.map.grid.norows'
							})}
						></AgGridReact>
					</div>
				</TabPanel>

				<TabPanel
					id="track-grid-tab-manual-panel"
					key="manual"
					className="grid-tab-panel"
				>
					<div
						id="track-grid-tab-manual-ctn"
						className="grid-tab-area-large ag-theme-balham-dark"
					>
						<AgGridReact
							ref={grid.manual}
							columnDefs={translatedColumns(
								gridData.manual.fields
							)}
							defaultColDef={{
								sortable: true,
								resizable: true,
								wrapText: true
							}}
							rowData={gridData.manual.data}
							rowSelection="single"
							rowDeselection="true"
							onGridReady={(params) =>
								onGridReady(params, 'manual')
							}
							getRowNodeId={(data) => data.id}
							onRowClicked={(params) => trackSelection(params)}
							overlayNoRowsTemplate={intl.formatMessage({
								id: 'app.map.grid.norows'
							})}
						></AgGridReact>
					</div>
				</TabPanel>

				<TabList
					id="track-grid-tab-list"
					className="track-grid-tab-list"
				>
					<div id="track-grid-tabs-before"></div>
					<Tab
						id="track-grid-tab-nav"
						key="nav"
						className="track-grid-tab"
					>
						{intl.formatMessage({
							id: 'app.map.grid.realtime.header.nav'
						})}
					</Tab>
					<Tab
						id="track-grid-tab-ais"
						key="ais"
						className="track-grid-tab"
					>
						{intl.formatMessage({
							id: 'app.map.grid.realtime.header.ais'
						})}
					</Tab>
					<Tab
						id="track-grid-tab-radar"
						key="radar"
						className="track-grid-tab"
					>
						{intl.formatMessage({
							id: 'app.map.grid.realtime.header.radar'
						})}
					</Tab>
					<Tab
						id="track-grid-tab-manual"
						key="manual"
						className="track-grid-tab"
					>
						{intl.formatMessage({
							id: 'app.map.grid.realtime.header.manual'
						})}
					</Tab>
					<div id="track-grid-tabs-after"></div>
				</TabList>
			</Tabs>
		</div>
	);
}
