// # Imports
// External Imports
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { sortable } from 'react-anything-sortable';
import Slider from 'rc-slider';

// # Assets
import Eye from '../../assets/icons/eye-icon.svg';
import EyeSlash from '../../assets/icons/eyeslash-icon.svg';
import './overlay-item.scss';

const OverlayItem = (props) => {
	const [layerOpacity, setLayerOpacity] = useState(props.overlay.opacity);
	const intl = useIntl();

	// # Funcs
	const changeOpacity = (value, overlay) => {
		let tempOverlay = {...overlay};
		tempOverlay.opacity = value;
		props.changeOpacity(tempOverlay);
	};

	const updateProfileOpacity = (value, overlay) => {
		let tempOverlay = {...overlay};
		tempOverlay.opacity = value;
		props.updateProfileOpacity(tempOverlay);
	}

	return (
		<li
			id={'overlay-sidebar-list-item-' + props.idx}
			className="overlay-item"
		>
			<div
				id={'overlay-item-left-col-' + props.idx}
				className="overlay-item-left-col"
			>
				<div
					className={'draggable'}
				>
					{props.overlay.name.replace('SISMap:', '')}
				</div>

				<button
					id={'Remove-' + props.idx}
					type="button"
					checked={props.overlay.shown === '1'}
					onClick={() => {
						props.removeFromProfile(props.overlay);
					}}
					title={intl.formatMessage({
						id: 'app.map.overlays.list.remove.button.tooltip'
					})}
					className={'overlay-item-remove-btn'}
				>
					{intl.formatMessage({
						id: 'app.map.overlays.list.remove.button'
					})}
				</button>
			</div>
			<div className="overlay-item-right-col">
				<div className="overlay-item-right-col-top">
					<span>
						{intl.formatMessage({
							id: 'app.map.overlays.list.opacity.label'
						})}{' '}
						{layerOpacity}%
					</span>
					<button
						id={'Visible-' + props.idx}
						type="button"
						checked={props.overlay.shown === '1'}
						onClick={(e) => {
							props.toggleVisibility(props.overlay);
						}}
						title={intl.formatMessage({
							id: 'app.map.overlays.list.visible.button.tooltip'
						})}
						className={'overlay-item-visible-btn'}
					>
						{props.overlay.shown === '1' ? (
							<img alt="Overlay Visible" src={Eye} />
						) : (
							<img alt="Overlay Hidden" src={EyeSlash} />
						)}
					</button>
				</div>
				<div className="overlay-item-right-col-bottom">
					<Slider
						id={'opacity-item-slider-' + props.idx}
						className="opacity-item-slider"
						min={0}
						max={100}
						disabled={props.overlay.shown !== '1'}
						value={layerOpacity}
						onChange={(e) => {		
							setLayerOpacity(e);
							changeOpacity(e, props.overlay);
						}}
						onAfterChange={() => {
						    updateProfileOpacity(layerOpacity, props.overlay);
						}}
					/>
				</div>
			</div>
		</li>
	);
};

export default sortable(OverlayItem);
