// # Imports
// External Imports
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SlideDown } from 'react-slidedown';

// Internal Imports

// Assets
import './collapsible-section.scss';
import 'react-slidedown/lib/slidedown.css';

export default function CollapsibleSection(props) {
	const onSubsectionClick = props.onSubsectionClick ? props.onSubsectionClick : () => {};
	const [isOpen, setIsOpen] = useState(props.sectionOpen || false);
	const SectionHeader = props.sectionHeader;
	
	useEffect(() => {
		setIsOpen(props.sectionOpen);
	}, [props.sectionOpen]);

	const propsClass = props.sectionClass
		? props.sectionClass
		: 'section-container';

	function handleClick() {
		if (props.sectionDisabled) {
			return;
		}
		if (props.onClick) {
			props.onClick(!isOpen);
		}
		setIsOpen(!isOpen);
		onSubsectionClick();
	}

	return (
		<div
			className={classNames(
				propsClass,
				{
					disabled: props.sectionDisabled
				},
				{
					show: isOpen
				}
			)}
		>
			<div
				className={classNames('section-header', {
					show: isOpen
				})}
				onClick={handleClick}
			>
				<SectionHeader />
			</div>
			<SlideDown
				className={classNames('section-content', {
					show: isOpen
				})}
				closed={!{ isOpen }}
			>
				{isOpen ? props.children : null}
			</SlideDown>
		</div>
	);
}
