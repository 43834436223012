// # Imports
// External Imports

import { useIntl, FormattedMessage } from 'react-intl';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal Imports
import { realtimeActions } from '../../_modules/realtime/realtime.actions';
import { mediaService } from '../../_services/media.service.js';
import MediaItem from '../Media/MediaItem';
import sisConfig from '../../sis.config.json';
import {canPlayMediaAsVideo, downloadMedia} from './../../_helpers/media.js';
import { alertActions } from '../../_modules/alerts/alert.actions';


// Assets
import './realtime-media.scss';
import { ReactComponent as DownloadMedia } from '../../assets/icons/icon_media-download-media.svg';
import ProgressSpinner from '../ProgressSpinner';
import ThumbnailWarning from './ThumbnailWarning';

export default function RealTimeMedia(props) {
	const [savedState, setSavedState] = useState(
		sessionStorage.getItem('c4-realtime:media') 
		? 
		JSON.parse(sessionStorage.getItem('c4-realtime:media'))
		: {
			selectedMediaData: {
				id: null,
				mimeType: "",
				fileName: ""
			},
			layout: 'tile'
		  });	
	const [mediaArr, setMediaArr] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingDown, setLoadingDown] = useState(false);
	const [layout, setLayout] = useState(savedState.layout);
	const [images, setImages] = useState(true);
	const [search, setSearch] = useState('');
	const [video, setVideo] = useState(true);
	const [selectedMediaData, setSelectedMediaData] = useState(savedState.selectedMediaData);

	const intl = useIntl();
	const dispatch = useDispatch();

	useEffect(() => {
		const newState = {
			...savedState,
			layout: layout,
			selectedMediaData: selectedMediaData
		};
		setSavedState(newState);
		sessionStorage.setItem('c4-realtime:media', JSON.stringify(newState));
	}, [layout, selectedMediaData]);

	useEffect(() => {
		setLoading(true);
		let mediaLinks = (props.mediaLinks)? props.mediaLinks : [];
		let mediaRequests = [];
		mediaLinks.map((media) => {
			mediaRequests.push(mediaService.getDetails(media.mediaId, props.dnsAddress).catch(() => null));
		});
		Promise.all(mediaRequests)
			.then((res) => {
				const noNullRes = res.filter(r => r !== null);
				let newMediaArr = [];
				noNullRes.map((details) => {
					let newMedia = {
						id: details._id,
						file: details.fileInfo.originalFileName,
						size: details.fileInfo.fileSizeBytes,
						type: details.fileInfo.mimeType,
						thumb: null
					};
					newMediaArr.push(newMedia);
				});
				return newMediaArr;
			})
			.then((newMediaArr) => {
				setMediaArr(newMediaArr);
				setLoading(false);
			});
	}, [props.mediaLinks]);

	const performSearch = (searchStr) => {
		//console.log(searchStr);
	};

	const handleChange = (event) => {
		const value = event.target.value;
		setSearch(value);
		performSearch(search);
	};

	const clearSearch = () => {
		setSearch('');
		performSearch();
	};

	const onKeyDown = (event) => {
		if (event.keyCode === 13) {
			performSearch();
		}
	};

	//TODO: Unused but perhaps for buttons or actions on the items
	const selectMedia = (id) => {
		if(id !== null) {
			mediaService.getDetails(id, props.dnsAddress)
				.then(res => {
					setSelectedMediaData({
						id,
						mimeType: res.fileInfo.mimeType,
						fileName: res.fileInfo.originalFileName
					});
				});
		}
	};

	const loadingDownOnCancel = () => {
		setLoadingDown(false)
	}
	
	const downloadRealtimeMedia = () => { 
		downloadMedia(selectedMediaData, props.dnsAddress)
								.then(() => setLoadingDown(false));
	}
	
	return (
		<div className="real-mission-media-container">
			<div className="real-mission-media-actions">
				<div className="left">
					<button
						type="button"
						onClick={(e) => {
							setLayout('list');
						}}
						className={layout === 'list' ? 'btn-selected' : 'btn'}
						disabled={false}
						title={intl.formatMessage({
							id: 'app.map.sidebar.realtime.media.list.title',
							defaultMessage: 'Organize media as a list'
						})}
					>
						<FormattedMessage
							id="app.map.sidebar.realtime.media.list"
							defaultMessage="list"
						/>
					</button>
					<button
						type="button"
						onClick={(e) => {
							setLayout('tile');
						}}
						className={layout === 'tile' ? 'btn-selected' : 'btn'}
						disabled={false}
						title={intl.formatMessage({
							id: 'app.map.sidebar.realtime.media.tile.title',
							defaultMessage: 'Organize media as tiles'
						})}
					>
						<FormattedMessage
							id="app.map.sidebar.realtime.media.tile"
							defaultMessage="Tile"
						/>
					</button>
				</div>
				<div className="right">
					{props.downloadMediaAccess === true && (<div style={{"visibility": loadingDown ? "visible" : "hidden"}}><ProgressSpinner/></div>)}
					<button
						className="realtime-media-download-button"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setLoadingDown(true);
							const confirmMediaDownloadAlert = {
								level: 'warning',
								messageId:
									'app.notification.realtime.media.warning.download',
								timeStamp: new Date(),
								action: downloadRealtimeMedia,
								onCancel: loadingDownOnCancel
							};
							dispatch(alertActions.addAlert(confirmMediaDownloadAlert));
						}}
						disabled={
							props.downloadMediaAccess === true && props.mediaLinks.filter(link => link.mediaId === selectedMediaData.id).length > 0 ? false : true
						}
					>
						<DownloadMedia 
							title={intl.formatMessage({
								id: 'app.map.trackinspector.media.download.title',
								defaultMessage: 'disassociate a media from a track'
							})}
							className="media-icon"
						/>
					</button>
				</div>
				</div>
				{props.downloadMediaAccess === false && (
					<ThumbnailWarning
						title= {intl.formatMessage({id: 'app.map.sidebar.media.warning.button'})}
						message={intl.formatMessage({id: 'app.map.sidebar.media.warning.message'})}
						downloadMediaRequestOn={props.downloadMediaRequestOn}
					/>
				)}
			
			{props.downloadMediaAccess === true && (
			<div id="real-mission-media-ctn" className="real-mission-media-container">
				<ul
					id="real-mission-media-list"
					className={
						layout === 'list'
							? 'real-mission-media-list-view'
							: 'real-mission-media-tile-view'
					}
				>
					{mediaArr.map((media, key) => (
						<MediaItem
							media={media}
							idx={key}
							layout={layout}
							selectMedia={selectMedia}
							isSelected={media.id === selectedMediaData.id}
							dnsAddress={props.dnsAddress}
						></MediaItem>
					))}
					{!loading && mediaArr.length === 0 && (
						<li
							id="real-mission-media-list-empty"
							className="real-mission-media-tab-empty"
						>
							{intl.formatMessage({
								id: 'app.media.nomedia'
							})}
						</li>
					)}
				</ul>
				<div className="absolute-spinner" style={{"display": loading ? "block" : "none"}}><ProgressSpinner size="large"/></div>
			</div>
		)}
	</div>
	)
}


