// # Imports
// External Imports
import React, { useState, useEffect } from 'react';
import * as R from 'ramda';

// Internal Imports
import { mediaService } from '../../_services/media.service.js';

// Assets
import loadingImage from '../../assets/images/sis-loading.gif';
import defaultThumb from '../../assets/images/sis-media-thumbnail_unknown.png';
import './media-item.scss';

export default function MediaItem(props) {
	const [mediaData, setMediaData] = useState(props.media);
	const isSelected = props.isSelected;
	useEffect(() => {
		mediaService
			.getThumbnail(mediaData.id, props.dnsAddress)
			.then((res) => {
				if (res.status !== 200) {
					return null;
				} else {
					return res.body;
				}
			})
			.then((stream) => new Response(stream))
			.then((response) => response.blob())
			.then((blob) => {
				if (blob.size !== 0) {
					const jpgBlob = new Blob([blob], {
						type: mediaData.type
					});
					return new Promise((resolve, reject) => {
						const reader = new FileReader();
						reader.onload = function () {
							resolve(reader.result);
						};
						reader.readAsDataURL(jpgBlob);
					});
				} else {
					return null;
				}
			})
			.then((res) => {
				if (res) {
					setMediaData(R.assoc('thumb', res, mediaData));
					return res;
				}
				return null;
			});
	}, props.media);

	const MediaType = (item) => {
		if (item) {
			if (props.layout === 'list') {
				return (
					<ul
						id={'media-item-details' + props.idx}
						className={isSelected ? "media-item-list-details-selected" : "media-item-list-details"}
					>
						<li
							id={'media-item-name-' + props.idx}
							className="media-name-wrap"
						>
							{item.file}
						</li>
						<li id={'media-item-type-' + props.idx}>{item.type}</li>
						<li id={'media-item-size-' + props.idx}>{(item.size*0.0009765625).toFixed(2) + " KB"}</li>
					</ul>
				);
			} else {
				return (
					<div 
						className={isSelected ? "media-item-tile-details-selected" : "media-item-tile-details"}
						title={item.file}
					>
						{Thumbnail(item)}
						{item.file.length < 16 ? item.file : item.file.substring(0, 13)+"..."}
					</div>
				);
			}
		} else {
			return (
				<div>
					<img
						width="70"
						height="70"
						alt="Loading"
						src={loadingImage}
					/>
				</div>
			);
		}
	};

	const Thumbnail = (item) => {
		if (item.thumb) {
			return (
				<img
					width="70"
					height="70"
					alt="Media Thumbnail"
					src={item.thumb}
				/>
			);
		} else {
			return (
				<img
					id={item.file}
					width="70"
					height="70"
					alt="Default Media Thumbnail"
					src={defaultThumb}
				/>
			);
		}
	};

	return (
		<li
			id={'media-item-' + props.idx}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				props.selectMedia(mediaData.id);
			}}
		>	
			{MediaType(mediaData)}
		</li>
	);
}
