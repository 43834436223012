import { authConstants } from './auth.constants';
import { alertActions } from '../alerts/alert.actions';
import { profileActions } from '../profiles/profile.actions';
import { httpInterceptor } from '../../_helpers/http-interceptor';
import { windowService } from '../../_services/window.service';
import sis3 from '../../_services/sis3.service';
import { permissionService } from '../../_services/permission.service';
import sisConfig from '../../sis.config.json';
import { startConverse } from '../../_components/Chat/Chat';

export const authActions = {
	buildUserObj,
	destroyUserObj
};

function buildUserObj(user, getAccessTokenSilently, location) {
	let auth0Token = "";
	return (dispatch) => {
		return getAccessTokenSilently({ audience: sisConfig.build[window.location.hostname].auth0_audience, scope: sisConfig.build[window.location.hostname].auth0_scope })
		.then(token => {
			auth0Token = token;
			return fetch(`https://${sisConfig.build[window.location.hostname].auth0_domain}/api/v2/users/${user.sub}`, { headers: { Authorization: `Bearer ${token}`}});
		})
		.then(metadata => metadata.json())
		.then(md => {
			const sisUserName = `${md.nickname}@${sisConfig.build[window.location.hostname].username_postfix}`;
			dispatch(request({ sisUserName }));
			const displayName = `${md.given_name} ${md.family_name}`;
			let features = [];
			md.groups.forEach(group => {
				if(sisConfig.sisRolesToFeatures[group])
					sisConfig.sisRolesToFeatures[group].forEach(feature => {
						if(!features.includes(feature))
							features.push(feature);
					});
			});
			const userObj = {
				displayName,
				token: auth0Token,
				nickname: md.nickname,
				username: sisUserName,
				userFeatures: features
			};
			sessionStorage.setItem("sisUser", JSON.stringify(userObj));
			dispatch(
				profileActions.getProfileByUserName(
					sisUserName,
					location
				)
			);
			dispatch(success(JSON.parse(sessionStorage.getItem("sisUser"))));
		});
	};

	function request(user) {
		return { type: authConstants.LOGIN_REQUEST, user };
	}
	function success(user) {
		return { type: authConstants.LOGIN_SUCCESS, user };
	}
	function failure(error) {
		return { type: authConstants.LOGIN_FAILURE, error };
	}
}

function destroyUserObj() {
	if (permissionService.hasFeature('Reports')) {
		sis3.logout();
	}
	sessionStorage.removeItem('sisUser');
	sessionStorage.removeItem('sisStore');
	sessionStorage.removeItem('reportRequest');
	windowService.closeChildWindows();
	return { type: authConstants.LOGOUT };
}
