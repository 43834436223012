// # Imports
// External Imports
import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

// Internal Imports
import { mediaService } from '../../_services/media.service.js';
import {canPlayMediaAsVideo, downloadMedia} from './../../_helpers/media.js';
import MediaItem from '../Media/MediaItem';
import { alertActions } from '../../_modules/alerts/alert.actions';

// Assets
import { ReactComponent as DownloadMedia } from '../../assets/icons/icon_media-download-media.svg';
import './realtime-track-media.scss';
import ProgressSpinner from '../ProgressSpinner.jsx';
import ThumbnailWarning from './ThumbnailWarning';

export default function RealTimeTrackMedia(props) {
	const [savedState, setSavedState] = useState(sessionStorage.getItem('state:realtime-track-media')
		? JSON.parse(sessionStorage.getItem('state:realtime-track-media'))
		: {
			layout: 'tile',
			selectedMediaData: {
				id: null,
				mimeType: "",
				fileName: ""
			}
		});

	const [mediaArr, setMediaArr] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingDown, setLoadingDown] = useState(false);
	const [layout, setLayout] = useState(savedState.layout);
	const [selectedMediaData, setSelectedMediaData] = useState(savedState.selectedMediaData);
	const intl = useIntl();
	const dispatch = useDispatch();

	useEffect(() => {
		const newState = {
			...savedState,
			layout: layout,
			selectedMediaData: selectedMediaData
		};
		sessionStorage.setItem('state:realtime-track-media', JSON.stringify(newState));
		setSavedState(savedState);
	}, [layout, selectedMediaData]);

	useEffect(() => {
		setLoading(true);
		let mediaRequests = [];
		props.mediaList.map((media) => {
			mediaRequests.push(mediaService.getDetails(media.MediaId, props.dnsAddress));
		});
		Promise.all(mediaRequests)
			.then((res) => {
				let newMediaArr = [];
				res.map((details) => {
					let newMedia = {
						id: details._id,
						file: details.fileInfo.originalFileName,
						size: details.fileInfo.fileSizeBytes,
						type: details.fileInfo.mimeType,
						thumb: null
					};
					newMediaArr.push(newMedia);
				});
				return newMediaArr;
			})
			.then((newMediaArr) => {
				setMediaArr(newMediaArr);
				setLoading(false);
			});
	}, [props.mediaList]);

	//TODO: Unused but perhaps for buttons or actions on the items
	const selectMedia = (id) => {
		if(id !== null) {
			mediaService.getDetails(id, props.dnsAddress)
				.then(res => {
					setSelectedMediaData({
						id,
						mimeType: res.fileInfo.mimeType,
						fileName: res.fileInfo.originalFileName
					});
				});
		}
	};

	const loadingDownOnCancel = () => {
		setLoadingDown(false)
	}
	
	const downloadRealtimeMedia = () => { 
		downloadMedia(selectedMediaData, props.dnsAddress)
								.then(() => setLoadingDown(false));
	}

	return (
		<div id="real-track-media-ctn" 
			className="real-track-media-container"
			onClick={() => {
				setSelectedMediaData({...selectedMediaData, id: null});
			}}
		>
		<div className="media-container">
			<div className="left">
				<button
					type="button"
					onClick={(e) => {
						setLayout('list');
					}}
					className={layout === 'list' ? 'btn-selected' : 'btn'}
					disabled={false}
					title={intl.formatMessage({
						id: 'app.map.sidebar.realtime.media.list.title',
						defaultMessage: 'Organize media as a list'
					})}
				>
					<FormattedMessage
						id="app.map.sidebar.realtime.media.list"
						defaultMessage="list"
					/>
				</button>
				<button
					type="button"
					onClick={(e) => {
						setLayout('tile');
					}}
					className={layout === 'tile' ? 'btn-selected' : 'btn'}
					disabled={false}
					title={intl.formatMessage({
						id: 'app.map.sidebar.realtime.media.tile.title',
						defaultMessage: 'Organize media as tiles'
					})}
				>
					<FormattedMessage
						id="app.map.sidebar.realtime.media.tile"
						defaultMessage="Tile"
					/>
				</button>
			</div>
			{props.downloadMediaAccess === false && (  
			<div>
				<ThumbnailWarning
					title= {intl.formatMessage({id: 'app.map.sidebar.media.warning.button'})}
					message={intl.formatMessage({id: 'app.map.sidebar.media.warning.message'})}
					downloadMediaRequestOn={props.downloadMediaRequestOn}
				/>
			</div>
			)}
			{props.downloadMediaAccess === true &&(
				<ul
					id="real-track-media-list"
					className={
						layout === 'list'
							? 'media-list-view'
							: 'media-tile-view'
					}
				>
					{mediaArr.map((media, key) => (
						<MediaItem
							media={media}
							idx={key}
							layout={layout}
							selectMedia={selectMedia}
							isSelected={selectedMediaData.id === media.id}
							dnsAddress={props.dnsAddress}
						></MediaItem>
					))}
					<div style={{"position": "absolute", "display": loading ? "block" : "none", "margin-left": "45%"}}><ProgressSpinner size="large"/></div>
					{!loading && mediaArr.length === 0 && (
						<li
							id="track-media-list-empty"
							className="real-media-tab-empty"
						>
							{intl.formatMessage({
								id: 'app.media.nomedia'
							})}
						</li>
					)}
				</ul>
			)}
			</div>
			{props.collectionId !== 'nav' && props.downloadMediaAccess === true && (<div className="real-track-media-actions">
				<div className="real-track-media-actions-group">
					<div className="real-track-media-actions-column">
						<button
							className="realtime-track-media-download-button"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								setLoadingDown(true);
								const confirmMediaDownloadAlert = {
									level: 'warning',
									messageId:
										'app.notification.realtime.media.warning.download',
									timeStamp: new Date(),
									action: downloadRealtimeMedia,
									onCancel: loadingDownOnCancel
								};
								dispatch(alertActions.addAlert(confirmMediaDownloadAlert));
							}}
							disabled={
								selectedMediaData.id === null ? 'disabled' : false
							}
						>
							<DownloadMedia 
								title={intl.formatMessage({
									id: 'app.map.trackinspector.media.download.title',
									defaultMessage: 'disassociate a media from a track'
								})}
								className="media-icon"
							/>
						</button>
					</div>
				</div>
				<div className="real-track-media-spinner-hidden"><ProgressSpinner/></div>
				<div className={loadingDown ? "real-track-media-spinner-visible" : "real-track-media-spinner-hidden"}><ProgressSpinner/></div>
			</div>)}

			{props.collectionId !== 'nav' && props.downloadMediaAccess === false && (<div className="real-track-media-actions">
				<div className="real-track-media-actions-group">
					<div className="real-track-media-actions-column">
						<button
							className="realtime-track-media-download-button"
							disabled={true}
						>
							<DownloadMedia 
								title={intl.formatMessage({
									id: 'app.map.trackinspector.media.download.title',
									defaultMessage: 'disassociate a media from a track'
								})}
								className="media-icon"
							/>
						</button>
					</div>
				</div>
			</div>)}
		</div>
	);
}
