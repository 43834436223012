import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import sisconfig from '../../sis.config.json';

export default function TrackIdentification(props) {
	let identificationType = props.item.identificationType;
	const intl = useIntl();

	const detailTypePropertyNames = [
		'AircraftType',
		'AnimalType',
		'BuoyType',
		'GearType',
		'IcebergType',
		'InfrastructureType',
		'MarineMammalType',
		'MissionEventType',
		'OtherType',
		'PackIceType',
		'PollutantType',
		'VesselType'
	];

	const detailTypePropertyName = Object.keys(
		props.item.detailIdentification || {}
	).find((k) => detailTypePropertyNames.some((p) => p === k));
	const detailProperties = Object.keys(
		props.item.detailIdentification || {}
	).filter((k) => k !== (detailTypePropertyName || ''));

	const details = (detailData) => {
		return Object.keys(detailData).map(function (id, key) {
			return (
				<li
					id={'track-inspector-detail-list-item-' + key}
					className={'track-properties-list-item'}
				>
					<span
						className="track-properties-list-item-comment-label"
						id={'track-inspector-detail-list-item-label' + key}
					>
						{	
							sisconfig.trackInspectorLabelTemplates[identificationType] !==undefined && sisconfig.trackInspectorLabelTemplates[identificationType].filter(item => item === id).length > 0 ? 

							intl.formatMessage({
								id: `app.map.trackinspector.identification.${identificationType.toLowerCase()}`
							})
							+" "+ intl.formatMessage({
								id: `app.map.trackinspector.identification.${id.toLowerCase()}`
							}):
							intl.formatMessage({
								id: `app.map.trackinspector.identification.${id.toLowerCase()}`
							})
						}
						:
					</span>
					{detailData[id]}
				</li>
			);
		});
	};
	return (
		<ul
			id="track-identification-list"
			className={classNames('track-properties', {
				'track-properties-tiles': true
			})}
		>
			<li id="track-identification-description">
				<span id="track-identification-label-description">
					{intl.formatMessage({
						id: 'app.map.trackinspector.identification.description'
					})}
					:
				</span>
				{props.item.trackIdentification.Description}
			</li>
			<li id="track-identification-type">
				<span id="track-identification-label-type">
					{intl.formatMessage({
						id:
							'app.map.trackinspector.identification.identificationtype'
					})}
					:
				</span>
				{props.item.trackIdentification.IdentificationType}
			</li>
			{props.item.groupIdentification && (
				<li id="track-identification-group-number">
					<span id="track-identification-label-group-number">
						{intl.formatMessage({
							id:
								'app.map.trackinspector.identification.group.number'
						})}
						:
					</span>
					{props.item.groupIdentification.Number}
				</li>
			)}
			{props.item.groupIdentification && (
				<li id="track-identification-group-radius">
					<span id="track-identification-label-group-radius">
						{intl.formatMessage({
							id:
								'app.map.trackinspector.identification.group.radius'
						})}
						:
					</span>
					{props.item.groupIdentification.Radius}
				</li>
			)}

			{props.item.detailIdentification &&
				props.item.trackIdentification.identificationTypeCode !== 9 &&
				details(props.item.detailIdentification)}

			{props.item.detailIdentification &&
				props.item.trackIdentification.identificationTypeCode === 9 && (
					<li id="track-identification-length">
						<span id="track-identification-label-length">
							{intl.formatMessage({
								id:
									'app.map.trackinspector.identification.length'
							})}
							:
						</span>
						{props.item.detailIdentification.Length}
					</li>
				)}
			{props.item.detailIdentification &&
				props.item.trackIdentification.identificationTypeCode === 9 && (
					<li id="track-identification-width">
						<span id="track-identification-label-width">
							{intl.formatMessage({
								id:
									'app.map.trackinspector.identification.width'
							})}
							:
						</span>
						{props.item.detailIdentification.Width}
					</li>
				)}

			{props.item.detailIdentification &&
				props.item.trackIdentification.identificationTypeCode === 9 && (
					<li id="track-identification-total-coverage">
						<span id="track-identification-label-total-coverage">
							{intl.formatMessage({
								id:
									'app.map.trackinspector.identification.totalcoverage'
							})}
							:
						</span>
						{props.item.detailIdentification.TotalCoverage}
					</li>
				)}

			{props.item.detailIdentification &&
				props.item.trackIdentification.identificationTypeCode === 9 && (
					<li id="track-identification-acoverage">
						<span id="track-identification-label-acoverage">
							{intl.formatMessage({
								id:
									'app.map.trackinspector.identification.acoverage'
							})}
							:
						</span>
						{props.item.detailIdentification.ACoverage}
					</li>
				)}

			{props.item.detailIdentification &&
				props.item.trackIdentification.identificationTypeCode === 9 && (
					<li id="track-identification-bcoverage">
						<span id="track-identification-label-bcoverage">
							{intl.formatMessage({
								id:
									'app.map.trackinspector.identification.bcoverage'
							})}
							:
						</span>
						{props.item.detailIdentification.BCoverage}
					</li>
				)}

			{props.item.detailIdentification &&
				props.item.trackIdentification.identificationTypeCode === 9 && (
					<li id="track-identification-ccoverage">
						<span id="track-identification-label-ccoverage">
							{intl.formatMessage({
								id:
									'app.map.trackinspector.identification.ccoverage'
							})}
							:
						</span>
						{props.item.detailIdentification.CCoverage}
					</li>
				)}

			{props.item.detailIdentification &&
				props.item.trackIdentification.identificationTypeCode === 9 && (
					<li id="track-identification-dcoverage">
						<span id="track-identification-label-dcoverage">
							{intl.formatMessage({
								id:
									'app.map.trackinspector.identification.dcoverage'
							})}
							:
						</span>
						{props.item.detailIdentification.DCoverage}
					</li>
				)}

			{props.item.detailIdentification &&
				props.item.trackIdentification.identificationTypeCode === 9 && (
					<li id="track-identification-ecoverage">
						<span id="track-identification-label-ecoverage">
							{intl.formatMessage({
								id:
									'app.map.trackinspector.identification.ecoverage'
							})}
							:
						</span>
						{props.item.detailIdentification.ECoverage}
					</li>
				)}

			{props.item.detailIdentification &&
				props.item.trackIdentification.identificationTypeCode === 9 && (
					<li id="track-identification-fcoverage">
						<span id="track-identification-label-fcoverage">
							{intl.formatMessage({
								id:
									'app.map.trackinspector.identification.fcoverage'
							})}
							:
						</span>
						{props.item.detailIdentification.FCoverage}
					</li>
				)}

			{props.item.detailIdentification &&
				props.item.trackIdentification.identificationTypeCode === 9 && (
					<li id="track-identification-total-amount">
						<span id="track-identification-label-total-amount">
							{intl.formatMessage({
								id:
									'app.map.trackinspector.identification.totalamount'
							})}
							:
						</span>
						{props.item.detailIdentification.TotalAmount}
					</li>
				)}
		</ul>
	);
}
