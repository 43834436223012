// # Imports
// External Imports
import React, { useState, useEffect } from 'react';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';

// Internal Imports
import HistoricalTrackMedia from '../Historical/HistoricalTrackMedia';
import RealTimeTrackMedia from '../RealTime/RealTimeTrackMedia';
import TrackIdentification from './TrackIdentification';
import sisConfig from '../../sis.config.json';
import { convertKeys } from '../../_helpers/data-columns';
import { navColumns } from '../../_schema/nav.schema';
import { aisColumns } from '../../_schema/ais.schema';
import { radarColumns } from '../../_schema/radar.schema';
import { manualColumns } from '../../_schema/manual.schema';
import { mediaService } from '../../_services/media.service.js';
import { historicalActions } from '../../_modules/historical/historical.actions';
import { alertActions } from '../../_modules/alerts/alert.actions';

// Assets
// import Gallery from '../../assets/icons/gallery.svg';
import './track-inspector.scss';

export default function TrackInspector(props) {
	const intl = useIntl();

	const dispatch = useDispatch();

	let modeConfig = sisConfig.sections.find((x) => x.id === props.mode);

	let selectedTracks = useSelector((state) =>
		props.mode === 'historical'
			? state.historical.selectedTracks
			: state.realtime.selectedTracks
	);
	
	//const [mediaTileView, mediaPropTileView] = useState('list');
	const [currentTab, setCurrentTab] = useState(0);
	const decodeLatLong = (encodedString) => {
		if (encodedString !== null) {
			return encodedString.toString().replace('&deg;', '°');
		} else {
			return '';
		}
	};

	const trackName = (track) => {
		if (track.collectionId === 'nav') {
			return 'Nav - ' + track['missionNumber'];
		} else {
			return `${track.collectionId.toUpperCase()} - ${track['cid']}`;
		}
	};

	const trackId = (track) => {
		return track.id;
	};

	const trackSchema = (track) => {
		let schema = {};
		if (track.collectionId === 'nav') {
			schema = navColumns;
		}
		if (track.collectionId === 'ais') {
			schema = aisColumns;
		}
		if (track.collectionId === 'radar') {
			schema = radarColumns;
		}
		if (track.collectionId === 'manual') {
			schema = manualColumns;
		}
		return schema;
	};

	const OnMediaDisassociation = (mediaId) => {
		//Callback for when the alert system's popup is confirmed by user 
		const disassociateMedia = () => {
			return mediaService
			.disAssociateMedia(
				trackId(selectedTracks[0]),
				mediaId
			)
			.then((res) => {
				if (res === 200) {
					dispatch(
						historicalActions.selectTrack(
							selectedTracks
						)
					);
					return res;
				}
			});
		};
		//Alert object that is passed on to the alert system
		const confirmMediaDisassociationAlert = {
			level: 'warning',
			messageId:
				'app.notification.asis.warning.mediadisassociation',
			timeStamp: new Date(),
			action: disassociateMedia
		};

		dispatch(alertActions.addAlert(confirmMediaDisassociationAlert));

		//empty promise to allow HistoricalTrackMedia to respond to disassociation
		return (async () => {})();
	};

	const OnMediaAssociation = (e) => {
		const files = e.target.files;
		
		let uploadPromises = [];
		Array.from(files).forEach(file => {
			if (files.length < 1) {
				return;
			}
		
			const formData = new FormData();
			formData.append('file', file);
	
			formData.append(
				'missionName',
				selectedTracks[0].missionNumber
			);
			formData.append('mimeType', file.type);
			formData.append('originalFileName', file.name);
			formData.append('fileSizeBytes', file.size);
	
			uploadPromises.push(mediaService.associateMedia(trackId(selectedTracks[0], formData), formData)
				.catch(()=> null)
			)

		});	
		return Promise.all(uploadPromises)
			.then(() => dispatch(historicalActions.selectTrack(selectedTracks)))
			.catch((e)=> console.error(e))
	}

	const MediaRedirect = () => {
		if (props.mode === 'historical') {
			return (
				<HistoricalTrackMedia
					onMediaDisAssociation={OnMediaDisassociation}
					onMediaAssociation={OnMediaAssociation}
					mediaList={selectedTracks[0].associatedMedia}
					collectionId={selectedTracks[0].collectionId}
					showVideoPlayer={props.showVideoPlayer}
				></HistoricalTrackMedia>
			);
		} else {
			return (
				<RealTimeTrackMedia
					mediaList={selectedTracks[0].associatedMedia}
					collectionId={selectedTracks[0].collectionId}
					showVideoPlayer={props.showVideoPlayer}
					dnsAddress={props.dnsAddress}
					downloadMediaAccess={props.downloadMediaAccess}
					downloadMediaRequestOn={props.downloadMediaRequestOn}
				></RealTimeTrackMedia>
				
			)
		}
	};

	const CommentList = (comments) => {
		return comments.map(function (id, key) {
			return (
				<li id={'track-properties-list-item-' + key}>
					<span id={'track-properties-list-item-label' + key}>
						{id.User} - {id.TimeEntered}
					</span>
					{id.Comment}
				</li>
			);
		});
	};

	const SelectedTrack = () => {
		if (selectedTracks.length !== 0) {
			let inspectorFields =
				modeConfig.trackInspector[selectedTracks[0].collectionId];

			const parseData = (tabName) => {
				return convertKeys(
					selectedTracks[0],
					trackSchema(selectedTracks[0]),
					inspectorFields[tabName]
				);
			};

			let trackData = (tabName) => {
				let tabData = parseData(tabName);
				if (Object.keys(tabData).length !== 0) {
					return Object.keys(tabData).map(function (id, key) {
						switch (id) {
							case 'trackIdentification':
								if (tabData[id].value !== null) {
									if (tabData[id].value.length !== 0) {
										return (
											<TrackIdentification
												item={selectedTracks[0]}
											></TrackIdentification>
										);
									}
								}
							case 'trackComments':
								if (tabData[id].value !== null) {
									if (tabData[id].value.length !== 0) {
										return tabData[id].value.map(function (
											id,
											key
										) {
											return (
												<li
													id={
														'track-properties-list-item-' +
														key
													}
													key={`${id}-${key}`}
													className={
														'track-properties-list-item-full'
													}
												>
													<span
														className="track-properties-list-item-comment-label"
														id={
															'track-properties-list-item-label' +
															key
														}
													>
														{id.User} (
														{id.TimeEntered}
														):
													</span>
													{id.Comment}
												</li>
											);
										});
									} else {
										return (
											<li
												className="track-properties-tab-empty"
												key={id}
											>
												{intl.formatMessage({
													id:
														'app.map.trackinspector.nodata'
												})}
											</li>
										);
									}
								} else {
									return (
										<ul
											id="track-identification-list"
											key={id}
											className={classNames(
												'track-properties',
												{
													'track-properties-tiles': true
												}
											)}
										>
											<li className="track-properties-tab-empty">
												{intl.formatMessage({
													id:
														'app.map.trackinspector.nodata'
												})}
											</li>
										</ul>
									);
								}
							case 'trackSpecies':
								if (tabData[id].value !== null) {
									if (tabData[id].value.length !== 0) {
										return tabData[id].value.map(function (
											id,
											key
										) {
											return (
												<li
													key={id}
													id={
														'track-properties-list-item-' +
														key
													}
													className={
														'track-properties-list-item'
													}
												>
													{id.WorkElementType} /{' '}
													{id.SpeciesType}
												</li>
											);
										});
									} else {
										return (
											<li
												className="track-properties-tab-empty"
												key={id}
											>
												{intl.formatMessage({
													id:
														'app.map.trackinspector.nodata'
												})}
											</li>
										);
									}
								} else {
									return (
										<ul
											id="track-identification-list"
											key={id}
											className={classNames(
												'track-properties',
												{
													'track-properties-tiles': true
												}
											)}
										>
											<li className="track-properties-tab-empty">
												{intl.formatMessage({
													id:
														'app.map.trackinspector.nodata'
												})}
											</li>
										</ul>
									);
								}
							default:
								return (
									<li
										id={'track-properties-list-item-' + key}
										key={id}
									>
										<span
											id={
												'track-properties-list-item-label' +
												key
											}
										>
											{intl.formatMessage({
												id: tabData[id].label
											})}
											:{' '}
										</span>
										{tabData[id.value] !== null
											? decodeLatLong(tabData[id].value)
											: ''}
									</li>
								);
						}
					});
				} else {
					return (
						<span className="track-properties-tab-empty">
							{intl.formatMessage({
								id: 'app.map.trackinspector.nodata'
							})}
						</span>
					);
				}
			};

			return (
				<div
					id="track-inspector-ctn"
					key={'track-inspector-' + selectedTracks[0].id}
					className="track-inspector-ctn"
				>
					<div
						id="track-properties-ctn"
						className="track-properties-ctn"
						key={'track-properties-' + selectedTracks[0].id}
					>
						<Tabs
							className="track-inspector-tabs"
							selectedTabClassName="tab-selected"
							selectedIndex={currentTab}
							onSelect={(index) => {
								setCurrentTab(index);
								props.downloadMediaRequestOff();
							}}
						>
							<TabPanel className="track-inspector-tab-panel">
								<ul
									id="track-properties-list"
									className={classNames('track-properties', {
										'track-properties-tiles': true
									})}
								>
									{trackData('general')}
								</ul>
							</TabPanel>
							<TabPanel className="track-inspector-tab-panel">
								{trackData('identification')}
							</TabPanel>
							{selectedTracks[0].collectionId !=="nav" && (
							<TabPanel className="track-inspector-tab-panel">
								<MediaRedirect
									mediaList={
										selectedTracks[0].associatedMedia
									}
								/>
							</TabPanel>
							)}
							<TabPanel className="track-inspector-tab-panel">
								<ul
									id="track-identification-list"
									className={classNames('track-properties', {
										'track-properties-tiles': true
									})}
								>
									{trackData('comments')}
								</ul>
							</TabPanel>
							<TabPanel className="track-inspector-tab-panel">
								<ul
									id="track-identification-list"
									className={classNames('track-properties', {
										'track-properties-tiles': true
									})}
								>
									{selectedTracks[0].trackSpecies.length !==
										0 && (
										<li
											id="track-identification-species-header"
											className="track-properties-list-item-header"
										>
											{intl.formatMessage({
												id: 'app.map.trackspecies.work'
											})}{' '}
											/{' '}
											{intl.formatMessage({
												id:
													'app.map.trackspecies.species'
											})}
											:
										</li>
									)}
									{trackData('species')}
								</ul>
							</TabPanel>
							
							<TabList className="track-inspector-tab-list">
							<div id="track-inspector-tabs-before"></div>
								<Tab
									id={'track-properties'}
									className="track-inspector-tab"
								>
									{trackName(selectedTracks[0])}
								</Tab>
								<Tab
									id={'track-identification'}
									className="track-inspector-tab"
								>
									{intl.formatMessage({
										id:
											'app.map.inspector.identification.button'
									})}
								</Tab>
								{selectedTracks[0].collectionId !=="nav" && (
									<Tab
										id={'track-media'}
										className="track-inspector-tab"
									>
										{intl.formatMessage({
											id: 'app.map.sidebar.media.button'
										})}
									</Tab>
								 )}
								<Tab
									id={'track-comments'}
									className="track-inspector-tab"
								>
									{intl.formatMessage({
										id: 'app.map.inspector.comments.button'
									})}
								</Tab> 
								<Tab
									id={'track-species'}
									className="track-inspector-tab"
								>
									{intl.formatMessage({
										id: 'app.map.inspector.species.button'
									})}
									
								</Tab>
								<div id="track-inspector-tabs-after"></div>
								
							</TabList>
							
						</Tabs>
						
					</div>
					
				</div>
				
			);
		} else {
			return <div />;
		}
	};
	return (
		<div className="track-inspector">
			<SelectedTrack />
		</div>
	);
}
