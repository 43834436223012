import moment from 'moment';

export const dateTimeService = {
    currentMoment,
    formatDateTimefromEpochOffset,
    computeAndFormatDuration,
    computeDuration,
    formatDuration
};

const missingTime = '---';
const minutesPerHour = 60;

function currentMoment() {
    return moment().utc().valueOf();
}

function formatDateTimefromEpochOffset(epochOffset) {
    return (epochOffset || 0) > 0
        ? moment(epochOffset).utc().format(process.env.REACT_APP_DATE_TIME_FORMAT)
        : missingTime;
}

function computeAndFormatDuration(startEpochOffset, endEpochOffset, intl, formatWithHours = null, formatWithoutHours = null) {
    const duration = computeDuration(startEpochOffset, endEpochOffset);

    return formatDuration(duration, intl, formatWithHours, formatWithoutHours);
}

function computeDuration(startEpochOffset, endEpochOffset) {
    const startTime = (startEpochOffset || 0);
    const endTime = (endEpochOffset || 0);

    if (startTime <= 0 || endTime <= 0) {
        return {};
    }

    if (endTime <= startTime) {
        return {
            hours: 0,
            minutes: 0
        };
    }

    const duration = moment.duration(moment(endTime).diff(moment(startTime)));

    return {
        hours: Math.trunc(duration.asHours()),
        minutes: Math.trunc(duration.asMinutes() % minutesPerHour)
    };
}

function formatDuration(duration, intl, formatWithHours = null, formatWithoutHours = null) {
    if ((duration || {}).minutes === undefined) {
        return missingTime;
    }

    return duration.hours > 0
        ? intl.formatMessage(
            { id: formatWithHours || 'app.common.format.duration.with.hours' },
            { ...duration }
        )
        : intl.formatMessage(
            { id: formatWithoutHours || 'app.common.format.duration.without.hours' },
            { minutes: duration.minutes }
        );
}
