
export function createAndDownloadCSVFromObj(obj, options) {
    const fileName = options.fileName || "default-csv-name";
    const columnOptions = options.columns;
    let columnHeaderOrder = [];
    let csvRowData = [];
    let rowData = [];
    Object.keys(columnOptions).forEach(key => {
        if(columnOptions[key].include === undefined || columnOptions[key].include) {
            columnHeaderOrder.push(key);
            rowData.push(columnOptions[key].header);
        }
    });
    csvRowData.push(rowData.join(","));
    rowData = [];
    let rowCounter = 0;
    let outOfData = false;
    while(!outOfData) {
        columnHeaderOrder.forEach(headerKey => {
            let nextValue = obj[headerKey][rowCounter];
            if(nextValue === undefined)
                outOfData = true;
            else if(columnOptions[headerKey].mapper !== undefined)
                nextValue = columnOptions[headerKey].mapper(nextValue);
            rowData.push(nextValue);
        });
        csvRowData.push(rowData.join(","));
        rowData = [];
        rowCounter++;
    }
    let csvRaw = csvRowData.join("\n");
    const fileBlob = new Blob([csvRaw], {type: "text/csv"});
    const aElement = document.createElement('a');
    aElement.style.display = 'none';
    document.body.appendChild(aElement);
    aElement.href = window.URL.createObjectURL(fileBlob);
    aElement.setAttribute('download', fileName+".csv");
    aElement.click();
    window.URL.revokeObjectURL(aElement.href);
    document.body.removeChild(aElement);
}

export function createAndDownloadCSVFromObjList(objList, options) {
    const fileName = options.fileName || "default-csv-name";
    const columnOptions = options.columns;
    let columnHeaderOrder = [];
    let csvRowData = [];
    let rowData = [];   
    Object.keys(columnOptions).forEach(key => {
        if(columnOptions[key].include === undefined || columnOptions[key].include) {
            columnHeaderOrder.push(key);
            rowData.push(columnOptions[key].header);
        }
    });
    csvRowData.push(rowData.join(","));
    rowData = [];
    let rowCounter = 0;
    let outOfData = false;
    while(!outOfData) {
        columnHeaderOrder.forEach(headerKey => {
            let nextValue = objList[rowCounter][headerKey];
            if(columnOptions[headerKey].mapper !== undefined)
                nextValue = columnOptions[headerKey].mapper(nextValue);
            rowData.push(nextValue);
        })
        csvRowData.push(rowData.join(","));
        rowData = [];
        rowCounter++;
        if(rowCounter >= objList.length)
            outOfData = true;
    }
    let csvRaw = "\ufeff" + csvRowData.join("\n");
    const fileBlob = new Blob([csvRaw], {type: "text/csv; charset=utf-8"});
    const aElement = document.createElement('a');
    aElement.style.display = 'none';
    document.body.appendChild(aElement);
    aElement.href = window.URL.createObjectURL(fileBlob);
    aElement.setAttribute('download', fileName+".csv");
    aElement.click();
    window.URL.revokeObjectURL(aElement.href);
    document.body.removeChild(aElement);
}