// External Imports
import React, { useState, useEffect, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';

//internal imports
import { mediaService } from '../../_services/media.service.js';
import MediaItem from '../Media/MediaItem';
import { historicalService } from '../../_services/historical.service.js';
import { cleanKeys, hydrateData } from '../../_helpers/data-cleanup';
import Paginator from '../Paginator.jsx';
import { downloadMedia, isVideoMedia } from './../../_helpers/media.js';
import ProgressSpinner from './../ProgressSpinner'

//Assets
import './global-media.scss';
import loadingImage from '../../assets/images/sis-loading.gif';
import mediaUnknown from '../../assets/images/sis-media-thumbnail_unknown.png';
import { ReactComponent as DownloadMedia } from '../../assets/icons/icon_media-download-media.svg';
import { alertActions } from '../../_modules/alerts/alert.actions.js';
import MP4VideoPlayer from '../Video/MP4VideoPlayer.jsx';

function GlobalMedia() {
	const [savedState, setSavedState] = useState(
		sessionStorage.getItem('state:media') 
		? 
		JSON.parse(sessionStorage.getItem('state:media'))
		: {
				startDate: (new Date(Date.now() - 12096e5)).toJSON(),
				endDate: (new Date()).toJSON(),
				filters: [],
				media: [],
				selectedMedia: [],
				mediaMissionLookup: {},
				mediaTrackLookup: {},
				filterInputText: '',
				layout: 'grid'
		  });	
	const [pageItemCount, setPageItemCount] = useState(0);
	const [filterInputText, setFilterInputText] = useState(savedState.filterInputText);
	const [startDate, setStartDate] = useState(new Date(savedState.startDate));
	const [endDate, setEndDate] = useState(new Date(savedState.endDate));
	const [layout, setLayout] = useState(savedState.layout);
	const [filters, setFilters] = useState(savedState.filters);
	const [loading, setLoading] = useState(false);
	const [loadingDown, setLoadingDown] = useState(false);
	const [media, setMedia] = useState(savedState.media);
	const [filteredMedia, setFilteredMedia] = useState(getSortedFilteredMedia());
	const [mediaTrackLookup, setMediaTrackLookup] = useState(savedState.mediaTrackLookup);
	const [mediaMissionLookup, setMediaMissionLookup] = useState(savedState.mediaMissionLookup);
	const [displayCount, setDisplayCount] = useState(0);
	const [pageIndex, setPageIndex] = useState(0);
	const [selectedMedia, setSelectedMedia] = useState(savedState.selectedMedia);
	const currentPage = React.createRef([]);
	const intl = useIntl();
	const locale = localStorage.getItem('sisLang') || 'en';
	const dispatch = useDispatch();
	
	mediaService.setMode('historical');
	
	useEffect(() => {
		const newState = {
			...savedState,
			filters: filters,
			endDate: endDate,
			startDate: startDate,
			filterInputText: filterInputText,
			media: media,
			selectedMedia: selectedMedia,
			mediaTrackLookup: mediaTrackLookup,
			mediaMissionLookup: mediaMissionLookup,
			layout: layout
		};
		sessionStorage.setItem('state:media', JSON.stringify(newState));
		setSavedState(newState);
	}, [filters, endDate, startDate, filterInputText, layout, selectedMedia, loading, locale]);
	window.onresize = () => setPageItemCount(getFullPageCount())
	useEffect(() => {
		async function setMediaArray() {
			setFilteredMedia(getSortedFilteredMedia());
		}
		setMediaArray().then(() => setPageItemCount(getFullPageCount()));
	}, [media, filters]);

	function search(event) {
		event.preventDefault();
		event.stopPropagation();
		if(!loading) {
			setLoading(true);
			setSelectedMedia([]);
			setMedia([]);
			setMediaTrackLookup({});
			setMediaMissionLookup({});
			historicalService
				.searchMissions({
					searchText: '',
					startDate: prepareDateForSearch(startDate),
					endDate: prepareDateForSearch(endDate),
					coords: ''
				})
				.then((missions) => {
					if(missions.length == 0) {
						setLoading(false);
						return;
					}
					let missionIds = missions.map(mission => mission.Id);
					let mediaLinkPromises = missionIds.map(id => historicalService.getHistoricalMediaLinks(id));
					let mediaTrackMap = {};
					let mediaMissionMap = {};
					Promise.all(mediaLinkPromises)
						.then((mediaLinks) => {
							let mediaIds = [];
							mediaLinks.forEach(mediaObjArr => 
								mediaObjArr.forEach(mediaObj => {
									mediaTrackMap[mediaObj.MediaId] = mediaObj.SisTargetId;
									mediaMissionMap[mediaObj.MediaId] = mediaObj.MissionId;
									return mediaIds.push(mediaObj.MediaId);
								})
							);
							let mediaPromises = mediaIds.map(mediaId => mediaService.getDetails(mediaId).catch(() => null));
							return Promise.all(mediaPromises)
								.then((resMedia) => {
									const noNullRes = resMedia.filter(mediaDetails => mediaDetails !== null);
									setMedia(
										noNullRes.map((details, index) => ({
											id: details._id,
											file: details.fileInfo.originalFileName,
											size: details.fileInfo.fileSizeBytes,
											type: details.fileInfo.mimeType,
											createTime: new Date(details.insertDate),
											thumb: null,
											listIndex: index
										}))
									);
									setMediaTrackLookup(mediaTrackMap);
									setMediaMissionLookup(mediaMissionMap);
									setLoading(false);
								});
						})
						
				})
		}
	}

	function shouldFilterMedia(m) {
		if(filters.length === 0) return true;
		const name = m.file.toLowerCase();
		const lowerCaseFilters = filters.map(filter => filter.toLowerCase());
		const nameContainsFilterArr = lowerCaseFilters.map(filter => name.includes(filter)).filter(res => res);
		return filters.length === nameContainsFilterArr.length;
	}

	function getSortedFilteredMedia() {
		const filtered = media.filter(shouldFilterMedia);
		return filtered.sort((a, b) => {
			const timeA = a.createTime;
			const timeB = b.createTime;
			return timeA < timeB ? -1 : timeA > timeB ? 1 : 0;
		});
	}

	function handleStartDateChange(date) { setStartDate(date === null ? new Date(Date.now() - 12096e5) : date); }
	function handleEndDateChange(date) {  setEndDate(date === null ? new Date() : date); }
	function handleFilterChange(event) {
		setFilterInputText(String(event.target.value).trimLeft())
	}
	function handleAddFilter(event) { 
		event.preventDefault();
		event.stopPropagation();
		if(filterInputText === '')
			return;
		if(filters.length < 5)
			setFilters([...filters, filterInputText]);
		else {
			const filterWarning = {
				level: 'warning',
				messageId: 'app.notification.media.warning.filter.limit',
				timeStamp: new Date()
			};
			dispatch(alertActions.addAlert(filterWarning));
		}
	}
	function handleRemoveFilter(event) {
		event.preventDefault();
		event.stopPropagation();
		let filterList = filters;
		filterList.splice(event.target.id.match(/\d+/g), 1);
		if(filterList.length == 0)
			setFilterInputText('');
		setFilters([...filterList]);
	}
	function prepareDateForSearch(date) {
		let d = new Date(date);
		let month = '' + (d.getMonth() + 1);
		let day = '' + d.getDate();
		let year = d.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	}
	function handleMediaSelect(id) {
		// handle preview
		Promise.all([mediaService.getDetails(id),  
			mediaService.getLargerThumbnail(id)
				.then((res) => {
					if (res.status === 200) {
						return res.body;
					} else return null;
				})
				.then((stream) => new Response(stream))
				.then((res) => res.blob())
				.then((blob) => {
					if (blob.size !== 0) {
						const fileBlob = new Blob([blob], {
							type: selectedMedia.type
						});
						const url = window.URL.createObjectURL(fileBlob);
						return url;
					} else return null;
				})
		])
			.then((resArr) => {
				const previewImage = resArr[1] || mediaUnknown;
				const res = resArr[0];
				let selected = {
					id,
					mimeType: res.fileInfo.mimeType,
					fileName: res.fileInfo.originalFileName,
					missionName: res.fileInfo.folderName,
					trackId: mediaTrackLookup[id],
					createTime: res.insertDate,
					preview: previewImage
				};
				
				const mediaId = selected.id;
				const missionId = mediaMissionLookup[mediaId];
				const trackId = mediaTrackLookup[mediaId];	
				let tracks = [
					historicalService.getNavByMissionId(missionId).catch(() => null),
					historicalService.getAISByMissionId(missionId).catch(() => null),
					historicalService.getRadarByMissionId(missionId).catch(() => null),
					historicalService.getManualByMissionId(missionId).catch(() => null),
				];
				Promise.all(tracks)
					.then((res) => {
						const noNullRes = res.filter(resItem => resItem !== null);
						noNullRes.forEach((collection) => {
							const cleanCollection = collection.map(cleanKeys);
							for(let track of cleanCollection) {
								if(trackId === track.id) {
									track = [track].map(hydrateData)[0];
									selected.cid = track.cid;
									selected.latitude = track.latitude;
									selected.longitude = track.longitude;
									selected.vesselName = track.vesselName;
									selected.vesselCountry = track.vesselCountry;
									selected.vesselCallsign = track.vesselCallsign;
									selected.surfaceType = track.surfaceType;
									setSelectedMedia([selected]);
									break;
								}
							}
						})
					});
			});
			setPageItemCount(getFullPageCount())
	}
	function isMediaSelected(id) {
		if(selectedMedia.length > 0)
			return selectedMedia.filter(sMedia => sMedia.id === id).length > 0;
		return false;
	}

	function onFirstPage() {
		setPageIndex(0);
	}

	function onNextPage() {
		setPageIndex(pageIndex + 1);
	}

	function onPrevPage() {
		setPageIndex(pageIndex - 1);
	}

	function onLastPage() {
		setPageIndex(Math.trunc(filteredMedia.length / pageItemCount));
	}

	function setPageCount(c) {
		setDisplayCount(c)
	}

	function getFullPageCount() {
		const mW = 79;
		const mH = 140;
		const mediaImagesElement = document.getElementById("media-images-ctn");
		if(mediaImagesElement === null)
			return 0;
		let W = mediaImagesElement.clientWidth;
		let H = mediaImagesElement.clientHeight;
		let xC = 0, yC = 0;
		while(H - mH > 0) { 
			while(W - mW > 0) {
				W -= mW;
				xC++;
			}
			H -= mH;
			yC++;
		}
		return xC * yC;
	}

	const page = (
		currentPageNumber,
		totalPages,
		pageData,
		firstPage,
		lastPage,
		nextPage,
		prevPage,
		goToPage
	) => {
		if (!pageData) pageData = [];

		if (totalPages <= 1 && currentPageNumber != 1) firstPage();
		
		setPageCount(pageData.length)
		
		return (<>
			<div id="media-images-ctn" className="media-images">
				<div className="media-content">
				<ul
					id="media-list"
					className={
						layout === 'list'
							? 'media-list-view-global-media'
							: 'media-tile-view-global-media'
					}
				>
				{totalPages >= 1 && (
					pageData.map((mediaData) => (
						<MediaItem
							idx={mediaData.listIndex}
							key={mediaData.listIndex}
							media={mediaData}
							layout={layout}
							selectMedia={handleMediaSelect}
							isSelected={isMediaSelected(mediaData.id)}
						/>
					))
				)}
				</ul>
				</div>
			</div>
				
				{totalPages > 1 && (
					<div className="pagination-controls-container">
					<div className="pagination-btn-container">
						<div className="pagination-btn-left">
							<button
								className="btn-first"
								id={'media-results-first'}
								title={intl.formatMessage({
									id: 'app.pagination.first.button.tooltip'
								})}
								disabled={currentPageNumber === 1}
								onClick={() => {
									onFirstPage();
									firstPage();
									currentPage.current.value = 1;
								}}
							>
								{intl.formatMessage({
									id: 'app.pagination.first.button'
								})}
							</button>
							<button
								className="btn-prev"
								id={'media-results-prev'}
								title={intl.formatMessage({
									id: 'app.pagination.previous.button.tooltip'
								})}
								disabled={currentPageNumber === 1}
								onClick={() => {
									onPrevPage();
									prevPage();
									currentPage.current.value =
										currentPageNumber - 1;
								}}
							></button>
						</div>
						<input
							type="text"
							id={'media-page-input'}
							ref={currentPage}
							defaultValue={1}
							onChange={(e) => {
								let cleanInput = e.target.value;
								cleanInput = cleanInput.match(/\d*/g)[0];
								cleanInput = cleanInput.length
									? parseInt(cleanInput)
									: cleanInput;
								e.target.value = cleanInput;
								cleanInput =
									cleanInput <= totalPages
										? cleanInput
										: currentPageNumber;
								if (cleanInput) goToPage(cleanInput);
							}}
						/>
						<span>
							<FormattedMessage
								id="app.media.count.of"
								defaultMessage="of"
							/>{' '}
							{totalPages}
						</span>
						<div className="pagination-btn-right">
							<button
								className="btn-next"
								id={'media-results-next'}
								disabled={totalPages === currentPageNumber}
								title={intl.formatMessage({
									id: 'app.pagination.next.button.tooltip'
								})}
								onClick={() => {
									onNextPage();
									nextPage();
									currentPage.current.value =
										currentPageNumber + 1;
								}}
							></button>
							<button
								onClick={() => {
									onLastPage();
									lastPage();
									currentPage.current.value = totalPages;
								}}
								id={'media-results-last'}
								className="btn-last"
								title={intl.formatMessage({
									id: 'app.pagination.last.button.tooltip'
								})}
								disabled={totalPages === currentPageNumber}
							>
								{intl.formatMessage({
									id: 'app.pagination.last.button'
								})}
							</button>
						</div>
					</div>
					</div>
				)}
			</>);
	};

	const FilterList = (props) => {
		return (
			<div className="filter-keyword-list">
				{props.keywords.map((item, key) => {
					return (
						<div
							className="keyword-container"
							id={'keyword-' + key}
							key={key}
						>
							{item}
							<div
								onClick={handleRemoveFilter}
								id={'remove-keyword-' + key}
							></div>
						</div>
					);
				})}
			</div>
		);
	};

	const ViewToggle = (props) => {
		return (
			<div className="layout-switch">
				<button
					value="list"
					id="media-view-list"
					className={props.layout === 'list' ? 'selected' : ''}
					onClick={(e) => {
						props.setLayout(e.target.value);
					}}
				>
					<FormattedMessage
						id="app.media.view.list"
						defaultMessage="List"
					/>
				</button>
				<button
					value="grid"
					id="media-view-grid"
					className={props.layout === 'grid' ? 'selected' : ''}
					onClick={(e) => {
						props.setLayout(e.target.value);
					}}
				>
					<FormattedMessage
						id="app.media.view.tiles"
						defaultMessage="Tiles"
					/>
				</button>
			</div>
		);
	};

	const MediaPreview = (props) => {
		switch(props.media.mimeType) {
			case "video/mp4":
				return (<MP4VideoPlayer source={props.media} />);
			default:
				return (<img src={props.media.preview} />);
		}
		
	}

	const PreviewPanel = (props) => {
		return (
			<div className="preview-content">
				<MediaPreview media={props.media}/>	
				<p className="preview-image-name">{props.media.fileName}</p>
				<div>
					<div>
						<span>
							<FormattedMessage
								id="app.media.preview.missionNumber"
								defaultMessage="Mission: "
							/>
						</span>
						:<span>{props.media.missionName}</span>
					</div>
					<div>
						<span>
							<FormattedMessage
								id="app.media.preview.cid"
								defaultMessage="CID: "
							/>
						</span>
						:<span>{props.media.cid}</span>
					</div>
					<div>
						<span>
							<FormattedMessage
								id="app.media.preview.MediaLinkCreationTimestamp"
								defaultMessage="Create Time: "
							/>
						</span>
						:<span>{props.media.createTime}</span>
					</div>
					<div>
						<span>
							<FormattedMessage
								id="app.media.preview.latitude"
								defaultMessage="Latitude: "
							/>
						</span>
						:<span>{props.media.latitude}</span>
					</div>
					<div>
						<span>
							<FormattedMessage
								id="app.media.preview.longitude"
								defaultMessage="Longitude: "
							/>
						</span>
						:<span>{props.media.longitude}</span>
					</div>
					<div>
						<span>
							<FormattedMessage
								id="app.media.preview.vesselName"
								defaultMessage="Vessel Name: "
							/>
						</span>
						:<span>{props.media.vesselName}</span>
					</div>
					<div>
						<span>
							<FormattedMessage
								id="app.media.preview.vesselCallsign"
								defaultMessage="Callsign: "
							/>
						</span>
						:<span>{props.media.vesselCallsign}</span>
					</div>
					<div>
						<span>
							<FormattedMessage
								id="app.media.preview.vesselNationality"
								defaultMessage="Nationality: "
							/>
						</span>
						:<span>{props.media.vesselNationality}</span>
					</div>
					<div>
						<span>
							<FormattedMessage
								id="app.media.preview.hostility"
								defaultMessage="Hostility: "
							/>
						</span>
						:<span>{props.media.hostility}</span>
					</div>
					<div>
						<span>
							<FormattedMessage
								id="app.media.preview.surfaceType"
								defaultMessage="Surface"
							/>
						</span>
						:<span>{props.media.surfaceType}</span>
					</div>
					<div>
						<span>
							<FormattedMessage
								id="app.media.preview.mmsi"
								defaultMessage="MMSI: "
							/>
						</span>
						:<span>{}</span>
					</div>
				</div>
				<div className="preview-media-actions"> 
					{loadingDown ? <ProgressSpinner /> : null}
					<button
						className="global-media-download-button"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setLoadingDown(true)
							downloadMedia({
								id: selectedMedia[0].id,
								fileName: selectedMedia[0].fileName,
								mimeType: selectedMedia[0].mimeType
							})
							.then(() => setLoadingDown(false));
						}}
						disabled={
							selectedMedia.MediaId === null ? 'disabled' : false
						}
					>
						<DownloadMedia
							title={intl.formatMessage({
								id:
									'app.map.trackinspector.media.download.title',
								defaultMessage: 'download media'
							})}
							className="media-icon"
						/>
					</button>
				</div>
			</div>
		);
	};

	return (
	<div className="global-media-container">
	<div className="media-browser">
		<div className="media-search-bar">
			<form
				id="media-search-form"
				name="searchForm"
				onSubmit={search}
			>
				<div className="search-container">
					<div className="search-date-container">
						<label className="search-date-label">
							<FormattedMessage
								id="app.map.historical.search.date.label"
								defaultMessage="Date Range"
							/>
						</label>
						<div className="search-date-selector">
							<div className="search-date-from-container">
								<div className="search-date-from">
									<DatePicker
										name="startDate"
										selected={startDate}
										onChange={handleStartDateChange}
										maxDate={endDate}
										locale={locale}
									/>
								</div>
							</div>
							<div className="search-date-to-container">
								<div className="search-date-to-label">
									<FormattedMessage
										id="app.media.search.date.to.label"
										defaultMessage="To"
									/>
								</div>
								<div className="search-date-to">
									<DatePicker
										name="endDate"
										selected={endDate}
										onChange={handleEndDateChange}
										maxDate={new Date()}
										locale={locale}
									/>
								</div>
							</div>
							<input
								id="media-search-default-btn"
								type="submit"
								name="media-search-submit"
								className="search-btn"
								value={intl.formatMessage({
									id: 'app.media.search.submit.button',
									defaultMessage: 'Default Search'
								})}
							/>
						</div>
					</div>
					<div style={{"visibility": loading?"visible":"hidden", "padding-top": "31px"}}><ProgressSpinner/></div>
				</div>
			</form>
			<div className="filter-keyword-container">
				<form
					id="filter-search-form"
					name="filterForm"
					onSubmit={handleAddFilter}
					className="filter-search-form"
				>
					<label
						htmlFor="nameSearch"
						className="search-name-label"
					>
						<FormattedMessage
							id="app.media.filter.search.label"
							defaultMessage="Mission Name"
						/>
					</label>
					<div className="filter-input">
						<input
							id="filter-search-keyword"
							type="text"
							name="searchName"
							value={filterInputText}
							onChange={handleFilterChange}
							className="filter-input"
							autoComplete="off"
						/>
						<input
							id="media-filter-default-btn"
							type="submit"
							name="media-filter-submit"
							className="filter-btn"
							value={intl.formatMessage({
								id: 'app.media.filter.submit.button',
								defaultMessage: 'Add'
							})}
						/>
						<FilterList keywords={filters} />
					</div>
				</form>
			</div>
		</div>

		<div className="media-image-browser">
			<div className="media-image-header">
				<ViewToggle layout={layout} setLayout={setLayout} />
				<div className="media-display-info">
					<span
						>
						<FormattedMessage
							id="app.media.count.displaying"
							defaultMessage="Displaying"
						/>{' '}
						{filteredMedia.length !== 0 ? displayCount.toLocaleString() : "0"}{' '}
						<FormattedMessage
							id="app.media.count.of"
							defaultMessage="of"
						/>{' '}
						{filteredMedia.length.toLocaleString()}

					</span>
					<span>
						<FormattedMessage
							id="app.media.sort.time.ascending"
							defaultMessage="Sorted Ascending by Date, Time"
						/>
					</span>
				</div>
			</div>
			{(!loading && filteredMedia.length === 0 && (
				<div className={`media-error`}>
					{intl.formatMessage({
						id: 'app.media.nomedia'
					})}
				</div>
			)) ||
				(media.length >=0 && (
						<Paginator
							data={filteredMedia}
							itemsPerPage={pageItemCount}
						>
							{page}
						</Paginator>
				))}

		</div>
	</div>
	<div
		className="media-preview-pane"
		style={{
			display: selectedMedia.length > 0
				? 'block'
				: 'none'
		}}
	>
		{selectedMedia.length > 0 && <PreviewPanel media={selectedMedia[0]} />}
	</div>
</div>
);
}

export { GlobalMedia };
