// Use the first row of existing dataset and schema to set localization and if a field should be shown in grid
export const dataColumns = (data, schema) => {
	return fieldList(sourceColumns(data[0]), schema);
};

export const gridColumns = (schema, fields) => {
	let headers = [];
	for (let i = 0; i < fields.length; i++) {
		// if field exists in data, find it in the schema
		let schemaIndex = getIndex(schema, fields[i]);
		if (schemaIndex !== -1) {
			headers.push({
				headerName: schema[schemaIndex].label,
				field: schema[schemaIndex].field
			});
		}
	}
	return headers;
};

// Use schema to set localization and if a field should be shown in inspector
export const convertKeys = (data, schema, fields) => {
	let newData = {};
	for (let i = 0; i < fields.length; i++) {
		// check list of fields (in the order they're displayed) for matching data
		let dataIndex = Object.keys(data).indexOf(fields[i]);
		if (dataIndex !== -1) {
			// if field exists in data, find it in the schema
			let schemaIndex = getIndex(schema, Object.keys(data)[dataIndex]);
			// if exists in schema, return schema as part of new data array
			if (schemaIndex !== -1) {
				newData[fields[i]] = {
					label: schema[schemaIndex].label,
					value: Object.values(data)[dataIndex],
					style: schema[schemaIndex].style
				};
			}
		}
	}
	return newData;
};

const sourceColumns = (data) => {
	let keys = [];
	for (var k in data) keys.push(k);
	return keys;
};

const getIndex = (data, id) => {
	for (var i = 0; i < data.length; i++) {
		if (data[i].field === id) {
			return i;
		}
	}
	return -1;
};

// Filter the fields based on schema and create new headers with localization codes
const fieldList = (keys, schema) => {
	let headers = [];
	for (var k in keys) {
		let index = getIndex(schema, keys[k]);
		if (index !== -1 && schema[index].grid === true) {
			headers.push({
				headerName: schema[index].label,
				field: schema[index].field
			});
		}
	}
	return headers;
};
