// # Imports
// External Imports
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useAuth0 } from "@auth0/auth0-react";

// Internal Imports
import { authActions } from '../../_modules/auth/auth.actions';

// Assets
import './login.scss';
import { ReactComponent as DFOLogo } from '../../assets/images/DFO_Logo.svg';
import { ReactComponent as PALLogo } from '../../assets/images/pal-logo.svg';

function Login(props) {
	const intl = useIntl();
	const dispatch = useDispatch();
	const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

	useEffect(() => {
		if(isAuthenticated)
			logout();
		
		dispatch(authActions.destroyUserObj());

		if (window._converse) {
			window._converse.api.user.logout('logout');

			sessionStorage.removeItem('sisChatSid');
			sessionStorage.removeItem('sisChatRid');
			sessionStorage.removeItem('sisChatJid');

			document
				.getElementsByClassName('converse-chatboxes')[0]
				.classList.add('hidden');
		}
	}, []);

	return (
		<div id="login-ctn" className="login-container">
			<div id="login-panel" className="login-panel">
				<div id="login-pal-logo" className="login-pal-logo">
					<PALLogo />
				</div>
				<div id="login-protected-b-paragraph" className="login-protected-b-paragraph">
					{intl.formatMessage({
						id: "app.login.agreement.start"
					})}
					<span> </span>
					<span className="login-protected-b">{intl.formatMessage({
						id: "app.login.agreement.protected.b"
					})}</span>
					<span> </span>
					{intl.formatMessage({
						id: "app.login.agreement.end"
					})}
				</div>
				<button id="login-button" className="login-button" onClick={() => {
					loginWithRedirect({
						ui_locales: localStorage.getItem('sisLang') || 'en'
					});
				}}>{intl.formatMessage({
					id: "app.login.button"
				})}</button>
				<div id="login-sis-paragraph" className="login-sis-paragraph">
					{intl.formatMessage({ id: "app.login.sis.link.start" })}<span> </span>
					<a href="https://sis3.provair.com/">{intl.formatMessage({
						id: "app.login.sis.link.aTag"
					})}</a>
					.
				</div>
				<div id="login-dfo-logo" className="login-dfo-logo">
					<DFOLogo />
				</div>
				<div id="login-dfo-logo-img" className="login-dfo-logo-img">
					<img src="images/dfo-logo-img.jpg" />
				</div>
			</div>
		</div>	
	);
}

export { Login };
